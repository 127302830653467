import React, { useState } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import GroupIcon from "@material-ui/icons/Group";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

// Internal
import AssetGroupSelector from "./AssetGroupSelector";
import CustomTooltip from "@Components/CustomTooltip";
import AssetDevice from "./AssetDevice";

export default function AssetGroup(props) {
  const [expanded, setExpanded] = useState({});

  const groups = props.collection;
  const {
    selection,
    setSelection,
    disabledGroups,
    setDisabledGroups,
    indeterminateGroups,
    setIndeterminateGroups,
    mainGroupName,
  } = props;

  const Language = require("sccLanguage").default;

  let listIds = [];

  const handleToggle = (id) => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  function recursiveDisable(listIds, startGroup) {
    startGroup.groups.forEach((group) => {
      listIds.push(group.id);
      recursiveDisable(listIds, group);
    });
  }

  function updateSelection(e, group) {
    recursiveDisable(listIds, group);
    let lclSelection = [];
    let lclDisabledGroups = [];
    if (e.target.checked) {
      lclDisabledGroups = _.union(_.clone(disabledGroups), listIds);
      lclSelection = _.clone(selection).filter(
        (el) => !lclDisabledGroups.includes(el)
      );
      setDisabledGroups(lclDisabledGroups);
      setSelection(_.concat(lclSelection, [group.id]));
    } else {
      lclDisabledGroups = _.difference(_.clone(disabledGroups), listIds);
      lclSelection = _.clone(selection).filter(
        (el) => !lclDisabledGroups.includes(el)
      );
      setDisabledGroups(lclDisabledGroups);
      setSelection(_.difference(lclSelection, [group.id]));
    }
    listIds = [];
  }

  const useStyles = makeStyles((theme) => ({
    assetGroup: {
      width: "100%",
      margin: 0,
      padding: 0,
      "& .assetGroupRow": {
        height: 35,
        background: theme.palette.colors.gray.main,
        borderLeft: `1px solid ${theme.palette.colors.gray.main}`,
        width: "100%",
        marginTop: 1,
        "& .MuiIconButton-edgeStart": {
          marginLeft: 0,
        },
        "& .MuiListItemIcon-root": {
          minWidth: 20,
        },
        "& .assetGroupIcon": {
          marginTop: -5,
          marginLeft: 0,
        },
        "& .assetGroupIconNav": {
          marginTop: 2,
          marginLeft: -25,
          position: "absolute",
        },
        "& .assetGroupLabel": {
          padding: 10,
        },
      },
      "& .MuiTypography-body2": {
        fontSize: "0.9rem",
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {groups.map((group) => {
        return (
          <List key={group.id} className={classes.assetGroup}>
            <ListItem className="assetGroupRow" role={undefined} dense button>
              <React.Fragment>
                {group.title !== mainGroupName &&
                (group.groups.length > 0 || group.devices.length > 0) ? (
                  expanded[group.id] ? (
                    <KeyboardArrowDownIcon
                      onClick={() => handleToggle(group.id)}
                      className="assetGroupIconNav"
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      onClick={() => handleToggle(group.id)}
                      className="assetGroupIconNav"
                    />
                  )
                ) : null}
                <Checkbox
                  edge="start"
                  indeterminate={indeterminateGroups.indexOf(group.id) > -1}
                  checked={selection.indexOf(group.id) > -1}
                  color="primary"
                  onChange={(e) => updateSelection(e, group)}
                  disabled={disabledGroups.indexOf(group.id) > -1}
                  disableRipple
                  inputProps={{ "aria-labelledby": group.id }}
                />
              </React.Fragment>
              <CustomTooltip
                title={Language.translate("Group")}
                arrow
                placement="top"
              >
                <IconButton className="assetGroupIcon">
                  <GroupIcon />
                </IconButton>
              </CustomTooltip>
              <ListItemText
                className="assetGroupLabel"
                onClick={() => handleToggle(group.id)}
                id={`AssetGroup-${group.id}`}
              >
                {group.title === mainGroupName
                  ? Language.translate(mainGroupName)
                  : group.title}
                &nbsp;({group.devices.length})
              </ListItemText>
            </ListItem>
            {
              <Collapse
                key={`collapseGroup-${group.id}`}
                in={group.title === mainGroupName ? true : expanded[group.id]}
                timeout="auto"
                unmountOnExit
              >
                <AssetGroupSelector
                  collection={group.groups}
                  selection={selection}
                  setSelection={setSelection}
                  disabledGroups={disabledGroups}
                  setDisabledGroups={setDisabledGroups}
                  indeterminateGroups={indeterminateGroups}
                  setIndeterminateGroups={setIndeterminateGroups}
                  name={props.name}
                  mainGroupName={mainGroupName}
                />
                <AssetDevice
                  devices={group.devices}
                  editMode={false}
                  hideDevices={props.hideDevices}
                  devicesButtons={false}
                  name={props.name}
                />
              </Collapse>
            }
          </List>
        );
      })}
    </React.Fragment>
  );
}
