import React, { useContext } from "react";

// Material-UI
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import Language from "sccLanguage";
import { MinimizeContext } from "../../context/MinimizeContext";

const useStyles = makeStyles((theme) => ({
  deviceClusterButtons: {
    height: 26,
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },

  deviceDisplayButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.colors.gray.dark,
    width: 26,
    height: 26,

    "&:hover": {
      borderRadius: 0,
      backgroundColor: theme.palette.colors.gray.dark,
      "& $deviceDisplayIcon": {
        color: theme.palette.colors.black.main,
      },
    },
  },

  deviceDisplayIcon: {
    color: theme.palette.colors.blue.dark,
    fontSize: 16,
  },
}));

export default function ClusterDisplayButtonGroup({ devices, history }) {
  const { state } = useContext(MinimizeContext);

  const classes = useStyles();

  const handleZoom = () => {
    if (history === "1") {
      HistoryOverlay.centerAndZoomTo(devices);
      HistoryOverlay.hideClusterPopup();
    } else {
      DeviceOverlay.centerAndZoomTo(devices);
      DeviceOverlay.hideClusterPopup();
    }
  };

  const showButtonGroup = !state.minimize ? (
    <div className={classes.deviceClusterButtons}>
      <CustomTooltip
        title={Language.translate("Zoom In")}
        arrow
        placement="top"
      >
        <IconButton
          className={classes.deviceDisplayButton}
          onClick={handleZoom}
        >
          <GpsFixedIcon className={classes.deviceDisplayIcon} />
        </IconButton>
      </CustomTooltip>
    </div>
  ) : (
    ""
  );
  return <>{showButtonGroup}</>;
}
