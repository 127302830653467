import { default as Module } from "sccModule";
var Permission= require("sccPermission");
var log= require("loglevel");

/**
 * The base class for Nearest Responder
 * 
 * @class Nr
 */
class NrModule extends Module.Module{
	constructor(){
		var options= {
			moduleName: "nr"
		};

		super(options);
	}

	init(){
		if(!Permission.default.verify("nr", "view")){
			log.debug("User is not permitted for Nearest Responder module");
			return Promise.resolve();
		}
	
		return super.init();
	}
	
}

//module.exports= New NrModule();
export default new NrModule();