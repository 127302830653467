import { default as Module } from "sccModule";

/**
 * The base object for Profile module
 * 
 */
class ProfileModule extends Module.Module{
	constructor(){
		var options= {
			moduleName: "profile"
		};

		super(options);
	}

	onSocketUpdate(url, data){
		this.set(data);
	}
}

//module.exports= New ProfileModule();
export default new ProfileModule();
