import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;
const regexSpecialChar = /^[^<>\\|;:[\]{}=~`&'"%*^=#!@$()]+$/;

const schema = Yup.object().shape({
  name: baseSchema(Language).requiredStringMinMax,
  mode: baseSchema(Language).dropdownSelectNum,
  non_report_threshold: baseSchema(Language).dropdownSelectNum,
  color: baseSchema(Language).dropdownSelectStr,
  ip_address: Yup.mixed().when("mode", {
    is: 8,
    then: baseSchema(Language).ip_address,
  }),
  encryption_key: baseSchema(Language).encryption_key,
  decryption_key: baseSchema(Language).decryption_key,
  min_speed_displayed:
    baseSchema(Language).notRequiredMax8DigitsNumberWithMax2Decimals,
  max_speed_displayed: Yup.number().when("min_speed_displayed", {
    is: (num) => num === undefined || num === 0,
    then: baseSchema(Language).notRequiredMax8DigitsNumberWithMax2Decimals,
    otherwise: baseSchema(
      Language
    ).notRequiredMax8DigitsNumberWithMax2Decimals.moreThan(
      Yup.ref("min_speed_displayed"),
      Language.translate("Max speed should be more than min speed")
    ),
  }),
  annotation: baseSchema(Language).notRequiredStringMinMax,
  client_id: baseSchema(Language).requiredNumberPositive,
  groups: Yup.array(),
  vehicle: Yup.object().when("type", {
    is: "Hawkeye 5500",
    then: Yup.object({
      vehicle_identification_number: baseSchema(Language)
        .restrictedString.min(
          17,
          Language.translate("This field must be 17 characters long")
        )
        .max(17, Language.translate("This field must be 17 characters long"))
        .matches(
          /^[A-Z0-9]{17}$/,
          "This field allows only numbers and capital letters"
        )
        .nullable(true),
      vehicle_model: Yup.string()
        .typeError(Language.translate("This field is required"))
        .matches(
          regexSpecialChar,
          Language.translate("This field contains invalid characters")
        )
        .max(
          45,
          Language.translate(
            "This field length must be less than or equal to 45 characters long"
          )
        )
        .nullable(true),
      vehicle_manufacturer: Yup.string()
        .typeError(Language.translate("This field is required"))
        .matches(
          regexSpecialChar,
          Language.translate("This field contains invalid characters")
        )
        .max(
          45,
          Language.translate(
            "This field length must be less than or equal to 45 characters long"
          )
        )
        .nullable(true),
    }),
  }),
  newSettingsCode: Yup.mixed().when("type", {
    is: (val) => ["Whisper", "Wave"].indexOf(val) > -1,
    then: baseSchema(Language).notRequiredNewSettingsCode,
    otherwise: Yup.mixed(),
  }),
  settingsVal: Yup.object().when("type", {
    is: "Hawkeye 5500",
    then: Yup.object({
      device_reporting: baseSchema(Language).dropdownSelectStr,
      internal_speaker: baseSchema(Language).dropdownSelectBoolean,
      network_selection: baseSchema(Language).dropdownSelectStr,
      change_network: baseSchema(Language).requiredNumberPositive.max(
        255,
        Language.translate("This field must be less than or equal to 255")
      ),
      power_off: baseSchema(Language).requiredNumberPositive.max(
        65535,
        Language.translate("This field must be less than or equal to 65535")
      ),
      stationary_toggle: baseSchema(Language).dropdownSelectBoolean,
      report_interval: Yup.mixed().when("stationary_toggle", {
        is: true,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(
            15,
            Language.translate("This field must be greater than or equal to 15")
          )
          .max(
            4294967295,
            Language.translate(
              "This field must be less than or equal to 4294967295"
            )
          ),
        otherwise: Yup.mixed(), // No validation
      }),
      stopped_speed: Yup.mixed().when("stationary_toggle", {
        is: true,
        then: baseSchema(Language).requiredNumberPositiveInt.max(
          255,
          Language.translate("This field must be less than or equal to 255")
        ),
        otherwise: Yup.mixed(),
      }),
      time_stopped: Yup.mixed().when("stationary_toggle", {
        is: true,
        then: baseSchema(Language).requiredNumberPositiveInt.max(
          65535,
          Language.translate("This field must be less than or equal to 65535")
        ),
        otherwise: Yup.mixed(),
      }),
      moving_toggle: baseSchema(Language).dropdownSelectBoolean,
      moving_report_interval: Yup.mixed().when("moving_toggle", {
        is: true,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(
            15,
            Language.translate("This field must be greater than or equal to 15")
          )
          .max(
            4294967295,
            Language.translate(
              "This field must be less than or equal to 4294967295"
            )
          ),
        otherwise: Yup.mixed(),
      }),
      moving_speed: Yup.mixed().when("moving_toggle", {
        is: true,
        then: baseSchema(Language).requiredNumberPositiveInt.max(
          255,
          Language.translate("This field must be less than or equal to 255")
        ),
        otherwise: Yup.mixed(),
      }),
      report_turn: baseSchema(Language).dropdownSelectBoolean,
      emergency_report: baseSchema(Language)
        .requiredNumberPositiveInt.min(
          15,
          Language.translate("This field must be greater than or equal to 15")
        )
        .max(
          65535,
          Language.translate("This field must be less than or equal to 65535")
        ),
      speeding: Yup.boolean().nullable(true), //does not come from the backend. Leaving here for future reference
      speed_report_rate: Yup.mixed().when("speeding", {
        is: true,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(
            15,
            Language.translate("This field must be greater than or equal to 15")
          )
          .max(
            65535,
            Language.translate("This field must be less than or equal to 65535")
          ),
        otherwise: Yup.mixed(),
      }),
      time_over_speed: Yup.mixed().when("speeding", {
        is: true,
        then: baseSchema(Language).requiredNumberPositiveInt.max(
          65535,
          Language.translate("This field must be less than or equal to 65535")
        ),
        otherwise: Yup.mixed(),
      }),
      max_speed: Yup.mixed().when("speeding", {
        is: true,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(
            10,
            Language.translate("This field must be greater than or equal to 10")
          )
          .max(
            65535,
            Language.translate("This field must be less than or equal to 65535")
          ),
        otherwise: Yup.mixed(),
      }),
      first_movement: baseSchema(Language).dropdownSelectBoolean,
      show_idling_items: Yup.boolean().nullable(true), //does not come from the backend. Leaving here for future reference
      delay_idle: Yup.mixed().when("show_idling_items", {
        is: true,
        then: baseSchema(Language).requiredNumberPositiveInt.max(
          65535,
          Language.translate("This field must be less than or equal to 65535")
        ),
        otherwise: Yup.mixed(),
      }),
      engine_report_interval: Yup.mixed().when("show_idling_items", {
        is: true,
        then: baseSchema(Language).requiredNumberPositiveInt.max(
          65535,
          Language.translate("This field must be less than or equal to 65535")
        ),
        otherwise: Yup.mixed(),
      }),
      roll_over: baseSchema(Language).dropdownSelectBoolean,
      crash: baseSchema(Language).dropdownSelectBoolean,
    }),
    otherwise: Yup.object().nullable(),
  }),
});

export default schema;
