import React, { useState, useEffect, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";

// Internal
import CustomTooltip from "@Components/CustomTooltip";
import { HistoryContext } from "../../HistoryContext";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  playbarSettingsPopover: {
    "& .MuiPopover-paper": {
      minWidth: "250px",
      maxWidth: "300px",
      "& h4": {
        fontWeight: "bold",
        paddingLeft: "10px",
      },
      "& .playbarSettingsMenu li": {
        display: "block",
        width: "100%",
        float: "left",
        padding: "5px 0 0 10px",
        fontSize: "15px",
        borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
        "& .settingsMenuAlertName": {
          float: "left",
          "& .MuiBadge-badge": {
            float: "right",
            fontSize: "12px",
            fontWeight: "bold",
            right: "-20px",
          },
          "& .emergency": {
            borderLeft: `4px solid ${theme.palette.alerts.emergency.main}`,
            paddingLeft: "5px",
          },
          "& .geofence": {
            borderLeft: `4px solid ${theme.palette.alerts.geofence.main}`,
            paddingLeft: "5px",
          },
          "& .report": {
            borderLeft: `4px solid ${theme.palette.alerts.report.main}`,
            paddingLeft: "5px",
          },
          "& .speed": {
            borderLeft: `4px solid ${theme.palette.alerts.speed.main}`,
            paddingLeft: "5px",
          },
          "& .non_report": {
            borderLeft: `4px solid ${theme.palette.alerts.nonreport.main}`,
            paddingLeft: "5px",
          },
          "& .cargo": {
            borderLeft: `4px solid ${theme.palette.alerts.cargo.main}`,
            paddingLeft: "5px",
          },
          "& .vehicle": {
            borderLeft: `4px solid ${theme.palette.alerts.vehicle.main}`,
            paddingLeft: "5px",
          },
        },
        "& .switch": {
          float: "right",
        },
        "& div": {
          float: "right",
        },
      },
    },
  },
}));

export default function PlaybarSettings(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [state] = useContext(HistoryContext);
  const [speed, setSpeed] = useState(1);
  const [autoSkip, setAutoSkip] = useState(true);
  const setSliderTicks = state.setSliderTicks;
  const filteredEvents = state.filteredEvents;
  const alerts = [
    "emergency",
    "speed",
    "geofence",
    "cargo",
    "non_report",
    "report",
    "vehicle",
  ];
  const [alertEvents, setAlertEvents] = useState({
    emergency: true,
    speed: true,
    geofence: true,
    cargo: true,
    non_report: true,
    report: true,
    vehicle: true,
  });

  const [alertEventLength, setAlertEventLength] = useState({
    emergency: 0,
    speed: 0,
    geofence: 0,
    cargo: 0,
    non_report: 0,
    report: 0,
    vehicle: 0,
  });

  const {
    Historic,
    sliderChanged,
    updateKeysInState,
    showHistoryControls,
    capitalizeFirstLetter,
  } = state;

  function setSettingsValues() {
    setSpeed(Historic.playSpeed);
    setAutoSkip(Historic.playOptions.autoSkip);
    setAlertEvents({
      emergency: Historic.getEventsByType("emergency")
        ? Historic.getEventsByType("emergency").length > 0
        : false,
      speed: Historic.getEventsByType("speed")
        ? Historic.getEventsByType("speed").length > 0
        : false,
      geofence: Historic.getEventsByType("geofence")
        ? Historic.getEventsByType("geofence").length > 0
        : false,
      cargo: Historic.getEventsByType("cargo")
        ? Historic.getEventsByType("cargo").length > 0
        : false,
      non_report: Historic.getEventsByType("non_report")
        ? Historic.getEventsByType("non_report").length > 0
        : false,
      report: Historic.getEventsByType("report")
        ? Historic.getEventsByType("report").length > 0
        : false,
      vehicle: Historic.getEventsByType("vehicle")
        ? Historic.getEventsByType("vehicle").length > 0
        : false,
    });

    setAlertEventLength({
      emergency: Historic.getEventsByType("emergency")
        ? Historic.getEventsByType("emergency").length
        : 0,
      speed: Historic.getEventsByType("speed")
        ? Historic.getEventsByType("speed").length
        : 0,
      geofence: Historic.getEventsByType("geofence")
        ? Historic.getEventsByType("geofence").length
        : 0,
      cargo: Historic.getEventsByType("cargo")
        ? Historic.getEventsByType("cargo").length
        : 0,
      non_report: Historic.getEventsByType("non_report")
        ? Historic.getEventsByType("non_report").length
        : 0,
      report: Historic.getEventsByType("report")
        ? Historic.getEventsByType("report").length
        : 0,
      vehicle: Historic.getEventsByType("vehicle")
        ? Historic.getEventsByType("vehicle").length
        : 0,
    });
  }

  function showEventTypeChanged(alert) {
    const changedStatus = !Historic.playOptions.event[alert];
    Historic.playOptions.event[alert] = changedStatus;
    _.each(Historic.getEventsByType(alert), (event) => {
      event.show = changedStatus;
    });

    updateKeysInState(Historic);
    sliderChanged();
  }

  useEffect(() => {
    setSettingsValues();
  }, [showHistoryControls]); // eslint-disable-line

  const handleChangeSpeed = (e) => {
    setSpeed(e.target.value);
    Historic.playSpeed = e.target.value;
    updateKeysInState(Historic);
  };
  const handleChangeAutoSkip = (e) => {
    setAutoSkip(e.target.checked);
    Historic.playOptions.autoSkip = e.target.checked;
    updateKeysInState(Historic);
  };
  function handleChangeEvents(alert, e) {
    filteredEvents[alert] = e.target.checked;
    setAlertEvents((p) => {
      return Object.assign({}, p, { [alert]: e.target.checked });
    });

    setSliderTicks(filteredEvents);
    showEventTypeChanged(alert);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "playbarSettingsPopover" : undefined;

  const classes = useStyles();

  return (
    <div>
      <CustomTooltip title={Language.translate("Settings")} placement="top">
        <Button
          className="btn"
          aria-describedby={id}
          variant="contained"
          color="default"
          onClick={handleClick}
          startIcon={<SettingsIcon />}
        ></Button>
      </CustomTooltip>
      <Popover
        className={classes.playbarSettingsPopover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ top: "-30px" }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <h4>{Language.translate("Settings")}</h4>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <IconButton disableRipple={true} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <ul className="playbarSettingsMenu">
          <li>
            {Language.translate("Speed")}
            <Select
              value={speed}
              onChange={handleChangeSpeed}
              inputProps={{ "aria-label": "primary select" }}
            >
              <MenuItem value={0.5}>0.5x</MenuItem>
              <MenuItem value={0.75}>0.75x</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2x</MenuItem>
              <MenuItem value={4}>4x</MenuItem>
              <MenuItem value={8}>8x</MenuItem>
              <MenuItem value={16}>16x</MenuItem>
              <MenuItem value={32}>32x</MenuItem>
            </Select>
          </li>
          <li>
            {Language.translate("Auto-Skip")}
            <Switch
              className="switch"
              checked={autoSkip}
              onChange={handleChangeAutoSkip}
              size="small"
              color="primary"
              name="autoSkip"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </li>
          {alerts.map((value, i) => {
            return (
              <li
                key={i}
                style={{ opacity: alertEventLength[value] !== 0 ? 1 : 0.5 }}
              >
                <div className="settingsMenuAlertName">
                  <span className={`${value}`}>
                    {value === "non_report"
                      ? Language.translate("Non Report")
                      : value === "vehicle"
                      ? Language.translate("Vehicle Events")
                      : capitalizeFirstLetter(Language.translate(value))}
                  </span>
                  {alertEventLength[value] !== 0 ? (
                    <Badge
                      size="small"
                      color="primary"
                      badgeContent={alertEventLength[value]}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <Switch
                  className="switch"
                  checked={alertEvents[value]}
                  onChange={(e) => handleChangeEvents(value, e)}
                  disabled={alertEventLength[value] === 0}
                  size="small"
                  color="primary"
                  name={`${value}Event`}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </li>
            );
          })}
        </ul>
      </Popover>
    </div>
  );
}
