var Language = require("sccLanguage").default;
var Utils = require("sccUtils").default;
var GuiUtils = require("sccGuiUtils").default;
var $ = require("jquery");

/**
 * ExtendedMapMenu Class
 *
 * @class
 */
function ExtendedMapMenu() {}

$(document).ready(function () {
  ExtendedMapMenu.PageUrl = "extended_map.html";
  ExtendedMapMenu.PageLimit = 4;
});

/**
 * opens a new Extended Map Window
 */
ExtendedMapMenu.openWindow = function () {
  if (typeof ExtendedMapMenu.openWindow.PageCount == "undefined") {
    ExtendedMapMenu.openWindow.PageCount = 0;
    ExtendedMapMenu.openWindow.windowRefs = {};
  }

  for (var i = 1; i <= ExtendedMapMenu.PageLimit; ++i) {
    if (
      typeof ExtendedMapMenu.openWindow.windowRefs["Extended" + i] ==
        "undefined" ||
      ExtendedMapMenu.openWindow.windowRefs["Extended" + i].closed
    ) {
      ExtendedMapMenu.openWindow.windowRefs["Extended" + i] =
        GuiUtils.openPopupWindow(ExtendedMapMenu.PageUrl, "Extended" + i);
      return;
    }
  }

  Utils.notify(
    Language.translate("Extended Maps"),
    Language.translate("You have reached the maximum number of open pages"),
    null,
    "error"
  );
  // Utils.notify("You have reached the maximum number of open pages.");
  ExtendedMapMenu.openWindow.windowRefs["Extended1"].focus();
};

/**
 * closes the open Extended Map windows
 */
ExtendedMapMenu.closeWindow = function () {
  for (var i = 1; i <= ExtendedMapMenu.PageLimit; ++i) {
    var winRef = window.open("", "Extended" + i, "", true);
    if (winRef != null) {
      winRef.close();
    }
  }
};

export default ExtendedMapMenu;
