import React from "react";

// External
import _ from "lodash";

// Material-UI
import { Grid, makeStyles } from "@material-ui/core";

// Internal
import Poi from "sccPoi";
import POIDataDisplay from "../../DataDisplay";
import POIOverlay from "sccPoiOverlay";
import Images from "sccImage";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  poiIcon: {
    marginRight: 4,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  poiBody: {
    maxWidth: 750,
    marginTop: -12,

    "& ul": {
      maxHeight: 250,
      overflowY: "auto",
    },
  },

  clusterContainer: {
    padding: "0px 8px",
    cursor: "pointer",
    minHeight: "37px",
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.main,
    },
  },
}));

export default function ClusterList(props) {
  const pois = props.pois;

  function handleClick(id) {
    POIOverlay.deselectFeature();
    POIOverlay.hideClusterPopup();
    POIOverlay.selectFeatureById(id, false);
    //  update POI popup data
    POIDataDisplay.updateFeatureSelected();
    return true;
  }

  const classes = useStyles();

  return (
    <div className={classes.poiBody}>
      <ul className="clusterPOIListing">
        {!_.isEmpty(pois) ? (
          pois.map((data) => {
            const poiData = Poi.get(data);
            return (
              <React.Fragment key={poiData.id}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  className={classes.clusterContainer}
                  key={poiData.id}
                  onClick={() => handleClick(poiData.id)}
                >
                  <Grid item className={classes.poiIcon}>
                    {poiData.image_id == null ? (
                      <img
                        className="poi-marker-img"
                        name="image"
                        alt={poiData.title}
                        src={Poi.getNatoSymbol(poiData.nato_code)}
                        required
                      />
                    ) : (
                      <img
                        className="poi-marker-img-platform"
                        name="image"
                        alt={poiData.title}
                        src={Images.getImageSource(poiData.image_id)}
                      />
                    )}
                  </Grid>
                  <Grid item id={poiData.id}>
                    {poiData.title}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })
        ) : (
          <div align="center">{Language.translate("No Results!")}</div>
        )}
      </ul>
    </div>
  );
}
