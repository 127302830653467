import React, { useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles, InputAdornment, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SearchIcon from "@material-ui/icons/Search";

// Internal
import Device from "sccDevice";
import Language from "sccLanguage";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomTextField from "@Components/CustomTextField";
import CustomCheckbox from "@Components/CustomCheckbox";

const useStyles = makeStyles((theme) => ({
  poiDeviceListContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px",
    height: 260,
  },

  poiDeviceList: {
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },

  noDevice: {
    textAlign: "center",
    fontSize: 18,
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const POIDeviceList = ({ checkedDevices, allDevices, onDeviceClick }) => {
  const [filterText, setFilterText] = useState("");
  const [allDevicesInfo, setAllDevicesInfo] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const ATAKMODE = 9;

  const classes = useStyles();

  useEffect(() => {
    if (allDevices.length > 0) {
      let DevicesInfo = [];
      allDevices.forEach((deviceId) => {
        if (Device.get(deviceId)) {
          const deviceInfo = Device.get(deviceId);
          if (deviceInfo.mode !== ATAKMODE) {
            DevicesInfo.push(deviceInfo);
          }
        }
      });

      setAllDevicesInfo(DevicesInfo);
      setFilteredDevices(DevicesInfo);
    }
  }, []);

  //  use debounce to avoid too many re-renders
  const handleFilter = _.debounce((value) => {
    const filterText = value;
    if (filterText !== "") {
      const filteredDevices = allDevicesInfo.filter(
        (deviceInfo) =>
          deviceInfo.name.toLowerCase().includes(filterText.toLowerCase()) ||
          deviceInfo.imei.includes(filterText.toLowerCase())
      );
      setFilteredDevices(filteredDevices);
    } else {
      setFilteredDevices(allDevicesInfo);
    }
  }, 300);

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
    handleFilter(e.target.value);
  };

  return (
    <React.Fragment>
      <CustomListItemCollapse
        title={Language.translate("Sync").toUpperCase()}
        color="primary"
        collapse={true}
        count={Device.getAvaiableDevices(checkedDevices).length}
      >
        <Paper elevation={6} className={classes.poiDeviceListContainer}>
          <CustomTextField
            label=""
            value={filterText}
            fullWidth={true}
            onChange={handleFilterChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List className={classes.poiDeviceList}>
            {filteredDevices.length > 0 ? (
              filteredDevices.map((deviceInfo) => (
                <ListItem key={deviceInfo.id}>
                  <CustomCheckbox
                    key={deviceInfo.id}
                    name={deviceInfo.name}
                    label={deviceInfo.name}
                    checked={checkedDevices.includes(deviceInfo.id)}
                    onChange={() => onDeviceClick(deviceInfo.id)}
                  />
                </ListItem>
              ))
            ) : (
              <div className={classes.noDevice}>
                {Language.translate("No Device Found")}
              </div>
            )}
          </List>
        </Paper>
      </CustomListItemCollapse>
    </React.Fragment>
  );
};

export default POIDeviceList;
