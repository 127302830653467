import React, { createContext, Component } from "react";

export const PoiCategoryContext = createContext({});

export default class PoiCategoryContextProvider extends Component {
  state = {
    selectedPoiCategoryArr: [],
  };

  setSelectedPoiCategoryArr = (selectedPoiCategoryArr) => {
    this.setState({ selectedPoiCategoryArr });
  };

  render() {
    return (
      <PoiCategoryContext.Provider
        value={{
          selectedPoiCategoryArr: this.state.selectedPoiCategoryArr,
          setSelectedPoiCategoryArr: this.setSelectedPoiCategoryArr,
        }}
      >
        {this.props.children}
      </PoiCategoryContext.Provider>
    );
  }
}
