import React from "react";

//Internal
import CustomTextField from "@Components/CustomTextField";

function SearchMessages(props) {
  const { name, label, value, onChange, ...other } = props;

  return (
    <CustomTextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      {...other}
    />
  );
}

export default SearchMessages;
