export const moduleListingDetails = (theme) => ({
  detailsContainer: {
    margin: 0,
    padding: 10,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .MuiListItem-gutters": {
      paddingLeft: 6,
      paddingRight: 6,
    },
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
      "& .MuiChip-root": {
        paddingLeft: 2,
        flex: "0 1 auto",
      },
    },
    "& .itemTextAlone": {
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "100%",
      marginTop: -1,
      "&:first-child": {
        marginTop: 0,
      },
    },
    "& .itemTextFirst": {
      background: theme.palette.colors.gray.main,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      "& .MuiChip-sizeSmall": {
        height: 14,
      },
      "& .MuiIconButton-sizeSmall": {
        padding: "0 3px 0 3px",
      },
    },
    "& .itemTextSecond": {
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      marginLeft: -1,
      "& .MuiChip-root": {
        marginRight: 5,
      },
    },
    "& .itemTextSecond.splitIn2, .itemTextFirst.splitIn2": {
      width: "calc(50% / 2 - 10px)",
    },
    "& .itemTextSecond.splitIn3, .itemTextFirst.splitIn3": {
      width: "calc(50% / 3 - 7px)",
    },
    "& .MuiTypography-root": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& .noResults": {
      textAlign: "center",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  noResults: {
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
