import { default as Module } from "sccModule";

var _ = require("lodash");
window.$ = require("jquery");
window.jQuery = window.$;

/**
 * The base class for Geofence Settings
 *
 * @class GeofenceSettingModule
 */
class GeofenceSettingModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "geofence/geofence_setting",
    };
    super(options);
  }

  static thePublicInstance = null;

  static getThePublicInstance() {
    if (GeofenceSettingModule.thePublicInstance == null) {
      GeofenceSettingModule.thePublicInstance = new GeofenceSettingModule();
    }
    return GeofenceSettingModule.thePublicInstance;
  }
}

export const getGeofenceSettings = GeofenceSettingModule.getThePublicInstance;

GeofenceSettingModule.prototype.onSocketUpdate = function (url, data) {
  const Geofence = require("sccGeofence").default;
  if (!data.id) {
    throw new Error("Socket received data must have 'id'.");
  } else {
    var geofences = Geofence.get();
    //grabbing original data
    var oldCustomColorEnabled = this.get("custom_colour_enabled");
    var oldInclusivePalette = this.get("inclusive_palette");
    var oldParsedInclusivePalette = oldInclusivePalette
      ? JSON.parse(oldInclusivePalette)
      : [];
    var oldExclusivePalette = this.get("exclusive_palette");
    var oldParsedExclusivePalette = oldExclusivePalette
      ? JSON.parse(oldExclusivePalette)
      : [];

    _.merge(this._data, data);

    // grabbing new incoming data
    var newCustomColorEnabled = this.get("custom_colour_enabled");
    var newInclusivePalette = this.get("inclusive_palette");
    var newParsedInclusivePalette = newInclusivePalette
      ? JSON.parse(newInclusivePalette)
      : [];
    var newExclusivePalette = this.get("exclusive_palette");
    var newParsedExclusivePalette = newExclusivePalette
      ? JSON.parse(newExclusivePalette)
      : [];

    // returns an boolean value to see if old inclusive data is equal to new inclusive data
    var dataEqualityInCheck = _.isEqual(
      oldParsedInclusivePalette,
      newParsedInclusivePalette
    );

    // this refreshes the page if an active inclusive custom colour is deleted. if its not active, it does not refreshes.
    if (!dataEqualityInCheck) {
      const initialVal = 0;
      const newHex = new Set(newParsedInclusivePalette.map((item) => item.hex));
      const missingIdsSearch = oldParsedInclusivePalette.filter(
        (item) => !newHex.has(item.hex)
      );
      for (const geoId in geofences) {
        if (geofences.hasOwnProperty(geoId)) {
          const entry = geofences[geoId];
          if (missingIdsSearch.length !== 0) {
            if (entry.custom_colour_id === missingIdsSearch[initialVal].id) {
              window.location.reload(true);
            }
          }
        }
      }
    }

    // returns an boolean value to see if old exclusive data is equal to new exclusive data
    var dataEqualityExCheck = _.isEqual(
      oldParsedExclusivePalette,
      newParsedExclusivePalette
    );
    // this refreshes the page if an active exclusive custom colour is deleted. if its not active, it does not refreshes.

    if (!dataEqualityExCheck) {
      const initialVal = 0;
      const newHex = new Set(newParsedExclusivePalette.map((item) => item.hex));
      const missingIdsSearch = oldParsedExclusivePalette.filter(
        (item) => !newHex.has(item.hex)
      );

      for (const geoId in geofences) {
        if (geofences.hasOwnProperty(geoId)) {
          const entry = geofences[geoId];
          if (missingIdsSearch.length !== 0) {
            if (entry.custom_colour_id === missingIdsSearch[initialVal].id) {
              window.location.reload(true);
            }
          }
        }
      }
    }

    //refreshes page if color palettes are turned off.
    if (newCustomColorEnabled === false && oldCustomColorEnabled === true) {
      window.location.reload(true);
    }

    //refreshes page if color palettes are turned on with previously active colours.
    if (newCustomColorEnabled === true && oldCustomColorEnabled === false) {
      const excluivePalette = newParsedExclusivePalette;
      const incluivePalette = newParsedInclusivePalette;

      for (const geoId in geofences) {
        if (geofences.hasOwnProperty(geoId)) {
          const entry = geofences[geoId];
          for (
            let inclusiveIndex = 0;
            inclusiveIndex < incluivePalette.length;
            inclusiveIndex++
          ) {
            if (entry.custom_colour_id === incluivePalette[inclusiveIndex].id) {
              window.location.reload(true);
            }
          }
          for (
            let exclusiveIndex = 0;
            exclusiveIndex < excluivePalette.length;
            exclusiveIndex++
          ) {
            if (entry.custom_colour_id === excluivePalette[exclusiveIndex].id) {
              window.location.reload(true);
            }
          }
        }
      }
    }
  }
};
export default new GeofenceSettingModule();
