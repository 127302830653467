import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { toLower } from "lodash";

//Material UI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { MenuItem } from "@material-ui/core";
import CustomButton from "@Components/CustomButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomCheckbox from "@Components/CustomCheckbox";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "@Components/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomPagination from "@Components/CustomPagination";

// Components
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import Clock from "sccClock";
import IconButton from "@material-ui/core/IconButton";
import MyLocationIcon from "@material-ui/icons/MyLocation";

// Styles
import { moduleListing } from "@Styles/ModuleListing";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import DeviceInfo from "../../device/components/DataDisplay/DeviceInfo";
import Language from "sccLanguage";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import Alert from "sccAlert";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  ...moduleListing(theme),
  alertChips: {
    marginRight: 10,
  },
  table: {
    "& .MuiTableCell-root": {
      padding: 5,
    },
    "& .tableBody": {
      overflow: "auto",
      "& .textOverflow": {
        display: "block",
        width: 100,
        paddingLeft: 5,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  settingsBtnSendSettingsCode: {
    float: "right",
    margin: "35px 0 0 0",
  },
  reportRateDropdown: {
    margin: "20px 0",
  },
  alertIcon: {
    display: "inline-flex",
    minWidth: 25,

    "& span": {
      display: "inline-flex",
    },
  },
  alerts: {
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: ".5rem",
      marginLeft: 3,
      marginTop: 5,
    },
    "& .MuiListItemIcon-root": {
      maxWidth: "10px !important",
    },
  },
  emergency: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#d9534f",
      fontSize: "0.65rem",
    },
  },
  geofence: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#f9840f",
      fontSize: "0.65rem",
    },
  },
  report: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#3d3d3d",
      fontSize: "0.65rem",
    },
  },
  speed: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#c43cc4",
      fontSize: "0.65rem",
    },
  },
  "non-report": {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#67d06c",
      fontSize: "0.65rem",
    },
  },
  cargo: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#148c90",
      fontSize: "0.65rem",
    },
  },
  vehicle: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#587fab",
      fontSize: "0.65rem",
    },
  },
  noResults: {
    fontWeight: "bold",
    width: "100%",
  },

  dropdown: {
    marginBottom: 0,
  },
}));

export default function HermesGatewaysDetail({ data }) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  // tab value
  const [value, setValue] = useState(0);

  // report rate value
  const [reportRate, setReportRate] = useState(null);
  const [originalReportRate, setOriginalReportRate] = useState(null);

  const pollCommands = mwState.pollCommands;
  const maximize = mwState.maximizePollSettingsForOrion;

  const [showEmergencyReports, setShowEmergencyReports] = useState(false);
  const [searchFilterText, setSearchFilterText] = useState("");
  const [allRemoteRadioReports, setAllRemoteRadioReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [allEmergencyReports, setAllEmergencyReports] = useState([]);
  const [searchedReports, setSearchedReports] = useState([]);
  const [dataArraysInitialized, setDataArraysInitialized] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagination, setPagination] = useState({});

  const setReportRateData = () => {
    if (data.settings && data.settings !== "NULL") {
      const report_i = maximize(JSON.parse(data.settings))["report_i"];
      setReportRate(report_i);
      setOriginalReportRate(report_i);
    }
  };

  const alerts = [
    "Emergency",
    "Geofence",
    "Speed",
    "Non-Report",
    "Cargo",
    "Vehicle",
  ];

  const handlePollCommands = () => {
    pollCommands(
      Device.get(data.id),
      "send_settings",
      JSON.stringify({ report_i: reportRate })
    );
  };

  const handleLocate = (e, id) => {
    e.stopPropagation();
    DeviceOverlay.centerAndZoomTo(id);
  };

  function handleTabs(e, val) {
    setValue(val);
  }

  const handleFilterChange = (e) => {
    setSearchFilterText(e.target.value);
    handleSearchFilter(e.target.value);
  };

  useEffect(() => {
    loadRemoteRadioReports();
    setDataArraysInitialized(true);
    setReportRateData();
  }, []);

  const loadRemoteRadioReports = () => {
    const remoteReports = createRemoteRadioList(data.reports);

    setAllRemoteRadioReports(remoteReports);
    setFilteredReports(remoteReports);
    setSearchedReports(remoteReports);


    var emergencyReports = [];
    _.each(remoteReports, (report) => {
      if (Alert.getDeviceAlert(report.device_id, "Emergency")) {
        emergencyReports.push(report);
      }
    });
    setAllEmergencyReports(emergencyReports);

    setPagination(() => ({
      currentPage: 1,
      itemsPerPage: 10,
      numberOfItems: remoteReports.length,
      maxPages: 5,
      startIndex: 0,
      endIndex:
        remoteReports.length > itemsPerPage
          ? itemsPerPage
          : remoteReports.length,
    }));
  };

  const onPageChange = (currentPage, startIndex, endIndex) => {
    //change list display to show the current index of records
    setPagination((prevs) => ({
      ...prevs,
      currentPage: currentPage,
      startIndex,
      endIndex,
    }));
  };

  const arrItemsPerPage = [
    {
      value: 10,
      label: `10 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 20,
      label: `20 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 50,
      label: `50 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 100,
      label: `100 ${Language.translate("PER PAGE")}`,
    },
  ];

  const classes = useStyles();

  const updateFilteredData = (showEmergencyReports) => {
    setShowEmergencyReports(showEmergencyReports);

    if (showEmergencyReports) {
      var updatedFilteredReports = [];
      updatedFilteredReports = _.filter(filteredReports, (report) => {
        return allEmergencyReports.includes(report);
      });

      setFilteredReports(updatedFilteredReports);
    } else {
      setFilteredReports(allRemoteRadioReports);
    }
  };

  useEffect(() => {
    if (dataArraysInitialized) handleSearchFilter(searchFilterText);
  }, [showEmergencyReports]);

  //  use debounce to avoid too many re-renders
  const handleSearchFilter = _.debounce((value) => {
    const filterText = value;
    if (filterText !== "") {
      const filteredSearchedReports = filteredReports.filter(
        (report) =>
          report.name.toLowerCase().includes(filterText.toLowerCase()) ||
          report.type.includes(filterText.toLowerCase())
      );
      setSearchedReports(filteredSearchedReports);
      setPagination((prevs) => ({
        ...prevs,
        numberOfItems: filteredSearchedReports.length,
      }));
    } else {
      setSearchedReports(filteredReports);
      setPagination((prevs) => ({
        ...prevs,
        numberOfItems: filteredReports.length,
      }));
    }
  }, 300);

  const onItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setPagination((prevs) => ({
      ...prevs,
      currentPage: 1, //reset the current page to 1
      itemsPerPage: e.target.value, //based on the selection
      numberOfItems: searchedReports.length, //based on the items in the list right now
      startIndex: 0,
      endIndex:
        searchedReports.length > e.target.value
          ? e.target.value
          : searchedReports.length,
    }));
  };

  const createRemoteRadioList = function (reports) {
    var reportsArray = reports;

    if (!_.isUndefined(reportsArray) && !_.isEmpty(reportsArray)) {
      var devices = Device.get();
      if (_.isUndefined(devices) || _.isEmpty(devices)) {
        return [];
      }

      for (let i = 0; i < reportsArray.length; i++) {
        if (_.isUndefined(devices[reportsArray[i].device_id.toString()])) {
          reportsArray.splice(i, 1);
          continue;
        }
        reportsArray[i].name =
          devices[reportsArray[i].device_id.toString()].name;
        reportsArray[i].timestamp = Clock.formatTimestamp(
          reportsArray[i].report_timestamp
        );
        reportsArray[i].type =
          devices[reportsArray[i].device_id.toString()].type;

        if (
          _.isNull(reportsArray[i].alerts) ||
          _.isUndefined(reportsArray[i].alerts)
        ) {
          reportsArray[i].alerts = [];

          var alertTypes = Alert.getAlertType();
          if (!_.isUndefined(reportsArray[i].historyAlerts)) {
            _.forEach(alertTypes, function (alert) {
              for (
                var reportAlertIterator = 0;
                reportAlertIterator < reportsArray[i].historyAlerts.length;
                reportAlertIterator++
              ) {
                if (
                  alert.id ==
                  reportsArray[i].historyAlerts[reportAlertIterator]
                    .alert_type_id
                ) {
                  reportsArray[i].alerts.push(alert.type);
                }
              }
            });
          }
        }
      }

      return reportsArray;
    }

    return [];
  };

  return (
    <>
      <button
        id="refreshHermesDetailAlertsList"
        style={{ display: "none" }}
        onClick={() => {
          loadRemoteRadioReports();
        }}
      />
      <Grid container className={classes.detailsContainer}>
        <Grid item xs={12} md={12}>
          <Tabs
            value={value}
            onChange={handleTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={Language.translate("Remote Radios")}
              icon={<CustomIcon fontSize="small" id="icon-group" />}
            />
            <Tab
              label={Language.translate("Info")}
              icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
            />
          </Tabs>
          {value === 0 && (
            <Grid container item direction="row" spacing={1}>
              <Grid item xs="4">
                <CustomDropdown
                  className={classes.reportRateDropdown}
                  label={Language.translate("Report Rate")}
                  name="newSettingsCode"
                  value={reportRate}
                  onChange={(e) => setReportRate(e.target.value)}
                >
                  {_.values(Device.getGatewayReportRate()).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.title} {option.type}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              </Grid>
              <Grid item xs="8">
                <Grid item xs={12}>
                  <CustomButton
                    className={classes.settingsBtnSendSettingsCode}
                    onClick={(e) => {
                      e.target.blur();
                      handlePollCommands();
                    }}
                    disabled={originalReportRate === reportRate}
                    size="medium"
                    color="command"
                    variant="contained"
                    startIcon={<SettingsIcon />}
                  >
                    {Language.translate("Send Settings Code")}
                  </CustomButton>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <List>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "25%" }} align="center">
                            {Language.translate("Name").toUpperCase()}
                          </TableCell>
                          <TableCell style={{ width: "20%" }} align="center">
                            {Language.translate("Type").toUpperCase()}
                          </TableCell>
                          <TableCell style={{ width: "25%" }} align="center">
                            {Language.translate("Last Reported").toUpperCase()}
                          </TableCell>
                          <TableCell style={{ width: "20%" }} align="center">
                            {Language.translate("Alert").toUpperCase()}
                          </TableCell>
                          <TableCell style={{ width: "10%" }} align="center">
                            {Language.translate("Locate").toUpperCase()}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tableBody">
                        <TableRow>
                          <TableCell align="center">
                            <div className={"classes.orionSearch"}>
                              <CustomTextField
                                label=""
                                value={searchFilterText}
                                fullWidth={true}
                                onChange={handleFilterChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <CustomDropdown
                              id="pagingSelector"
                              className={classes.dropdown}
                              value={itemsPerPage}
                              onChange={onItemsPerPageChange}
                            >
                              {arrItemsPerPage.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomDropdown>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            <CustomCheckbox
                              label={Language.translate("Emergency")}
                              name="showEmergencyReports"
                              checked={showEmergencyReports}
                              color="primary"
                              onChange={(e) => {
                                updateFilteredData(e.target.checked);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                        {searchedReports
                          .slice(pagination.startIndex, pagination.endIndex)
                          .map((item, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{item.name}</TableCell>
                              <TableCell align="center">{item.type}</TableCell>
                              <TableCell align="center">
                                {Clock.formatTimestamp(item.report_timestamp)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {alerts.map((alert, index) => {
                                  if (
                                    Alert.getDeviceAlert(item.device_id, alert)
                                  ) {
                                    return (
                                      <ListItemIcon
                                        key={index}
                                        className={classes.alertIcon}
                                      >
                                        <CustomTooltip
                                          title={Language.translate(alert)}
                                          placement="top"
                                          className={
                                            classes[`${toLower(alert)}`]
                                          }
                                        >
                                          <span>
                                            <CustomIcon
                                              type={toLower(alert)}
                                              id={`icon-${toLower(alert)}`}
                                              fontSize="small"
                                            />
                                          </span>
                                        </CustomTooltip>
                                      </ListItemIcon>
                                    );
                                  }
                                })}
                              </TableCell>
                              <TableCell align="center">
                                <CustomTooltip
                                  title={Language.translate("Locate")}
                                  placement="top"
                                >
                                  <IconButton
                                    className="zoomToCoord"
                                    edge="end"
                                    aria-label="locate"
                                    size="small"
                                    onClick={(e) =>
                                      handleLocate(e, item.device_id)
                                    }
                                  >
                                    <MyLocationIcon />
                                  </IconButton>
                                </CustomTooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </List>
                <Grid item align="right" className="pagination" xs={12}>
                  <CustomPagination
                    pagination={pagination}
                    onPageChange={onPageChange}
                  />
                </Grid>
              </Grid>
              {!searchedReports.length && (
                <div align="center" className={classes.noResults}>
                  {Language.translate("No Radios")}
                </div>
              )}
            </Grid>
          )}
          {value === 1 && (
            <DeviceInfo device={data} displayMode="moduleListing" />
          )}
        </Grid>
      </Grid>
    </>
  );
}
