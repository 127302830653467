import React, { useContext } from "react";

// Components
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function AssetsList(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  //this function is local to assets module as we make it work differently
  function handleItemEdit(data, subMode) {
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_ADDEDIT_MODE,
      wrapperDisplaySubMode: subMode,
      moduleItemData: data,
      moduleItemDataOriginal: data,
    }));
  }

  return (
    <React.Fragment>
      {/* filteredModuleData needs to be of format [{}] and contain only the main group */}
      <CustomAssetWrapper
        collection={mwState.filteredModuleData}
        editMode={false}
        devicesButtons={true}
        openMain={true}
        name="Listing"
        groupButtons={true}
        showSelectedOnly={false}
        handleItemEdit={handleItemEdit}
        showSearchField={false}
      />
    </React.Fragment>
  );
}
