import React, { Component } from "react";

// Material-UI
import Paper from "@material-ui/core/Paper";

// Internal
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import DataDisplayButtonGroup from "./DataDisplayButtonGroup";
import DeviceInfo from "./DeviceInfo";
import DataDisplayButtonGroupHistory from "./DataDisplayButtonGroupHistory";
import DeviceInfoHistory from "./DeviceInfoHistory";
import DataDisplayHeader from "./DataDisplayHeader";
import MinimizeContextProvider from "../context/MinimizeContext.js";
import { HistoryContextProvider } from "../../../history/components/RightPanel/HistoryContext";
import DeviceAlertBar from "./DeviceAlertBar";
import DeviceHistoryAlertBar from "./DeviceHistoryAlertBar";

export default class DataDisplay extends Component {
  constructor() {
    super();
    this.state = {
      device: null,
      isDeviceLocked: DeviceOverlay.centerLocked,
      previousDevice: null,
    };

    if (!DataDisplay.publicInstance) {
      DataDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      DataDisplay.publicInstance.setFeatureSelected();
    });
  }

  setFeatureSelected() {
    if (this.props.history !== "1" && DeviceOverlay.getSelectedId()) {
      this.setState({ device: Device.get(DeviceOverlay.getSelectedId()) });
    }
    if (this.props.history === "1" && HistoryOverlay.getSelectedId()) {
      this.setState({ device: Device.get(HistoryOverlay.getSelectedId()) });
    }
  }

  updateDeviceIsLocked = (isDeviceLocked) => {
    this.setState({ isDeviceLocked });
  };

  render() {
    const { device, isDeviceLocked } = this.state;
    const history = this.props.history || 0;
    
    if(this.state.previousDevice !== device) {
      this.state.previousDevice = device; 
      this.updateDeviceIsLocked(false)
    }

    return (
      <MinimizeContextProvider>
        <HistoryContextProvider>
          {/* <SelectedDeviceProvider> */}
          <Paper
            elevation={3}
            id={history === "1" ? "olMapHistoryPopup" : "olMapDevicePopup"}
          >
            {device ? (
              <>
                {history === "1" ? (
                  <DeviceHistoryAlertBar isDeviceDataDisplay device={device} />
                ) : (
                  <DeviceAlertBar
                    device={device}
                    isDeviceDataDisplay
                    history={history}
                  />
                )}

                <DataDisplayHeader
                  device={device}
                  history={history}
                  isDeviceLocked={isDeviceLocked}
                  updateDeviceIsLocked={this.updateDeviceIsLocked}
                />
                {history === "1" ? (
                  <>
                    <DataDisplayButtonGroupHistory device={device} />
                    <DeviceInfoHistory device={device} />
                  </>
                ) : (
                  <>
                    <DataDisplayButtonGroup
                      device={device}
                      updateDeviceIsLocked={this.updateDeviceIsLocked}
                      isDeviceLocked={isDeviceLocked}
                    />
                    <DeviceInfo device={device} displayMode={"popup"} />
                  </>
                )}
              </>
            ) : null}
          </Paper>
        </HistoryContextProvider>
        {/* added only to get the device cluster working. Needs to be checked and edited */}
      </MinimizeContextProvider>
    );
  }
}
