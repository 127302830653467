import React, { useState } from "react";

// Material-UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// Internal
import log from "loglevel";
import Utils from "sccUtils";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";
import "./login.scss";

export default function Login(props) {
  //parent functions and state
  const translateText = props.translateText;
  const setErrorMessage = props.setErrorMessage;
  const setLoginState = props.setLoginState;
  const errorOnPage = props.loginState.errorOnPage;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isChecking, setIsChecking] = useState(false);

  const handleSignIn = (e) => {
    e.preventDefault();
    setIsChecking(true);
    nodejsLogin(Utils, username, password);
  };

  const forgotPassword = () => {
    setLoginState((p) => {
      return Object.assign({}, p, {
        step: 3,
        errorOnPage: 0,
        errorMessage: "",
      });
    });
  };

  const clearCache = function (e) {
    e.preventDefault();
    window.location.reload(true);
  };

  const changeLanguage = function (e) {
    e.preventDefault();
    let queryParameters = window.location.search;
    if (
      queryParameters.indexOf("en") > -1 ||
      queryParameters.indexOf("?") === -1
    ) {
      queryParameters = "?lang=fr";
    } else {
      queryParameters = "?lang=en";
    }
    var currentUrl = window.location.href;
    var languageChangeUrl = currentUrl.split("?")[0] + queryParameters;
    if (languageChangeUrl.indexOf("#") > -1) {
      //Cleans up anything like password reset url contamination
      languageChangeUrl = currentUrl.split("#")[0] + queryParameters;
    }

    log.debug("going to language parameter of: " + languageChangeUrl);

    window.location.href = languageChangeUrl;
  };

  function nodejsLogin(Utils, username, password) {
    return fetch(Utils.apiUrlPrefix + "/auth/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        log.info("Login Successful:", data);

        let errorLoggingIn = false;
        if (data.message) {
          errorLoggingIn = false;
        } else if (data.error_info) {
          switch (data.error_info.name) {
            case "ClientInactive":
              setErrorMessage(
                translateText(
                  "Login error. Customer account has been deactivated"
                )
              );
              break;
            case "ClientExpired":
              setErrorMessage(
                translateText("Login error. Customer account has expired")
              );
              break;
            case "ContactUser":
              setErrorMessage(
                translateText("Login error. User's Role Prohibits Access")
              );
              break;
            default:
              setErrorMessage(
                translateText("Login error. Invalid username or password")
              );
              break;
          }
          errorLoggingIn = true;
        } else {
          setErrorMessage(
            translateText("Login error. Invalid username or password")
          );
          errorLoggingIn = true;
        }

        if (errorLoggingIn) {
          setIsChecking(false);
          return Promise.resolve();
        }

        if (data["2fa_expiry"]) {
          setLoginState((p) => {
            return Object.assign({}, p, {
              step: 2,
              userId: data["user_id"],
              tfaExpiry: data["2fa_expiry"],
              errorOnPage: 0,
              errorMessage: "",
            });
          });
        } else {
          const dateAfter1Week = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
          Utils.setCookie("accesstoken", data.token, {
            path: "/",
            expires: dateAfter1Week,
          });
          Utils.setCookie("userrole", data.role_id, {
            path: "/",
            expires: dateAfter1Week,
          });
          console.log(
            "Login Level 1 achieved. User logged in and cookie saved"
          );
          window.location.replace("/t24.html");
        }
        return Promise.resolve();
      })
      .catch((e) => log.info(e));
  }

  return (
    <form
      className="loginForm"
      id="loginPageForm"
      name="logForm"
      noValidate
      autoComplete="off"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item className="input-field">
          <CustomTextField
            id="txtLoginUsername"
            name="login"
            fullWidth
            placeholder={translateText("Username")}
            error={errorOnPage}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item className="input-field">
          <CustomTextField
            id="txtLoginPassword"
            name="password"
            type="password"
            fullWidth={true}
            placeholder={translateText("Password")}
            error={errorOnPage}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item className="signIn-btn">
          <CustomButton
            fullWidth
            size="large"
            color="command"
            id="btnLoginSubmit"
            disabled={!(username && password) ? true : false}
            onClick={handleSignIn}
            variant="contained"
            type="submit"
          >
            {isChecking ? translateText("Checking") : translateText("Sign In")}
          </CustomButton>
        </Grid>
        <Grid item>
          <Grid
            container
            item
            justifyContent="space-between"
            direction="row"
            spacing={3}
          >
            <Grid item>
              <button
                className="forgot-password"
                onClick={() => forgotPassword()}
              >
                {translateText("Forgot password?")}
              </button>
            </Grid>
            <Grid item>
              <button
                id="btnRefreshLoginPage"
                className="clear-cache"
                onClick={clearCache}
              >
                {translateText("Refresh")}
              </button>
            </Grid>
            <Grid container item>
              <Box mx="auto">
                <button
                  id="btnChangeLanguage"
                  className="language"
                  onClick={changeLanguage}
                >
                  {translateText("Francais")}
                </button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
