import React from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import LoopIcon from "@material-ui/icons/Loop";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  alertFixed: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 9999,
    fontSize: "18px",
    borderRadius: 0,
    backgroundColor: theme.palette.colors.white.main,
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
}));

function Notification(props) {
  const options = props.options;
  const classes = useStyles();

  function closeNotification() {
    props.closeNotification();
  }

  return (
    <React.Fragment>
      <CssBaseline> </CssBaseline>
      <div
        className={classes.alertFixed}
        style={{ display: props.showNotification ? "block" : "none" }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid
            container
            item
            justifyContent="center"
            className={classes.content}
            xs={11}
          >
            <LoopIcon fontSize="large" />
            &nbsp;{options.msg}
          </Grid>
          <Grid item container justifyContent="flex-end" xs={1}>
            <IconButton onClick={closeNotification}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Notification;
