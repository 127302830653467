import React from "react";
import Register from "../../login/components/Register";

function RegisterMain() {
  return (
    <div>
      <Register />
    </div>
  );
}

export default RegisterMain;
