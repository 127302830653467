import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import CustomTooltip from "@Components/CustomTooltip";
import IconButton from "@material-ui/core/IconButton";
import Language from "sccLanguage";
import "./CustomDialog.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  const showCancel = props.onCancelAction ? true : false;

  const handleOKClick = () => {
    props.onOkAction();
  };

  const handleCancelClick = () => {
    if(showCancel) {
      props.onCancelAction()
    }
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      className="CustomDialog"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span style={{ top: 0, right: 0, position: "absolute" }}>
            <CustomTooltip
              title={Language.translate("Close Menu")}
              placement="bottom"
            >
              <IconButton
                onClick={showCancel ? handleCancelClick : handleOKClick}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
          </span>
          <span style={{ color: "black", fontSize: "1rem" }}>{props.text}</span>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {showCancel && (
          <Button
            onClick={handleCancelClick}
            variant="contained"
            color="default"
            startIcon={<CloseIcon />}
          >
            {Language.translate("Cancel")}
          </Button>
        )}
        <Button
          onClick={handleOKClick}
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
        >
          {Language.translate("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
