import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  enabled: Yup.boolean(),
  title: baseSchema(Language).requiredStringMinMax,
  alerts: Yup.array().test({
    name: "alerts_test",
    exclusive: true,
    message: Language.translate("Required"),
    test: (value) => value && value.length > 0,
  }),
});

export default schema;
