import React from "react";

// Material-UI
import { IconButton } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Internal
import CustomTooltip from "@Components/CustomTooltip";
import Language from "sccLanguage";
import POIOverlay from "sccPoiOverlay";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  poiClusterButtons: {
    height: 26,
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },

  poiDisplayButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.colors.gray.dark,
    width: 26,
    height: 26,

    "&:hover": {
      borderRadius: 0,
      backgroundColor: theme.palette.colors.gray.dark,
      "& $deviceDisplayIcon": {
        color: theme.palette.colors.black.main,
      },
    },
  },

  poiDisplayIcon: {
    //color: "#293239",
    color: alpha(theme.palette.colors.black.main, 0.85),
    width: 16,
    height: 16,
  },
}));

export default function ClusterDisplayButtonGroup({ pois }) {
  const handleZoom = () => {
    // zoom in to cluster area
    POIOverlay.centerAndZoomTo(pois);
    //  hide cluster
    POIOverlay.hideClusterPopup();
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.poiClusterButtons}>
        <CustomTooltip
          title={Language.translate("Zoom In")}
          arrow
          placement="top"
        >
          <IconButton className={classes.poiDisplayButton} onClick={handleZoom}>
            <GpsFixedIcon className={classes.poiDisplayIcon} />
          </IconButton>
        </CustomTooltip>
      </div>
    </>
  );
}
