import React, { createContext, Component } from "react";
import GeofenceSetting from "../scripts/GeofenceSetting";
import Utils from "sccUtils";
import log from "loglevel";

export const GeofenceSettingContext = createContext({});

export default class GeofenceSettingContextProvider extends Component {
  state = {
    geofenceSettingData: {},
    openSoundsBox: true,
  };

  setGeofenceSettingData = (geofenceSettingData) => {
    this.setState({ geofenceSettingData });
  };

  setOpenSoundsBox = (openSoundsBox) => {
    this.setState({ openSoundsBox });
  };

  componentDidMount() {
    if (GeofenceSetting.routeUrl) {
      const options = {
        url: GeofenceSetting.routeUrl,
        method: "GET",
      };
      Utils.httpRequestHandler(options)
        .then((response) => {
          if (response?.data?.result) {
            this.setGeofenceSettingData(response.data.result);
          }
        })
        .catch((e) => log.info(e));
    }
  }

  render() {
    return (
      <GeofenceSettingContext.Provider
        value={{
          geofenceSettingData: this.state.geofenceSettingData,
          setGeofenceSettingData: this.setGeofenceSettingData,
          openSoundsBox: this.state.openSoundsBox,
          setOpenSoundsBox: this.setOpenSoundsBox,
        }}
      >
        {this.props.children}
      </GeofenceSettingContext.Provider>
    );
  }
}
