import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import AdminDevice from "sccAdminDevice";

const Language = require("sccLanguage").default;

const regexDeviceID = /^[0-9A-Z]*$/;
const regexNumsOnlySerialNo = /^[0-9]{15}$/;

const schema = Yup.object().shape({
  active: Yup.boolean(),
  name: baseSchema(Language).requiredStringMinMax,
  type_id: baseSchema(Language).requiredNumber,
  client_id: baseSchema(Language).requiredNumber,
  imei: Yup.string().when("type", {
    is: (val) =>
      [
        "NORTAC Orion",
        "Sentry H6120 BM",
        "Sentry H6110 MP",
        "SAT-COM Leopard1",
        "Trellisware TSM TW-950",
      ].indexOf(val) > -1,
    then: Yup.string().when("type", {
      is: (val) => ["NORTAC Orion"].indexOf(val) > -1,
      then: Yup.string()
        .required(Language.translate("This field is required"))
        .uppercase()
        .matches(
          regexDeviceID,
          Language.translate("Only Numbers and Letters allowed")
        )
        .max(
          30,
          Language.translate("This field should have a maximum of") +
            " 30 " +
            Language.translate("characters")
        ),
      otherwise: baseSchema(Language).notRequiredString,
    }),
    otherwise: baseSchema(Language).imei,
  }),

  sms: baseSchema(Language).sms,
  Serial_No: Yup.mixed().when("type_id", {
    is: (typeID) => AdminDevice.getDeviceType(typeID).title === "NORTAC Orion",
    then: Yup.string()
      .matches(
        regexNumsOnlySerialNo,
        Language.translate("Must be a 15-digit number")
      ).nullable(true),
    otherwise: Yup.mixed().when("type_id", {
      is: (typeID) => AdminDevice.getDeviceType(typeID).title === "Sentry H6120 BM" ||
      AdminDevice.getDeviceType(typeID).title === "Sentry H6110 MP" ||
      AdminDevice.getDeviceType(typeID).title === "SAT-COM Leopard1",
      then: baseSchema(Language).Serial_No,
  })}),
  ale_address: Yup.mixed().when("type_id", {
    is: (typeID) =>
      AdminDevice.getDeviceType(typeID).title === "Sentry H6120 BM" ||
      AdminDevice.getDeviceType(typeID).title === "Sentry H6110 MP" ||
      AdminDevice.getDeviceType(typeID).title === "SAT-COM Leopard1",
    then: baseSchema(Language).ale_address,
  }),
  ip_address: Yup.mixed().when("mode", {
    is: 8,
    then: baseSchema(Language).ip_address,
  }),
  device_incoming_mode: Yup.mixed().when("type_id", {
    is: (val) =>
      val && AdminDevice.getDeviceType(val).device_incoming_modes.length > 0,
    then: baseSchema(Language).requiredNumber,
  }),
  device_outgoing_mode: Yup.mixed().when("type_id", {
    is: (val) =>
      val && AdminDevice.getDeviceType(val).device_outgoing_modes.length > 0,
    then: baseSchema(Language).requiredNumber,
  }),
  encryption_key: baseSchema(Language).encryption_key,
  decryption_key: baseSchema(Language).decryption_key,
  device_configurations: Yup.object().when("type", {
    is: (val) => ["New Pocket Buddy", "Pocket Buddy"].indexOf(val) > -1,
    then: Yup.object({
      apn_host: baseSchema(Language).requiredStringMinMax,
      apn_user: baseSchema(Language).requiredStringMinMax,
      apn_password: baseSchema(Language).requiredStringPassword,
      sos_number: baseSchema(Language).phoneNumber.nullable(),
      interval: baseSchema(Language).notRequiredNumberPositiveInt,
    }),
    otherwise: Yup.object().when("type", {
      is: (val) => ["Container CCU", "Container TAG"].indexOf(val) > -1,
      then: Yup.object({
        zigBee_id: baseSchema(Language).imei.nullable(),
      }),
      otherwise: Yup.object().nullable(true),
    }),
  }),
});

export default schema;
