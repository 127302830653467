import React, { useState } from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

// Internal
import AssetSelectorDisplay from "./AssetSelectorDisplay";
import CustomTooltip from "@Components/CustomTooltip";

const useStyles = makeStyles((theme) => ({
  assetGroup: {
    width: "100%",
    margin: 0,
    marginTop: 1,
    padding: 0,
    "& .assetGroupRow": {
      background: theme.palette.colors.gray.main,
      borderLeft: `1px solid ${theme.palette.colors.gray.main}`,
      width: "100%",
      "& .assetGroupIcon": {
        marginTop: -5,
        marginLeft: 0,
      },
      "& .assetGroupLabel": {
        padding: 10,
      },
    },
    "& .assetDeviceLabel": {
      background: theme.palette.colors.white.main,
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
      marginTop: 1,
      marginLeft: 50,
      padding: "10px 5px 10px 5px",
    },
    "& .MuiTypography-body2": {
      fontSize: "0.9rem",
      fontWeight: "bold",
    },
  },
}));

export default function AssetGroup(props) {
  const [expanded, setExpanded] = useState({});
  const groups = props.collection.groups;
  const Device = require("sccDevice").default;
  const Language = require("sccLanguage").default;
  const mainGroupName = props.mainGroupName;

  const handleToggle = (id) => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      {groups.map((group) => {
        return (
          <List key={group.id} className={classes.assetGroup}>
            <ListItem role={undefined} dense button className="assetGroupRow">
              <CustomTooltip
                title={Language.translate("Group")}
                arrow
                placement="top"
              >
                <IconButton className="assetGroupIcon">
                  <GroupIcon />
                </IconButton>
              </CustomTooltip>
              <ListItemText
                className="assetGroupLabel"
                onClick={() => handleToggle(group.id)}
                id={`AssetGroup-${group.id}`}
              >
                {group.title === mainGroupName
                  ? Language.translate(mainGroupName)
                  : group.title}
                &nbsp;({Device.getAvaiableDevices(group.devices).length})
              </ListItemText>
            </ListItem>
            {
              <Collapse
                key={`collapseGroup-${group.id}`}
                in={true}
                timeout="auto"
                unmountOnExit
              >
                {group.title !== mainGroupName && group.devices.length > 0 ? (
                  group.devices.map((deviceId) => (
                    <div key={deviceId} className="assetDeviceLabel">
                      {Device.get(deviceId).name}
                    </div>
                  ))
                ) : (
                  <AssetSelectorDisplay
                    collection={{ groups: group.groups }}
                    name={props.name}
                  />
                )}
                {
                  //this is an exception to the devices rule. If main has devices we show them and still cntinue to parse further
                  group.title === mainGroupName && group.devices.length > 0
                    ? group.devices.map((deviceId) => {
                        if (Device.get(deviceId)) {
                          return (
                            <div className="assetDeviceLabel" key={deviceId}>
                              {Device.get(deviceId)?.name}
                            </div>
                          );
                        }
                      })
                    : null
                }
              </Collapse>
            }
          </List>
        );
      })}
    </React.Fragment>
  );
}
