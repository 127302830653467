import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";

import Alert from "sccAlert";
import PoiMenu from "sccPoiMenu";
import GeofenceMenu from "sccGeofenceMenu";
import Language from "sccLanguage";
import theme from "./styles/theme";

const AppContext = React.createContext([{}, () => {}]);

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: 6,
    display: "inline-flex",
  },
  message: {
    fontSize: 15,
    paddingBottom: 10,
    display: "inline-flex",
    fontWeight: 700,
  },
  subMessage: {
    fontSize: 13,
  },
  ackButton: {
    width: 140,
    height: 30,
    backgroundColor: "#4bff4c",
    color: "#000",
    display: "flex",
    alignItems: "center",
    marginTop: 10,

    "&:hover": {
      backgroundColor: "#293239",
      color: "#fff",
    },
  },
  ackIcon: {
    paddingRight: "4px",
  },
  poiButtons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 10,
    width: 300,
  },

  poiButtonAcc: {
    width: 100,
    height: 30,
    padding: 8,
    lineHeight: "35px",
    backgroundColor: theme.palette.colors.green.main,
    color: "#fff",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      backgroundColor: theme.palette.colors.green.dark,
      color: "#fff",
    },
  },

  nanoIcon: {
    paddingRight: "4px",
    paddingBottom: "2px",
  },

  poiButtonDec: {
    width: 100,
    height: 30,
    padding: 8,
    lineHeight: "35px",
    backgroundColor: theme.palette.colors.red.main,
    color: "#fff",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      backgroundColor: theme.palette.colors.red.dark,
      color: "#fff",
    },
  },
});

// use poiSyncText and geofenceSyncText here to avoid sometimes Language module makes website crash
const poisyncText = Language.translate("POI SYNC: ");
const geofencesyncText = Language.translate("GEOFENCE SYNC: ");

const AppContextProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const displaySnackbarMessage = (obj) => {
    const title = obj.title && Language.translate(obj.title);
    const message = obj.message && Language.translate(obj.message);
    const subMessage = obj.subMessage && Language.translate(obj.subMessage);
    const variant = obj.variant;
    const acknowledgeAlertId = obj.acknowledgeAlertId;

    const options = obj.options || {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      variant: variant,
      autoHideDuration: acknowledgeAlertId?.length > 0 ? null : 5000,
    };
    const eKey = enqueueSnackbar(
      <div className={classes.root}>
        {title ? (
          <React.Fragment>
            <span className={classes.title}>{title}</span>
            <br />
          </React.Fragment>
        ) : null}
        <span className={classes.message}>{message}</span>
        {subMessage ? (
          <React.Fragment>
            <br />
            <span className={classes.subMessage}>{subMessage}</span>
          </React.Fragment>
        ) : null}
        {acknowledgeAlertId?.length > 0 ? (
          <Button
            className={classes.ackButton}
            onClick={() => {
              Alert.acknowledge(acknowledgeAlertId).then(() =>
                closeSnackbar(eKey)
              );
            }}
          >
            <CheckCircleIcon className={classes.ackIcon} />
            {Language.translate("ACKNOWLEDGE")}
          </Button>
        ) : null}
      </div>,
      options
    );
  };

  const poiMenuDisplaySnackbarMessage = (obj) => {
    const title = obj.title && Language.translate(obj.title);
    const message = obj.message && Language.translate(obj.message);
    const subMessage = obj.subMessage && Language.translate(obj.subMessage);
    const variant = obj.variant;
    const poi = obj.poi;

    const options = obj.options || {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      variant: variant,
      autoHideDuration: null,
    };
    const key = enqueueSnackbar(
      <div className={classes.root}>
        {title ? (
          <React.Fragment>
            <span className={classes.title}>
              {poisyncText} {title}
            </span>
            <br />
          </React.Fragment>
        ) : null}
        <span className={classes.message}>{message}</span>
        {subMessage ? (
          <React.Fragment>
            <br />
            <span className={classes.subMessage}>{subMessage}</span>
          </React.Fragment>
        ) : null}
        {poi?.length > 0 && (
          <div className={classes.poiButtons}>
            <Button
              className={classes.poiButtonAcc}
              onClick={() => {
                PoiMenu.poiSyncAccept({ id: poi }).then(() =>
                  closeSnackbar(key)
                );
              }}
            >
              <CheckCircleIcon className={classes.ackIcon} />
              {Language.translate("Accept")}
            </Button>

            <Button
              className={classes.poiButtonDec}
              onClick={() => {
                PoiMenu.poiSyncReject({ id: poi }).then(() =>
                  closeSnackbar(key)
                );
              }}
            >
              <ClearIcon className={classes.nanoIcon} />
              {Language.translate("Decline")}
            </Button>
          </div>
        )}
      </div>,
      options
    );
  };

  const geofenceMenuDisplaySnackbarMessage = (obj) => {
    const title = obj.title && Language.translate(obj.title);
    const message = obj.message && Language.translate(obj.message);
    const subMessage = obj.subMessage && Language.translate(obj.subMessage);
    const variant = obj.variant;
    const geofence = obj.geofence;

    const options = obj.options || {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      variant: variant,
      autoHideDuration: null,
    };

    console.log("Geofence being requested: " + geofence);
    
    const key = enqueueSnackbar(
      <div className={classes.root}>
        {title ? (
          <React.Fragment>
            <span className={classes.title}>
              {geofencesyncText} {title}
            </span>
            <br />
          </React.Fragment>
        ) : null}
        <span className={classes.message}>{message}</span>
        {subMessage ? (
          <React.Fragment>
            <br />
            <span className={classes.subMessage}>{subMessage}</span>
          </React.Fragment>
        ) : null}
        {geofence?.length > 0 && (
          <div className={classes.poiButtons}>
            <Button
              className={classes.poiButtonAcc}
              onClick={() => {
                GeofenceMenu.geofenceSyncAccept({ id: geofence }).then(() =>
                  closeSnackbar(key)
                );
              }}
            >
              <CheckCircleIcon className={classes.ackIcon} />
              {Language.translate("Accept")}
            </Button>

            <Button
              className={classes.poiButtonDec}
              onClick={() => {
                GeofenceMenu.geofenceSyncReject({ id: geofence }).then(() =>
                  closeSnackbar(key)
                );
              }}
            >
              <ClearIcon className={classes.nanoIcon} />
              {Language.translate("Decline")}
            </Button>
          </div>
        )}
      </div>,
      options
    );
  };

  const [appState, setAppState] = useState({
    //libraries
    //common reusable functions for this module only
    displaySnackbarMessage,
    poiMenuDisplaySnackbarMessage,
    geofenceMenuDisplaySnackbarMessage,
    //shared flags and variables
    //objects
  });

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {props.children}
    </AppContext.Provider>
  );
};
export { AppContext, AppContextProvider };
