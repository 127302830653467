import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Language from "sccLanguage";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import ABList from "./ABList";
import ABForm from "./ABForm";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  abListing: {
    padding: "1px 2px 2px 2px",
    backgroundColor: theme.palette.colors.white.main,
    margin: 0,
  },

  noResult: {
    lineHeight: "52px",
    textAlign: "center",
  },
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

function ModuleWrapperABBody(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);

  const filteredModuleData = mwState.convertAndSort(
    mwState.filteredModuleData,
    mwState.searchField
  );

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  const classes = useStyles();

  return (
    <ul className={classes.abListing}>
      {mwState.wrapperDisplayMode === MW_LIST_MODE ? (
        !_.isEmpty(filteredModuleData) ? (
          filteredModuleData
            .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
            .map((data) => {
              return (
                <ABList
                  key={data.id}
                  data={data}
                  handleClick={handleClick}
                  openDetailsFor={openDetailsFor}
                />
              );
            })
        ) : (
          <div className={classes.noResult}>
            {Language.translate("No Results")}
          </div>
        )
      ) : (
        <ABForm />
      )}
    </ul>
  );
}

export default ModuleWrapperABBody;
