// import { default as Menu } from "sccMenu";
var Language = require("sccLanguage").default;
const Menu = require("sccMenu").default;
const Device = require("sccDevice").default;

const AdminDevice = require("sccAdminDevice").default;
const Permission = require("sccPermission");
const DeviceOverlay = require("sccDeviceOverlay");
const _ = require("lodash");
/**
 * Device menu class
 *
 * @class DeviceMenu
 * @param {object} options - device menu information object
 */
class DeviceMenu extends Menu {
  constructor() {
    const options = {
      tab: "Asset",
      title: "Asset",
      mainClass: "Device",
      imageClass: "tablet",
      searchable: true,
      showPagination: false,
      showFooter: true,
      mapLayer: DeviceOverlay,
      verifyAddPermission: Permission.default.verify("group", "add"),
      moduleName: "device",
      module: Device,
    };

    super(options);
    this.universalAppSettings = {};
    this.hawkeyeAppSettings = {}; //newly added hawkeye
    this.limitAlertInterval = null;
  }
  /**
   * @override
   */
  add(obj) {
    obj = _.assign(obj, { sync: { groups: [], devices: [] } });
    super.add(obj);
  }

  save(obj) {
    const devices = Device.get();
    var $scope = this.$scope;
    var removeArray = _.difference(
      $scope.editObj.devices,
      _.keys(devices).map(Number)
    );
    if (removeArray.length > 0) {
      $scope.editObj.devices = _.intersection(
        $scope.editObj.devices,
        _.keys(devices).map(Number)
      );
    }
    if ($scope.editObj.type === "NORTAC Orion") {
      $scope.editObj.settings = {
        report_i: this.universalAppSettings.lock_report_i
          ? this.universalAppSettings.report_i + ", 1"
          : this.universalAppSettings.report_i + ", 0",
        alert_i: this.universalAppSettings.lock_alert_i
          ? this.universalAppSettings.alert_i + ", 1"
          : this.universalAppSettings.alert_i + ", 0",
        distance_i: this.universalAppSettings.lock_distance_i
          ? this.universalAppSettings.distance_i + ", 1"
          : this.universalAppSettings.distance_i + ", 0",
        distance_enabled: this.universalAppSettings.lock_distance_enabled
          ? this.universalAppSettings.distance_enabled + ", 1"
          : this.universalAppSettings.distance_enabled + ", 0",
        measurements: this.universalAppSettings.lock_measurements
          ? this.universalAppSettings.measurements + ", 1"
          : this.universalAppSettings.measurements + ", 0",
      };
      $scope.editObj.settings = JSON.stringify($scope.editObj.settings);

      obj = $scope.editObj;
    } else if ($scope.editObj.type === "Hawkeye 5500") {
      $scope.editObj.settings = {
        device_reporting: this.hawkeyeAppSettings.device_reporting,
        internal_speaker:
          this.hawkeyeAppSettings.internal_speaker === "Enabled" ? true : false,
        network_selection: this.hawkeyeAppSettings.network_selection,
        change_network: this.hawkeyeAppSettings.change_network,
        power_off: this.hawkeyeAppSettings.power_off,
        stationary_toggle:
          this.hawkeyeAppSettings.stationary_toggle === "Enabled"
            ? true
            : false,
        report_interval: this.hawkeyeAppSettings.report_interval,
        stopped_speed: this.hawkeyeAppSettings.stopped_speed,
        time_stopped: this.hawkeyeAppSettings.time_stopped,
        moving_toggle:
          this.hawkeyeAppSettings.moving_toggle === "Enabled" ? true : false,
        moving_report_interval: this.hawkeyeAppSettings.moving_report_interval,
        moving_speed: this.hawkeyeAppSettings.moving_speed,
        report_turn:
          this.hawkeyeAppSettings.report_turn === "Enabled" ? true : false,
        emergency_report: this.hawkeyeAppSettings.emergency_report,
        speed_report_rate: this.hawkeyeAppSettings.speed_report_rate,
        time_over_speed: this.hawkeyeAppSettings.time_over_speed,
        max_speed: this.hawkeyeAppSettings.max_speed,
        first_movement:
          this.hawkeyeAppSettings.first_movement === "Enabled" ? true : false,
        delay_idle: this.hawkeyeAppSettings.delay_idle,
        engine_report_interval: this.hawkeyeAppSettings.engine_report_interval,
        //diagnostic_codes: this.hawkeyeAppSettings.diagnostic_codes === "Enabled" ? true : false,
        roll_over:
          this.hawkeyeAppSettings.roll_over === "Enabled" ? true : false,
        crash: this.hawkeyeAppSettings.crash === "Enabled" ? true : false,
      };
      $scope.editObj.settings = JSON.stringify($scope.editObj.settings);
      //convert to JSON string for submission

      obj = $scope.editObj;

      //SET the Network Selection to editObj - when user presses Save Button
      obj.networkSelection = this.hawkeyeAppSettings.network_selection;
    }

    super.save(obj);
  }

  closeAfterHawkeyeConfig() {
    super.closeEdit();
    super.clearFormFields();
  }

  saveAfterHawkeyeConfig(settingsAsJSON) {
    var $scope = this.$scope;
    $scope.editObj.settings = settingsAsJSON;
    super.save($scope.editObj);
  }
  /**
   * Override the Menu class and sets the correct data values for Nortac Orion devices,
   */
  openEditMenu(obj) {
    if (obj.type === "NORTAC Orion") {
      // Check if the device is new and settings are null. If so set default settings
      if (typeof obj.settings === "object") {
        this.edit(obj);
        return;
      }
      if (obj.settings === "NULL") {
        this.setUaDefaultValues(obj);
      } else {
        this.setUaValues(obj);
      }
    }
    this.edit(obj);
  }
  /**
   * Set the default values for the Nortac Orion device if there are no settings yet
   */
  setUaDefaultValues(obj) {
    this.universalAppSettings = {
      report_i: "1 min",
      lock_report_i: false,
      alert_i: "1 min",
      lock_alert_i: false,
      distance_i: "0 meters",
      lock_distance_i: false,
      distance_enabled: "Disabled",
      lock_distance_enabled: false,
      measurements: "Metric",
      lock_measurements: false,
    };
    obj.settings = this.universalAppSettings;
  }
  /**
   * Set the values of the Nortac Orion device settings from the database to the platform settings form.
   */
  setUaValues(obj) {
    // Data is saved as string. Set string to JSON object
    obj.settings = JSON.parse(obj.settings);
    // Add data to object to populate settings form
    this.universalAppSettings = {
      report_i: this.txtString(obj.settings.report_i),
      lock_report_i: this.statusString(obj.settings.report_i),
      alert_i: this.txtString(obj.settings.alert_i),
      lock_alert_i: this.statusString(obj.settings.alert_i),
      distance_i: this.txtString(obj.settings.distance_i),
      lock_distance_i: this.statusString(obj.settings.distance_i),
      distance_enabled: this.txtString(obj.settings.distance_enabled),
      lock_distance_enabled: this.statusString(obj.settings.distance_enabled),
      measurements: this.txtString(obj.settings.measurements),
      lock_measurements: this.statusString(obj.settings.measurements),
    };
    obj.settings = this.universalAppSettings;
  }

  //used by device.js to update the settings page or Orion after a settings poll
  getEditObj() {
    return this.$scope.editObj;
  }

  // Split string and return value of second string
  statusString(str) {
    str = str.split(",");
    str = str.reverse();
    str = Number(str.shift());
    if (str === 1) return true;
    return false;
  }

  // Split string and return the first text value
  txtString(str) {
    str = str.split(",");
    str = str.shift();
    return str;
  }

  /**
   * overriding the getDisplay method to toggle between 'Asset' and 'Group'
   */
  getDisplayName(obj) {
    if (!obj.imei) {
      return Language.translate("Group");
    }

    return super.getDisplayName(obj);
  }

  getDeviceTypes() {
    var result = {};
    AdminDevice.getDeviceType().forEach((value) => {
      result.title = value.title;
      result.value = value.id;
    });

    return result;
  }
}
export default new DeviceMenu();
