import React, { useState, useContext, useEffect } from "react";

//External
import _ from "lodash";
import moment from "moment";
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";

// Styles
import { moduleIridiumBilling } from "@Styles/ModuleIridiumBilling";

// Internal
import Language from "sccLanguage";
import IridiumBilling from "sccIridiumBilling";
import { AppContext } from "../../../AppContext";

const useStyles = makeStyles((theme) => ({
  ...moduleIridiumBilling(theme),
  textField: {
    borderRadius: 0,
    width: 260,
    "& .MuiOutlinedInput-input": {
      padding: "12px",
      "& .MuiOutlinedInput-input:focus": {
        border: "1px solid red",
      },
    },
  },
}));

const formatDateForCalendar = (date) => {
  const d = new Date(date);
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join(
      "-"
    ) +
    "T" +
    [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
  );
};

function ModuleWrapperIridiumBillingBody(props) {
  const d = new Date().getTime();
  const dateOffsetOneDay = 24 * 60 * 60 * 1000; //hr*min*sec*ms to offset one day
  const dstart = (d - dateOffsetOneDay) / 1000;
  const dend = d / 1000;

  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [startDate, setStartDate] = useState(dstart);
  const [endDate, setEndDate] = useState(dend);
  const [billingData, setBillingData] = useState();
  const [appState] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const formik = useFormikContext();
  const handleFormBlur = formik.handleBlur;

  const newSearch = { start_date: dstart, end_date: dend };

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: {
        ...newSearch,
      },
      moduleItemDataOriginal: { ...newSearch },
    }));
    setStartDate(formatDateForCalendar(moment.unix(newSearch.start_date)));
    setEndDate(formatDateForCalendar(moment.unix(newSearch.end_date)));
  }, []);

  const handleSubmit = () => {
    const startTimeStamp = moment(startDate).format("X");
    const endTimeStamp = moment(endDate).format("X");

    if (startTimeStamp > endTimeStamp) {
      appState.displaySnackbarMessage({
        title: "Iridium Billing",
        message: "Invalid start date. Please select a new start date",
        subMessage: null,
        variant: "error",
      });
      return;
    }

    if (startTimeStamp === 0 || endTimeStamp === 0) {
      appState.displaySnackbarMessage({
        title: "Iridium Billing",
        message: "Please select both a start and end date.",
        subMessage: null,
        variant: "error",
      });
      return;
    }
    setBillingData([]);
    setNoResults(false);
    setLoading(true);
    const options = {
      url: IridiumBilling.routeUrl + "/" + startTimeStamp + "/" + endTimeStamp,
      method: "GET",
    };
    return fetch(options.url, {
      method: options.method,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setBillingData(data);
        setMwState((p) => ({
          ...p,
          moduleItemData: data,
        }));
        setLoading(false);
        if (_.isEmpty(data)) {
          setNoResults(true);
        }
        return;
      })
      .catch((e) => {
        console.log("ERROR from Iridium Billing catch", e);
      });
  };

  // DATES
  const handleDate = (e) => {
    if (e.target.name === "start_date") setStartDate(e.target.value);
    if (e.target.name === "end_date") setEndDate(e.target.value);
    formik.setFieldValue(e.target.name, moment(e.target.value).unix());
  };

  const classes = useStyles();

  return (
    <div className={classes.searchDateContainer}>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs="5">
          <CustomTextField
            type="datetime-local"
            className={classes.textFieldDate}
            label={`${Language.translate("Start Date")}*`}
            name="start_date"
            defaultValue={startDate}
            value={startDate}
            inputProps={{ max: endDate }}
            onChange={handleDate}
            onBlur={handleFormBlur}
            error={
              formik.touched.start_date && Boolean(formik.errors.start_date)
            }
            helperText={Language.translate(
              formik.touched.start_date && formik.errors.start_date
            )}
          />
        </Grid>
        <Grid item xs="5">
          <CustomTextField
            type="datetime-local"
            className={classes.textFieldDate}
            label={`${Language.translate("End Date")}*`}
            name="end_date"
            defaultValue={endDate}
            value={endDate}
            inputProps={{ min: startDate }}
            onChange={handleDate}
            onBlur={handleFormBlur}
            error={formik.touched.end_date && Boolean(formik.errors.end_date)}
            helperText={Language.translate(
              formik.touched.end_date && formik.errors.end_date
            )}
          />
        </Grid>
        <Grid item xs="2">
          <CustomButton
            color="command"
            type="submit"
            onClick={handleSubmit}
            disabled={!_.isEmpty(formik.errors)}
            className={classes.submitButton}
          >
            Submit
          </CustomButton>
        </Grid>
      </Grid>
      {loading ? (
        <div className={classes.loading}>
          <br></br>
          <CircularProgress />
          <h4> Loading</h4>
        </div>
      ) : null}
      {!_.isEmpty(billingData) ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">IMEI</TableCell>
                <TableCell align="center">BYTES</TableCell>
                <TableCell align="center">INBOX MAIL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billingData.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.imei}</TableCell>
                  <TableCell align="center">{item.total_bytes}</TableCell>
                  <TableCell align="center">{item.total_inbox}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        noResults && (
          <div className={classes.noResults}>
            {Language.translate("No Results!")}
          </div>
        )
      )}
    </div>
  );
}

export default ModuleWrapperIridiumBillingBody;
