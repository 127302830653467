import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import Language from "sccLanguage";

const schema = Yup.object().shape({
  title: baseSchema(Language).requiredStringMinMax,
  note: baseSchema(Language).requiredStringMinMax,
});

export default schema;
