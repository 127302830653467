import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

function CustomTooltip(props) {
  const classes = useStyles();
  let { title, placement, ...other } = props;
  if (!title) title = "";
  return (
    <Tooltip
      classes={classes}
      arrow
      title={title}
      placement={placement}
      {...other}
    >
      <span>{props.children}</span>
    </Tooltip>
  );
}

export default CustomTooltip;
