import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import BuildIcon from "@material-ui/icons/Build";
import CustomTooltip from "@Components/CustomTooltip";
import Button from "@material-ui/core/Button";
import "./MapTools.scss";

const Language = require("sccLanguage").default;

export default function ClickAway(props) {
  const OlMap =
    props.history == 1
      ? require("sccOlMapHistory").default
      : require("sccOlMapNew").default;
  const [open, setOpen] = useState(false);
  const [lineControl, setLineControl] = useState(false);
  const [areaControl, setAreaControl] = useState(false);
  const [lineValue, setLineValue] = useState(false);
  const [areaValue, setAreaValue] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = (e) => {
    if (!e.isTrusted) return false;
    // Check if the user has started to use the measuring tools, keep popover open
    if (lineControl || areaControl) {
      setOpen(true);
      setLineValue(OlMap.Measure.lineValue);
      setAreaValue(OlMap.Measure.areaValue);
    } else {
      setOpen(false);
    }
  };

  const toggleLineControl = (e) => {
    setLineControl(!lineControl);
    setAreaControl(false);
    OlMap.Measure.toggleLineControl();
  };

  const toggleAreaControl = (e) => {
    setLineControl(false);
    setAreaControl(!areaControl);
    OlMap.Measure.toggleAreaControl();
  };

  const id = open ? "mapToolsPopover" : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="mapToolsPopover">
        <Button
          className="mapToolsBtn btn btn-default"
          aria-describedby={id}
          variant="contained"
          color="default"
          onClick={handleClick}
          startIcon={
            <CustomTooltip
              title={Language.translate("Map Tools")}
              arrow
              placement="top"
            >
              <BuildIcon />
            </CustomTooltip>
          }
        ></Button>

        {open ? (
          <div className="mapToolsPopoverBox">
            <h5>{Language.translate("Map Tools")}</h5>
            <ul className="mapToolsMenu">
              {OlMap.Measure.lineActive ? (
                <li>
                  {lineValue || Language.translate("Draw line to be measured")}
                </li>
              ) : null}

              {OlMap.Measure.areaActive ? (
                <li>
                  {areaValue || Language.translate("Draw line to be measured")}
                </li>
              ) : null}

              <li>
                {Language.translate("Length")}
                {lineControl ? (
                  <Button
                    onClick={toggleLineControl}
                    size="small"
                    variant="outlined"
                    color="secondary"
                  >
                    {Language.translate("Close")}
                  </Button>
                ) : (
                  <Button
                    onClick={toggleLineControl}
                    size="small"
                    variant="outlined"
                  >
                    {Language.translate("Measure")}
                  </Button>
                )}
              </li>
              <li>
                {Language.translate("Area")}
                {areaControl ? (
                  <Button
                    onClick={toggleAreaControl}
                    size="small"
                    variant="outlined"
                    color="secondary"
                  >
                    {Language.translate("Close")}
                  </Button>
                ) : (
                  <Button
                    onClick={toggleAreaControl}
                    size="small"
                    variant="outlined"
                  >
                    {Language.translate("Measure")}
                  </Button>
                )}
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
