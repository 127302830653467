// External
import _ from "lodash";
import bootbox from "bootbox";

// Internal
import AddressBook from "sccAddressBook";
import Language from "sccLanguage";
import Menu from "sccMenu";
import Permission from "sccPermission";
import User from "sccUser";

/**
 * User Menu class
 * @class UserMenu
 * @param {object} options - User menu information object
 **/
class UserMenu extends Menu {
  constructor() {
    const options = {
      tab: "User",
      title: Language.translate("Users"),
      mainClass: "Users",
      imageClass: "laptop",
      isSubMenu: true,
      entityTitle: "username",
      displayName: "User",
      module: User,
      showPagination: true,
    };

    super(options);
  }

  // overrides the method from the Menu class
  openEditMenu(obj) {
    this.getPermittedRoles(); // recomputing the permitted roles

    // resetting change password toggle to false so that it is not visible when user enters the edit form
    obj.toggleResetPassword = false;
    super.openEditMenu(obj);
  }

  // overrides the method from the Menu class
  openAddMenu(obj) {
    obj = obj || {};
    this.getPermittedRoles();
    obj.email_registration = true;
    super.openAddMenu(obj);
  }

  /**
   * overriding the delete method of the Menu class
   * add info box if user exists in an addressbook
   **/
  delete(obj) {
    // Check am I a member
    var abList = AddressBook.get();
    var blockingABList = [];

    _.forEach(abList, function (addressbook) {
      if (_.includes(addressbook.members.users, obj.id)) {
        blockingABList.push(addressbook.title);
      }
    });

    // if yes, impede delete and list addressbook names, otherwise delete as normal
    if (blockingABList.length > 0) {
      this.dialogBox(obj, blockingABList);
    } else {
      super.delete(obj);
    }
  }

  dialogBox(obj, titles) {
    var name = obj.first_name + " " + obj.last_name;
    var messageStart = Language.translate("The user ");
    var messageEnd = Language.translate(
      " cannot be deleted because the user has been selected as a member in an Address Book. Please remove the user from the following Address Book(s) and try again;"
    );
    var baseMessage = Language.translate(messageStart) + '"' + name + '"' + Language.translate(messageEnd);
    _.forEach(titles, function (title) {
      baseMessage += "<br>" + Language.translate(title);
    });
    bootbox.dialog({
      title: Language.translate("Cannot Delete User"),
      message: baseMessage,
      buttons: {
        ok: {
          label: Language.translate("Ok"),
          className: "btn-primary",
          callback: function () {},
        },
      },
    });
  }

  // temporary solution to hide 'customer admin' role
  getPermittedRoles() {
    var $scope = this.$scope;
    $scope.permittedRoles = _.cloneDeep(Permission.default.getAllRoles());
    $scope.permittedRoles = _.omitBy($scope.permittedRoles, function (role) {
      return role.title === Language.translate("Customer Admin");
    });
    $scope.permittedRoles = _.map($scope.permittedRoles, function (value) {
      var result = {};
      result.title = value.title;
      result.value = value.id;
      return result;
    });
  }
}
export default new UserMenu();
