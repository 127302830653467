import React, { useContext } from "react";

// Material-UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";

// Internal
import { MinimizeContext } from "../../context/MinimizeContext";
import { UserSettingContext } from "../../../../user_setting/context/UserSettingContext";
import POI from "sccPoi";
import OlMap from "sccOlMapNew";
import Language from "sccLanguage";
import Clock from "sccClock";
import CustomTooltip from "@Components/CustomTooltip";
import Device from "sccDevice";
import { makeStyles } from "@material-ui/core";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  POIInfo: {
    "& .MuiList-root": {
      padding: "0",
      "& .MuiListItem-root": {
        border: `1px solid ${theme.palette.colors.gray.main}`,
        padding: "0px",
        marginTop: "-1px",
      },
      "& .POIInfoListItemText-first": {
        background: theme.palette.colors.gray.main,
        margin: "0",
        padding: "4px 8px",
        width: "55px",
        "& .MuiListItemText-primary": {
          fontSize: "13px",
          overflow: "hidden",
          "white-space": "nowrap",
          "text-overflow": "ellipsis",
        },
      },
      "& .POIInfoListItemIcon": {
        background: theme.palette.colors.gray.main,
        fontWeight: "bold",
        fontSize: "12px",
      },
      "& .POIInfoListItemText-second": {
        margin: "0",
        textAlign: "right",
        padding: "4px 8px",
        width: "85px",
        "& .MuiListItemText-primary": {
          fontSize: "13px",
          overflow: "hidden",
          "white-space": "nowrap",
          "text-overflow": "ellipsis",
        },
      },
    },
  },
}));

export default function POIInfo({ device }) {
  const { state } = useContext(MinimizeContext);
  const { userSettingData } = useContext(UserSettingContext);
  const deviceTypeFeatures = POI.get(device.id);
  const classes = useStyles();

  const location =
    (device &&
      device.latitude &&
      OlMap.formatCoordinate([device.longitude, device.latitude])) ||
    "N/A";

  return (
    <React.Fragment>
      {!state.minimize &&
      deviceTypeFeatures &&
      Object.keys(deviceTypeFeatures).length > 0 ? (
        <Grid item xs={12} md={12} className={classes.POIInfo}>
          <List>
            <CustomTooltip
              title={Clock.formatTimestamp(
                deviceTypeFeatures.created_timestamp
              )}
              arrow
              placement="top"
            >
              <ListItem>
                <ListItemText
                  md={6}
                  className="POIInfoListItemText-first"
                  primary={Language.translate("CREATED")}
                />
                {userSettingData["clock_format"] && (
                  <ListItemText
                    md={6}
                    className="POIInfoListItemText-second"
                    primary={Clock.formatTimestamp(
                      deviceTypeFeatures.created_timestamp
                    )}
                  />
                )}
              </ListItem>
            </CustomTooltip>
          </List>
          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="POIInfoListItemText-first"
                primary={Language.translate("Created By").toUpperCase()}
              />
              <ListItemText
                md={6}
                className="POIInfoListItemText-second"
                primary={
                  Device.get(deviceTypeFeatures.creator_device_id)?.imei
                    ? Device.get(deviceTypeFeatures.creator_device_id).imei
                    : "N/A"
                }
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="POIInfoListItemText-first"
                primary={Language.translate("Annotation").toUpperCase()}
              />
              <ListItemText
                md={6}
                className="POIInfoListItemText-second"
                primary={device.note}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="POIInfoListItemText-first"
                primary={
                  <>
                    <CustomTooltip
                      title={OlMap.getUserLonlatFormat().toUpperCase()}
                      arrow
                      placement="bottom"
                    >
                      {OlMap.getUserLonlatFormat().toUpperCase()}
                    </CustomTooltip>
                  </>
                }
              />
              <ListItemText
                md={6}
                className="POIInfoListItemText-second"
                primary={
                  <>
                    <CustomTooltip title={location} arrow placement="bottom">
                      {location}
                    </CustomTooltip>
                  </>
                }
              />
            </ListItem>
          </List>
        </Grid>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
