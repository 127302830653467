import { default as Module } from "sccModule";
import { default as Utils } from "sccUtils";

var Permission = require("sccPermission");
var log = require("loglevel");

/**
 * The base class for Geofence
 *
 * @class GeofenceModule
 */
class GeofenceModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "geofence",
    };

    super(options);
  }
  init() {
    if (!Permission.default.verify("geofence", "view")) {
      log.debug("User is not permitted for Geo-Fence module");
      return Promise.resolve();
    }

    return super.init();
  }

  onSocketUpdate(event, data) {
    super.onSocketUpdate(event, data);
    const GeofenceOverlay = require("sccGeofenceOverlay").default;
    GeofenceOverlay.refresh(data);
    const {
      default: GeofenceDataDisplay,
    } = require("../components/DataDisplay");

    GeofenceDataDisplay.updateFeatureSelected();
    GeofenceOverlay.doAfterGeofencePUTorPOST();
  }

  // onSocketAdd(event, data) {
  //   super.onSocketAdd(event, data);
  //   const GeofenceOverlay = require("sccGeofenceOverlay").default;
  //   GeofenceOverlay.refresh(data);
  //   const {
  //     default: GeofenceDataDisplay,
  //   } = require("../components/DataDisplay");
  //   GeofenceDataDisplay.updateFeatureSelected();
  //   GeofenceOverlay.doAfterGeofencePUTorPOST();
  // }

  onSocketDelete(event, data) {
    super.onSocketDelete(event, data);
    const GeofenceOverlay = require("sccGeofenceOverlay").default;
    GeofenceOverlay.refresh(null, data);
    GeofenceOverlay.endDrawing();
    const {
      default: GeofenceDataDisplay,
    } = require("../components/DataDisplay");
    GeofenceDataDisplay.updateFeatureSelected();
  }
}
GeofenceModule.prototype.onSocketAdd = function (url, data) {
  Module.Module.prototype.onSocketAdd.call(this, url, data);
  const GeofenceOverlay = require("sccGeofenceOverlay").default;
  GeofenceOverlay.refresh(data);

  if (!data.creator_device_id) {
    return;
  }
  if (!data.approved) {
    Utils.geofenceMenuNotify(data.title, data.note, "", "success", data.id);
  }
};

export default new GeofenceModule();
