import React from "react";

// Material-UI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function FeedsDetail(props) {
  const data = props.data;

  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.detailsContainer}>
      <List>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst"
            primary={Language.translate("Name")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.title}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst"
            primary={Language.translate("Feed Code")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.feed_code}
          />
        </ListItem>
        {data.token && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Feed Token")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.token}
            />
          </ListItem>
        )}
      </List>
    </Grid>
  );
}
