import React, { createContext, Component } from "react";

export const MinimizeContext = createContext();

export default class MinimizeContextProvider extends Component {
  state = {
    minimize: false,
  };
  toggleMinimize = () => {
    this.setState({ minimize: !this.state.minimize });
  };
  render() {
    return (
      <MinimizeContext.Provider
        value={{ state: this.state, toggleMinimize: this.toggleMinimize }}
      >
        {this.props.children}
      </MinimizeContext.Provider>
    );
  }
}
