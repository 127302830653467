/* eslint no-console: 0 */
//var EnvVar= require("sccEnvVar");
const _ = require("lodash");
let initialized = false;
let allModulesLoaded = false;
let inProgress = false;
let start = new Date().getTime();
let dependencies = {};
let resolved = [];
let modules = {}; // stores module objects by their name

const log = require("loglevel");
log.setLevel(log.levels.DEBUG);

/**
 * Load class
 *
 * @class
 */
class Load {
  static get start() {
    return start;
  }

  static get initialized() {
    return initialized;
  }

  static set initialized(value) {
    initialized = value;
  }

  static get allModulesLoaded() {
    return allModulesLoaded;
  }

  static set allModulesLoaded(value) {
    allModulesLoaded = value;
  }

  static get resolvedModuleCount() {
    return resolved.length;
  }

  static get totalModuleCount() {
    return _.keys(modules).length;
  }

  /**
   * setting the log level to only show warnings and errors if the environment is 'production'
   * and to show everything otherwise
   */
  static setLogLevel(level) {
    if (level) {
      log.setLevel(level, false);
      return;
    }

    //disabled for testing should be put back
    // if(EnvVar.get("WEB_ENV") == "production"){

    // 	log.setLevel(log.levels.WARN, false);
    // 	console.log = function() {};
    // 	console.info = function() {};
    // 	console.debug = function() {};
    // }
  }

  /**
   * Resets the Load module
   *
   * @method reset
   * @static
   */
  static reset() {
    initialized = false;
    allModulesLoaded = false;
    inProgress = false;
    start = new Date().getTime();
    dependencies = {};
    resolved = [];
    modules = {}; // stores module objects by their name
  }

  /**
   * Injects a new dependency
   *
   * @method inject
   * @static
   * @param {Array[]} deps - the list of dependencies
   * @param {Object} mod - the module function/class
   */
  static inject(deps, mod, initFunc) {
    // extracting the function/class name
    var moduleName = Load.functionName(mod);
    if (!moduleName)
      throw new Error("Load: inject: Failed to get the module name.");

    deps = _.concat([], deps);

    modules[moduleName] = {};
    modules[moduleName].module = mod;
    modules[moduleName].initFunc = initFunc;

    dependencies[moduleName] = dependencies[moduleName] || [];
    dependencies[moduleName] = _.union(dependencies[moduleName], deps);

    // if all modules are already loaded, resolve the dependency
    if (allModulesLoaded && !inProgress) {
      log.warn(
        "Module",
        moduleName,
        " loaded after module dependency resolution."
      );
      return Load.resolve();
    }
  }

  /**
   * resolves and run module based on injected dependencies
   *
   * @method resolve
   * @static
   * @return {Object} a promise when all modules are loaded
   */
  static resolve() {
    inProgress = true;
    var noDepModules = [];
    noDepModules = Load.getNoDepModules();
    return Promise.map(noDepModules, function (moduleName) {
      return Load.runModule(moduleName).then(function () {
        resolved.push(moduleName);
        return Promise.resolve();
      });
    }).then(function () {
      // check if all dependencies are resolved
      // Note: the check to see if total number of modules are more than 10 is added to prevent
      // platform resolve modules too early and before all dependencies are injected.
      // This issue has happened in IE11.
      if (
        _.difference(_.keys(dependencies), resolved).length === 0 &&
        Load.totalModuleCount > 7
      ) {
        inProgress = false;
        return Promise.resolve();
      }

      // check if any unmet dependency exists
      if (Load.getNoDepModules().length > 0) {
        return Load.resolve();
      }

      // There are still unmet dependencies. Waiting for them to initialize.
      var message =
        "Could not resolve the dependency. Will try in 100 milliseconds. " +
        JSON.stringify(_.omit(dependencies, resolved));
      log.debug(message);
      return Promise.delay(100).then(function () {
        return Load.resolve();
      });
    });
  }

  /**
   * Gets the list of modules that have their dependencies resolved
   *
   * @method getNoDepModules
   * @static
   * @return {Array} an array of module names
   */
  static getNoDepModules() {
    // getting all modules that are not resolved
    var notResolved = _.omit(dependencies, resolved);

    // getting all moudles that their dependency is met
    return _.keys(
      _.pickBy(notResolved, function (deps) {
        return _.difference(deps, resolved).length === 0;
      })
    );
  }

  /**
   * Runs the init method of the module
   *
   * @method runModule
   * @static
   * @param {String} moduleName - name of the module
   * @return {Object} a promise wrapped result from module's init method
   */
  static runModule(moduleName) {
    log.debug(">>>> Initializing module", moduleName);
    var mod = modules[moduleName].module;
    var initFunc = modules[moduleName].initFunc;
    return (function () {
      if (initFunc) {
        return Promise.resolve(initFunc());
      } else if (!mod.init) {
        log.warn("Class " + moduleName + " does not have init() method.");
        return Promise.resolve();
      }
      return Promise.resolve(mod.init());
    })()
      .then(function () {
        log.debug("<<<< Module", moduleName, "Initialized.");
        return Promise.resolve();
      })
      .catch((err) => {
        log.error("Load.js:", err);
      });
  }

  /**
   * Extracts module name from the function/class
   *
   * @method functionName
   * @static
   * @param {Object} fun - the function representing the module
   * @return {String} name of the function
   */
  static functionName(fun) {
    if (fun.moduleName) return fun.moduleName;

    var ret = fun.toString();
    ret = ret.substr("function ".length);
    ret = ret.substr(0, ret.indexOf("("));
    return ret;
  }
}

export default Load;
