import React, { useContext } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

//External
import _ from "lodash";

//Internal
import Language from "sccLanguage";
import Message from "sccMessage";
import Device from "sccDevice";
import User from "sccUser";
import Clock from "sccClock";
import CustomCheckbox from "@Components/CustomCheckbox";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { moduleMessage } from "@Styles/ModuleMessage";

function Trash(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const filteredModuleData =
    mwState.filteredModuleData && Object.values(mwState.filteredModuleData);
  const handleClick = props.handleClick;
  const handleSingleCheckbox = props.handleSingleCheckbox;
  const resetSorting = props.resetSorting;

  function getMessageFrom(data) {
    if (data.senders.devices.length) {
      if (Device.get(data.senders.devices)) {
        const from = Device.get(data.senders.devices).name;
        data.from = from;
        return from;
      } else {
        data.from = Language.translate("Unauthorized User");
        return Language.translate("Unauthorized User");
      }
    } else {
      if (data.senders.users.length) {
        const getUser = User.get(data.senders.users);
        data.from = `${
          getUser ? getUser.first_name + " " + getUser.last_name : ""
        }`;
        return `${getUser ? getUser.first_name + " " + getUser.last_name : ""}`;
      } else {
        data.from = "";
        return null;
      }
    }
  }

  function getMessageTo(data) {
    if (data.recipients.devices.length) {
      data.to = data.recipients.devices
        .map((recpt, index) => {
          return index > 1
            ? ", " + Device.get(recpt)?.name
            : Device.get(recpt)?.name;
        })
        .join();
      return data.recipients.devices.map((recpt, index) => {
        return index > 1
          ? ", " + Device.get(recpt)?.name
          : Device.get(recpt)?.name;
      });
    } else {
      if (data.recipients.users.length) {
        data.to = data.recipients.users
          .map((recpt, index) => {
            const getUser = User.get(recpt);
            return index > 1
              ? ", " +
                  `${
                    getUser ? getUser.first_name + " " + getUser.last_name : ""
                  }`
              : `${
                  getUser ? getUser.first_name + " " + getUser.last_name : ""
                }`;
          })
          .join();
        return data.recipients.users.map((recpt, index) => {
          const getUser = User.get(recpt);
          return index > 1
            ? ", " +
                `${getUser ? getUser.first_name + " " + getUser.last_name : ""}`
            : `${getUser ? getUser.first_name + " " + getUser.last_name : ""}`;
        });
      } else {
        data.to = "";
        return null;
      }
    }
  }

  const useStyles = makeStyles((theme) => ({
    ...moduleMessage(theme),
  }));
  const classes = useStyles();

  return (
    <Grid container className={classes.listContainer}>
      <Grid container spacing={0} className="header" alignContent="center">
        <Grid item xs={1} className="chkBox"></Grid>
        <Grid item container xs={11}>
          <Grid item xs={2}>
            {Language.translate("FROM")}
          </Grid>
          <Grid item xs={2}>
            {Language.translate("TO")}
          </Grid>
          <Grid item xs={3}>
            {Language.translate("MESSAGE")}
          </Grid>
          <Grid item xs={2}>
            {Language.translate("Canned").toUpperCase()}
          </Grid>
          <Grid item xs={2}>
            <Link href="#" onClick={resetSorting} underline="none">
              <span className="dateLink">{Language.translate("DATE")}</span>
              {mwState.moduleData.sortDesc ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )}
            </Link>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
      {!_.isEmpty(filteredModuleData) && mwState.moduleData.checked ? (
        filteredModuleData.map((data) => {
          const messageViewable = !(
            Device.get(data.senders.devices) === undefined
          );
          return !(
            Device.get(data.senders.devices) === undefined &&
            mwState.moduleData.hideUnauthorized
          ) ? (
            <Grid
              container
              spacing={0}
              className={`listItem read 
              ${
                mwState.moduleData.checked.indexOf(data.id) > -1
                  ? "checked"
                  : null
              }`}
              alignItems="center"
              key={data.id}
            >
              <Grid item xs={1} className="chkBox">
                <CustomCheckbox
                  onChange={() => handleSingleCheckbox(data.id)}
                  checked={
                    mwState.moduleData.checked
                      ? mwState.moduleData.checked.indexOf(data.id) > -1
                      : false
                  }
                  name={`chk_${data.id}`}
                  value={data.id}
                />
              </Grid>
              <Grid
                item
                container
                className="rowText"
                xs={11}
                onClick={() => handleClick(data)}
              >
                <Grid className="ellipsis" item xs={2}>
                  {getMessageFrom(data)}
                </Grid>
                <Grid className="ellipsis" item xs={2}>
                  {getMessageTo(data)}
                </Grid>
                <Grid className="ellipsis" item xs={3}>
                  {messageViewable ? data.message : null}
                </Grid>
                <Grid className="ellipsis" item xs={2}>
                  {messageViewable
                    ? Message.getCannedMessageById(data.cannedmessage_id)
                    : null}
                </Grid>
                <Grid className="ellipsis" item xs={2}>
                  {Clock.formatTimestamp(data.message_timestamp)}
                </Grid>
                <Grid className="ellipsis status" item xs={1}>
                  {_.keys(data.attachments).length > 0 ? (
                    <AttachmentIcon />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ) : null;
        })
      ) : (
        <Grid item xs={12} className="noMessages">
          {Language.translate("No Messages!")}
        </Grid>
      )}
    </Grid>
  );
}

export default Trash;
