import React, { useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Components
import CustomCheckbox from "@Components/CustomCheckbox/CustomCheckbox";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  passwordRequirements: {
    backgroundColor: theme.palette.colors.white.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    padding: 8,
  },
  passwordRequirementsTooltip: {
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
  },
  registerWithEmailCheckbox: {
    marginLeft: 8,
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function UserForm(props) {
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const sortPermittedRoles = (roles) => {
    let permittedRoles = roles;

    // alphabetical sorting of both admin and custom roles using hard-coded ids for conditional statement
    let adminRoles = _.filter(permittedRoles, function (a) {
      return a.id < 8;
    });
    adminRoles = _.sortBy(adminRoles, "title");
    let customRoles = _.filter(permittedRoles, function (c) {
      return c.id > 7;
    });
    customRoles = _.sortBy(customRoles, "title");
    permittedRoles = _.concat(adminRoles, customRoles);
    // end conditional sorting

    permittedRoles = _.omitBy(permittedRoles, function (role) {
      return role.title === "Customer Admin";
    });

    permittedRoles = _.map(permittedRoles, function (value) {
      var result = {};
      result.title = value.title;
      result.id = value.id;
      return result;
    });
    return permittedRoles;
  };

  const permittedRoles = sortPermittedRoles(_.values(Permission.getAllRoles()));

  const newUser = {
    email_registration: true,
    role_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  };

  const handleEmailRegistration = (e) => {
    if (!moduleItemData.email_registration) {
      const newObj = Utils.omit(moduleItemData, [
        "username",
        "password",
        "confirm",
      ]);
      setMwState((p) => ({
        ...p,
        moduleItemData: { ...newObj, email_registration: true },
      }));
    } else {
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...moduleItemData,
          email_registration: false,
          username: "",
          password: "",
          confirm: "",
        },
      }));
    }
    setTimeout(() => {
      formik.setTouched({
        first_name: moduleItemData.first_name ? true : false,
        role_id: moduleItemData.role_id ? true : false,
        last_name: moduleItemData.last_name ? true : false,
        email: moduleItemData.email ? true : false,
        phone_number: moduleItemData.phone_number ? true : false,
      });
    }, 100);
  };

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const userData =
        mwState.moduleItemData.username == null
          ? Utils.omit(mwState.moduleItemData, ["username"])
          : mwState.moduleItemData;
      setMwState((p) => ({
        ...p,
        moduleItemData: { ...newUser, ...userData },
        moduleItemDataOriginal: { ...newUser, ...userData },
      }));
    }
  }, [mwState.wrapperDisplayMode]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <p className="pullRight">
              {"*" + Language.translate("Required fields")}
            </p>
          </Grid>
          <Grid item xs={12}>
            <CustomDropdown
              label={`${Language.translate("Role")}*`}
              name="role_id"
              value={moduleItemData.role_id || ""}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.role_id && Boolean(formik.errors.role_id)}
              helperText={Language.translate(
                formik.touched.role_id && formik.errors.role_id
              )}
            >
              {permittedRoles.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {Language.translate(option.title)}
                </MenuItem>
              ))}
            </CustomDropdown>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={`${Language.translate("First Name")}*`}
              value={moduleItemData.first_name}
              name="first_name"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={Language.translate(
                formik.touched.first_name && formik.errors.first_name
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={`${Language.translate("Last Name")}*`}
              value={moduleItemData.last_name}
              name="last_name"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={Language.translate(
                formik.touched.last_name && formik.errors.last_name
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={`${Language.translate("Email")}*`}
              value={moduleItemData.email}
              name="email"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={Language.translate(
                formik.touched.email && formik.errors.email
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={Language.translate("Phone Number")}
              value={moduleItemData.phone_number}
              name="phone_number"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={Language.translate(
                formik.touched.phone_number && formik.errors.phone_number
              )}
            />
          </Grid>
          {!moduleItemData.id ? (
            <Grid item xs={12}>
              <CustomCheckbox
                label={Language.translate("Register via Email")}
                name="email_registration"
                onChange={handleEmailRegistration}
                color="primary"
                checked={Boolean(moduleItemData.email_registration)}
                className={classes.registerWithEmailCheckbox}
              />
              <Grid item container spacing={2}>
                {!moduleItemData.email_registration ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <CustomTextField
                        label={`${Language.translate("Username")}*`}
                        value={String(moduleItemData.username)}
                        name="username"
                        onChange={handleFormInput}
                        onBlur={handleFormBlur}
                        error={
                          formik.touched.username &&
                          Boolean(formik.errors.username)
                        }
                        helperText={Language.translate(
                          formik.touched.username && formik.errors.username
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="pullRight">
                        <Tooltip
                          className={classes.passwordRequirementsTooltip}
                          title={
                            <div>
                              <h5>
                                {Language.translate("Password Requirements")}
                              </h5>
                              <Divider />
                              <ul>
                                <li>
                                  {Language.translate("Numbers (0-9)")},{" "}
                                  <b>{Language.translate("mandatory")}</b>
                                </li>
                                <li>
                                  {Language.translate("Alphabets") +
                                    " (A-Z, a-z)"}
                                  , <b>{Language.translate("mandatory")}</b>
                                </li>
                                <li>
                                  {Language.translate("Special characters") +
                                    " (_.-)"}
                                  , <b>{Language.translate("optional")}</b>
                                </li>
                                <li>
                                  {Language.translate(
                                    "Minimum length 7 and maximum length 20"
                                  )}
                                </li>
                              </ul>
                            </div>
                          }
                          placement="top"
                        >
                          <button className={classes.passwordRequirements}>
                            {Language.translate(
                              "Password Requirements"
                            ).toUpperCase()}
                          </button>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        label={`${Language.translate("Password")}*`}
                        value={moduleItemData.password}
                        name="password"
                        onChange={handleFormInput}
                        onBlur={handleFormBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={Language.translate(
                          formik.touched.password && formik.errors.password
                        )}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        label={`${Language.translate("Confirm Password")}*`}
                        value={moduleItemData.confirm}
                        name="confirm"
                        onChange={handleFormInput}
                        onBlur={handleFormBlur}
                        error={
                          formik.touched.confirm &&
                          Boolean(formik.errors.confirm)
                        }
                        helperText={Language.translate(
                          formik.touched.confirm && formik.errors.confirm
                        )}
                        type="password"
                      />
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </React.Fragment>
  );
}
