import React from "react";

// External
import _ from "lodash";

// Material-UI
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  chips: {
    alignItems: "normal",
  },
}));

export default function PermissionsDetail(props) {
  const { groupedPermissions } = props.data;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.detailsContainer}>
        <Grid item xs={12} md={12}>
          {groupedPermissions && _.keys(groupedPermissions).length !== 0 ? (
            Object.keys(groupedPermissions).map((item, index) => (
              <ListItem key={index} className={classes.chips}>
                <ListItemText
                  md={6}
                  className="itemTextFirst splitIn3"
                  primary={Language.translate(Permission.getModuleName(item))}
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={Object.values(groupedPermissions)
                    .filter((mod) => mod[0].module === item)
                    .map((item2) =>
                      item2.map((perm, index) => (
                        <Chip
                          key={index}
                          size="small"
                          label={Language.translate(
                            Permission.getActionName(perm.action)
                          )}
                        />
                      ))
                    )}
                />
              </ListItem>
            ))
          ) : (
            <ListItem className={classes.noResults}>
              <ListItemText className="itemTextAlone">
                {Language.translate("This role does not have any permissions")}
              </ListItemText>
            </ListItem>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
