import React, { useContext } from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// Internal
import { HistoryContext } from "../../HistoryContext";
import Clock from "sccClock";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  displayTime: {
    textAlign: "center",
    padding: "7px 0",
    fontSize: "16px",
    color: alpha(theme.palette.colors.black.main, 0.85),
	fontWeight: "bold",
  },
  chipText: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginTop: "-5px",
    marginLeft: "6px",
  },
}));

function DisplayCentralLabels() {
  const [state] = useContext(HistoryContext);
  const Historic = state.Historic;
  let playEvent = state.playEvent;
  let eventFinished = state.eventFinished;

  const classes = useStyles();
  
  return (
    <div>
      {Historic.currentTimestamp !== 0 ? (
        <div className={classes.displayTime}>
          {Clock.formatTimestamp(Historic.currentTimestamp)}
          {Historic.playSpeed !== 1 ? (
            <Chip
              className={classes.chipText}
              size="small"
              label={Historic.playSpeed + "x"}
            />
          ) : (
            ""
          )}
          {Historic.playOptions.autoSkip ? (
            <Chip
              className={classes.chipText}
              size="small"
              label={Language.translate("Auto-Skip")}
            />
          ) : (
            ""
          )}
          {!playEvent &&
          Historic.currentTimestamp !== Historic.getStartTimestamp() &&
          Historic.currentTimestamp !== Historic.getEndTimestamp() ? (
            <Chip
              className={classes.chipText}
              size="small"
              color="secondary"
              label={Language.translate("Paused")}
            />
          ) : (
            ""
          )}
          {playEvent ? (
            <Chip
              className={classes.chipText}
              size="small"
              color="primary"
              label={Language.translate("Playing")}
            />
          ) : (
            ""
          )}

          {eventFinished ? (
            <Chip
              className={classes.chipText}
              size="small"
              color="secondary"
              label={Language.translate("Finished")}
            />
          ) : (
            ""
          )}
        </div>
      ) : null}
    </div>
  );
}

export default DisplayCentralLabels;
