import React, { useState, useEffect, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, alpha } from "@material-ui/core/styles";

// Internal
import CustomIcon from "@Components/CustomIcon";
import CustomButton from "@Components/CustomButton";
import { AppContext } from "../../../AppContext";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import Language from "sccLanguage";
import Profile from "sccProfile";
import Images from "sccImage";

const useStyles = makeStyles((theme) => ({
  logoBody: {
    padding: "8px",
    "& .titleInLogoBody": {
      marginTop: "2px",
      marginBottom: "2px",
      fontSize: "24px",
    },
    "& .logoDisplay": {
      maxWidth: "125px",
      maxHeight: "125px",
      "& .platformImage": {
        maxWidth: "120px",
        maxHeight: "120px",
      },
    },
    "& .LogoDeleteBtn": {
      width: "100px !important",
    },

    "& .submitBtn": {
      backgroundColor: theme.palette.colors.primary.main,
      color: theme.palette.colors.white.main,
      "& #icon-paper-plane": {
        paddingRight: "6px",
        fontSize: "8px",
      },
    },

    "& .logoPreview": {
      maxWidth: "125px",
      maxHeight: "125px",
      "& .picFile": {
        maxWidth: "120px",
        maxHeight: "120px",
      },
    },

    "& .selectImg": {
      fontSize: "18px",
      marginTop: "2px",
      marginBottom: "8px",
    },

    "& #icon-upload": {
      paddingright: "6px",
      fontsize: "8px",
    },

    "& .fileUploadInput": {
      display: "none",
    },

    "& .fileNameBox": {
      background: alpha(theme.palette.colors.headerFooterText.main, 0.85),
      width: "565px",
      marginLeft: "6px",
      "& .fileName": {
        paddingLeft: "8px",
        marginTop: "9px !important",
        fontSize: "14px",
      },
    },
  },
  uploadButton: {
    maxWidth: 160,
  },
}));

const dataSizeLimit = 1000000; //1MB size in bytes

function ModuleWrapperLogoBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);
  const [uploadFile, setUploadFile] = useState(null);

  //to be used in add edit
  const updateModuleData = mwState.updateModuleData;
  const handleItemDelete = mwState.handleItemDelete;
  function deleteItem() {
    //confirm box
    handleItemDelete(
      `${mwState.routeUrl}/image/platform`,
      mwState.title,
      Language.translate("Custom") + " " + Language.translate("Logo"),
      null
    );
  }

  function isFileImage(file) {
    return file && file["type"].split("/")[0] === "image";
  }

  const handleFileRead = async (event) => {
    const file = event.target.files[0] && event.target.files[0];
    if (file) {
      if (!isFileImage(file)) {
        appState.displaySnackbarMessage({
          title: Language.translate("Incorrect Image Type"),
          message: Language.translate("Please upload image type files only"),
          subMessage: file.name,
          variant: "error",
        });
        return;
      } else if (file?.size > dataSizeLimit) {
        //if adding a platform logo with size larger than 1 Mb, display error
        appState.displaySnackbarMessage({
          message:
            Language.translate("File too large") +
            ": " +
            (file.size / dataSizeLimit).toFixed(1) +
            "MB. " +
            Language.translate("Maximum") +
            ": 1MB",
          variant: "error",
        });
      } else {
        const base64 = await convertBase64(file);
        const data = base64.substring(base64.indexOf(",") + 1);
        // adding data to the file object
        file.data = data;
        file.id = 1;
        setUploadFile(file);
        document.getElementById("contained-button-file").value = "";
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadMsg = {
    title: mwState.title,
    text: Language.translate("Successfully Updated"),
    subText: null,
  };

  const uploadNewLogo = () => {
    if (uploadFile?.size > dataSizeLimit) {
      //if adding a platform logo with size larger than 1 Mb, display error
      appState.displaySnackbarMessage({
        message:
          Language.translate("File too large") +
          ": " +
          (uploadFile.size / dataSizeLimit).toFixed(1) +
          "MB. " +
          Language.translate("Maximum") +
          ": 1MB",
        variant: "error",
      });
    } else {
      updateModuleData(
        _.pick(uploadFile, ["id", "name", "type", "size", "data"]),
        `${mwState.routeUrl}/image/platform`,
        uploadMsg
      );
      //just to create artificial delay so the moduleData is set by then
      setTimeout(() => {
        setUploadFile(null);
      }, 300);
    }
  };

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleData: Images.get(),
    }));
  }, []);

  const setLogosState = () => {
    setMwState((p) => ({
      ...p,
      moduleData: Images.get(),
    }));
  };

  const classes = useStyles();

  return (
    <div className={classes.logoBody}>
      <Grid container direction="column">
        <Grid container item direction="row">
          <Grid container item direction="column" xs={6}>
            <Grid>
              <p className="titleInLogoBody">{Language.translate("Logo")}</p>
              {_.filter(mwState.moduleData, { tag: "platform" }).map(
                (item) =>
                  /*Check to see if the client_id is available, OR you are signed in as the provider admin
                If so, show the given image in the module. This is here to prevent customer admin from deleting the provider
                admins logo*/
                  (!_.isNull(item.client_id) ||
                    Profile.get().role_id === 1) && (
                    <React.Fragment key={item.id}>
                      <div className="logoDisplay">
                        <img
                          className="platformImage"
                          name="image"
                          alt={item.title}
                          src={Images.getImageSource(item.id)}
                        />
                      </div>
                      <br />
                      <CustomButton
                        className="LogoDeleteBtn"
                        onClick={deleteItem}
                        size="medium"
                        color="delete"
                        id="btnDelete"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                      >
                        {Language.translate("Delete")}
                      </CustomButton>
                    </React.Fragment>
                  )
              )}
              {_.isEmpty(_.filter(mwState.moduleData, { tag: "platform" })) && (
                <p>{Language.translate("No image saved")}</p>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {uploadFile && (
              <Grid>
                <p className="titleInLogoBody">
                  {Language.translate("Preview Image")}
                </p>
                <div className="logoPreview">
                  <img
                    className="picFile"
                    thumbnail="picFile"
                    alt=""
                    src={URL.createObjectURL(uploadFile)}
                  />
                </div>
                <br />
                <CustomButton
                  size="large"
                  color="cancel"
                  variant="contained"
                  type="submit"
                  onClick={() => uploadNewLogo()}
                  className="submitBtn"
                >
                  <CustomIcon id="icon-paper-plane" fontSize="small" />
                  {Language.translate("Submit")}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <br />
        <p className="selectImg">{Language.translate("Select an image")}</p>
        <Grid container item direction="row">
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.uploadButton}
            >
              <CustomIcon id="icon-upload" fontSize="small" />
              {Language.translate("Upload File").toUpperCase()}
            </Button>
          </label>
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={(e) => handleFileRead(e)}
            className="fileUploadInput"
          />
          <div className="fileNameBox">
            <p className="fileName">{uploadFile && uploadFile["name"]}</p>
          </div>
        </Grid>
      </Grid>
      <button
        style={{ display: "none" }}
        onClick={setLogosState}
        id="hiddenLogoBtn"
      >
        Hidden Button
      </button>
    </div>
  );
}

export default ModuleWrapperLogoBody;
