import React, { useContext } from "react";

// Material-UI
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LoopIcon from "@material-ui/icons/Loop";
import SendIcon from "@material-ui/icons/Send";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Client from "sccClient";
import Language from "sccLanguage";
import Permission from "sccPermission";
import Profile from "sccProfile";
import UserDetail from "../../user/body/UserDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  companyLabel: {
    display: "inline",
    color: theme.palette.colors.white.main,
    background: theme.palette.colors.gray.veryDark,
    marginLeft: 5,
    marginRight: 5,
    padding: "1px 4px",
  },
}));

export default function AdminUserList(props) {
  const data = props.data;
  const [mwState] = useContext(ModuleWrapperContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip
            title={
              data.username
                ? data.username
                : data.first_name + " " + data.last_name
            }
            placement="left"
          >
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={
            <React.Fragment>
              {data.first_name + " " + data.last_name}
              <span className={classes.companyLabel}>
                {Client.get(data.client_id)?.company || ""}
              </span>
              {!data.username ? (
                <Chip
                  label={Language.translate("Pending")}
                  size="small"
                  className={classes.pending}
                  icon={<LoopIcon className={classes.pending} />}
                />
              ) : (
                ""
              )}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          <React.Fragment>
            {!data.username && (
              <CustomTooltip
                title={Language.translate("Resend Registration")}
                placement="left"
              >
                <IconButton
                  edge="end"
                  aria-label="resend-registration"
                  onClick={() => mwState.handleResendRegistration(data.id)}
                >
                  <SendIcon />
                </IconButton>
              </CustomTooltip>
            )}
            {mwState.verifyEditPermission &&
              Permission.getAllRoles([data.role_id, "title"]) &&
              Profile.get().id !== data.id && (
                <CustomTooltip
                  title={Language.translate("Edit")}
                  placement="bottom"
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            {mwState.verifyDeletePermission &&
              Permission.getAllRoles([data.role_id, "title"]) &&
              Profile.get().id !== data.id && (
                <CustomTooltip
                  title={Language.translate("Delete")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      handleItemDelete(
                        `${mwState.routeUrl}/${data.id}`,
                        mwState.title,
                        "admin user",
                        `${data.first_name}`
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              )}
          </React.Fragment>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <UserDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
