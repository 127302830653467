import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PropTypes from "prop-types";
import clsx from "clsx";

const CustomDrawer = (props) => {
  const direction = props.direction;
  const open = props.open;
  const title = props.title;
  const titleIcon = props.titleIcon;
  const Component = props.component;
  const drawerWidth = props.width;
  const drawerHeight = props.height;
  const onCloseClick = props.onCloseClick;
  const displayErrorOnTop = props.errorOnTop;

  const anchor = direction === "ltr" ? "left" : "right";

  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      flexGrow: 1,
      overflowX: "hidden",
      width: drawerWidth || "100%",
      height: drawerHeight || "100%",
      zIndex: 100,
      backgroundColor: "rgba(255,255,255,0.8)",
      boxShadow:
        "0px 10px 10px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    },
    fullArea: {
      alignContent: "flex-start",
      height: "100%",
    },
    drawerHeader: {
      padding: theme.spacing(0, 1),
    },
    drawerTitle: {
      textAlign: "center",
      fontSize: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& svg": {
        fontSize: 22,
        marginRight: 8,
      },
    },
    content: {
      padding: "0 20px 8px 20px",
      height: "calc(100% - 56px)", // 56 is the height of title
    },
    errorOnTop: {
      paddingTop: "20px",
    },
  }));

  const classes = useStyles();
  return (
    <div>
      <Drawer
        variant="persistent"
        anchor={anchor}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={clsx(
            classes.fullArea,
            displayErrorOnTop ? classes.errorOnTop : null
          )}
        >
          <Grid container item xs={12} className={classes.drawerHeader}>
            {direction === "rtl" ? (
              <React.Fragment>
                <Grid item xs={2}>
                  <IconButton onClick={onCloseClick}>
                    <ChevronRightIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item xs={10} className={classes.drawerTitle}>
                  {titleIcon} {title}
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={10} className={classes.drawerTitle}>
                  {titleIcon} {title}
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={onCloseClick}>
                    <ChevronLeftIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <Grid container item xs={12} className={classes.content}>
            {Component}
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

CustomDrawer.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.object,
  direction: PropTypes.string,
  component: PropTypes.any,
};

CustomDrawer.defaultProps = {
  open: false,
  width: "450px",
  direction: "ltr",
};

export default CustomDrawer;
