import React, { Component } from "react";
import { ModuleWrapperContext } from "../../../../reusable-components/ModuleWrapper/ModuleWrapperContext";

// External
import _ from "lodash";
import Language from "sccLanguage";
import OlMap from "sccOlMapNew";
import log from "loglevel";

// Material-UI
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import PlaceIcon from "@material-ui/icons/Place";
import Paper from "@material-ui/core/Paper";

// Internal
import "./MapRightClickMenu.scss";

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default class MapRightClickMenu extends Component {
  constructor() {
    super();
    this.state = {
      title: null,
      items: null,
      addNewPOIclicked: false,
      coord: [],
    };

    if (!MapRightClickMenu.publicInstance) {
      MapRightClickMenu.publicInstance = this;
    }
  }

  static publicInstance;

  static contextType = ModuleWrapperContext;

  static updateMapRightClickMenu() {
    MapRightClickMenu.publicInstance.getMapRightClickMenu();
  }

  addContextMenuItems() {
    OlMap.addContextMenuItem({
      title: Language.translate("Center Map Here"),
      icon: <GpsFixedIcon />,
      onClick: function (coord) {
        OlMap.setCenterAndZoom(coord);
      },
    });
    OlMap.addContextMenuItem({
      title: Language.translate("Zoom In On Map"),
      icon: <ZoomInIcon />,
      onClick: function (coord) {
        const zoom = OlMap.mapQuickZoomInLevel;
        OlMap.setCenterAndZoom(coord, zoom);
      },
    });
    if (!window.name.includes("Extended")) {
      OlMap.addContextMenuItem({
        title: Language.translate("Add New POI"),
        icon: <PlaceIcon />,
        onClick: function (coord) {
          log.debug("context menu clicked on coordinate", coord);
          const olProj = require("ol/proj");

          const newCoord = olProj.transform(coord, "EPSG:3857", "EPSG:4326");
          MapRightClickMenu.publicInstance.setState({ addNewPOIclicked: true });
          MapRightClickMenu.publicInstance.setState({ coord: newCoord });
        },
      });
    }
  }

  getMapRightClickMenu() {
    this.setState({ items: OlMap.getOptions("contextMenu").items });
    this.setState({
      title: OlMap.formatCoordinate(
        OlMap.getOptions("contextMenu").eventCoordinate,
        "EPSG:3857"
      ),
    });
  }

  render() {
    const Poi = require("sccPoi").default;
    const PoiCategory = require("sccPoiCategory").default;
    const Permission = require("sccPermission").default;
    const PoiOverlay = require("sccPoiOverlay").default;
    const poiNumber = Poi.get() ? Object.keys(Poi.get()).length : 0;

    const { items } = this.state;
    const handleItems = (e, item) => {
      item.onClick(OlMap.getOptions("contextMenu").eventCoordinate);
      OlMap.closeContextMenu();
    };

    const showContextItems = items
      ? items.map((item) => (
          <ListItem button key={`btnMapRightClick${item.title.split(" ")[0]}`}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              onClick={(e) => handleItems(e, item)}
              primary={item.title.toUpperCase()}
            />
          </ListItem>
        ))
      : "";

    const showContextTitle = (
      <ListSubheader component="div">
        {this.state.title ? this.state.title : ""}
      </ListSubheader>
    );

    const obj = {
      tab: "POI",
      shortTitle: "POI",
      title: "POI",
      moduleName: "Poi",
      module: Poi,
      imageClass: "map-marker",
      extLink: false,
      isList: true,
      wrapperDisplayMode: MW_ADDEDIT_MODE,
      showFilter: true,
      showPagination: poiNumber > 10 ? true : false,
      showSearch: true,
      searchField: ["title"],
      filterField: [
        {
          title: "Category",
          list: _.assign(
            { null: { id: null, title: Language.translate("NATO") } },
            PoiCategory.get()
          ),
          id: "category_id",
          displayField: "title",
          searchField: "title",
        },
      ],
      showFooter: true,
      verifyAddPermission: Permission.verify("poi", "add"),
      verifyEditPermission: Permission.verify("poi", "edit"),
      verifyDeletePermission: Permission.verify("poi", "delete"),
      verifyShownPermission: Permission.verify("poi", "view"),
      mapLayer: PoiOverlay,
      coordinates: MapRightClickMenu.publicInstance.state.coord,
    };

    const [mwState] = this.context;

    const goToOpenModuleWrapperPopper = () => {
      mwState.openModuleWrapperPopper(obj);
      MapRightClickMenu.publicInstance.setState({ addNewPOIclicked: false }); //resetted this state
    };

    if (MapRightClickMenu.publicInstance.state.addNewPOIclicked == true)
      goToOpenModuleWrapperPopper();

    return (
      <Paper
        elevation={3}
        id={OlMap.getOptions("contextMenu").containerId}
        className="OlMapContextMenu"
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={showContextTitle}
        >
          {showContextItems}
        </List>
      </Paper>
    );
  }
}
