import React, { useState, useEffect, useContext } from "react";

// Material-UI
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import CustomIcon from "@Components/CustomIcon";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import AssetSelectorDisplay from "@Components/CustomAssetWrapper/AssetSelectorListingDisplay/AssetSelectorDisplay";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomCheckbox from "@Components/CustomCheckbox";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";
import Language from "sccLanguage";
import Alert from "sccAlert";
import User from "sccUser";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  alertChips: {
    marginRight: 10,
    marginTop: 5,
  },
  table: {
    "& .MuiTableCell-root": {
      padding: 5,
    },
    "& .tableBody": {
      overflow: "auto",
      "& .textOverflow": {
        display: "block",
        width: 100,
        paddingLeft: 5,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  noSelectionMessage: {
    textAlign: "center",
  },
}));

export default function ARDetail(props) {
  const data = props.data;
  const [value, setValue] = useState(0);
  const [subscribersDeviceCount, setSubscribersDeviceCount] = useState(0);
  const [membersCount, setMembersCount] = useState(0);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const totalDevicesFromGroups = assetSelectorState.totalDevicesFromGroups;

  const buildCollectionToDisplay = assetSelectorState.buildCollectionToDisplay;

  function handleTabs(e, val) {
    setValue(val);
  }

  const alertTypes = Object.values(Alert.getAlertType());
  const checkedSMS =
    data.subscribers.users &&
    Object.values(data.subscribers.users).map(
      (user) => user.send_sms && user.id
    );
  const checkedEmails =
    data.subscribers.users &&
    Object.values(data.subscribers.users).map(
      (user) => user.send_email && user.id
    );

  const classes = useStyles();

  function setSubscribersTotalDevice() {
    // Get devices from groupid's then set the subscriber count
    const groupDevicesCount =
      totalDevicesFromGroups(data.subscribers.groups, data.subscribers.devices)
        .length || 0;
    setSubscribersDeviceCount(groupDevicesCount);
  }

  function setMembersTotalDevice() {
    // Get devices from groupid's then set the member count
    const groupDevicesCount =
      totalDevicesFromGroups(data.members.groups, data.members.devices)
        .length || 0;
    setMembersCount(groupDevicesCount);
  }

  useEffect(() => {
    setSubscribersTotalDevice();
    setMembersTotalDevice();
  }, []);

  return (
    <Grid container item xs={12} className={classes.detailsContainer}>
      <Grid item xs={12}>
        <CustomListItemCollapse
          title={Language.translate("Alerts").toUpperCase()}
          color="primary"
          collapse={true}
          count={data.alerts.length}
        >
          {data.alerts.map((item) => (
            <Chip
              key={item}
              label={Language.translate(
                alertTypes.find((obj) => obj.id === item).type
              )}
              className={classes.alertChips}
              size="small"
            />
          ))}
        </CustomListItemCollapse>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <CustomListItemCollapse
            title={Language.translate("Subscribers").toUpperCase()}
            color="primary"
            collapse={true}
            count={
              subscribersDeviceCount +
              (Object.values(data.subscribers.users).length || 0)
            }
          >
            <Tabs
              value={value}
              onChange={handleTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab
                label={Language.translate("Users")}
                style={{ fontSize: 9 }}
                icon={
                  <Badge
                    color="primary"
                    badgeContent={Object.values(data.subscribers.users).length}
                  >
                    <CustomIcon style={{ fontSize: 9 }} id="icon-laptop" />
                  </Badge>
                }
              />
              <Tab
                label={Language.translate("Assets")}
                style={{ fontSize: 9 }}
                icon={
                  <Badge badgeContent={subscribersDeviceCount} color="primary">
                    <CustomIcon style={{ fontSize: 9 }} id="icon-group" />
                  </Badge>
                }
              />
            </Tabs>
            {value === 0 &&
              (Object.values(data.subscribers.users).length ? (
                <List>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {Language.translate("Name").toUpperCase()}{" "}
                          </TableCell>
                          <TableCell>
                            {Language.translate("Username").toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {Language.translate("Email").toUpperCase()}
                          </TableCell>
                          <TableCell>{Language.translate("SMS")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tableBody">
                        {Object.values(User.get()).map((item) => (
                          <TableRow key={item.id}>
                            <TableCell component="th" scope="row">
                              <span className="textOverflow">
                                {item.first_name + " " + item.last_name}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              {item.username}
                            </TableCell>
                            <TableCell align="right">
                              {
                                <CustomCheckbox
                                  name="send_email"
                                  checked={checkedEmails.indexOf(item.id) > -1}
                                  disabled
                                  color="primary"
                                />
                              }
                            </TableCell>
                            <TableCell align="right">
                              {item.phone_number ? (
                                <CustomCheckbox
                                  name="send_sms"
                                  checked={checkedSMS.indexOf(item.id) > -1}
                                  disabled
                                  color="primary"
                                />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </List>
              ) : (
                <p className={classes.noSelectionMessage}>
                  {Language.translate("No Users selected")}
                </p>
              ))}
            {value === 1 &&
              (subscribersDeviceCount ? (
                <AssetSelectorDisplay
                  collection={{
                    groups: [
                      buildCollectionToDisplay({
                        groups: data.subscribers.groups,
                        devices: data.subscribers.devices,
                      }),
                    ],
                  }}
                  name="subscribers"
                />
              ) : (
                <p className={classes.noSelectionMessage}>
                  {Language.translate("There are no Assets or Groups selected")}
                </p>
              ))}
          </CustomListItemCollapse>
        </Grid>
        <Grid item xs={6}>
          <CustomListItemCollapse
            title={Language.translate("Members").toUpperCase()}
            count={membersCount}
            color="primary"
            collapse={true}
          >
            {membersCount ? (
              <AssetSelectorDisplay
                collection={{
                  groups: [
                    buildCollectionToDisplay({
                      groups: data.members.groups,
                      devices: data.members.devices,
                    }),
                  ],
                }}
                name="members"
              />
            ) : (
              <p className={classes.noSelectionMessage}>
                {Language.translate("There are no Assets or Groups selected")}
              </p>
            )}
          </CustomListItemCollapse>
        </Grid>
      </Grid>
    </Grid>
  );
}
