import React, { createContext, Component } from "react";

export const SelectedGeofenceContext = createContext();

export default class SelectedGeofenceContextProvider extends Component {
  state = {
    moduleEditMode: "",
  };

  setModuleEditMode = (moduleEditMode) => {
    this.setState({ moduleEditMode });
  };

  render() {
    return (
      <SelectedGeofenceContext.Provider
        value={{
          geoState: this.state,
          setModuleEditMode: this.setModuleEditMode,
        }}
      >
        {this.props.children}
      </SelectedGeofenceContext.Provider>
    );
  }
}
