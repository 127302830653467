import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext, getIn } from "formik";

// Material-UI
import { makeStyles, Grid, Button, MenuItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// Components
import AssetGroupSelector from "@Components/CustomAssetWrapper/AssetGroupSelector_Devices/AssetGroupSelector";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomInputAdornment from "@Components/CustomInputAdornment";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomTextField from "@Components/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import theme from "@Styles/theme";

// Internal
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import SaMenu from "sccSaMenu";
import UserSetting from "sccUserSetting";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  assetsFormInfo: {
    margin: "20px 0",
    "& .encryptionButtonsToggle": {
      margin: "10px 0",
    },
    "& .encryptionButtons": {
      padding: "10px 5px",
      width: "calc(100% - 10px)",
      "& .encryptionButtonsGenerate": {
        float: "left",
      },
      "& .encryptionButtonsCancel": {
        float: "right",
      },
    },
    "& .unitsLabel": {
      padding: "6px 8px",
      backgroundColor: theme.palette.colors.gray.dark,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      fontSize: "0.9rem",
    },
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function AssetsFormInfo(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();

  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const deviceTypeFeatures = Device.getDeviceType(moduleItemData.type_id);
  const [membersCount, setMembersCount] = useState(0);
  const [selection, setSelection] = useState([1]);
  const [minSpeed, setMinSpeed] = useState("");
  const [maxSpeed, setMaxSpeed] = useState("");

  const hawkeyeDefaultString = {
    device_reporting: "Compressed Land",
    internal_speaker: true,
    network_selection: "Cellular Primary",
    change_network: 60,
    power_off: 60,
    stationary_toggle: true,
    report_interval: 600,
    stopped_speed: 2,
    time_stopped: 300,
    moving_toggle: true,
    moving_report_interval: 120,
    moving_speed: 4,
    report_turn: true,
    emergency_report: 15,
    speed_report_rate: 60,
    time_over_speed: 5,
    max_speed: 10,
    first_movement: false,
    delay_idle: 300,
    engine_report_interval: 0,
    roll_over: true,
    crash: true,
    speeding: false,
    show_idling_items: false,
  };
  const orionDefaultString = {
    report_i: "1 min",
    lock_report_i: false,
    alert_i: "1 min",
    lock_alert_i: false,
    distance_i: "0 meters",
    lock_distance_i: false,
    distance_enabled: false,
    lock_distance_enabled: false,
    measurements: "Metric",
    lock_measurements: false,
  };

  const newDevice = {
    id: null,
    client_id: null,
    sms: null,
    type: null, //validation purpose
    type_id: null,
    name: "",
    mode: 0,
    encryption_key: null,
    decryption_key: null,
    color: theme.palette.colors.black.main,
    min_speed: null,
    min_speed_displayed: null,
    max_speed: null,
    max_speed_displayed: null,
    comm_id: null,
    groups: [],
    vehicle: {
      vehicle_identification_number: null,
      vehicle_model: null,
      vehicle_manufacturer: null,
    },
    newSettingsCode: "",
  };

  useEffect(() => {
    formik.setFieldValue("groups", selection);
    setMembersCount(selection.length);
  }, [selection]);

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const deviceData = mwState.moduleItemData;
      if (
        deviceData.type === "Hawkeye 5500" &&
        deviceData.settings === "NULL"
      ) {
        // Add default settings to settings key
        deviceData.settings = JSON.stringify(hawkeyeDefaultString);
      }
      // check device type for NORTAC Orion device
      if (
        deviceData.type === "NORTAC Orion" &&
        deviceData.settings === "NULL"
      ) {
        // Add default settings to settings key
        deviceData.settings = JSON.stringify(orionDefaultString);
      }

      const settings =
        mwState.moduleItemData.settings === "NULL"
          ? null
          : JSON.parse(mwState.moduleItemData.settings);

      deviceData.min_speed_displayed = Utils.transformSpeedFromKph(
        deviceData.min_speed,
        UserSetting.get("speed_format")
      );
      deviceData.max_speed_displayed = Utils.transformSpeedFromKph(
        deviceData.max_speed,
        UserSetting.get("speed_format")
      );
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...newDevice,
          ...deviceData,
          settingsVal: settings,
          device_id: deviceData.id,
        },
        moduleItemDataOriginal: {
          ...newDevice,
          ...deviceData,
          settingsVal: settings,
          device_id: deviceData.id,
        },
      }));
      if (deviceData.min_speed !== null) {
        setMinSpeed(
          Utils.transformSpeedFromKph(
            deviceData.min_speed || "",
            UserSetting.get("speed_format")
          )
        );
      }
      if (deviceData.max_speed !== null) {
        setMaxSpeed(
          Utils.transformSpeedFromKph(
            deviceData.max_speed || "",
            UserSetting.get("speed_format")
          )
        );
      }
      setSelection(mwState.moduleItemData.groups);
    }
  }, [mwState.wrapperDisplayMode]);

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      handleSpecialSaveValidation: () =>
        props.handleSpecialSaveValidation(formik),
    }));
  }, [formik.errors]);

  const deviceType = props.deviceType;

  const colors = [
    {
      name: Language.translate("Default"),
      code: "#000000",
    },
    { name: Language.translate("Red"), code: "#ff0000" },
    { name: Language.translate("Blue"), code: "#0000ff" },
    {
      name: Language.translate("Green"),
      code: "#008000",
    },
    {
      name: Language.translate("Yellow"),
      code: "#e6e600",
    },
  ];

  const generateEncryption = (cancel) => {
    if (cancel) {
      formik.setFieldValue("encryption_key", "");
      formik.setFieldValue("decryption_key", "");
    } else {
      return Device.generateAes256().then(function (key) {
        formik.setFieldValue("encryption_key", key);
        formik.setFieldValue("decryption_key", key);
      });
    }
  };

  function handleFormInputSpeed(e) {
    handleFormBlur(e);

    const field = e.target.name;
    const value = e.target.value;

    if (field === "min_speed_displayed") {
      setMinSpeed(value);
      formik.setFieldValue(
        "min_speed",
        Utils.transformSpeedToKph(value, UserSetting.get("speed_format"))
      );
      formik.setFieldValue("min_speed_displayed", value);
    } else if (field === "max_speed_displayed") {
      setMaxSpeed(value);
      formik.setFieldValue(
        "max_speed",
        Utils.transformSpeedToKph(value, UserSetting.get("speed_format"))
      );
      formik.setFieldValue("max_speed_displayed", value);
    }
  }

  const updateIMEIstring = () => {
    if (["Trellisware TSM TW-950"].includes(deviceTypeFeatures.title)) {
      moduleItemData.imei = moduleItemData.Serial_No + "II000";
    } else {
      moduleItemData.imei =
        moduleItemData.Serial_No + "II" + moduleItemData.ale_address;
    }
  };

  function upperCase(e) {
    e.target.value = e.target.value.toUpperCase();
    handleFormInput(e);
  }

  const classes = useStyles();

  return (
    <div className={classes.assetsFormInfo}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={6}>
          <CustomTextField
            label={`${Language.translate("Name")}*`}
            value={moduleItemData.name}
            name="name"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={Language.translate("Device Type")}
            value={moduleItemData.type}
            name="type"
            disabled={true}
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          />
        </Grid>
        <Grid item xs={6}>
          {!_.isUndefined(deviceType) && (
            <CustomDropdown
              label={Language.translate("Device Mode")}
              name="mode"
              value={Number(moduleItemData.mode)}
              disabled={
                deviceType.components.mode === "disable" ||
                SaMenu.isDeviceAnSaSubscriber(moduleItemData.id) ||
                (Number(moduleItemData.mode) == 0 &&
                  Device.getParentDevice(moduleItemData))
              }
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.mode && Boolean(formik.errors.mode)}
              helperText={formik.touched.mode && formik.errors.mode}
            >
              {_.values(deviceType.modes).map((option) => (
                <MenuItem key={option.id} value={Number(option.id)}>
                  {option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          )}
        </Grid>
        <Grid item xs={6}>
          {!_.isUndefined(deviceType) && (
            <CustomDropdown
              label={Language.translate("Non Report Alert")}
              name="non_report_threshold"
              value={Number(moduleItemData.non_report_threshold)}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.non_report_threshold &&
                Boolean(formik.errors.non_report_threshold)
              }
              helperText={
                formik.touched.non_report_threshold &&
                formik.errors.non_report_threshold
              }
              disabled={
                Number(moduleItemData.mode) == 0 &&
                Device.getParentDevice(moduleItemData)
              }
            >
              {_.values(Device.getNonReportThreshold()).map((option) => (
                <MenuItem key={option.value} value={Number(option.value)}>
                  {option.title} {Language.translate(option.type)}
                </MenuItem>
              ))}
            </CustomDropdown>
          )}
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            label={Language.translate("Colour")}
            name="color"
            value={moduleItemData.color || "#000000"}
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.color && Boolean(formik.errors.color)}
            helperText={formik.touched.color && formik.errors.color}
          >
            {colors.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </CustomDropdown>
        </Grid>
        {!_.isUndefined(deviceType) &&
          (deviceType.components.mode === "disable" ||
            deviceType.components.mode === "visible") && (
            <Grid item xs={6}>
              <CustomTextField
                label={Language.translate("Comm ID")}
                value={Number(moduleItemData.comm_id)}
                name="comm_id"
                disabled={true}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={formik.touched.comm_id && Boolean(formik.errors.comm_id)}
                helperText={formik.touched.comm_id && formik.errors.comm_id}
              />
            </Grid>
          )}
        {deviceTypeFeatures.title === "NORTAC Orion" && (
          <Grid item xs="6">
            <CustomTextField
              label={`${Language.translate("IMEI")}`}
              value={moduleItemData.Serial_No}
              name="Serial_No"
              disabled={true}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.orionIMEI && Boolean(formik.errors.orionIMEI)
              }
              helperText={formik.touched.orionIMEI && formik.errors.orionIMEI}
            />
          </Grid>
        )}
        {Device.getHermesDevices().includes(deviceTypeFeatures.title) && (
          <Grid item xs="6">
            <CustomTextField
              label={`${Language.translate("Serial Number")}*`}
              value={moduleItemData.Serial_No}
              name="Serial_No"
              onChange={(e) => {
                upperCase(e);
                handleFormInput(e);
              }}
              disabled={true}
              onBlur={(e) => {
                handleFormBlur(e);
                updateIMEIstring();
              }}
              error={
                formik.touched.Serial_No && Boolean(formik.errors.Serial_No)
              }
              helperText={formik.touched.Serial_No && formik.errors.Serial_No}
            />
          </Grid>
        )}

        {![...Device.getHermesDevices(), "NORTAC Orion"].includes(
          deviceTypeFeatures.title
        ) && (
          <Grid item xs="6">
            <CustomTextField
              label={`${Language.translate("IMEI")}`}
              value={moduleItemData.imei}
              name="imei"
              disabled={true}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.imei && Boolean(formik.errors.imei)}
              helperText={formik.touched.imei && formik.errors.imei}
            />
          </Grid>
        )}

        {["NORTAC Orion"].includes(deviceTypeFeatures.title) ? (
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <CustomTextField
                label={`${Language.translate("Device ID")}*`}
                value={moduleItemData.imei}
                name="imei"
                onChange={(e) => upperCase(e)}
                onBlur={handleFormBlur}
                error={formik.touched.imei && Boolean(formik.errors.imei)}
                helperText={Language.translate(
                  formik.touched.imei && formik.errors.imei
                )}
              />
            </Grid>
          </Grid>
        ) : null}

        {Device.getHermesDevices().includes(deviceTypeFeatures.title) ? (
          deviceTypeFeatures.title === "Trellisware TSM TW-950" ? (
            <Grid item xs="6">
              <CustomTextField
                label={`${Language.translate("IP Address")}`}
                value={moduleItemData.ip_address}
                name="ip_address"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                disabled={true}
                error={
                  formik.touched.ip_address && Boolean(formik.errors.ip_address)
                }
                helperText={
                  formik.touched.ip_address && formik.errors.ip_address
                }
              />
            </Grid>
          ) : (
            <Grid item xs="6">
              <CustomTextField
                label={`${Language.translate("ALE Address")}`}
                value={moduleItemData.ale_address}
                name="imei"
                disabled={true}
                onChange={(e) => upperCase(e)}
                onBlur={(e) => {
                  handleFormBlur(e);
                  updateIMEIstring();
                }}
                error={
                  formik.touched.ale_address &&
                  Boolean(formik.errors.ale_address)
                }
                helperText={
                  formik.touched.ale_address && formik.errors.ale_address
                }
              />
            </Grid>
          )
        ) : null}

        {!_.isUndefined(deviceType) && deviceType.components.phone_number && (
          <Grid item xs={6}>
            <CustomTextField
              label={Language.translate("Telephone")}
              value={moduleItemData.sms}
              name="sms"
              disabled={true}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.sms && Boolean(formik.errors.sms)}
              helperText={formik.touched.sms && formik.errors.sms}
            />
          </Grid>
        )}
        {moduleItemData.type === "Hawkeye 5500" && moduleItemData.vehicle && (
          <React.Fragment>
            <Grid item xs={6}>
              <CustomTextField
                label={`${Language.translate("Vehicle Manufacturer")}`}
                value={moduleItemData.vehicle.vehicle_manufacturer}
                name="vehicle.vehicle_manufacturer"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={Boolean(
                  getIn(formik.touched, "vehicle.vehicle_manufacturer") &&
                    getIn(formik.errors, "vehicle.vehicle_manufacturer")
                )}
                helperText={
                  getIn(formik.touched, "vehicle.vehicle_manufacturer") &&
                  getIn(formik.errors, "vehicle.vehicle_manufacturer")
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label={Language.translate("Vehicle Model")}
                value={moduleItemData.vehicle.vehicle_model}
                name="vehicle.vehicle_model"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={Boolean(
                  getIn(formik.touched, "vehicle.vehicle_model") &&
                    getIn(formik.errors, "vehicle.vehicle_model")
                )}
                helperText={
                  getIn(formik.touched, "vehicle.vehicle_model") &&
                  getIn(formik.errors, "vehicle.vehicle_model")
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label={Language.translate("Vehicle Identification Number")}
                value={moduleItemData.vehicle.vehicle_identification_number}
                name="vehicle.vehicle_identification_number"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={Boolean(
                  getIn(
                    formik.touched,
                    "vehicle.vehicle_identification_number"
                  ) &&
                    getIn(
                      formik.errors,
                      "vehicle.vehicle_identification_number"
                    )
                )}
                helperText={
                  getIn(
                    formik.touched,
                    "vehicle.vehicle_identification_number"
                  ) &&
                  getIn(formik.errors, "vehicle.vehicle_identification_number")
                }
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {!_.isUndefined(deviceType) &&
        deviceType.components.cipher &&
        !Device.getHermesDevices().includes(deviceTypeFeatures.title) && (
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <CustomTextField
                label={Language.translate("Encryption")}
                value={moduleItemData.encryption_key}
                name="encryption_key"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.encryption_key &&
                  Boolean(formik.errors.encryption_key)
                }
                helperText={
                  formik.touched.encryption_key && formik.errors.encryption_key
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label={Language.translate("Decryption")}
                value={moduleItemData.decryption_key}
                name="decryption_key"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.decryption_key &&
                  Boolean(formik.errors.decryption_key)
                }
                helperText={
                  formik.touched.decryption_key && formik.errors.decryption_key
                }
              />
            </Grid>
            <div className="encryptionButtons">
              <Button
                className="encryptionButtonsGenerate"
                color="primary"
                variant="contained"
                onClick={() => generateEncryption(false)}
                disabled={
                  Number(moduleItemData.mode) == 0 &&
                  Device.getParentDevice(moduleItemData)
                }
              >
                {Language.translate("Generate Encryption")}
              </Button>
              <Button
                className="encryptionButtonsCancel"
                color="secondary"
                variant="contained"
                disabled={
                  Number(moduleItemData.mode) == 0 &&
                  Device.getParentDevice(moduleItemData)
                }
                onClick={() => generateEncryption(true)}
              >
                {Language.translate("Cancel Encryption")}
              </Button>
            </div>
          </Grid>
        )}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CustomTextField
            label={Language.translate("Min Speed")}
            value={minSpeed}
            onChange={handleFormInputSpeed}
            onBlur={handleFormInputSpeed}
            name="min_speed_displayed"
            type="number"
            disabled={
              Number(moduleItemData.mode) == 0 &&
              Device.getParentDevice(moduleItemData)
            }
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position="end">
                  <CustomTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <Typography>
                          <b>{Language.translate("Min Speed")}</b>
                          <br />
                          <span>
                            {Utils.transformSpeedFromKph(
                              moduleItemData.min_speed,
                              "KPH"
                            )}{" "}
                            <b>{Language.translate("KPH")}</b>
                          </span>
                          <br />
                          <span>
                            {Utils.transformSpeedFromKph(
                              moduleItemData.min_speed,
                              "MPH"
                            )}{" "}
                            <b>{Language.translate("MPH")}</b>
                          </span>
                          <br />
                          <span>
                            {Utils.transformSpeedFromKph(
                              moduleItemData.min_speed,
                              "KNOTS"
                            )}{" "}
                            <b>{Language.translate(" KNOTS")}</b>
                          </span>
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span className="unitsLabel">
                      {UserSetting.get("speed_format")}
                    </span>
                  </CustomTooltip>
                </CustomInputAdornment>
              ),
            }}
            error={
              formik.touched.min_speed_displayed &&
              Boolean(formik.errors.min_speed_displayed)
            }
            helperText={
              formik.touched.min_speed_displayed &&
              formik.errors.min_speed_displayed
            }
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            label={Language.translate("Max Speed")}
            value={maxSpeed}
            onChange={handleFormInputSpeed}
            onBlur={handleFormInputSpeed}
            name="max_speed_displayed"
            type="number"
            disabled={
              Number(moduleItemData.mode) == 0 &&
              Device.getParentDevice(moduleItemData)
            }
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position="end">
                  <CustomTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <Typography>
                          <b>{Language.translate("Max Speed")}</b>
                          <br />
                          <span>
                            {Utils.transformSpeedFromKph(
                              moduleItemData.max_speed,
                              "KPH"
                            )}{" "}
                            <b>{Language.translate("KPH")}</b>
                          </span>
                          <br />
                          <span>
                            {Utils.transformSpeedFromKph(
                              moduleItemData.max_speed,
                              "MPH"
                            )}{" "}
                            <b>{Language.translate("MPH")}</b>
                          </span>
                          <br />
                          <span>
                            {Utils.transformSpeedFromKph(
                              moduleItemData.max_speed,
                              "KNOTS"
                            )}{" "}
                            <b>{Language.translate(" KNOTS")}</b>
                          </span>
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span className="unitsLabel">
                      {UserSetting.get("speed_format")}
                    </span>
                  </CustomTooltip>
                </CustomInputAdornment>
              ),
            }}
            error={
              formik.touched.max_speed_displayed &&
              Boolean(formik.errors.max_speed_displayed)
            }
            helperText={
              formik.touched.max_speed_displayed &&
              formik.errors.max_speed_displayed
            }
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            type="text"
            label={Language.translate("Unit of Speed")}
            name="radius"
            value={UserSetting.get("speed_format")}
            onChange={handleFormInput}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <CustomTextField
            label={Language.translate("Annotation")}
            value={moduleItemData.annotation}
            name="annotation"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={
              formik.touched.annotation && Boolean(formik.errors.annotation)
            }
            helperText={formik.touched.annotation && formik.errors.annotation}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <CustomListItemCollapse
            title={Language.translate("Asset Groups").toUpperCase()}
            color="primary"
            collapse={true}
            count={membersCount}
          >
            <AssetGroupSelector
              collection={Group.getGroupTree().groups}
              selection={selection}
              setSelection={setSelection}
              name="assetGroups"
              mainGroupName={mwState.mainGroupName}
            />
          </CustomListItemCollapse>
        </Grid>
      </Grid>
    </div>
  );
}
