import React, { createContext, Component } from "react";
import UserSetting from "sccUserSetting";
import Utils from "sccUtils";
import log from "loglevel";

export const UserSettingContext = createContext({});

export default class UserSettingContextProvider extends Component {
  state = {
    userSettingData: {},
    openSoundsBox: true,
  };

  setUserSettingData = (userSettingData) => {
    this.setState({ userSettingData });
  };

  setOpenSoundsBox = (openSoundsBox) => {
    this.setState({ openSoundsBox });
  };

  componentDidMount() {
    if (UserSetting.routeUrl) {
      const options = {
        url: UserSetting.routeUrl,
        method: "GET",
      };
      Utils.httpRequestHandler(options)
        .then((response) => {
          if (response?.data?.result) {
            this.setUserSettingData(response.data.result);
          }
        })
        .catch((e) => log.info(e));
    }
  }

  render() {
    return (
      <UserSettingContext.Provider
        value={{
          userSettingData: this.state.userSettingData,
          setUserSettingData: this.setUserSettingData,
          openSoundsBox: this.state.openSoundsBox,
          setOpenSoundsBox: this.setOpenSoundsBox,
        }}
      >
        {this.props.children}
      </UserSettingContext.Provider>
    );
  }
}
