import { default as Module } from "sccModule";
var Permission= require("sccPermission");
const log = require("loglevel");

/**
 * The base class for Situational Awarness
 * 
 * @class Sa
 */
class SaModule extends Module.Module{
	constructor() {
		var options= {
			moduleName: "sa"
		};

		super(options);
	}

	init(){
		if(!Permission.default.verify("sa", "view")){
			log.debug("User is not permitted for Situational Awarness module");
			return Promise.resolve();
		} 

		return Module.Module.prototype.init.call(this);
	}
}

export default new SaModule();
