import React from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import DeviceMenu from "sccDeviceMenu";
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  deviceClusterDisplayHeader: {
    backgroundColor: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: theme.palette.colors.blue.dark,
  },

  deviceClusterToolBar: {
    padding: "2px 0px",
    minHeight: 30,
    display: "flex",
    backgroundColor: theme.palette.colors.gray.main,
  },

  deviceAvatar: {
    backgroundColor: "transparent",
    color: theme.palette.colors.black.main,
    width: 28,
    height: 28,

    "& > svg": {
      fontSize: 12,
    },
  },

  deviceClusterButton: {
    minHeight: 30,
    minWidth: 30,
    height: 30,
    width: 30,
    color: theme.palette.colors.gray.dark,

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: theme.palette.colors.blue.dark,
    },
  },

  deviceClusterterHeaderTitle: {
    flex: 1,
    fontSize: 18,
    padding: "0px 10px",
    color: theme.palette.colors.blue.dark,
  },

  deviceCloseIcon: {
    fontSize: "1.2rem",
    color: alpha(theme.palette.colors.black.main, 0.25),

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: theme.palette.colors.blue.dark,
    },
  },
}));

export default function ClusterDisplayHeader(props) {
  const classes = useStyles();

  const items = props.items;

  // on top-right close button click
  const handleClose = () => {
    if (props.history === "1") {
      HistoryOverlay.hideClusterPopup();
      HistoryOverlay.deselectFeature();
    } else {
      DeviceOverlay.hideClusterPopup();
      DeviceOverlay.deselectFeature();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.deviceClusterDisplayHeader}>
        <Toolbar className={classes.deviceClusterToolBar}>
          <Avatar className={classes.deviceAvatar}>
            <CustomIcon id={`icon-${DeviceMenu._options.imageClass}`} />
          </Avatar>
          <Typography
            variant="h6"
            className={classes.deviceClusterterHeaderTitle}
          >
            {Language.translate("Assets")} ({items.length})
          </Typography>
          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <Button
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.deviceClusterButton}
            >
              <CloseIcon className={classes.deviceCloseIcon} />
            </Button>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
