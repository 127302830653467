import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Popover from "@material-ui/core/Popover";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// Internal
import Language from "sccLanguage";
import Poi from "sccPoi";
import PoiOverlay from "sccPoiOverlay";
import PoiCategory from "sccPoiCategory";
import { PoiCategoryContext } from "../../../poi/context/PoiCategoryContext";

const StyledBadge = withStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

var poiData = [];

const useStyles = makeStyles({
  root: {
    padding: "0 15px",
  },
  h5: {
    fontWeight: "bold",
    "& svg": {
      marginRight: "2px",
      position: "relative",
      top: "2px",
    },
    paddingLeft: 8,
  },
  poiCatLabelsContainer: {
    paddingLeft: 15,
    width: 270,
  },
  poiCatLabel: {
    fontSize: "1.1rem",
    maxWidth: 230,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  poiCategoryList: {
    display: "flex",
    alignItems: "center",
  },

  mapOverlayPoiCategoryTotal: {
    padding: "7px 15px",
    fontSize: "1.1rem",
  },
  mapOverlayPoiCategory: {
    position: "absolute",
    float: "right",
    right: 0,

    "& button": {
      padding: 6,
    },
  },
});

export default function MapOverlayPoiCategory(props) {
  const { selectedPoiCategoryArr, setSelectedPoiCategoryArr } =
    useContext(PoiCategoryContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    poiData = Poi.get();
    _.forEach(poiData, (poi) => {
      if (poi.category_id == null) poi.category_id = 0;
    });
    //undoing the category_id change from null to 0 as this causes the filter in POI module to stop working
    //since both screens are never open together, just this change should work, with no other changes in this module
    return function cleanup() {
      _.forEach(poiData, (poi) => {
        if (poi.category_id == 0) poi.category_id = null;
      });
    };
  });
  const handlePoiCategoryChange = (e) => {
    if (e.target.checked) {
      const newPoiCategoryArr = [
        ...selectedPoiCategoryArr,
        Number(e.target.value),
      ];
      setSelectedPoiCategoryArr(newPoiCategoryArr);
      filterChangeOverlay("category_id", _.uniq(newPoiCategoryArr));
    } else {
      const newPoiCategoryArr = selectedPoiCategoryArr.filter((a) => {
        if (a === Number(e.target.value)) return false;
        return true;
      });
      setSelectedPoiCategoryArr(newPoiCategoryArr);
      filterChangeOverlay("category_id", _.uniq(newPoiCategoryArr));
    }
  };

  // Sets and removes the POI category overlays
  const filterChangeOverlay = (filterKey, filterValues) => {
    if (filterValues == null || filterValues.length == 0) {
      PoiOverlay.unsetFilter(filterKey);
    } else {
      PoiOverlay.setFilter(filterKey, filterValues);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "MapOverlayPoiCategory" : undefined;

  const objPoiCategories = PoiCategory.get();
  let poiCategories = !_.isEmpty(objPoiCategories)
    ? Object.values(objPoiCategories)
    : [];

  // add Nato(id is 0) to poiCategories
  poiCategories.unshift({ id: 0, title: "NATO" });

  const showPoiCategories = poiCategories
    ? poiCategories.map((item) => (
        <div key={item.id} className={classes.poiCategoryList}>
          <FormControlLabel
            className={classes.poiCatLabelsContainer}
            value={item.id}
            onChange={(e) => handlePoiCategoryChange(e)}
            control={
              <Checkbox
                disabled={
                  _.filter(_.values(poiData), { category_id: item.id })
                    .length === 0
                }
                color="primary"
                name={item.title}
                checked={selectedPoiCategoryArr.includes(item.id)}
              />
            }
            label={
              <Typography className={classes.poiCatLabel}>
                {item.title}
              </Typography>
            }
          />
          <div className={classes.mapOverlayPoiCategoryTotal}>
            ({_.filter(_.values(poiData), { category_id: item.id }).length || 0}
            )
          </div>
        </div>
      ))
    : "";

  return (
    <span className={classes.mapOverlayPoiCategory}>
      <IconButton
        aria-describedby={id}
        variant="contained"
        color="primary"
        aria-label="filter"
        onClick={handleClick}
      >
        <StyledBadge badgeContent={showPoiCategories.length} color="secondary">
          <FilterListIcon fontSize="large" />
        </StyledBadge>
      </IconButton>
      <Popover
        id={id}
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <h5 className={classes.h5}>
          <FilterListIcon />
          {Language.translate("Filter")}
        </h5>
        {showPoiCategories}
      </Popover>
    </span>
  );
}
