import React from "react";
import { HistoryContextProvider } from "./HistoryContext";
import History from "./History";

function Wrapper() {
	return (
		<HistoryContextProvider>
			<History />
		</HistoryContextProvider>
	);
}

export default Wrapper;
