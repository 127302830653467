import React, { useState } from "react";

// External
import _ from "lodash";
import log from "loglevel";

// Internal
import Historic from "sccHistory";
import Device from "sccDevice";
import HistoryOverlay from "sccHistoryOverlay";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";
import Language from "sccLanguage";

const HistoryContext = React.createContext([{}, () => {}]);

const HistoryContextProvider = (props) => {
  const [state, setState] = useState({
    hideNoEvents: false,
    Historic,
    Device,
    Language,
    updateObjectsInState,
    updateKeyInState,
    resetTrailOverlay,
    showDeviceTrailChanged,
    deviceVisibilityChanged,
    sliderChanged,
  });

  function updateObjectsInState({ objjects }) {
    setState((p) => {
      return Object.assign({}, p, { objjects });
    });
  }

  function updateKeyInState(key, value) {
    setState((p) => {
      return Object.assign({}, p, { [key]: value });
    });
  }

  function showDeviceTrailChanged(value) {
    if (value) return;
    resetTrailOverlay();
  }

  /**
   * refreshes the trail overlay
   */
  function resetTrailOverlay() {
    const pastReports = Historic.getPastDeviceReports();
    HistoryTrailOverlay.removeFeature();
    HistoryTrailOverlay.addFeatures(pastReports);
  }

  function deviceVisibilityChanged(deviceIds) {
    deviceIds = _.concat([], deviceIds);
    _.each(deviceIds, (deviceId) => {
      if (!Historic.playOptions.device[deviceId].showTracks) {
        Historic.playOptions.device[deviceId].isFollow = false;
        Historic.playOptions.device[deviceId].showTrail = false;
        setState((p) => {
          return Object.assign({}, p, { Historic });
        });
      }
    });
    Historic.resetEventsStructures();
    sliderChanged();
  }

  function sliderChanged() {
    const timestamp = Historic.currentTimestamp;
    log.debug("history slider changed to timestamp", timestamp);
    const data = _.values(Historic.getCurrentDeviceReports());
    HistoryOverlay.removeFeature();
    HistoryOverlay.addFeature({ data: data });

    resetTrailOverlay();
  }

  return (
    <HistoryContext.Provider value={[state, setState]}>
      {props.children}
    </HistoryContext.Provider>
  );
};

export { HistoryContext, HistoryContextProvider };
