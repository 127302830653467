import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import IconButton from "@material-ui/core/IconButton";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Internal
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";
import CustomTooltip from "@Components/CustomTooltip";
import { UserSettingContext } from "../../../modules/user_setting/context/UserSettingContext";

export default function LocationButton({ deviceId }) {
  const { userSettingData } = useContext(UserSettingContext);
  const [isShow, setIsShow] = useState(false);

  const userSettingReportAge = userSettingData["report_age"];

  const reportAgeSeconds = userSettingReportAge * 60 * 60;

  const handleZoom = (e, id) => {
    e.stopPropagation();
    DeviceOverlay.centerAndZoomTo(id);
  };

  useEffect(() => {
    isShowLocationButton(deviceId);
    // update is show location icon every 5 second
    const interval = setInterval(() => isShowLocationButton(deviceId), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isShowLocationButton = (deviceId) => {
    const nowReportTime = TimeUtils.getTimestamp();

    const deviceInfo = Device.get(deviceId);

    if (
      userSettingReportAge !== -1 &&
      nowReportTime - reportAgeSeconds <= deviceInfo.report_timestamp &&
      deviceInfo.latitude &&
      deviceInfo.longitude
    ) {
      return setIsShow(true);
    } else if (
      userSettingReportAge === -1 &&
      deviceInfo.report_timestamp &&
      deviceInfo.latitude &&
      deviceInfo.longitude
    ) {
      return setIsShow(true);
    } else {
      return setIsShow(false);
    }
  };

  return isShow ? (
    <CustomTooltip title={Language.translate("Zoom")} arrow placement="top">
      <IconButton
        edge="end"
        aria-label="edit"
        onClick={(e) => handleZoom(e, deviceId)}
      >
        <GpsFixedIcon />
      </IconButton>
    </CustomTooltip>
  ) : null;
}
