import GeofenceSetting from "../../geofence/scripts/GeofenceSetting";
var _ = require("lodash");
var Menu = require("sccMenu").default;
var Geofence = require("sccGeofence").default;
const GeofenceOverlay = require("sccGeofenceOverlay");
var Utils = require("sccUtils").default;

/**
 * Geo-Fence menu class
 *
 * @class GeofenceMenuModule
 * @param {object} options - geo-fence menu information object
 */
class GeofenceMenuModule extends Menu {
  constructor() {
    const options = {
      tab: "Geofence",
      shortTitle: "GEO",
      title: "Geo-Fence",
      mainClass: "Geofence",
      mapLayer: GeofenceOverlay,
      searchable: true,
      imageClass: "geofence",
      imageIconMenu: false,
      module: Geofence,
      showPagination: true,
    };

    super(options);
  }

  /**
   * overriding the save method of the Menu class
   */
  save() {
    return super.save().then(() => {
      GeofenceOverlay.finishEditing();
      return Promise.resolve();
    });
  }

  /**
   * @override
   */
  add(obj) {
    obj = _.assign(obj, {
      inclusive: true,
      active: false,
      triggers: { groups: [], devices: [] },
      sync: { groups: [], devices: [] },
    });
    GeofenceOverlay.setBlockSelection(true);
    GeofenceOverlay.resetEndReshape();
    super.add(obj);
  }

  /**
   * deletes a given object from data
   * @param {Object} obj object to be deleted
   */
  delete(obj) {
    GeofenceOverlay.finishEditing();
    GeofenceOverlay.resetEndReshape();
    return super.delete(obj);
  }

  /**
   * @override
   * @param {*} obj
   */
  edit(obj) {
    GeofenceOverlay.selectFeatureById(obj.id, true);
    GeofenceOverlay.resetEndReshape();
    return super.edit(obj);
  }

  /**
   * @override
   */
  cancelEdit() {
    var $this = this;
    return super.cancelEdit().then(function () {
      var geofenceId = $this.$scope.editObj && $this.$scope.editObj.id;
      GeofenceOverlay.finishEditing();
      if (geofenceId) {
        const geofence = Geofence.get(geofenceId);
        GeofenceOverlay.refresh(geofence);
        GeofenceOverlay.resetEndReshape();
      }

      return Promise.resolve();
    });
  }

  geofenceSyncAccept(geofence) {
    var options = {
      url: Utils.apiUrlPrefix + "/geofence/accept",
      method: "PUT",
      body: { id: geofence.id, approved: true },
    };

    return Utils.httpRequestHandler(options);
  }

  geofenceSyncReject(geofence) {
    var options = {
      url: Utils.apiUrlPrefix + "/geofence/decline/" + geofence.id,
      method: "DELETE",
    };

    return Utils.httpRequestHandler(options).then(function () {
      geofence.kill && geofence.kill(true);
    });
  }
}

export default new GeofenceMenuModule();
