import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomTooltip from "@Components/CustomTooltip";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PublicIcon from "@material-ui/icons/Public";

import "./MapZoomButtons.scss";

const Language = require("sccLanguage").default;

const useStyles = makeStyles((theme) => ({
  btnGroup: {
    width: 36,
  },
}));

export default function MapZoomButtons(props) {
  const OlMap =
    props.history == 1
      ? require("sccOlMapHistory").default
      : require("sccOlMapNew").default;
  const [levels, setLevels] = useState(OlMap.getZoom());

  const zoomOutFull = () => {
    OlMap.fullZoomOut();
    setLevels(OlMap.getZoom());
  };

  const zoomIn = () => {
    OlMap.zoomIn();
    setLevels(OlMap.getZoom());
  };

  const zoomOut = () => {
    OlMap.zoomOut();
    setLevels(OlMap.getZoom());
  };

  ////////////////////////////////////////////////
  //Update zoom levels - Have to find a better way
  ////////////////////////////////////////////////
  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanUp() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setLevels(OlMap.getZoom());
  }

  const classes = useStyles();

  const mapZoomButtons = OlMap.getOptions("zoomDisplay") ? (
    <ButtonGroup
      orientation="vertical"
      aria-label="vertical contained primary button group"
      variant="contained"
      className={classes.btnGroup}
    >
      <Button
        startIcon={
          <CustomTooltip
            title={Language.translate("Zoom Out Full")}
            arrow
            placement="left"
          >
            <PublicIcon />
          </CustomTooltip>
        }
        onClick={zoomOutFull}
      ></Button>

      <Button
        startIcon={
          <CustomTooltip
            title={Language.translate("Zoom In")}
            arrow
            placement="left"
          >
            <AddIcon />
          </CustomTooltip>
        }
        onClick={zoomIn}
      ></Button>

      <Button className="zoomLevels">
        <CustomTooltip
          title={Language.translate("Map Zoom Levels")}
          arrow
          placement="left"
        >
          {levels}
        </CustomTooltip>
      </Button>

      <Button
        startIcon={
          <CustomTooltip
            title={Language.translate("Zoom Out")}
            arrow
            placement="left"
          >
            <RemoveIcon />
          </CustomTooltip>
        }
        onClick={zoomOut}
      ></Button>
    </ButtonGroup>
  ) : (
    ""
  );

  return (
    <div id={OlMap.getOptions("zoomDisplay").elementId} className="hide-mobile">
      <div>{mapZoomButtons}</div>
    </div>
  );
}
