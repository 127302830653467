import { default as Module } from "sccModule";

/**
 * The base object for EnvVar module
 * 
 */
class EnvVarModule extends Module.Module{
	constructor(){
		var options= {
			moduleName: "env_var"
		};
		super(options);
	}
}

export default new EnvVarModule();
