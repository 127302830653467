import React, { Component } from "react";

// Internal
import DataDisplayButtonGroup from "./DataDisplayButtonGroup";
import POIInfo from "./POIInfo";
import DataDisplayHeader from "./DataDisplayHeader";
import MinimizeContextProvider from "../context/MinimizeContext.js";
import POI from "sccPoi";
import POIOverlay from "sccPoiOverlay";

// Material-UI
import Paper from "@material-ui/core/Paper";

export default class DataDisplay extends Component {
  constructor() {
    super();
    this.state = {
      device: null,
    };

    if (!DataDisplay.publicInstance) {
      DataDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      DataDisplay.publicInstance.setFeatureSelected();
    });
  }

  setFeatureSelected() {
    this.setState({ device: POI.get(POIOverlay.getSelectedId()) });
  }

  render() {
    const { device } = this.state;
    return (
      <MinimizeContextProvider>
        <Paper elevation={3} id={"olMapPoiPopup"}>
          {device?.id ? (
            <React.Fragment>
              <DataDisplayHeader device={device} />
              {
                <React.Fragment>
                  <DataDisplayButtonGroup device={device} />
                  <POIInfo device={device} />
                </React.Fragment>
              }
            </React.Fragment>
          ) : null}
        </Paper>
      </MinimizeContextProvider>
    );
  }
}
