import { default as Module } from "sccModule";
import { default as Permission } from "sccPermission";
import { default as Utils } from "sccUtils";
import { default as log } from "loglevel";

/**
 * The base class for Pois
 *
 * @class Poi
 */
class PoiModule extends Module.Module {
  constructor() {
    super({
      moduleName: "poi",
    });
  }

  init() {
    if (!Permission.verify("poi", "view")) {
      log.debug("User is not permitted for POI module");
      return Promise.resolve();
    }

    return Module.Module.prototype.init.call(this);
  }
}

PoiModule.prototype.getNatoSymbol = function (code, options) {
  options = options || { size: 60 };
  var MS = require("milsymbol");
  var sym = new MS.Symbol(code, options);

  var marker = sym.asCanvas().toDataURL();
  return marker;
};

/* ADD Notification when new poi threat is  synced */
PoiModule.prototype.onSocketAdd = function (url, data) {
  Module.Module.prototype.onSocketAdd.call(this, url, data);

  const PoiOverlay = require("sccPoiOverlay").default;
  PoiOverlay.refresh(data);

  if (!data.creator_device_id) {
    return;
  }
  var str = data.nato_code;

  if (!data.approved) {
    if (str) {
      Utils.poiMenuNotify(data.title, data.note, "", "success", data.id);
    }
  } /* end if !data.approved */
};

PoiModule.prototype.onSocketUpdate = function (url, data) {
  Module.Module.prototype.onSocketUpdate.call(this, url, data);

  if (data.approved) {
    var poi = this.get(data.id);
    poi.kill && poi.kill(true);
  }
  const PoiOverlay = require("sccPoiOverlay").default;
  const { default: PoiDataDisplay } = require("../components/DataDisplay");
  PoiDataDisplay.updateFeatureSelected();
  PoiOverlay.refresh(data);
};

PoiModule.prototype.onSocketDelete = function (url, data) {
  const PoiOverlay = require("sccPoiOverlay").default;
  PoiOverlay.refresh(null, data);
  var poi = this.get(data.id);
  poi.kill && poi.kill(true);
  Module.Module.prototype.onSocketDelete.call(this, url, data);
  const { default: PoiDataDisplay } = require("../components/DataDisplay");
  PoiDataDisplay.updateFeatureSelected();
};

//module.exports= New PoiModule();
export default new PoiModule();
