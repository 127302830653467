import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";

function CustomInputAdornment(props) {
  const { position } = props;
  return (
    <InputAdornment position={position} {...props}>
      {props.children}
    </InputAdornment>
  );
}

export default CustomInputAdornment;
