import { default as Module } from "sccModule";
const Permission= require("sccPermission");
const log = require("loglevel")
/**
 * The base class for IridiumBillingModule
 * 
 * @class IridiumBillingModule
 */
class IridiumBillingModule extends Module.Module{
	constructor(){
		super({
			moduleName: "iridium_billing",
			routeUrl: "/ib/api/v1/iridium_billing"
		});
	}

	init(){
		log.debug("User for Iridium Billing module", Permission.default.verify("iridium_billing", "add"));
		if(!Permission.default.verify("iridium_billing", "view")){
			log.debug("User is not permitted for Iridium Billing module");
			return Promise.resolve();
		}
	}
}

export default new IridiumBillingModule();
