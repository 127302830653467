// External
import _ from "lodash";
import log from "loglevel";

// Internal
import Client from "sccClient";
import Module from "sccModule";
import Permission from "sccPermission";
import Utils from "sccUtils";

/**
 * The base class for AdminUserModule
 * @class AdminUserModule
 **/
class AdminUserModule extends Module.Module {
  constructor() {
    super({
      moduleName: "admin_user",
    });
  }

  init() {
    if (!Permission.verify("admin_user", "view")) {
      log.debug("User is not permitted for User module");
      return Promise.resolve();
    }

    return super.init();
  }

  onSocketAdd(url, data) {
    // updates customer data and adds a user to the client/customer editObj user array
    Client.get(data.client_id).users.push(data.id);
    Client.get(data.client_id).main_user_id = data.id;

    Module.Module.prototype.onSocketAdd.call(this, url, data);
  }

  onSocketDelete(url, data) {
    // updates customer data and removes a user from the client/customer editObj user array
    Client.get(data.client_id).users.pop(data.id);
    Client.get(data.client_id).main_user_id = null;

    Module.Module.prototype.onSocketDelete.call(this, url, data);
  }

  resendRegistration(user) {
    const userid = user.id;
    const data = {
      id: userid,
    };

    return Utils.httpRequestHandler({
      method: "POST",
      url: Utils.apiUrlPrefix + "/user/resend_registration",
      data: data,
    });
  }

   // gets the list of customers who do not have any customer admin users
   getAvailableCustomers() {
    const newAvailableCustomers = _.filter(Client.get(), {
      main_user_id: null})
      .map(function (value) {
        const result = {};
        result.title = value.company;
        result.value = value.id;
        return result;
    });
    return newAvailableCustomers;
  }
  
}
export default new AdminUserModule();
