import React from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function MapsDetail(props) {
  const data = props.data;

  const classes = useStyles();

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <List>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Name")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.title}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Map Code")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.code}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
