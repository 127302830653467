import { default as Module } from "sccModule";
const Utils= require("sccUtils");

/**
 * The base class for Cargo
 * 
 * @class Cargo
 */
class CargoModule extends Module.Module{
	constructor(){
		var options= {
			moduleName: "cargo",
			routeUrl: Utils.apiUrlPrefix+ "/device/cargo"
		};
		super(options);
	}
}

export default new CargoModule();
