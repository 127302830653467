import React, { useEffect, useState } from "react";
import Paginate from "./Paginate";
import Language from "sccLanguage";
import "./CustomPagination.scss";

function CustomPagination(props) {
  const { currentPage, itemsPerPage, numberOfItems, maxPages } =
    props.pagination;
  const [pager, setPager] = useState(
    Paginate(currentPage, itemsPerPage, numberOfItems, maxPages)
  );

  const labels = {
    first: Language.translate("First"),
    last: Language.translate("Last"),
    previous: Language.translate("Previous"),
    next: Language.translate("Next"),
  };

  useEffect(() => {
    setPage(currentPage);
  }, [numberOfItems, currentPage, itemsPerPage]);

  function setPage(page) {
    //check and reset the page if getting out of bounds
    const totalPages = Math.ceil(numberOfItems / Number(itemsPerPage));
    if (page < 1) page = 1;
    if (page > totalPages) page = totalPages;

    // get new pager object for specified page and update the state
    const paginate = Paginate(
      page,
      Number(itemsPerPage),
      numberOfItems,
      maxPages
    );
    setPager(paginate);
    // call change page function in parent component
    //supplying the currentpage and start and end index of the items to show
    props.onPageChange(page, paginate.startIndex, paginate.endIndex + 1);
  }

  return (
    pager.pages &&
    pager.pages.length > 1 &&
    numberOfItems > itemsPerPage && (
      <ul className="pagination">
        <li
          className={`page-item first ${
            pager.currentPage === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
              setPage(1);
            }}
          >
            {labels.first}
          </button>
        </li>
        <li
          className={`page-item previous ${
            pager.currentPage === 1 ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
              setPage(pager.currentPage - 1);
            }}
          >
            {labels.previous}
          </button>
        </li>
        {pager.startPage >= maxPages ? (
          <li className={"page-item page-number"}>
            <button
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                setPage(pager.startPage - 2);
              }}
            >
              ...
            </button>
          </li>
        ) : null}
        {pager.pages.map((page, index) => (
          <li
            key={index}
            className={`page-item page-number ${
              pager.currentPage === page ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                setPage(page);
              }}
            >
              {page}
            </button>
          </li>
        ))}
        {pager.currentPage >= 3 &&
        pager.endPage < pager.totalPages - maxPages ? (
          <li className={"page-item page-number"}>
            <button
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                setPage(pager.endPage + 2);
              }}
            >
              ...
            </button>
          </li>
        ) : null}
        <li
          className={`page-item next ${
            pager.currentPage === pager.totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
              setPage(pager.currentPage + 1);
            }}
          >
            {labels.next}
          </button>
        </li>
        <li
          className={`page-item last ${
            pager.currentPage === pager.totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
              setPage(pager.totalPages);
            }}
          >
            {labels.last}
          </button>
        </li>
      </ul>
    )
  );
}

export default CustomPagination;
