import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
	title: baseSchema(Language).requiredStringMinMax,
  interval: baseSchema(Language).requiredNumberPositiveInt.min(30, Language.translate("This field must be larger than equal to 30")),
  distance: baseSchema(Language).requiredNumberPositiveInt.min(10, Language.translate("This field must be larger than equal to 10")),
	membersCount: baseSchema(Language).requiredNumberPositiveInt.max(50, Language.translate("You cannot exceed over 50 Members")),
	subscriberSelectionInValid: Yup.boolean().test({ 
    name: "invalid",
    exclusive: true,
    message: Language.translate("You have exceeded the client limit"),
    test: (value) => value==false || value==undefined,
  }),
});

export default schema;