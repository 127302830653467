// import { default as Menu } from "sccMenu";
var Menu = require("sccMenu").default;
var Language = require("sccLanguage").default;
const Sync = require("sccSync").default;
const _ = require("lodash");
const User = require("sccUser").default;
const Device = require("sccDevice").default;

/**
 * Sync menu class
 *
 * @class SyncMenu
 */
class SyncMenu extends Menu {
  /**
   * SyncMenu constructor function
   *
   */
  constructor() {
    var options = {
      tab: "Sync",
      title: "Sync",
      mainClass: "Sync",
      //imageClass: "fa-refresh",
      imageClass: "refresh",
      moduleName: "sync",
      module: Sync,
      showPagination: true,
    };
    super(options);
    this.orderByLastSynced = true;
  }

  init($scope) {
    $scope.Language = Language;
    $scope.Geofence = require("sccGeofence").default;
    $scope.GeofenceOverlay = require("sccGeofenceOverlay");
    $scope.Device = require("sccDevice").default;
    $scope.Poi = require("sccPoi").default;
    $scope.AddressBook = require("sccAddressBook");
    $scope.PoiOverlay = require("sccPoiOverlay");
    $scope.Object = Object;
    $scope.Clock = require("sccClock");
    $scope.Utils = require("sccUtils");
    $scope.GuiUtils = require("sccGuiUtils");
    $scope.TimeUtils = require("sccTimeUtils");
    $scope.UserSetting = require("sccUserSetting").default;
    $scope.OlMap = require("sccOlMapNew");
    $scope.Sync = Sync;

    $scope.SyncMenu = this;
    return super.init($scope).then(() => {
      $scope.menuInitialized = true;
      return Promise.resolve();
    });
  }
  /**
   * Return the total count of Each status
   * @param {*} syncData
   */
  getCountOfStatus(syncData) {
    var info = {
      synced_count: 0,
      syncing_count: 0,
      pending_count: 0,
    };

    _.each(syncData, function (sync) {
      switch (sync.status) {
        case "synced":
          info.synced_count++;
          break;
        case "syncing":
          info.syncing_count++;
          break;
        case "pending":
          info.pending_count++;
          break;
        default:
          break;
      }
    });

    return info;
  }

  /**
   * This function is used to get the username of the user that modified an entity being synced in the sync module
   * @param {Number} commId CommId of the device
   * @return {String} Username of the user who modified an entity
   */
  getModifierUserName(commId) {
    var userData = _.find(User.get(), function (user) {
      return user.comm_id === commId;
    });

    if (!userData) {
      return "N/A";
    }

    return userData.username;
  }

  /**
   * Returns Sync object and adds device title and device type_id t object
   * Need title to search from search bar and device type to be able to filter
   */
  getDevicesSynced() {
    _.each(Sync.get(), (sync) => {
      sync.title = Device.get(sync.id).name;
      sync.type_id = Device.get(sync.id).type_id;
    });
    return Sync.get();
  }

  toggleList(item) {
    item ? (this.orderByLastSynced = true) : (this.orderByLastSynced = false);
    //this.orderByLastSynced = !this.orderByLastSynced;
  }
}

export default new SyncMenu();
