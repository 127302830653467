import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";

import "./CustomAlert.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Language = require("sccLanguage").default;

export default function CustomAlert(props) {
  const handleOKClick = () => {
    props.onOkAction();
  };
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      className="CustomDialog"
    >
      <DialogTitle id="alert-dialog-title">
        <div
          style={{
            display: "flex",
            fontSize: "1.1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.titleText}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{ color: "black", fontSize: "1rem" }}>{props.text}</div>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={handleOKClick}
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
        >
          {Language.translate("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
