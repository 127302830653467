import React, { useState } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import GroupIcon from "@material-ui/icons/Group";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

// Internal
import AssetGroupSelector from "./AssetGroupSelector";
import CustomTooltip from "@Components/CustomTooltip";

export default function AssetGroup(props) {
  const [expanded, setExpanded] = useState({});

  const groups = props.collection;
  const { selection, setSelection } = props;
  const mainGroupName = props.mainGroupName;

  let listIds = [];

  const Language = require("sccLanguage").default;

  const handleToggle = (id) => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  function recursiveUncheck(listIds, startGroup) {
    startGroup.groups.forEach((group) => {
      if (selection.indexOf(group.id) > -1) {
        listIds.push(group.id);
        recursiveUncheck(listIds, group);
      }
    });
  }

  function updateSelection(e, group) {
    if (!e.target.checked) {
      recursiveUncheck(listIds, group);
    }
    listIds.push(group.id);
    setSelection(_.xor(_.clone(selection), listIds));
    listIds = [];
  }

  const useStyles = makeStyles((theme) => ({
    assetGroup: {
      width: "100%",
      margin: 0,
      padding: 0,
      "& .assetGroupRow": {
        height: 35,
        background: theme.palette.colors.gray.main,
        borderLeft: `1px solid ${theme.palette.colors.gray.main}`,
        width: "100%",
        marginTop: 1,
        "& .assetGroupIcon": {
          marginTop: -5,
          marginLeft: 0,
        },
        "& .assetGroupIconNav": {
          marginTop: 2,
          marginLeft: -25,
          position: "absolute",
        },
        "& .assetGroupLabel": {
          padding: 10,
        },
      },
      "& .MuiTypography-body2": {
        fontSize: "0.9rem",
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {groups.map((group) => {
        return (
          <List key={group.id} className={classes.assetGroup}>
            <ListItem className="assetGroupRow" role={undefined} dense button>
              <React.Fragment>
                {group.title !== mainGroupName && group.groups.length > 0 ? (
                  expanded[group.id] ? (
                    <KeyboardArrowDownIcon
                      onClick={() => handleToggle(group.id)}
                      className="assetGroupIconNav"
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      onClick={() => handleToggle(group.id)}
                      className="assetGroupIconNav"
                    />
                  )
                ) : null}
                <Checkbox
                  edge="start"
                  checked={selection.indexOf(group.id) > -1}
                  color="primary"
                  onChange={(e) => updateSelection(e, group)}
                  disabled={selection.indexOf(group.parent_id) === -1} // || containsAny(selection, group.groups.map((item)=>item.id))
                  disableRipple
                  inputProps={{ "aria-labelledby": group.id }}
                />
                {selection.indexOf(group.id) > -1}
              </React.Fragment>
              <CustomTooltip
                title={Language.translate("Group")}
                arrow
                placement="top"
              >
                <IconButton className="assetGroupIcon">
                  <GroupIcon />
                </IconButton>
              </CustomTooltip>
              <ListItemText
                className="assetGroupLabel"
                onClick={() => handleToggle(group.id)}
                id={`AssetGroup-${group.id}`}
              >
                {group.title === mainGroupName
                  ? Language.translate(mainGroupName)
                  : group.title}
                &nbsp;({group.devices.length})
              </ListItemText>
            </ListItem>
            {
              <Collapse
                key={`collapseGroup-${group.id}`}
                in={group.id === 1 ? true : expanded[group.id]}
                timeout="auto"
                unmountOnExit
              >
                <AssetGroupSelector
                  collection={group.groups}
                  selection={selection}
                  setSelection={setSelection}
                  name={props.name}
                />
              </Collapse>
            }
          </List>
        );
      })}
    </React.Fragment>
  );
}
