import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./CustomItemCount.scss";
import CustomIcon from "@Components/CustomIcon";

function CustomItemCount(props) {
  const { title, count } = props;
  return (
    <Paper
      elevation={2}
      className={`CustomItemCount ${count > 0 && "countNumber"}`}
    >
      <Grid container item className="CustomItemContainer">
        <Grid item xs={5} className="CustomItemCountIcon">
          <CustomIcon id={`icon-tablet`} fontSize="large" />
        </Grid>
        <Grid item xs={7}>
          <div className="CustomItemCountTitle">{title}</div>
          <div className="CustomItemCountNumber">{count}</div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CustomItemCount;
