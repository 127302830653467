import React, { useState } from "react";

// External
import _ from "lodash";

// Internal
import Historic from "sccHistory";
import Device from "sccDevice";
import Group from "sccGroup";
import HistoryOverlay from "sccHistoryOverlay";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";

const HistoryContext = React.createContext([{}, () => {}]);

const HistoryContextProvider = (props) => {
  const [state, setState] = useState({
    Historic,
    Device,
    Group,
    //common reusable functions
    updateKeysInState,
    resetTrailOverlay,
    resetAllComponents,
    showDeviceTrailChanged,
    deviceVisibilityChanged,
    sliderChanged,
    displayMessage,
    setSliderTicks,
    capitalizeFirstLetter,
    setDeviceSelectData,
    setDeviceTreeData,
    //shared variables
    hideNoEvents: false,
    showLeftPanel: true,
    showRightPanel: false,
    showHistoryControls: false,
    currentTimestamp: null,
    playEvent: false,
    eventFinished: false,
    noPrevEvent: true,
    showNotification: false,
    eventsDatasetValid: false, //used as the events are loaded in Historic even though they are out of our date range
    playInterval: null, // holds the interval number for History when in play mode.
    //objects
    notificationOptions: {},
    sliderTicks: [],
    filteredEvents: {
      emergency: true,
      speed: true,
      geofence: true,
      cargo: true,
      non_report: true,
      report: true,
      vehicle: true,
    },
    deviceSelectData: [],
    deviceTreeData: [],
  });

  function updateKeysInState(keys) {
    setState((p) => {
      return Object.assign({}, p, keys);
    });
  }

  function setDeviceSelectData(data) {
    updateKeysInState({ deviceSelectData: data });
  }

  function setDeviceTreeData(data) {
    updateKeysInState({ deviceTreeData: data });
  }

  function showDeviceTrailChanged(value) {
    if (value) return;
    resetTrailOverlay();
  }

  function setSliderTicks(events) {
    const marks = [];

    const filteredEvents = events || Historic.playOptions.event;

    const playOptionDevices = Historic.playOptions.device;

    let eventsToShow = [];
    _.each(filteredEvents, (event, key) => {
      if (event) {
        eventsToShow.push(key);
      }
    });

    _.each(Historic._eventsByTypeMaster, (reports, type) => {
      if (eventsToShow.includes(type)) {
        _.each(reports, (report) => {
          _.each(playOptionDevices, (device, deviceId) => {
            if (Number(report.device_id) === Number(deviceId) && device.showTracks) {
              marks.push({
                value: report.event_timestamp,
                label: report.event,
              });
            }
          });
        });
      }
    });

    updateKeysInState({ sliderTicks: _.orderBy(marks, ["value"]) });
  }

  function displayMessage(
    enqueueSnackbar,
    title,
    message,
    type = "error",
    options = {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    }
  ) {
    enqueueSnackbar(
      <p>
        {title != null ? (
          <React.Fragment>
            <strong>{title}</strong> <br />
          </React.Fragment>
        ) : null}
        {message}
      </p>,
      options
    );
  }

  function resetTrailOverlay() {
    const pastReports = Historic.getPastDeviceReports();

    //  make sure display report is after firstReportTimestamp
    const timestamp = Historic.currentTimestamp;
    const firstReportTimestamp = Historic.getFirstReportTimestamp();
    HistoryTrailOverlay.removeFeature();

    if (firstReportTimestamp <= timestamp) {
      HistoryTrailOverlay.addFeatures(pastReports);
    }
  }

  function resetAllComponents() {
    HistoryOverlay.deselectFeature();
    updateKeysInState({
      showHistoryControls: false,
      currentTimestamp: Historic.getStartTimestamp(),
      playEvent: false,
      eventFinished: false,
      noPrevEvent: true,
      playInterval: null,
    });
  }

  function deviceVisibilityChanged(deviceIds) {
    deviceIds = _.concat([], deviceIds);
    _.each(deviceIds, (deviceId) => {
      if (!Historic.playOptions.device[deviceId].showTracks) {
        Historic.playOptions.device[deviceId].isFollow = false;
        Historic.playOptions.device[deviceId].showTrail = false;
        setState((p) => {
          return Object.assign({}, p, { Historic });
        });
      }
    });

    // reset history all process with only showed devices
    Historic.resetAllProcessByDeviceOptions();
    // reset sliderTicks with only showed devices
    setSliderTicks();
    sliderChanged();
  }


  function checkForRemoteDevice(id) {
    const device = Device.get(id);
    if (
      (device.type == "Sentry H6120 BM" ||
        device.type == "Sentry H6110 MP" ||
        device.type == "SAT-COM Leopard1" ||
        device.type == "Trellisware TSM TW-950") &&
      Device.getDeviceMode(device) == "Standalone"
    ) {
      return true;
    }
    return false;
  }


  function sliderChanged() {
    const timestamp = Historic.currentTimestamp;

    const data = _.values(Historic.getCurrentDeviceReports());

    HistoryOverlay.removeFeature();

    //  make sure the data time is before timestamp and after start time
    const filteredData = data.filter(
      (d) =>
        d.event_timestamp <= timestamp &&
        d.event_timestamp >= Historic.getStartTimestamp() &&
        Historic.playOptions.device[d.id].showTracks
    );

    if (filteredData?.length > 0) {
      HistoryOverlay.addFeature({ data: filteredData });
    }

    const noPrevEvent =
      Historic.currentTimestamp > Historic.getStartTimestamp() ? false : true;

    const eventFinished =
      Historic.currentTimestamp === Historic.getEndTimestamp() ? true : false;

    updateKeysInState({
      noPrevEvent: noPrevEvent,
      eventFinished: eventFinished,
    });

    resetTrailOverlay();
  }

  /**
   * Returns the string with capitalized first letter
   * @param String string
   */
  function capitalizeFirstLetter(string) {
    if (string === "Non_Report") {
      return "Non Report";
    }
    if (string === "vehicle") {
      return "Vehicle Events";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <HistoryContext.Provider value={[state, setState]}>
      {props.children}
    </HistoryContext.Provider>
  );
};
export { HistoryContext, HistoryContextProvider };
