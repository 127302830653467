import React, { useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext, getIn } from "formik";

// Material-UI
import { makeStyles, Grid, MenuItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@material-ui/icons/Settings";

// Components
import CustomButton from "@Components/CustomButton";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  assetsFormSettingsHawkeye: {
    margin: 0,
    padding: 10,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .settingsBtnSendSettingsCode": {
      float: "right",
      margin: "20px 0",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 6,
      paddingRight: 6,
    },
    "& .MuiListItem-root": {
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .itemTextFirst": {
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      textTransform: "uppercase",
      fontWeight: 700,
    },
    "& .itemTextSecond": {
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      marginLeft: -1,
      "& .dropdown": {
        marginTop: "0px !important",
        marginBottom: "0px !important",
      },
    },
    "& .MuiTypography-root": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& .parentOpen": {
      borderBottom: 0,
    },
    "& .parentClose": {
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
    },
    "& .subList": {
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
      "& .MuiListItem-root": {
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        border: 0,
      },
      "& .MuiListItemText-root": {
        marginTop: 0,
        marginBottom: 0,
      },
      "& .itemTextFirst": {
        padding: "4px 8px",
        width: "50%",
        marginTop: -1,
        textTransform: "capitalize",
        "& .MuiTypography-root": {
          textOverflow: "ellipsis",
          paddingLeft: 50,
          overflow: "hidden",
        },
      },
      "& .itemTextSecond": {
        padding: "4px 8px",
        width: "50%",
        marginTop: -1,
        marginLeft: -1,
      },
    },
  },
}));

export default function AssetsFormSettingsHawkeye(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const pollCommands = mwState.pollCommands;

  const deviceMode = ["Normal", "Compressed Land"];

  const enableObj = [
    { item: "Enabled", value: true },
    { item: "Disabled", value: false },
  ];

  const netWorkSelection = [
    "Cellular Primary",
    "Cellular Only",
    "Iridium Primary",
    "Iridium Only",
  ];

  useEffect(() => {
    formik.setFieldValue(
      "settings",
      JSON.stringify(moduleItemData.settingsVal)
    );
  }, [moduleItemData.settingsVal]);

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      handleSpecialSaveValidation: () =>
        props.handleSpecialSaveValidation(formik),
    }));
  }, [formik.errors]);

  const sendNewHakeyeSettingsConfig = () => {
    // Send Device Settings Commands
    pollCommands(moduleItemData, "send_settings", moduleItemData.settings);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.assetsFormSettingsHawkeye}>
        <Grid item xs={12} md={12}>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Device Reporting Mode")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.device_reporting"
                  value={moduleItemData.settingsVal.device_reporting}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.device_reporting") &&
                      getIn(formik.errors, "settingsVal.device_reporting")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.device_reporting") &&
                    getIn(formik.errors, "settingsVal.device_reporting")
                  }
                >
                  {deviceMode.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Internal Speaker")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.internal_speaker"
                  value={Boolean(moduleItemData.settingsVal.internal_speaker)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.internal_speaker") &&
                      getIn(formik.errors, "settingsVal.internal_speaker")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.internal_speaker") &&
                    getIn(formik.errors, "settingsVal.internal_speaker")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Network Selection")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.network_selection"
                  value={String(moduleItemData.settingsVal.network_selection)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.network_selection") &&
                      getIn(formik.errors, "settingsVal.network_selection")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.network_selection") &&
                    getIn(formik.errors, "settingsVal.network_selection")
                  }
                >
                  {netWorkSelection.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={
                Language.translate("Time To Change Network") +
                " (" +
                Language.translate("Seconds") +
                ")"
              }
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomTextField
                  value={moduleItemData.settingsVal.change_network}
                  name="settingsVal.change_network"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.change_network") &&
                      getIn(formik.errors, "settingsVal.change_network")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.change_network") &&
                    getIn(formik.errors, "settingsVal.change_network")
                  }
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={
                Language.translate("Power Off Behaviour") +
                " (" +
                Language.translate("Seconds") +
                ")"
              }
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomTextField
                  value={moduleItemData.settingsVal.power_off}
                  name="settingsVal.power_off"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.power_off") &&
                      getIn(formik.errors, "settingsVal.power_off")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.power_off") &&
                    getIn(formik.errors, "settingsVal.power_off")
                  }
                />
              }
            />
          </ListItem>
          <ListItem
            className={
              moduleItemData.settingsVal.stationary_toggle
                ? "parentOpen"
                : "parentClose"
            }
          >
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Report Rate While Stationary")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.stationary_toggle"
                  value={Boolean(moduleItemData.settingsVal.stationary_toggle)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.stationary_toggle") &&
                      getIn(formik.errors, "settingsVal.stationary_toggle")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.stationary_toggle") &&
                    getIn(formik.errors, "settingsVal.stationary_toggle")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <Collapse
            in={moduleItemData.settingsVal.stationary_toggle}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding className="subList">
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Report Interval") +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.report_interval}
                      name="settingsVal.report_interval"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.report_interval") &&
                          getIn(formik.errors, "settingsVal.report_interval")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.report_interval") &&
                        getIn(formik.errors, "settingsVal.report_interval")
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Speed To Consider Stopped") +
                    " (" +
                    Language.translate("KPH") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.stopped_speed}
                      name="settingsVal.stopped_speed"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.stopped_speed") &&
                          getIn(formik.errors, "settingsVal.stopped_speed")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.stopped_speed") &&
                        getIn(formik.errors, "settingsVal.stopped_speed")
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Time Stopped To Report") +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.time_stopped}
                      name="settingsVal.time_stopped"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.time_stopped") &&
                          getIn(formik.errors, "settingsVal.time_stopped")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.time_stopped") &&
                        getIn(formik.errors, "settingsVal.time_stopped")
                      }
                    />
                  }
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            className={
              moduleItemData.settingsVal.moving_toggle
                ? "parentOpen"
                : "parentClose"
            }
          >
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Report Rate While Moving")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.moving_toggle"
                  value={Boolean(moduleItemData.settingsVal.moving_toggle)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.moving_toggle") &&
                      getIn(formik.errors, "settingsVal.moving_toggle")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.moving_toggle") &&
                    getIn(formik.errors, "settingsVal.moving_toggle")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <Collapse
            in={moduleItemData.settingsVal.moving_toggle}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding className="subList">
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Report Interval") +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.moving_report_interval}
                      name="settingsVal.moving_report_interval"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(
                          formik.touched,
                          "settingsVal.moving_report_interval"
                        ) &&
                          getIn(
                            formik.errors,
                            "settingsVal.moving_report_interval"
                          )
                      )}
                      helperText={
                        getIn(
                          formik.touched,
                          "settingsVal.moving_report_interval"
                        ) &&
                        getIn(
                          formik.errors,
                          "settingsVal.moving_report_interval"
                        )
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Speed To Consider Moving") +
                    " (" +
                    Language.translate("KPH") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.moving_speed}
                      name="settingsVal.moving_speed"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.moving_speed") &&
                          getIn(formik.errors, "settingsVal.moving_speed")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.moving_speed") &&
                        getIn(formik.errors, "settingsVal.moving_speed")
                      }
                    />
                  }
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("REPORT Turn Direction")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.report_turn"
                  value={Boolean(moduleItemData.settingsVal.report_turn)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.report_turn") &&
                      getIn(formik.errors, "settingsVal.report_turn")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.report_turn") &&
                    getIn(formik.errors, "settingsVal.report_turn")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={
                Language.translate("Emergency Report Rate") +
                " (" +
                Language.translate("Seconds") +
                ")"
              }
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomTextField
                  value={moduleItemData.settingsVal.emergency_report}
                  name="settingsVal.emergency_report"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.emergency_report") &&
                      getIn(formik.errors, "settingsVal.emergency_report")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.emergency_report") &&
                    getIn(formik.errors, "settingsVal.emergency_report")
                  }
                />
              }
            />
          </ListItem>
          <ListItem
            className={
              moduleItemData.settingsVal.speeding ? "parentOpen" : "parentClose"
            }
          >
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Speeding")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.speeding"
                  value={Boolean(moduleItemData.settingsVal.speeding)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.speeding") &&
                      getIn(formik.errors, "settingsVal.speeding")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.speeding") &&
                    getIn(formik.errors, "settingsVal.speeding")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <Collapse
            in={moduleItemData.settingsVal.speeding}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding className="subList">
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Report Rate") +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.speed_report_rate}
                      name="settingsVal.speed_report_rate"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(
                          formik.touched,
                          "settingsVal.speed_report_rate"
                        ) &&
                          getIn(formik.errors, "settingsVal.speed_report_rate")
                      )}
                      helperText={
                        getIn(
                          formik.touched,
                          "settingsVal.speed_report_rate"
                        ) &&
                        getIn(formik.errors, "settingsVal.speed_report_rate")
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Time Over Speed") +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.time_over_speed}
                      name="settingsVal.time_over_speed"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.time_over_speed") &&
                          getIn(formik.errors, "settingsVal.time_over_speed")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.time_over_speed") &&
                        getIn(formik.errors, "settingsVal.time_over_speed")
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Max Speed") +
                    " (" +
                    Language.translate("KPH") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.max_speed}
                      name="settingsVal.max_speed"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.max_speed") &&
                          getIn(formik.errors, "settingsVal.max_speed")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.max_speed") &&
                        getIn(formik.errors, "settingsVal.max_speed")
                      }
                    />
                  }
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("First Movement From Stationary")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.first_movement"
                  value={Boolean(moduleItemData.settingsVal.first_movement)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.first_movement") &&
                      getIn(formik.errors, "settingsVal.first_movement")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.first_movement") &&
                    getIn(formik.errors, "settingsVal.first_movement")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem
            className={
              moduleItemData.settingsVal.show_idling_items
                ? "parentOpen"
                : "parentClose"
            }
          >
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Engine Idling")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.show_idling_items"
                  value={Boolean(moduleItemData.settingsVal.show_idling_items)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.show_idling_items") &&
                      getIn(formik.errors, "settingsVal.show_idling_items")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.show_idling_items") &&
                    getIn(formik.errors, "settingsVal.show_idling_items")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <Collapse
            in={moduleItemData.settingsVal.show_idling_items}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding className="subList">
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate(
                      "Delay Until Vehicle Considered Idling"
                    ) +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.delay_idle}
                      name="settingsVal.delay_idle"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(formik.touched, "settingsVal.delay_idle") &&
                          getIn(formik.errors, "settingsVal.delay_idle")
                      )}
                      helperText={
                        getIn(formik.touched, "settingsVal.delay_idle") &&
                        getIn(formik.errors, "settingsVal.delay_idle")
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={
                    Language.translate("Report Interval") +
                    " (" +
                    Language.translate("Seconds") +
                    ")"
                  }
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    <CustomTextField
                      value={moduleItemData.settingsVal.engine_report_interval}
                      name="settingsVal.engine_report_interval"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(
                          formik.touched,
                          "settingsVal.engine_report_interval"
                        ) &&
                          getIn(
                            formik.errors,
                            "settingsVal.engine_report_interval"
                          )
                      )}
                      helperText={
                        getIn(
                          formik.touched,
                          "settingsVal.engine_report_interval"
                        ) &&
                        getIn(
                          formik.errors,
                          "settingsVal.engine_report_interval"
                        )
                      }
                    />
                  }
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Rollover")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.roll_over"
                  value={Boolean(moduleItemData.settingsVal.roll_over)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.roll_over") &&
                      getIn(formik.errors, "settingsVal.roll_over")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.roll_over") &&
                    getIn(formik.errors, "settingsVal.roll_over")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Crash")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                <CustomDropdown
                  name="settingsVal.crash"
                  value={Boolean(moduleItemData.settingsVal.crash)}
                  className="dropdown"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={Boolean(
                    getIn(formik.touched, "settingsVal.crash") &&
                      getIn(formik.errors, "settingsVal.crash")
                  )}
                  helperText={
                    getIn(formik.touched, "settingsVal.crash") &&
                    getIn(formik.errors, "settingsVal.crash")
                  }
                >
                  {enableObj.map((key) => (
                    <MenuItem key={key.item} value={key.value}>
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            className="settingsBtnSendSettingsCode"
            onClick={sendNewHakeyeSettingsConfig}
            disabled={
              _.isEqual(
                mwState.moduleItemDataOriginal.settings,
                moduleItemData.settings
              ) || !_.isEmpty(formik.errors.settingsVal)
            }
            size="medium"
            color="command"
            variant="contained"
            startIcon={<SettingsIcon />}
          >
            {Language.translate("Send Settings Configuration")}
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
