import React, { useContext } from "react";

// Material-UI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import MenuButtonList from "../MenuButtonList";
import ProfileButtton from "../ProfileButton";
import Alerts from "../../../alert/components/Alerts";
import ModuleWrapper from "@Components/ModuleWrapper";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

const useStyles = makeStyles((theme) => ({
  modulePaper: {
    height: "100%",
  },
  menuButtonBox: {
    right: 5,
    position: "fixed",
    top: 5,
    zIndex: 1000,
    "& div > button": {
      marginLeft: 5,
      padding: "10px 0",
      backgroundColor: theme.palette.colors.white.main,
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    },
    "& .MuiButtonGroup-contained": {
      boxShadow: "none",
    },
  },
  moduleWrapperPopper: {
    minWidth: 750,
    maxWidth: 1000,
    maxHeight: "calc(100% - 60px)",
    zIndex: 1000,
  },
}));

export default function MenuButtonBox() {
  const classes = useStyles();
  const [mwState] = useContext(ModuleWrapperContext);

  return (
    <React.Fragment>
      <div className={classes.menuButtonBox}>
        <ButtonGroup
          id="menuButtonBoxGroup"
          aria-label="outlined vertical contained primary button group"
          variant="contained"
        >
          <Alerts />
          <MenuButtonList></MenuButtonList>
          <ProfileButtton></ProfileButtton>
        </ButtonGroup>
      </div>
      <Popper
        className={classes.moduleWrapperPopper}
        id={mwState.popperId}
        open={mwState.popperOpen}
        anchorEl={mwState.popperAnchorEl}
        placement="bottom-end"
        //  todo: uncomment here if we need to cover the menu button
        // modifiers={{ offset: { enabled: true, offset: "125, 5" } }}
      >
        <Paper elevation={8} className={classes.modulePaper} square={true}>
          <ModuleWrapper />
        </Paper>
      </Popper>
    </React.Fragment>
  );
}
