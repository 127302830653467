import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { HistoryContext } from "../../HistoryContext";
import Language from "sccLanguage";



const useStyles = makeStyles((theme) => ({
  checkBoxContainer: {
    "& .MuiFormControlLabel-root": {
      paddingRight: "22px",
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
    },
    "& .PrivateSwitchBase-root-1": {
      padding: "7px",
    },
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },
}));

const HistoryCheckboxes = (props) => {
  const [state] = useContext(HistoryContext);
  const Historic = state.Historic;
  const devices = Historic.queryParams.devices;
  const updateKeysInState = state.updateKeysInState;
  const sliderChanged = state.sliderChanged;
  const resetTrailOverlay = state.resetTrailOverlay;

  function viewAll() {
    var check =
      devices.length ===
      _.filter(Historic.playOptions.device, { showTracks: true }).length;

    //If View All is checked then hide no events cannot be checked
    if (!check) {
      updateKeysInState({ hideNoEvents: false });
    }
    _.each(devices, (deviceId) => {
      Historic.playOptions.device[deviceId].showTracks = !check;
      if (check) {
        Historic.playOptions.device[deviceId].isFollow = false;
        Historic.playOptions.device[deviceId].showTrail = false;
        updateKeysInState(Historic);
      }
    });
    // reset history all process with showed devices
    Historic.resetAllProcessByDeviceOptions();
    // reset history slider ticks with showed devices
    state.setSliderTicks();
    sliderChanged();
  }

  function followAll() {
    var check =
      devices.length ===
        _.filter(Historic.playOptions.device, { isFollow: true }).length ||
      _.filter(Historic.playOptions.device, { isFollow: true }).length > 0;
    _.each(devices, (deviceId) => {
      if (Historic.playOptions.device[deviceId].showTracks) {
        Historic.playOptions.device[deviceId].isFollow = !check;
        updateKeysInState(Historic);
      }
    });
  }

  function trailAll() {
    var check =
      devices.length ===
        _.filter(Historic.playOptions.device, { showTrail: true }).length ||
      _.filter(Historic.playOptions.device, { showTrail: true }).length > 0;
    _.each(devices, (deviceId) => {
      if (Historic.playOptions.device[deviceId].showTracks) {
        Historic.playOptions.device[deviceId].showTrail = !check;
        updateKeysInState(Historic);
      }
    });
    resetTrailOverlay();
  }

  function hideDevicesWithNoEvents() {
    var check =
      devices.length ===
      _.filter(Historic.playOptions.device, { showTracks: true }).length;

    const noEventsChecked = state.hideNoEvents;
    updateKeysInState({ hideNoEvents: !noEventsChecked });

    _.each(devices, (deviceId) => {
      if (check && Historic.playOptions.device[deviceId].noEvents) {
        Historic.playOptions.device[deviceId].showTracks = false;
        updateKeysInState(Historic);
      }
      if (!check && Historic.playOptions.device[deviceId].noEvents) {
        Historic.playOptions.device[deviceId].showTracks = true;
        updateKeysInState(Historic);
      }
    });

    // reset history all process with showed devices
    Historic.resetAllProcessByDeviceOptions();
    // reset history slider ticks with showed devices
    state.setSliderTicks();
    sliderChanged();
  }

  const classes = useStyles();

  return (
    <div className={classes.checkBoxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            onClick={viewAll}
            checked={
              _.filter(Historic.playOptions.device, { showTracks: true })
                .length === 0
                ? false
                : devices.length ===
                  _.filter(Historic.playOptions.device, { showTracks: true })
                    .length
                ? true
                : false
            }
            indeterminate={
              devices.length !==
                _.filter(Historic.playOptions.device, { showTracks: true })
                  .length &&
              _.filter(Historic.playOptions.device, { showTracks: true })
                .length > 0
            }
            color="primary"
            name="viewAll"
          />
        }
        label={Language.translate("View All")}
      />
      <FormControlLabel
        control={
          <Checkbox
            onClick={followAll}
            checked={
              _.filter(Historic.playOptions.device, { isFollow: true })
                .length === 0
                ? false
                : devices.length ===
                  _.filter(Historic.playOptions.device, { isFollow: true })
                    .length
                ? true
                : false
            }
            indeterminate={
              devices.length !==
                _.filter(Historic.playOptions.device, { isFollow: true })
                  .length &&
              _.filter(Historic.playOptions.device, { isFollow: true }).length >
                0
            }
            color="primary"
            name="followAll"
          />
        }
        label={Language.translate("Follow All")}
      />
      <FormControlLabel
        control={
          <Checkbox
            onClick={trailAll}
            checked={
              _.filter(Historic.playOptions.device, { showTrail: true })
                .length === 0
                ? false
                : devices.length ===
                  _.filter(Historic.playOptions.device, { showTrail: true })
                    .length
                ? true
                : false
            }
            indeterminate={
              devices.length !==
                _.filter(Historic.playOptions.device, { showTrail: true })
                  .length &&
              _.filter(Historic.playOptions.device, { showTrail: true })
                .length > 0
            }
            color="primary"
            name="trailAll"
          />
        }
        label={Language.translate("Trail All")}
      />
      <br />
      {Historic.showHideNoEvents ? (
        <FormControlLabel
          control={
            <Checkbox
              onClick={hideDevicesWithNoEvents}
              checked={state.hideNoEvents}
              color="primary"
              name="hideNoEvents"
            />
          }
          label={Language.translate("Hide No Events")}
        />
      ) : null}
    </div>
  );
};
export default HistoryCheckboxes;
