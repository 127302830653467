import React, { useState } from "react";

// External
import _ from "lodash";

// Material-UI
import Popper from "@material-ui/core/Popper";
import CustomButton from "@Components/CustomButton";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTooltip from "@Components/CustomTooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/styles";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    minWidth: 300,
    maxWidth: 600,
    zIndex: 1000,
    maxHeight: 750,
  },
  button: {
    height: 30,
    width: 130,
    marginRight: 10,
    "& .MuiButton-endIcon": {
      marginLeft: 0,
    },
  },
  filter: {
    width: "100%",
    overflowY: "auto",
    maxHeight: 550,
  },

  filterContainer: {
    padding: 16,
  },

  title: {
    margin: "4px 0px",
  },

  labelItem: {
    display: "flex",
    alignItems: "center",
  },

  clearButton: {
    color: "#fff",
    backgroundColor: "#d9534f",
    borderColor: "#d43f3a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    borderRadius: 15,
    cursor: "pointer",
    maxWidth: 66,
    "& svg": {
      fontSize: 12,
    },
    "& span": {
      fontSize: 12,
    },
  },

  labelText: {
    fontWeight: 700,
  },

  topClearButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#d9534f",
    borderRadius: "50%",
    marginLeft: 4,
    "& .MuiSvgIcon-root": {
      color: theme.palette.colors.white.main,
      fontSize: "1rem",
    },
  },

  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    whiteSpace: "nowrap",
    lineHeight: "3px",
  },

  searchField: {
    padding: "2px 16px 2px 4px",
  },

  clearSearch: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  filterGrid: {
    width: 250,
  },

  noResult: {
    textAlign: "center",
  },
}));

function CustomFilter(props) {
  const refPicker = React.useRef();
  const {
    name,
    label,
    originalData,
    filterSelection,
    onChange,
    filterField,
    ...other
  } = props;

  const [open, setOpen] = useState(false);
  const id = open ? "filterPopper" : undefined;

  const handleClick = (event) => {
    setOpen(Boolean(refPicker.current));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickAwayClose = (e) => {
    if (!e.isTrusted) return false;
    handleClose();
  };

  const classes = useStyles();

  const filtersLength =
    _.flattenDeep(Object.values(filterSelection))?.length || 0;

  return (
    <div ref={refPicker}>
      <CustomButton
        className={classes.button}
        onClick={handleClick}
        size="medium"
        color="command"
        variant="contained"
        startIcon={<FilterListIcon />}
        endIcon={<ArrowDropDownIcon />}
        {...other}
      >
        <CustomTooltip title={label} arrow placement="top">
          <div className={classes.labelContainer}>
            {label}
            {filtersLength > 0 && (
              <>
                <span>({filtersLength})</span>
                <span
                  className={classes.topClearButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null, null, originalData);
                  }}
                >
                  <CloseIcon />
                </span>
              </>
            )}
          </div>
        </CustomTooltip>
      </CustomButton>
      <Popper
        id={id}
        disablePortal={false}
        open={open}
        className={classes.root}
        anchorEl={refPicker.current}
        onClose={handleClose}
        placement="bottom-start"
      >
        <Paper elevation={8} className={classes.filterContainer}>
          <ClickAwayListener onClickAway={handleClickAwayClose}>
            <Grid container>
              <Grid item container xs={10} className={classes.labelItem}>
                <Grid item xs={2}>
                  <h5 className={classes.labelText}>{label}</h5>
                </Grid>

                {filtersLength > 0 && (
                  <Grid item container xs={10}>
                    <Grid
                      item
                      xs={5}
                      className={classes.clearButton}
                      onClick={() => onChange(null, null, originalData)}
                    >
                      <CloseIcon /> <span>{Language.translate("CLEAR")}</span>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid container item xs={12}>
                {/* Actual content comes here
								By Device (Alert, Sync) / By Device Type (Asset) / By Alert Type (Alert) / By Category (POI) */}
                {filterField.length > 0 &&
                  filterField.map((item) => {
                    return (
                      <Grid
                        key={item.id}
                        item
                        // if filterField has one more columns, divide them by half, otherwise use the whole Grid
                        xs={filterField.length > 1 ? 6 : 12}
                      >
                        <h6 className={classes.title}>
                          {Language.translate("By")}{" "}
                          {Language.translate(item.title)}
                        </h6>
                        <div className={classes.filter}>
                          <List>
                            {item.list &&
                              Object.values(item.list).map((chk) => {
                                const exists = _.values(
                                  _.filter(Object.values(originalData), {
                                    [item.id]: chk["id"],
                                  })
                                ).length;
                                return exists ? (
                                  <ListItem key={chk["id"]}>
                                    <CustomCheckbox
                                      name={item.title}
                                      value={chk["id"]}
                                      checked={
                                        filterSelection[item.id]?.includes(
                                          chk["id"]
                                        ) === undefined
                                          ? false
                                          : filterSelection[item.id]?.includes(
                                              chk["id"]
                                            )
                                      }
                                      onChange={(e) =>
                                        onChange(e, item.id, originalData)
                                      }
                                      color="primary"
                                    />
                                    {chk[item.displayField]}
                                    <span>
                                      <Chip label={exists} size="small" />
                                    </span>
                                  </ListItem>
                                ) : null;
                              })}
                          </List>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
}
export default CustomFilter;
