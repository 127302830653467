import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import Language from "sccLanguage";

const schema = Yup.object().shape({
  cannedmessage_id: baseSchema(Language).dropdownSelectNum,
  message: Yup.string()
    .max(
      255,
      Language.translate(
        "This field length must be less than or equal to 255 characters long"
      )
    )
    .test({
      name: "canned_message_or_attachment",
      exclusive: true,
      test: function (val) {
        return (
          this.options.parent.attachments?.length > 0 ||
          this.options.parent.cannedmessage_id > 0 ||
          val?.length > 0
        );
      },
      message: Language.translate("This field is required"),
    }),
  _attachmentTooBig: Yup.boolean(),
  _invalidFileName: Yup.boolean(),
  attachments: Yup.array().test({
    name: "attachments_test",
    exclusive: true,
    message: Language.translate("Required"),
    test: function (value) {
      return !(
        this.options.parent._attachmentTooBig ||
        this.options.parent._invalidFileName
      );
    },
  }),
});

export default schema;
