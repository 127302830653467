import { default as Module } from "sccModule";
var Permission= require("sccPermission");
const log= require("loglevel");

/**
 * The base class for SyncModule
 * 
 * @class SyncModule
 */
class SyncModule extends Module.Module{
	constructor(){
		super({
			moduleName: "sync"
		});
	}

	init(){
		log.debug("User  for Sync module", Permission.default.verify("sync", "add"));
		return super.init();
	}
}

////module.exports= New SyncModule();
export default new SyncModule();
