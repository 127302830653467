import { default as Module } from "sccModule";

const _ = require("lodash");
/**
 * The base object for Profile module
 *
 */
class ImageModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "image",
    };

    super(options);
  }

  static thePublicInstance = null;

  /**
   * overriding loadData to add resized to 48x48 base64 image
   */
  loadData() {
    return super.loadData().then(function (data) {
      const imageSize = 48; // width and height of image
      // going over images and build a resized image
      const promises = _.map(data, function (image) {
        return new Promise(function (resolve) {
          const img = new Image();
          img.onload = function () {
            image.width = img.width;
            image.height = img.height;
            let height = imageSize;
            let width = imageSize;
            if (img.width > img.height) {
              height = (img.height * imageSize) / img.width;
            } else {
              width = (img.width * imageSize) / img.height;
            }
            image.data_resized = imageToDataUri(this, width, height);
            resolve(image);
          };
          img.src = "data:" + image.type + ";base64," + image.data;
        });
      });

      return Promise.all(promises);
    });
  }

  getResizedImg(image) {
    const imageSize = 48; // width and height of image
    const img = new Image();
    img.src = "data:" + image.type + ";base64," + image.data;
    image.width = img.width;
    image.height = img.height;
    let height = imageSize;
    let width = imageSize;
    if (img.width > img.height) {
      height = (img.height * imageSize) / img.width;
    } else {
      width = (img.width * imageSize) / img.height;
    }
    image.data_resized = imageToDataUri(img, width, height);
    return image.data_resized;
  }

  /**
   * returns object structure of the folder collection
   * @param {String} collectionName name of the image collection folder
   * @return {Object} the image collection structure put into an object
   */
  getImageCollection(collectionName) {
    return require("../images")[collectionName];
  }

  /**
   * returns image data in the format required by ng-src element
   * @param {Number} id of the image from the image table
   * @return {Object} image data in the format required by ng-src element
   */
  getImageSource(imageId) {
    const $this = this;
    if (!imageId || !$this.get(imageId)) {
      return "";
    }
    return (
      "data:" + $this.get(imageId).type + ";base64," + $this.get(imageId).data
    );
  }

  /**
   * overrides the onSocket method of image module
   * @param {*} event socket event
   * @param {*} data socket data
   */
  onSocket(event, data) {
    this.processSocketData(event, data);
    if (this.$scope) {
      this.$scope.safeApply();
    }
  }

  onSocketDelete(event, data) {
    super.onSocketDelete(event, data);
  }

  onSocketUpdate(event, data) {
    super.onSocketUpdate(event, data);
  }

  onSocketAdd(event, data) {
    super.onSocketAdd(event, data);
  }

  /**
   * parses the socket data and calls the respective Module socket function, based on event (post, put, delete)
   * @param {*} event socket event
   * @param {*} data socket data
   */
  processSocketData(event, data) {
    var $this = this;

    if (event === "delete:/image/:id") {
      $this.onSocketDelete(event, data);
    } else {
      var addImages = _.reduce(
        data,
        function (result, value, key) {
          if (!$this.get(key)) {
            result[key] = value;
          }
          return result;
        },
        {}
      );

      var updateImages = _.reduce(
        data,
        function (result, value, key) {
          if ($this.get(key)) {
            result[key] = value;
          }
          return result;
        },
        {}
      );

      if (!_.isEmpty(addImages)) {
        $this.onSocketAdd(event, addImages);
      }

      if (!_.isEmpty(updateImages)) {
        $this.onSocketUpdate(event, updateImages);
      }
    }
  }
}

export function getImageSource(imageId) {
  return getTheStaticInstance().getImageSource(imageId);
}

export function loadData() {
  return getTheStaticInstance().loadData();
}

// export function getImageCollection(collectionName) {
// 	return require("../images")[collectionName];
// };

export function getTheStaticInstance() {
  if (ImageModule.thePublicInstance == null) {
    ImageModule.thePublicInstance = new ImageModule();
  }
  return ImageModule.thePublicInstance;
}
/**
 * resizes a given base64 image to a given width and height
 */
function imageToDataUri(img, width, height) {
  // create an off-screen canvas
  var canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");

  // set its dimension to target size
  canvas.width = width;
  canvas.height = height;

  // draw source image into the off-screen canvas:
  ctx.drawImage(img, 0, 0, width, height);
  // encode image to data-uri with base64 version of compressed image
  return canvas.toDataURL();
}

//module.exports = new ImageModule();
export default new ImageModule();
