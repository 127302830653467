import React, { useContext } from "react";

// Internal
import HistoryCheckboxes from "./HistoryCheckboxes";
import FilteredAssets from "./FilteredAssets";
import { HistoryContext } from "../HistoryContext";
import Language from "sccLanguage";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rightPanelContainer: {
    width: "100%",
    padding: "3px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  alertBox: {
    width: "100%",
    left: "0",
    top: "0",
    position: "absolute",
    padding: "2px",
    textAlign: "center",
    border: "1px solid transparent",
    borderRadius: "4px",
    backgroundColor: "#fcf8e3",
    borderColor: "#faebcc",
    fontSize: "1.2rem",
  },
}));

function RightPanel() {
  const [state] = useContext(HistoryContext);
  const hideNoEvents = state.hideNoEvents;
  const eventsDatasetValid = state.eventsDatasetValid;
  const Historic = state.Historic;
  const classes = useStyles();

  const displayRightPanelContent = (hideNoEvents) => {
    return (
      <React.Fragment>
        {hideNoEvents ? (
          <div className={classes.alertBox}>
            <strong>{Language.translate("Warning")}!</strong>&nbsp;
            {Language.translate("Assets with no events are hidden")}
          </div>
        ) : null}
        <div className={classes.rightPanelContainer}>
          <HistoryCheckboxes />
          <FilteredAssets></FilteredAssets>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {Object.keys(Historic.playOptions.device).length !==
        0 && eventsDatasetValid
        ? displayRightPanelContent(hideNoEvents)
        : null}
    </React.Fragment>
  );
}

export default RightPanel;
