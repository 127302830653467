import React, { useState } from "react";
import Historic from "sccHistory";
import Reports from "sccReport";
const Device = require("sccDevice").default;

const ReportContext = React.createContext([{}, () => {}]);

const DEFAULT_ITEMS_PER_PAGE = process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE;

const ReportContextProvider = (props) => {
  const devices = Device.get();
  const numberOfItems = devices ? Object.keys(devices).length : 0;
  const itemsPerPage = Number(DEFAULT_ITEMS_PER_PAGE);
  const maxPages = 5;
  const [state, setState] = useState({
    Historic,
    Reports,
    //common reusable functions
    updateKeysInState,
    resetAllComponents,
    displayMessage,
    capitalizeFirstLetter,
    sortReportData,
    //shared variables
    reportType: 1,
    reportTypeDisplaying: 1,
    leftPanelDrawerOpen: true,
    showNotification: false,
    showLatestReportsTable: true,
    tableLoaded: false,
    eventsDatasetValid: false, //used as the events are loaded in Historic even though they are out of our date range
    itemsPerPage: 20,
    orderByASC: true,
    sortField: "report_timestamp",
    //objects
    reportData: [],
    filteredReportData: [],
    pagination: {
      currentPage: 1,
      itemsPerPage: itemsPerPage,
      numberOfItems: numberOfItems,
      maxPages: maxPages,
      startIndex: 0,
      endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
    },
    notificationOptions: {},
  });

  function updateKeysInState(keys) {
    setState((p) => {
      return Object.assign({}, p, keys);
    });
  }
  function displayMessage(
    enqueueSnackbar,
    title,
    message,
    type = "error",
    options = {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    }
  ) {
    enqueueSnackbar(
      <p>
        {title != null ? (
          <React.Fragment>
            <strong>{title}</strong> <br />
          </React.Fragment>
        ) : null}
        {message}
      </p>,
      options
    );
  }

  function sortReportData(data, orderByASC, sortField) {
    return data.sort((a, b) =>
      orderByASC
        ? parseFloat(b[sortField]) - parseFloat(a[sortField])
        : parseFloat(a[sortField]) - parseFloat(b[sortField])
    );
  }

  /**
   * reset All Components for Report
   */
  function resetAllComponents() {
    updateKeysInState({});
  }

  /**
   * Returns the string with capitalized first letter
   * @param String string
   */
  function capitalizeFirstLetter(string) {
    if (string === "non_report") {
      return "Non Report";
    }
    if (string === "vehicle") {
      return "Vehicle Events";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <ReportContext.Provider value={[state, setState]}>
      {props.children}
    </ReportContext.Provider>
  );
};
export { ReportContext, ReportContextProvider };
