import React from "react";
import "./MapBottomLeft.scss";
import CustomTooltip from "@Components/CustomTooltip";
//import * as OlMap from "sccOlMapNew";
const OlMap = require("sccOlMapNew").default;
const Language = require("sccLanguage").default;

export default function MapBottomLeft(){
	const attributionDisplay = OlMap.getOptions("attributionDisplay").elementId &&
    <li id={OlMap.getOptions("attributionDisplay").elementId}></li>;

	const coordsDisplay = OlMap.getOptions("coordinateDisplay") &&
    <CustomTooltip title={Language.translate("Latitude") +" - "+ Language.translate("Longitude")} arrow placement="top">
    	<li id={OlMap.getOptions("coordinateDisplay").elementId}></li>
    </CustomTooltip>;

	const scaleLineDisplay = OlMap.getOptions("scaleLineDisplay") &&
    <li><div id={OlMap.getOptions("scaleLineDisplay").elementId}></div></li>;

	return (
		<ul className="mapBottomLeftElements">
			{attributionDisplay}
			{coordsDisplay}
			{scaleLineDisplay}
		</ul>
	);  
}