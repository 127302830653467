import { moduleListing } from "./ModuleListing";

export const moduleMessage = (theme) => ({
  listContainer: {
    ...moduleListing(theme).listContainer,
    "& .noMessages": {
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
      color: theme.palette.colors.black.main,
      height: 35,
      paddingTop: 5,
      marginTop: 7,
      textAlign: "center",
    },
    "& .header": {
      backgroundColor: theme.palette.colors.gray.main,
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
      color: theme.palette.colors.black.main,
      height: 35,
      fontWeight: 700,
      "& .dateLink": {
        color: theme.palette.colors.black.main,
      },
    },
    "& .listItem": {
      backgroundColor: theme.palette.colors.white.main,
      height: 40,
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
      "& .chkBox": {
        width: 30,
        textAlign: "center",
      },
      "& .rowText": {
        marginTop: 7,
      },
      "& .ellipsis": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        "& .MuiSvgIcon-root": {
          width: "1.35rem",
          height: "1.35rem",
        },
      },
      "& .status": {
        width: 30,
        minHeight: 22,
        textAlign: "center",
        "& .badge": {
          marginLeft: 10,
        },
      },
      "& .MuiSvgIcon-fontSizeSmall": {
        fontSize: 10,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 35,
      },
      "& .MuiTypography-body2": {
        fontSize: 14,
      },
    },
    "& .listItem.read": {
      backgroundColor: theme.palette.colors.white.main,
    },
    "& .listItem.unread": {
      backgroundColor: theme.palette.colors.green.light,
    },
    "& .listItem.checked": {
      backgroundColor: theme.palette.colors.yellow.main,
    },
  },
  compose: {
    margin: 0,
    padding: 10,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .heading": { fontSize: "1.5rem", textAlign: "right" },
    "& .messageAttachmentButton": {
      alignSelf: "end",
    },
    "& .messageText": {
      marginTop: 5,
    },
    "& .maxSizeInfoBox": {
      background: theme.palette.colors.gray.light,
      width: 100,
      marginLeft: 6,
      padding: 3,
      height: 35,
      "& .maxSizeInfo": {
        fontSize: 14,
      },
    },
    "& .warningText": {
      marginTop: 5,
      backgroundColor: theme.palette.background.warning.main,
      color: theme.palette.background.warning.dark,
      alignContent: "center",
      height: 30,
      "& strong": {
        alignSelf: "center",
        textAlign: "center",
        width: "-webkit-fill-available",
        fontSize: ".85rem",
      },
    },
    "& .attachments": {
      marginTop: 5,
    },
    "& .fileUploadInput": {
      display: "none",
    },
    "& .fileNameBox": {
      background: theme.palette.colors.gray.light,
      width: 300,
      marginLeft: 6,
      padding: 3,
      height: 30,
      "& .fileName": {
        paddingLeft: 8,
        marginTop: 8,
        fontSize: 14,
      },
    },
    "& .fileSize": {
      paddingTop: 15,
    },
  },
  messageContainer: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiDivider-root": {
      marginTop: 10,
      marginBottom: 10,
      width: "100%",
    },
    "& .date": {
      paddingTop: 8,
    },
    "& .action": {
      "& .count": {
        marginLeft: 12,
        opacity: 0.7,
        "& .MuiBadge-badge": {
          backgroundColor: theme.palette.colors.black.light,
          color: theme.palette.colors.white.main,
        },
      },
    },
    "& h2": {
      fontSize: "1.25rem",
      margin: 0,
    },
    "& h3": {
      fontSize: "1.1rem",
      marginBottom: 0,
    },
    "& .attachments": {
      fontSize: ".8rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.25rem",
        verticalAlign: "text-bottom",
      },
    },
    "& .chip-success": {
      height: 20,
      border: 0,
      marginRight: 7,
      marginBottom: 2,
      background: theme.palette.colors.green.main,
      color: theme.palette.colors.white.main,
      "& .MuiAvatar-colorDefault": {
        backgroundColor: "unset",
        color: theme.palette.colors.white.main,
      },
    },
    "& .chip-warning": {
      height: 20,
      border: 0,
      marginRight: 7,
      marginBottom: 2,
      background: theme.palette.colors.orange.main,
      color: theme.palette.colors.white.main,
      "& .MuiAvatar-colorDefault": {
        backgroundColor: "unset",
        color: theme.palette.colors.white.main,
      },
    },
    "& .chip-danger": {
      height: 20,
      border: 0,
      marginRight: 7,
      marginBottom: 2,
      background: theme.palette.colors.red.main,
      color: theme.palette.colors.white.main,
      "& .MuiAvatar-colorDefault": {
        backgroundColor: "unset",
        color: theme.palette.colors.white.main,
      },
    },
  },
});
