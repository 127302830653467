
var Menu= require("sccMenu").default;
var _= require("lodash");
var Ar = require("sccAr");
var Language = require("sccLanguage").default;
require("../style/alert_rule.scss");
/**
 * Alert Rule menu class
 * 
 * @class ArMenu
 * @param {object} options - alert rule menu information object
 */
class ArMenu extends Menu {
	constructor(){
		var options= {
			tab: "AR",
			shortTitle: "AR",
			title: Language.translate("Alert Rule"),
			mainClass: "Alert-Rule",
			searchable: true,
			imageClass: "list",
			module: Ar,
			showPagination: true
		};
		super(options);
		this.showContactEditForm= false;
		this.editContactObj= {};
	}

	init($scope){
		$scope.Ar= Ar;
		$scope.Device= require("sccDevice").default;
		$scope.Group= require("sccGroup");
		$scope._= require("lodash");
		$scope.ArMenu= this;		
		return super.init($scope)
		.then(()=> {
			$scope.menuInitialized= true;
			return Promise.resolve();
		});
	}

	/**
	 * overriding the add method of the Menu class
	 * 
	 */
	add(obj){
		obj= _.assign(obj, { alerts: [], members: { groups: [], devices: [] }, subscribers: { groups: [], devices: [], users: {}}});
		super.add(obj);	
	}
}

export default new ArMenu();
