import React, { useContext, useEffect } from "react";

// Material-UI
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import AdminUser from "sccAdminUser";
import ClientDetail from "./ClientDetail";
import Language from "sccLanguage";
import Permission from "sccPermission";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  itemPrimaryText: {
    ...moduleListing(theme).itemPrimaryText,
    maxWidth: 400,
  },
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

export default function ClientList(props) {
  const data = props.data;
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const adminUserName = AdminUser.get(data.main_user_id).username;

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...p.moduleItemData },
    }));
  }, []);

  const addCustomAdminObject = {
    tab: "admin_user",
    shortTitle: "Admin User",
    title: "Admin User",
    moduleName: "Admin User",
    module: AdminUser,
    imageClass: "laptop",
    extLink: false,
    isList: true,
    wrapperDisplayMode: MW_LIST_MODE,
    showFilter: false,
    showPagination: true,
    showSearch: true,
    searchField: ["first_name", "last_name"],
    showFooter: true,
    verifyAddPermission: Permission.verify("admin_user", "addsibling"),
    verifyEditPermission: Permission.verify("admin_user", "edit"),
    verifyDeletePermission: Permission.verify("admin_user", "delete"),
    verifyShownPermission: Permission.verify("admin_user", "view"),
  };

  const handleAddCustomerAdmin = (clientId) => {
    // open admin user add form
    mwState.openModuleWrapperPopper(addCustomAdminObject, {
      client_id: clientId,
    });

    // set form company value
    mwState.handleItemEdit({
      email_registration: true,
      client_id: Number(clientId),
      role_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    });
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.company} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={
            <React.Fragment>
              {data.company + " "}
              {adminUserName ? (
                <Chip
                  label={adminUserName}
                  size="small"
                  avatar={<Avatar>{adminUserName.charAt(0)}</Avatar>}
                  color="primary"
                />
              ) : null}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {data.role_id !== 2 ? (
            <React.Fragment>
              {!data.main_user_id && (
                <CustomTooltip
                  title={Language.translate("Add Customer Admin")}
                  placement="left"
                >
                  <IconButton
                    edge="end"
                    aria-label="add-customer-admin"
                    onClick={() => handleAddCustomerAdmin(data.id)}
                  >
                    <PersonAddIcon />
                  </IconButton>
                </CustomTooltip>
              )}
              {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Edit")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              )}
              {mwState.verifyDeletePermission && !data.active && (
                <CustomTooltip
                  title={Language.translate("Delete")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      handleItemDelete(
                        `${mwState.routeUrl}/${data.id}`,
                        mwState.title,
                        "Company",
                        data.company
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </React.Fragment>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <ClientDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
