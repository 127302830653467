import React, { useContext, useEffect, useState } from "react";

//External
import _ from "lodash";
import { useFormikContext } from "formik";

//Material UI
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LaptopIcon from "@material-ui/icons/Laptop";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Popover from "@material-ui/core/Popover";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";

// Components
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import CustomTextField from "@Components/CustomTextField";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTooltip from "@Components/CustomTooltip";

// Styles
import theme from "@Styles/theme";

//Internal
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import Permission from "sccPermission";
import User from "sccUser";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.gray.main,
    height: 30,
    boxShadow: "none",
    marginLeft: 40,
  },
  chip: {
    height: 15,
    backgroundColor: theme.palette.colors.green.dark,
    marginLeft: 5,
  },
  chipRecipient: {
    height: 25,
    marginRight: 10,
    marginBottom: 5,
    backgroundColor: theme.palette.colors.gray.main,
    fontSize: 10,
  },
  item: {
    height: "30px",
    borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
    whitespace: "nowrap",
    "& .MuiListItemText-root": {
      "& .MuiListItemText-primary": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "5px 5px",
      },
    },
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
      "& .MuiCheckbox-root": {
        marginLeft: 0,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 20,
    },
  },
  viewMessageRecipients: {
    margin: 0,
    marginBottom: 10,
    padding: 10,
    minHeight: 20,
    maxHeight: 200,
    overflowX: "hidden",
    overflowY: "auto",
    border: `1px solid ${theme.palette.colors.red.dark}`,
  },
  viewMessageAttachment: {
    padding: 0,
    fontSize: 12,
  },
  controls: { width: "100%" },
  btn: {
    padding: 7,
    marginRight: 10,
    marginBottom: 3,
    minWidth: "50px !important",
    height: 30,
    color: theme.palette.colors.black.main,
    background: theme.palette.colors.gray.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    verticalAlign: "unset",
  },
  btnRemove: {
    marginBottom: 3,
    padding: 7,
    minWidth: "50px !important",
    height: 30,
    color: theme.palette.colors.black.main,
    float: "right",
    border: 0,
    alignItems: "start",
  },
  userSearch: {
    width: "calc(100% - 8px)",
    padding: 4,
  },
  noResult: {
    padding: 4,
    textAlign: "center",
  },
}));

function Recipients(props) {
  // const {name, label, value, color="default", checked, indeterminate, labelPlacement="end", actions, onCheckAllChange, handleAction, ...other} = props;
  const {
    recipients,
    handleRecipientDelete,
    updateAssetRecipients,
    onUserChange,
  } = props;
  const formik = useFormikContext();
  const [assetSelectorState, setAssetSelectorState] = useContext(
    CustomAssetSelectorContext
  );

  const classes = useStyles();
  const [filteredUsers, setFilteredUsers] = useState(usersList());
  const [userSearchValue, setUserSearchValue] = useState("");

  const [anchorElAssets, setAnchorElAssets] = React.useState(null);
  const [anchorElUsers, setAnchorElUsers] = React.useState(null);

  function usersList() {
    const users = (User.get() && Object.values(User.get())) || [];
    var output = users.filter((user) => user.username !== null);
    return output;
  }

  // use debounce to avoid to many re-renders
  const onUserSearch = _.debounce((value) => {
    let newFilteredUsers = usersList();

    if (newFilteredUsers.length > 0 && value.length > 0) {
      value = value.toLowerCase();
      newFilteredUsers = newFilteredUsers.filter(
        (user) =>
          user.username.toLowerCase().includes(value) ||
          user.first_name.toLowerCase().includes(value) ||
          user.last_name.toLowerCase().includes(value)
      );
    }

    setFilteredUsers(newFilteredUsers);
  }, 300);

  const handleUserSearch = (e) => {
    e.preventDefault();
    setUserSearchValue(e.target.value);
    onUserSearch(e.target.value);
  };

  const clearUserSearch = () => {
    setUserSearchValue("");
    onUserSearch("");
  };

  const handleClickAssets = (event) => {
    setAnchorElAssets(event.currentTarget);
  };
  const handleCloseAssets = () => {
    setAnchorElAssets(null);
  };
  const handleClickUsers = (event) => {
    setAnchorElUsers(event.currentTarget);
  };
  const handleCloseUsers = () => {
    setAnchorElUsers(null);
  };

  function usersLength() {
    const ids = [];
    _.each(filteredUsers, function (key) {
      if (key.username) {
        ids.push(key.id);
      }
    });
    return ids.length;
  }

  const openAssets = Boolean(anchorElAssets);
  const openUsers = Boolean(anchorElUsers);
  const idAssets = openAssets ? "assetsPopover" : undefined;
  const idUsers = openUsers ? "userPopover" : undefined;

  function displayRecipients() {
    let recipientChips = [];
    recipients &&
      recipients.users.map((user) => {
        const getUser = User.get(user);
        recipientChips.push(
          <Chip
            className={classes.chipRecipient}
            icon={<LaptopIcon />}
            label={getUser ? getUser.first_name + " " + getUser.last_name : ""}
            onDelete={() => handleRecipientDelete("users", user)}
            key={user}
          />
        );
      });
    recipients &&
      recipients.devices.map((device) =>
        recipientChips.push(
          <Chip
            className={classes.chipRecipient}
            icon={<PhoneAndroidIcon />}
            label={Device.get(device)?.name}
            variant="outlined"
            onDelete={() => handleRecipientDeleteLocal(device)}
            key={device}
          />
        )
      );
    return recipientChips;
  }

  function handleRemoveAll() {
    formik.setFieldValue("recipients.users", []);
    setAssetSelectorState((p) => ({
      ...p,
      selection: {
        recipients: {
          devices: [],
          groups: [],
        },
      },
    }));
  }
  function handleRecipientDeleteLocal(device) {
    //this function copied and modified from the AS module. The idea is to update the correct groups and devices in AS module from outside
    const name = "recipients";
    let arrGroups = assetSelectorState.selection[name].groups;
    const arrDevices = _.xor(assetSelectorState.selection[name].devices, [
      device,
    ]);
    // Get the groups associated to this device
    const groups = Device.get(device).groups;

    // Loop through each group to get total devices
    groups.forEach((groupId) => {
      arrGroups = _.xor(
        arrGroups,
        checkGroups(groupId, _.uniq(arrDevices.concat(device)))
      );
    });
    setAssetSelectorState((p) => ({
      ...p,
      selection: {
        recipients: {
          ...p.selection.recipients,
          devices: arrDevices,
          groups: arrGroups,
        },
      },
    }));

    function checkGroups(groupId, arrDevices) {
      //Get all devices in group
      const devices = Group.get(groupId).devices;
      if (_.intersection(devices, arrDevices).length === devices.length) {
        return [groupId];
      }
      return [];
    }
  }

  useEffect(() => {
    if (assetSelectorState.selection["recipients"]) {
      //each selection change would update the local recipients
      updateAssetRecipients(assetSelectorState.selection["recipients"]);
    }
  }, [assetSelectorState.selection]);

  return (
    <div className={classes.controls}>
      <Button
        aria-describedby={idAssets}
        variant="contained"
        onClick={handleClickAssets}
        className={classes.btn}
      >
        {Language.translate("Assets")} (
        {Device.get() ? Object.keys(Device.get()).length : 0}){" "}
        {recipients && recipients.devices.length > 0 ? (
          <Chip
            size="small"
            color="primary"
            label={recipients.devices.length}
            className={classes.chip}
          />
        ) : null}{" "}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={idAssets}
        open={openAssets}
        anchorEl={anchorElAssets}
        onClose={handleCloseAssets}
        style={{ position: "absolute", top: 0, left: 0, borderRadius: 0 }}
        PaperProps={{
          style: {
            width: 450,
            maxHeight: 300,
            borderRadius: 0,
            borderTop: `1px solid ${theme.palette.colors.gray.pure}`,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomAssetWrapper
          selection={recipients}
          openMain={true}
          name="recipients"
          editMode={true}
        />
      </Popover>
      <Button
        aria-describedby={idUsers}
        variant="contained"
        onClick={handleClickUsers}
        className={classes.btn}
      >
        {Language.translate("Users")} ({usersLength()}){" "}
        {recipients && recipients.users.length > 0 ? (
          <Chip
            size="small"
            color="primary"
            label={recipients.users.length}
            className={classes.chip}
          />
        ) : null}{" "}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={idUsers}
        open={openUsers}
        anchorEl={anchorElUsers}
        onClose={handleCloseUsers}
        style={{ position: "absolute", top: 0, left: 0, borderRadius: 0 }}
        PaperProps={{
          style: {
            width: 250,
            maxHeight: 300,
            borderRadius: 0,
            borderTop: `1px solid ${theme.palette.colors.gray.pure}`,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <>
          <CustomTextField
            name="search"
            placeholder={Language.translate("Search")}
            className={classes.userSearch}
            value={userSearchValue}
            onChange={(e) => handleUserSearch(e)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  id="clearSearch"
                  onClick={() => clearUserSearch()}
                  style={{ cursor: "pointer", paddingRight: "8px" }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />

          {Permission.verify("user") ? (
            usersLength() === 0 ? (
              <div className={classes.noResult}>
                {Language.translate("Currently there are no Users")}
              </div>
            ) : (
              <List>
                {Object.values(filteredUsers).map((option) => {
                  const getUser = User.get(option.id);
                  return (
                    <ListItem className={classes.item} key={option.id}>
                      <ListItemIcon>
                        <CustomCheckbox
                          edge="start"
                          checked={
                            recipients &&
                            recipients.users.indexOf(option.id) > -1
                          }
                          onChange={onUserChange}
                          tabIndex={-1}
                          value={option.id}
                          inputProps={{ "aria-labelledby": option.id }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={option.id}
                        primary={
                          <CustomTooltip
                            title={
                              getUser
                                ? getUser.first_name + " " + getUser.last_name
                                : ""
                            }
                            placement="right"
                          >
                            {option.username}
                          </CustomTooltip>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            )
          ) : (
            <div className={classes.noResult}>
              {Language.translate("You do not have Permission to view Users")}
            </div>
          )}
        </>
      </Popover>

      {!_.every(recipients, function (recipient) {
        return _.isEmpty(recipient);
      }) && (
        <Button
          aria-describedby={idUsers}
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={handleRemoveAll}
          className={classes.btnRemove}
        >
          {Language.translate("REMOVE ALL")}
        </Button>
      )}
      <div className={classes.viewMessageRecipients}>
        {displayRecipients().map((r) => r)}
      </div>
    </div>
  );
}

export default Recipients;
