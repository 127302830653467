
import { default as Module } from "sccModule";

/**
 * The base class for Alert Rules
 * 
 * @class ArModule
 */
class ArModule extends Module.Module{
	constructor(){
		const options= {
			moduleName: "ar"

		};	
		super(options);
	
	}
}
export default new ArModule();
