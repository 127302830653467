import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape(
  {
    active: Yup.boolean(),
    company: baseSchema(Language).requiredStringMinMax,
    city: baseSchema(Language).requiredStringMinMax,
    country: baseSchema(Language).dropdownSelectStr,
    email: baseSchema(Language).emailRequired,
    start_timestamp: Yup.number()
      .typeError(Language.translate("Select a valid start date"))
      .positive()
      .required(),
    expiry_timestamp: Yup.number()
      .typeError(Language.translate("Select a valid end date"))
      .positive()
      .moreThan(
        Yup.ref("start_timestamp"),
        Language.translate("End date must be after start date")
      )
      .when("expiry_exists", {
        is: true,
        then: Yup.number().nullable(true),
      }),
    device_limit: baseSchema(Language).requiredNumberPositive,
    user_limit: baseSchema(Language).requiredNumberPositive,
    "2fa": baseSchema(Language).dropdownSelectNum,
    phone_number: baseSchema(Language).phoneNumber.nullable(true),
    map_layers: Yup.array().test({
      name: "map_layers_test",
      exclusive: true,
      message: Language.translate("Select at least 1 Map layer"),
      test: (value) => value.length > 0,
    }),
    saFeatureSelected: Yup.boolean().nullable(true),
    // 	sa_wave_limit: Yup.number().when(['saFeatureSelected', 'sa_scct_limit'], {
    // 		is: (saFeatureSelected, sa_scct_limit) =>
    // 			saFeatureSelected === true && !sa_scct_limit,
    // 		then: baseSchema(Language)
    // 			.requiredNumberPositiveInt.min(1, Language.translate(''))
    // 			.max(
    // 				Yup.ref('device_limit'),
    // 				Language.translate('Wave limit cannot exceed the device limit')
    // 			),
    // 	}),
    // 	sa_scct_limit: Yup.number().when(['saFeatureSelected', 'sa_wave_limit'], {
    // 		is: (saFeatureSelected, sa_wave_limit) =>
    // 			saFeatureSelected === true && !sa_wave_limit,
    // 		then: baseSchema(Language)
    // 			.requiredNumberPositiveInt.min(1, Language.translate(''))
    // 			.max(
    // 				Yup.ref('device_limit'),
    // 				Language.translate('SCCT limit cannot exceed the device limit')
    // 			),
    //   }),
    //   sa_theia_limit: Yup.number(),
    // },
    // [['sa_scct_limit', 'sa_wave_limit']]

    sa_wave_limit: Yup.number().when(
      ["saFeatureSelected", "sa_scct_limit", "sa_theia_limit"],
      {
        is: (saFeatureSelected, sa_scct_limit, sa_theia_limit) =>
          saFeatureSelected === true && !sa_scct_limit && !sa_theia_limit,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(1, Language.translate(""))
          .max(
            Yup.ref("device_limit"),
            Language.translate("Wave limit cannot exceed the device limit")
          ),
      }
    ),
    sa_scct_limit: Yup.number().when(
      ["saFeatureSelected", "sa_wave_limit", "sa_theia_limit"],
      {
        is: (saFeatureSelected, sa_wave_limit, sa_theia_limit) =>
          saFeatureSelected === true && !sa_wave_limit && !sa_theia_limit,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(1, Language.translate(""))
          .max(
            Yup.ref("device_limit"),
            Language.translate("SCCT limit cannot exceed the device limit")
          ),
      }
    ),
    sa_theia_limit: Yup.number().when(
      ["saFeatureSelected", "sa_wave_limit", "sa_scct_limit"],
      {
        is: (saFeatureSelected, sa_wave_limit, sa_scct_limit) =>
          saFeatureSelected === true && !sa_wave_limit && !sa_scct_limit,
        then: baseSchema(Language)
          .requiredNumberPositiveInt.min(1, Language.translate(""))
          .max(
            Yup.ref("device_limit"),
            Language.translate(
              "NORTAC Theia limit cannot exceed the device limit"
            )
          ),
      }
    ),
  },
  [
    ["sa_scct_limit", "sa_wave_limit"],
    ["sa_scct_limit", "sa_theia_limit"],
    ["sa_theia_limit", "sa_wave_limit"],
  ]
);

export default schema;
