import React from "react";

import Images from "sccImage";

export default function Logo() {
  const logoImage =
    Images.getImageCollection("platform_images").scc_titan_logo.default;

  return (
    <div className="navbar-header">
      <div className="navbar-brand scc-logo">
        <img src={logoImage} className="logo" alt="Titan by NORTAC Platform" />
      </div>
    </div>
  );
}
