import React, { useState, useEffect, useContext } from "react";

// External
import _ from "lodash";
import clsx from "clsx";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

// Internal
import Language from "sccLanguage";
import Group from "sccGroup";
import Device from "sccDevice";
import CustomTextField from "@Components/CustomTextField";
import { CustomAssetSelectorContext } from "./CustomAssetSelectorContext";
import MinimizeContextProvider from "../../modules/device/components/context/MinimizeContext";
import CustomAssetSelector from "./CustomAssetSelector";
import { customAssetSelector } from "@Styles/CustomAssetSelector";

export default function CustomAssetWrapper({
  showSearchField = true, // default showSearch field in the top
  showDeviceSearchField = true, // default show devices search field
  ...props
}) {
  const [groupTree, setGroupTree] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const name = props.name || "AssetSelector";

  const setStateForEditMode = assetSelectorState.setStateForEditMode;
  const setHideDeviceCheckbox = assetSelectorState.setHideDeviceCheckbox;
  const setHideGroupCheckbox = assetSelectorState.setHideGroupCheckbox;
  const setShowGroupRadioButtons = assetSelectorState.setShowGroupRadioButtons;
  const setHideDevices = assetSelectorState.setHideDevices;
  const setShowSelectedOnly = assetSelectorState.setShowSelectedOnly;

  const setStateForSelection = assetSelectorState.setStateForSelection;
  const collection = !props.collection
    ? Group.getGroupTree()?.groups
    : props.collection; //the collection should always be the main object
  const originalCollection = _.cloneDeep(collection);

  const maximizeSelection = assetSelectorState.maximizeSelection;

  const useStyles = makeStyles((theme) => ({
    ...customAssetSelector(theme),
    ASheight: {
      maxHeight: props.height ? props.height : "auto",
      overflowY: props.height ? "auto" : "hidden",
      overflowX: "hidden",
    },
    searchField: {
      padding: "2px 5px",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    setStateForEditMode(props.editMode);
    setHideDeviceCheckbox(props.hideDeviceCheckbox);
    setHideGroupCheckbox(props.hideGroupCheckbox);
    setShowGroupRadioButtons(props.showGroupRadioButtons);
    setHideDevices(props.hideDevices);
    setShowSelectedOnly(props.showSelectedOnly);

    setGroupTree(collection);
    if (_.isUndefined(props.selection)) return;
    if (!props.showGroupRadioButtons) {
      setStateForSelection(maximizeSelection(props.selection), name);
    } else {
      setStateForSelection(props.selection, name);
    }
  }, []);

  useEffect(() => {
    setGroupTree(collection);
  }, [collection]);

  // for search use functions
  function matchingDevices(group, filters) {
    var title = filters.title && filters.title.toLowerCase();
    var typeId = filters.type_id;

    return _.reduce(
      group.devices,
      function (result, deviceId) {
        var device = Device.get(deviceId);
        if (!device) return result || false;

        var filterTitleCheck =
          title !== ""
            ? device.name.toLowerCase().indexOf(title) > -1 ||
              device.imei.toLowerCase().indexOf(title) > -1
            : true;
        var filterTypeCheck = _.isEmpty(typeId)
          ? true
          : _.indexOf(typeId, device.type_id) > -1;

        if (filterTitleCheck && filterTypeCheck)
          result = _.concat(result, [deviceId]);

        return result || (filterTitleCheck && filterTypeCheck);
      },
      []
    );
  }

  function recursiveFilter(group, filters, level, type) {
    group.groups.forEach((subGroup) => {
      if (_.isArray(subGroup.groups) && subGroup.groups.length > 0) {
        level += 1;
        recursiveFilter(subGroup, filters, level, type);
        level -= 1;
      }
      if (matchingDevices(subGroup, filters).length > 0) {
        subGroup.devices = matchingDevices(subGroup, filters);
      } else {
        //if there is no matching device we dont need to look anY further, so we remove the group from parent groups array
        group.groups = group.groups.filter((obj) => !_.isEqual(obj, subGroup));
      }
    });
  }

  function filteredTree(group, filters, type) {
    if (!filters) return false;

    if (!(filters.title || (filters.type_id && filters.type_id.length)))
      return false;

    type = type || "device";

    recursiveFilter(group, filters, 1, type);
  }

  const handleClearSearch = () => {
    setSearchText("");
    setGroupTree(originalCollection);
  };

  const handleSearch = _.debounce((value) => {
    const filters = { title: value, type_id: [] };
    let newGroupTree = originalCollection;

    if (newGroupTree.length > 0) {
      newGroupTree.forEach((group) => {
        filteredTree(group, filters, "device");
        group.devices = matchingDevices(group, filters);
      });
    }

    setGroupTree(newGroupTree);
  }, 300);

  const onSearch = (e) => {
    setSearchText(e.target.value);
    handleSearch(e.target.value);
  };

  return (
    <div
      className={clsx(
        classes.customAssetSelectorWrapper,
        classes.ASheight,
        props.editMode ? "edtiMode" : ""
      )}
    >
      {showSearchField && groupTree && (
        <div className={classes.searchField}>
          <CustomTextField
            id="txtSearch"
            name="search"
            placeholder={Language.translate("Search")}
            value={searchText}
            onChange={onSearch}
            onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment:
                searchText.length > 0 ? (
                  <InputAdornment
                    position="end"
                    id="clearSearch"
                    onClick={handleClearSearch}
                    style={{ cursor: "pointer" }}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ) : (
                  <div />
                ),
            }}
          />
        </div>
      )}
      {groupTree ? (
        <React.Fragment>
          <MinimizeContextProvider>
            {/* collection will always be Group.getGroupTree and thus in the for {[{},{}]} and contain only the main group*/}
            <CustomAssetSelector
              collection={groupTree}
              name={name}
              showGroupRadioButtons={props.showGroupRadioButtons}
              selectionGroups={
                assetSelectorState.selection[name]
                  ? assetSelectorState.selection[name].groups
                  : []
              }
              selectionDevices={
                assetSelectorState.selection[name]
                  ? assetSelectorState.selection[name].devices
                  : []
              }
              editMode={props.editMode}
              devicesButtons={props.devicesButtons}
              groupButtons={props.groupButtons}
              openMain={props.openMain}
              hideDevices={props.hideDevices}
              showSelectedOnly={props.showSelectedOnly}
              handleItemEdit={props.handleItemEdit}
              isHistory={props.isHistory || false}
              showDeviceSearchField={showDeviceSearchField}
              displayDeviceImei={
                props.displayDeviceImei === undefined ||
                props.displayDeviceImei === null
                  ? true
                  : props.displayDeviceImei
              }
            />
          </MinimizeContextProvider>
        </React.Fragment>
      ) : (
        <div className="customAssetSelectorTitle">
          {Language.translate("Loading Asset Groups")} ...
        </div>
      )}
    </div>
  );
}
