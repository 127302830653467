import React, { Component } from "react";

// Material-UI
import Paper from "@material-ui/core/Paper";

// Internal
import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import ChatMessageFeed from "./ChatMessageFeed";
import MinimizeContextProvider from "../../../device/components/context/MinimizeContext";
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";

export default class ChatOverlay extends Component {
  constructor() {
    super();
    this.state = {
      device: null,
    };

    if (!ChatOverlay.publicInstance) {
      ChatOverlay.publicInstance = this;
    }
    this.hidden = true;
    this.minimize = false;
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      ChatOverlay.publicInstance.setFeatureSelected();
    });
  }

  static hideChatWindow() {
    setTimeout(() => {
      ChatOverlay.publicInstance.close();
    });
  }

  static minimizeChatWindow() {
    setTimeout(() => {
      ChatOverlay.publicInstance.hide();
    });
  }

  static maximizeChatWindow() {
    setTimeout(() => {
      ChatOverlay.publicInstance.show();
    });
  }

  close() {
    this.hidden = true;
    this.setState({ device: Device.get(DeviceOverlay.getSelectedId()) });
  }

  hide() {
    this.minimize = true;
    this.setState({ device: Device.get(DeviceOverlay.getSelectedId()) });
  }

  show() {
    this.minimize = false;
    this.setState({ device: Device.get(DeviceOverlay.getSelectedId()) });
  }

  setFeatureSelected() {
    if (DeviceOverlay.getSelectedId()) {
      this.setState({ device: Device.get(DeviceOverlay.getSelectedId()) });
      ChatOverlay.publicInstance.hidden = false;
    }
  }

  render() {
    const { device } = this.state;
    var hidden = this.hidden;
    var minimize = this.minimize;

    return (
      <MinimizeContextProvider>
        <Paper
          className={"ChatOverlay"}
          elevation={3}
          id={"olMapChatPopup"}
          style={{
            width: "400px",
            position: "fixed",
            bottom: "calc(1%)",
            left: "calc(1%)",
            zIndex: "200",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {device && !hidden ? (
            <React.Fragment>
              <ChatHeader device={device} minimize={minimize} />

              {!minimize ? <ChatMessageFeed device={device} /> : ""}
              {!minimize ? <ChatFooter device={device} /> : ""}
            </React.Fragment>
          ) : null}
        </Paper>
      </MinimizeContextProvider>
    );
  }
}
