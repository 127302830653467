import React from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";

// Internal
import POIOverlay from "sccPoiOverlay";
import CustomTooltip from "@Components/CustomTooltip";
import Language from "sccLanguage";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  DataDisplayHeader: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: alpha(theme.palette.colors.black.main, 0.85),
  },

  DataDisplayHeaderToolbar: {
    minHeight: 30,
    padding: "1px 4px",
  },

  DataDisplayHeaderAvatar: {
    width: 30,
    height: 30,
    borderRadius: "inherit",
    backgroundColor: "transparent",
    justifyContent: "flex-start",
  },

  root: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
    fontSize: 18,
    paddingLeft: 10,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 225,
    width: 225,
  },

  DataDisplayHeaderButton: {
    minWidth: 30,
    width: 30,
    height: 30,

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
    },

    "& span": {
      display: "flex",
      alignItems: "center",
    },
  },

  DataDisplayHeaderIcon: {
    color: alpha(theme.palette.colors.black.main, 0.25),
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: theme.palette.colors.blue.dark,
    },
  },

  DataDisplayHeaderImage: {
    maxHeight: 28,
    maxWidth: 28,
  },
}));

export default function DataDisplayHeader(props) {
  const classes = useStyles();

  const obj = props.device.id ? props.device : Object.values(props.device)[0];

  const handleClose = () => {
    POIOverlay.deselectFeature();
  };

  return (
    <div className={classes.root}>
      <div className="olMapPopupArrow" />
      <AppBar position="static" className={classes.DataDisplayHeader}>
        <Toolbar className={classes.DataDisplayHeaderToolbar}>
          <Avatar className={classes.DataDisplayHeaderAvatar}>
            <img
              className={classes.DataDisplayHeaderImage}
              alt={obj ? obj.title : null}
              src={POIOverlay.getIconById(POIOverlay.getSelectedId())}
            />
          </Avatar>

          <CustomTooltip
              title={obj.title}
              arrow
              placement="top"
            >
          <Typography variant="h6" className={classes ? classes.title : null}>
            {obj ? obj.title : null}
          </Typography>
          </CustomTooltip>

          <Button
            size="medium"
            color="inherit"
            onClick={handleClose}
            className={classes.DataDisplayHeaderButton}
          >
            <CustomTooltip
              title={Language.translate("Close")}
              arrow
              placement="top"
            >
              <CloseIcon
                fontSize="large"
                className={classes.DataDisplayHeaderIcon}
              />
            </CustomTooltip>
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
