import React, { createRef } from "react";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

function CustomSnackbar(props) {
	const notistackRef = createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const useStyles = makeStyles((theme) => ({
		contentRoot: {
			maxWidth: 200,
		},
		icons: {
			position: "absolute",
			top: 0,
			right: 0
		},
 	  closeIcon: {
     color: '#fff',
    },
	}));
	const classes = useStyles();
	return (
		<SnackbarProvider
			maxSnack={5}
			ref={notistackRef}
			autoHideDuration={5000}
			hideIconVariant={true}
			action={(key) => (
				<div className={classes.icons}>
					<IconButton className={classes.expand} onClick={onClickDismiss(key)}>
						<CloseIcon className={classes.closeIcon} />
					</IconButton>
				</div>
			)}
			classes={{ contentRoot: classes.contentRoot }}>
			{props.children}
		</SnackbarProvider>
	);
}

export default CustomSnackbar;