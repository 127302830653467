import React from "react";
import { withStyles } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
  root: (props) => {
    const styles = {};
    return styles;
  },
});

const CustomCheckbox = withStyles(styles)(function (props) {
  const {
    name,
    label,
    value,
    color = "default",
    checked,
    labelPlacement = "end",
    onChange,
    ...other
  } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          label={label}
          value={value}
          color={color}
          onChange={onChange}
          {...other}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
});

export default CustomCheckbox;
