import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import moment from "moment";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import BarChartIcon from "@material-ui/icons/BarChart";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import GroupIcon from "@material-ui/icons/Group";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

// Internal
import Language from "sccLanguage";
import Profile from "sccProfile";
import Device from "sccDevice";
import { ReportContext } from "../ReportContext";
import { AppContext } from "../../../../AppContext";
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  fullHeight: {
    height: "100%",
  },

  assetWrapper: {
    height: 'calc(100% - 300px)',
    overflow: "hidden",
  },

  textField: {
    borderRadius: 0,
    width: 340,
    "& .MuiOutlinedInput-input": {
      padding: "12px",
      "& .MuiOutlinedInput-input:focus": {
        border: "1px solid red",
      },
    },
  },
  buttonMain: {
    borderRadius: 0,
    fontSize: "1rem",
    width: 340,
  },
  buttonGroup: {
    width: "100%",
    height: 35,
    display: "flex",
  },
  button: {
    backgroundColor: "#FAFAFA",
    color: "#BBB",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    padding: 8,
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: 8,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonText: {
    fontSize: ".75rem",
  },
  assetBoxText: {
    height: 45,
    padding: 12,
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    width: 340,
    marginBottom: 2,
    cursor: "pointer",
  },
  assetText: {
    padding: "0px 8px",
    fontSize: 16,
  },
  assetBoxCollapse: {
    maxHeight: "calc(100% - 45px)",
    overflowY: "auto",
  },
}));

const formatDateForCalendar = (date) => {
  const d = new Date(date);
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join(
      "-"
    ) +
    "T" +
    [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
  );
};

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const d = new Date().getTime();
const dateOffsetOneDay = 24 * 60 * 60 * 1000; //hr*min*sec*ms to offset one day

const dstart = formatDateForCalendar(d - dateOffsetOneDay);
const dend = formatDateForCalendar(d);

function LeftPanel() {
  const [startDate, setStartDate] = useState(dstart);
  const [endDate, setEndDate] = useState(dend);
  const [quickSelect, setQuickSelect] = useState(null);
  const [loadReportText, setLoadReportText] = useState(
    Language.translate("Get Latest Reports") //this needs to change based on the selection of latest report and position alerts
  );
  const [loadReportDisabled, setLoadReportDisabled] = useState(false);

  const [reportState, setReportState] = useContext(ReportContext);
  const [appState] = useContext(AppContext);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const updateKeysInState = reportState.updateKeysInState;
  const notificationOptions = reportState.notificationOptions;
  const selectionDevices = assetSelectorState.selection["devices"]?.devices;
  const reportType = reportState.reportType;
  const Historic = reportState.Historic;
  const Reports = reportState.Reports;
  const sortReportData = reportState.sortReportData;
  const orderByASC = reportState.orderByASC;

  const setReportType = (type) => {
    setReportState((p) => ({
      ...p,
      reportType: type,
    }));
  };

  const displayMessage = (title, message, type) => {
    const obj = {
      title,
      message,
      variant: type,
    };
    appState.displaySnackbarMessage(obj);
  };

  useEffect(() => {
    Historic.queryParams.startTimestamp = dstart;
    Historic.queryParams.endTimestamp = dend;
  }, []);

  useEffect(() => {
    updateKeysInState({ showNotification: !_.isEmpty(notificationOptions) });
  }, [notificationOptions]);

  useEffect(() => {
    if (reportType === 2) {
      handleDateBlur(startDate, endDate);
      setLoadReportDisabled(
        _.isUndefined(selectionDevices) || selectionDevices.length <= 0
      );
    }
  }, [selectionDevices]);

  useEffect(() => {
    if (reportType === 2) {
      setLoadReportDisabled(
        _.isUndefined(selectionDevices) || selectionDevices.length <= 0
      );
    }
  }, [assetSelectorState]);

  const handleDateBlur = (startDate, endDate) => {
    Historic.queryParams.startTimestamp = moment(startDate).unix() * 1000; //fixing the value retrieved from the calendar to match the format stored in historic;
    Historic.queryParams.endTimestamp = moment(endDate).unix() * 1000; //fixing the value retrieved from the calendar to match the format stored in historic
    Reports.startTimestamp = moment(startDate).unix() * 1000;
    Reports.endTimestamp = moment(endDate).unix() * 1000;
    validateHistoricDataSetSize();
  };

  const handleStartDate = (e) => {
    setQuickSelect(null);
    if (endDate !== null) {
      handleDateBlur(e.target.value, endDate);
    }
    if (reportType === 2) {
      setLoadReportDisabled(
        _.isUndefined(selectionDevices) || selectionDevices.length <= 0
      );
    }
  };

  const handleEndDate = (e) => {
    setQuickSelect(null);
    if (startDate !== null) {
      handleDateBlur(startDate, e.target.value);
    }
    if (reportType === 2) {
      setLoadReportDisabled(
        _.isUndefined(selectionDevices) || selectionDevices.length <= 0
      );
    }
  };

  const handleReportType = (type) => {
    if (type === 1) {
      setLoadReportText(Language.translate("Get Latest Reports"));
    } else if (type === 2) {
      setLoadReportText(Language.translate("Get Position/Alerts"));
    }
    setReportType(type);
  };
  const handleQuickSelect = (time, type) => {
    let qsVal = null;
    if (quickSelect === time.toString().concat(type)) {
      qsVal = null;
    } else {
      // Subtract selected set timeframe from End Date and set to start date
      Historic.queryParams.startTimestamp =
        moment(Historic.queryParams.endTimestamp).subtract(time, type).unix() *
        1000;
      setStartDate(formatDateForCalendar(Historic.queryParams.startTimestamp));
      qsVal = time.toString().concat(type);
    }
    setQuickSelect(qsVal);
  };

  /*
		Validate historic data set size 
		and control enabling of search button 
		and output error or warning message via buildNotificationError 
	*/
  const validateHistoricDataSetSize = function () {
    Historic.queryParams.devices = selectionDevices;
    const devices = Historic.queryParams.devices;
    //Convert Moment object to timestamp for backend
    const startTimestamp = Historic.getStartTimestamp();
    const endTimestamp = Historic.getEndTimestamp();

    const accountStartTimestamp = Profile.get()
      ? Profile.get().client.start_timestamp
      : startTimestamp; //account creation date or current day -1
    if (startTimestamp && endTimestamp && devices && devices.length > 0) {
      //date inputs validation and return on failure
      if (
        !validateTimestamps(startTimestamp, endTimestamp, accountStartTimestamp)
      ) {
        updateKeysInState({ notificationOptions: {} });
        return;
      }

      buildNotificationOptions(Language.translate("Calculating Data"));

      //since the dates are valid we are validating the dataset
      //execute request to fetch report info
      //before enabling the report button we are making sure the results for this query would be valid
      Historic.validateDataSet(devices, startTimestamp, endTimestamp).then(
        function (validation) {
          if (validation.type === "warning") {
            buildNotificationOptions(
              Language.translate(
                "The data requested is large and may slow down your browser"
              ),
              validation.type
            );
            setLoadReportDisabled(false);
          } else if (validation.type === "error") {
            buildNotificationOptions(
              Language.translate(
                "The data requested is too large. Please narrow your search criteria and try again"
              ),
              validation.type
            );
            setLoadReportDisabled(true);
          } else {
            updateKeysInState({ notificationOptions: {} });
            setLoadReportDisabled(false);
          }
        }
      );
    } else {
      validateTimestamps(startTimestamp, endTimestamp, accountStartTimestamp);
    }
  };

  //validates client's date inputs. If incorrect, returns false
  const validateTimestamps = (
    startTimestamp,
    endTimestamp,
    accountTimestamp
  ) => {
    setLoadReportDisabled(true); //at this time the load button is disabled and remains so
    if (isNaN(startTimestamp)) {
      displayMessage(
        Language.translate("Reports"),
        Language.translate("Invalid start date. Please select new start date"),
        "error"
      );
      return false;
    }
    if (isNaN(endTimestamp)) {
      displayMessage(
        Language.translate("Reports"),
        Language.translate("Invalid end date. Please select new end date"),
        "error"
      );
      return false;
    }
    if (startTimestamp < accountTimestamp) {
      displayMessage(
        Language.translate("Reports"),
        Language.translate("Invalid start date. Please select new start date"),
        "error"
      );
      return false;
    }

    if (endTimestamp < accountTimestamp) {
      displayMessage(
        Language.translate("Reports"),
        Language.translate("Invalid end date. Please select new end date"),
        "error"
      );
      return false;
    }

    if (moment(new Date().toString()) < moment.unix(endTimestamp)) {
      displayMessage(
        Language.translate("Reports"),
        Language.translate("Invalid end date. Please select new end date"),
        "error"
      );
      return false;
    }

    if (startTimestamp > endTimestamp) {
      displayMessage(
        Language.translate("Reports"),
        Language.translate("Invalid start date. Please select new start date"),
        "error"
      );
      return false;
    }
    setLoadReportDisabled(false); //at this time the load button is enabled as dates are valid
    return true;
  };

  function buildNotificationOptions(msg, icon = "", type = "info", time = 0) {
    updateKeysInState({
      notificationOptions: {
        msg: msg,
        icon: icon,
        type: type,
        time: time,
      },
    });
  }

  function handleSearch() {
    const itemsPerPage = reportState.itemsPerPage;
    const maxPages = 5;

    setLoadReportText(Language.translate("Loading") + "...");
    if (reportType === 1) {
      //creating a fail safe by checking if the user doesn't have access to devices, he doesnt land into errors
      if (Device.get() == null) {
        setLoadReportDisabled(true);
        setReportState((p) => ({
          ...p,
          reportTypeDisplaying: reportType,
          reportData: [],
          filteredReportData: [],
          pagination: {
            currentPage: 1,
            itemsPerPage: 20,
            numberOfItems: 0,
            maxPages: maxPages,
          },
          tableLoaded: true,
          showLatestReportsTable: true,
        }));
        setTimeout(() => {
          setLoadReportDisabled(false);
          setLoadReportText(Language.translate("Get Latest Reports"));
        }, 200);
        return false;
      }
      let devices = Object.values(Device.get());
      const startTimestamp = moment(startDate).unix();
      const endTimestamp = moment(endDate).unix();

      const filteredDevices = devices.filter((device) => {
        return (
          device.report_timestamp >= startTimestamp &&
          device.report_timestamp <= endTimestamp
        );
      });
      const numberOfItems = filteredDevices.length;
      setLoadReportDisabled(true);
      setReportState((p) => ({
        ...p,
        reportTypeDisplaying: reportType,
        reportData: sortReportData(
          filteredDevices,
          orderByASC,
          "report_timestamp"
        ),
        filteredReportData: sortReportData(
          filteredDevices,
          orderByASC,
          "report_timestamp"
        ),
        pagination: {
          currentPage: 1,
          itemsPerPage: itemsPerPage,
          numberOfItems: numberOfItems,
          maxPages: maxPages,
          startIndex: 0,
          endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
        },
        tableLoaded: true,
        showLatestReportsTable: true,
        sortField: "report_timestamp",
      }));
      setTimeout(() => {
        setLoadReportDisabled(false);
        setLoadReportText(Language.translate("Get Latest Reports"));
      }, 200);
    } else {
      Reports.position(); //responsible for loadData in historic
      setLoadReportDisabled(true);
      setTimeout(() => {
        const numberOfItems = Reports.historicData.length;
        setReportState((p) => ({
          ...p,
          reportTypeDisplaying: reportType,
          reportData: sortReportData(
            Reports.historicData,
            orderByASC,
            "event_timestamp"
          ),
          filteredReportData: sortReportData(
            Reports.historicData,
            orderByASC,
            "event_timestamp"
          ),
          pagination: {
            currentPage: 1,
            itemsPerPage: itemsPerPage,
            numberOfItems: numberOfItems,
            maxPages: maxPages,
            startIndex: 0,
            endIndex:
              numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
          },
          tableLoaded: true,
          showLatestReportsTable: false,
          sortField: "event_timestamp",
        }));
        setLoadReportDisabled(false);
        setLoadReportText(Language.translate("Get Position/Alerts"));
      }, 1500);
    }
  }

  const classes = useStyles();
  const [assetBoxChecked, setAssetBoxChecked] = useState(false);
  const handleAssetBoxChange = () => {
    setAssetBoxChecked((prev) => !prev);
  };
  return (
    // use the toggle button controls for quick select and main tab
    <form className={classes.container} noValidate>
      <Grid container spacing={2} direction="column" alignItems="center" className={classes.fullHeight} >
        <Grid item>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              className={
                reportType === 1 ? classes.activeButton : classes.button
              }
              onClick={() => handleReportType(1)}
            >
              <span className={classes.buttonText}>Latest Reports</span>
            </Button>
            <Button
              className={
                reportType === 2 ? classes.activeButton : classes.button
              }
              onClick={() => handleReportType(2)}
            >
              <span className={classes.buttonText}>Position / Alerts</span>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <div>{Language.translate("Quick Select")}</div>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              className={
                quickSelect === "12hour" ? classes.activeButton : classes.button
              }
              onClick={() => handleQuickSelect(12, "hour")}
            >
              <span className={classes.buttonText}>12&nbsp;hrs</span>
            </Button>
            <Button
              className={
                quickSelect === "24hour" ? classes.activeButton : classes.button
              }
              onClick={() => handleQuickSelect(24, "hour")}
            >
              <span className={classes.buttonText}>24&nbsp;hrs</span>
            </Button>
            <Button
              className={
                quickSelect === "48hour" ? classes.activeButton : classes.button
              }
              onClick={() => handleQuickSelect(48, "hour")}
            >
              <span className={classes.buttonText}>48&nbsp;hrs</span>
            </Button>
            <Button
              className={
                quickSelect === "1week" ? classes.activeButton : classes.button
              }
              onClick={() => handleQuickSelect(1, "week")}
            >
              <span className={classes.buttonText}>1&nbsp;week</span>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <TextField
            id="datetime-local"
            variant="outlined"
            label={Language.translate("Start Date")}
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onBlur={(e) => handleStartDate(e)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="datetime-local"
            variant="outlined"
            label={Language.translate("End Date")}
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onBlur={(e) => handleEndDate(e)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {reportType === 2 && (
          <Grid item className={assetBoxChecked && classes.assetWrapper}>
            <Paper
              onClick={handleAssetBoxChange}
              className={classes.assetBoxText}
            >
              <GroupIcon />
              <span className={classes.assetText}>
                {Language.translate("Assets")}
              </span>
              {assetBoxChecked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Paper>
            <Collapse
              in={assetBoxChecked}
              classes={{
                entered: classes.assetBoxCollapse,
              }}
            >
                <CustomAssetWrapper
                  editMode={true}
                  name="devices"
                  isHistory={false}
                  openMain={true}
                  height="100%"
                />
            </Collapse>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            disabled={loadReportDisabled}
            color="primary"
            startIcon={<BarChartIcon />}
            onClick={handleSearch}
            className={classes.buttonMain}
          >
            {loadReportText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default LeftPanel;
