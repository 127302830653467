import { createTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const primaryColor = "#337ab7"; //2e6da4";
const secondaryColor = "#d52b1e";
const moduleHeaderFooterBackgroundColor = "#222b30";
const moduleHeaderFooterTextColor = "#5b7182";
const whiteColor = "#ffffff";
const grayColor = "#eeeeee";
const grayColorLight = "#fafafa";
const grayColorDark = "#dadada";
const grayColorVeryDark = "#777777";
const grayColorPure = "ccc";
const blackColor = "#000000";
const blackColorLight = "3d3d3d";
const redColor = "#d52b1e";
const redColorDark = "#a94442";
const greenColor = "#67d06c";
const greenColorLight = "#dff0d8";
const greenColorPure = "#008000";
const blueColor = "#337ab7";
const blueColorDark = "#293239";
const blueColorPure = "#0000ff";
const orangeColor = "#f9840f";
const purpleColor = "#c43cc4";
const yellowColor = "#ffc";
const yellowColorPure = "#fc0";
const cyanColor = "#b2ebf2";

const textBoxFocus = "#b2dbff";
const textBoxError = "#d52b1e";

const buttonCommand = "#337AB7";
const buttonAction = "#5cb85c";
const buttonCancel = "#fff";
const buttonBack = "#222b30";
const buttonDownload = "#eea236";
const buttonDelete = "#d9534f";
const buttonDefault = "#eea236";

const geofenceIconGreen = "#2ff336";
const geofenceIconDarkGreen = "#3c763d";
const geofenceIconRed = "#ee080d";
const geofenceIconDarkRed = "#a94442";

const emergencyAlertColor = "#d52b1e";
const speedAlertColor = "#c43cc4";
const geofenceAlertColor = "#f9840f";
const nonreportAlertColor = "#67d06c";
const cargoAlertColor = "#148c90";
const reportAlertColor = "#222b30";
const vehicleAlertColor = "#587fab";

const chipGreen = "#67d06c";
const chipPink = "#b63e5a";

const warningBG = "#f2dede";
const warningText = "#a94442";
const warningBorder = "#ebccd1";

const collapseHeader = "#5b7182";
const collapseBorder = "#eee";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    textbox: {
      focus: { main: textBoxFocus },
      error: { main: textBoxError },
    },
    buttons: {
      command: { main: buttonCommand },
      action: { main: buttonAction },
      cancel: { main: buttonCancel },
      back: { main: buttonBack },
      download: { main: buttonDownload },
      delete: { main: buttonDelete },
      default: { main: buttonDefault },
    },
    alerts: {
      emergency: { main: emergencyAlertColor },
      speed: { main: speedAlertColor },
      geofence: { main: geofenceAlertColor },
      nonreport: { main: nonreportAlertColor },
      cargo: { main: cargoAlertColor },
      report: { main: reportAlertColor },
      vehicle: { main: vehicleAlertColor },
    },
    geofence: {
      redIcon: { main: geofenceIconRed, dark: geofenceIconDarkRed },
      greenIcon: { main: geofenceIconGreen, dark: geofenceIconDarkGreen },
    },
    chips: {
      green: { main: chipGreen },
      pink: { main: chipPink },
    },
    collapse: {
      header: { main: collapseHeader },
      border: { main: collapseBorder },
    },
    background: {
      warning: { main: warningBG, dark: warningText, light: warningBorder },
    },
    colors: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
      headerFooterBackground: { main: moduleHeaderFooterBackgroundColor },
      headerFooterText: { main: moduleHeaderFooterTextColor },
      white: { main: whiteColor },
      gray: {
        main: grayColor,
        light: grayColorLight,
        dark: grayColorDark,
        veryDark: grayColorVeryDark,
        pure: grayColorPure,
      },
      black: { main: blackColor, light: blackColorLight },
      red: { main: redColor, dark: redColorDark },
      green: { main: greenColor, light: greenColorLight, pure: greenColorPure },
      blue: { main: blueColor, dark: blueColorDark, pure: blueColorPure },
      orange: { main: orangeColor },
      purple: { main: purpleColor },
      yellow: { main: yellowColor, pure: yellowColorPure },
      cyan: { main: cyanColor },
    },
  },
});

theme.palette.textbox.focus = theme.palette.augmentColor(
  theme.palette.textbox.focus,
  500,
  300,
  700
);
theme.palette.textbox.error = theme.palette.augmentColor(
  theme.palette.textbox.error,
  500,
  300,
  700
);
theme.palette.buttons.command = theme.palette.augmentColor(
  theme.palette.buttons.command,
  500,
  300,
  700
);
theme.palette.buttons.action = theme.palette.augmentColor(
  theme.palette.buttons.action,
  500,
  300,
  700
);
theme.palette.buttons.cancel = theme.palette.augmentColor(
  theme.palette.buttons.cancel,
  500,
  300,
  700
);
theme.palette.buttons.back = theme.palette.augmentColor(
  theme.palette.buttons.back,
  500,
  300,
  700
);
theme.palette.buttons.download = theme.palette.augmentColor(
  theme.palette.buttons.download,
  500,
  300,
  700
);
theme.palette.buttons.delete = theme.palette.augmentColor(
  theme.palette.buttons.delete,
  500,
  300,
  700
);
theme.palette.buttons.default = theme.palette.augmentColor(
  theme.palette.buttons.default,
  500,
  300,
  700
);
theme.palette.alerts.emergency = theme.palette.augmentColor(
  theme.palette.alerts.emergency,
  500,
  300,
  700
);
theme.palette.alerts.speed = theme.palette.augmentColor(
  theme.palette.alerts.speed,
  500,
  300,
  700
);
theme.palette.alerts.geofence = theme.palette.augmentColor(
  theme.palette.alerts.geofence,
  500,
  300,
  700
);
theme.palette.alerts.nonreport = theme.palette.augmentColor(
  theme.palette.alerts.nonreport,
  500,
  300,
  700
);
theme.palette.alerts.cargo = theme.palette.augmentColor(
  theme.palette.alerts.cargo,
  500,
  300,
  700
);
theme.palette.alerts.report = theme.palette.augmentColor(
  theme.palette.alerts.report,
  500,
  300,
  700
);
theme.palette.alerts.vehicle = theme.palette.augmentColor(
  theme.palette.alerts.vehicle,
  500,
  300,
  700
);
theme.palette.chips.green = theme.palette.augmentColor(
  theme.palette.chips.green,
  500,
  300,
  700
);
theme.palette.chips.pink = theme.palette.augmentColor(
  theme.palette.chips.pink,
  500,
  300,
  700
);
theme.palette.collapse.header = theme.palette.augmentColor(
  theme.palette.collapse.header,
  500,
  300,
  700
);
theme.palette.collapse.border = theme.palette.augmentColor(
  theme.palette.collapse.border,
  500,
  300,
  700
);
theme.palette.background.warning = theme.palette.augmentColor(
  theme.palette.background.warning,
  500,
  300,
  700
);
theme.palette.colors.primary = theme.palette.augmentColor(
  theme.palette.colors.primary,
  500,
  300,
  700
);
theme.palette.colors.secondary = theme.palette.augmentColor(
  theme.palette.colors.secondary,
  500,
  300,
  700
);
theme.palette.colors.white = theme.palette.augmentColor(
  theme.palette.colors.white,
  500,
  300,
  700
);
theme.palette.colors.gray = theme.palette.augmentColor(
  theme.palette.colors.gray,
  500,
  300,
  700
);
theme.palette.colors.black = theme.palette.augmentColor(
  theme.palette.colors.black,
  500,
  300,
  700
);
theme.palette.colors.red = theme.palette.augmentColor(
  theme.palette.colors.red,
  500,
  300,
  700
);
theme.palette.colors.green = theme.palette.augmentColor(
  theme.palette.colors.green,
  500,
  300,
  700
);
theme.palette.colors.blue = theme.palette.augmentColor(
  theme.palette.colors.blue,
  500,
  300,
  700
);
theme.palette.colors.orange = theme.palette.augmentColor(
  theme.palette.colors.orange,
  500,
  300,
  700
);
theme.palette.colors.purple = theme.palette.augmentColor(
  theme.palette.colors.purple,
  500,
  300,
  700
);
theme.palette.colors.yellow = theme.palette.augmentColor(
  theme.palette.colors.yellow,
  500,
  300,
  700
);
theme.palette.geofence.redIcon = theme.palette.augmentColor(
  theme.palette.geofence.redIcon,
  500,
  300,
  700
);
theme.palette.geofence.greenIcon = theme.palette.augmentColor(
  theme.palette.geofence.greenIcon,
  500,
  300,
  700
);

theme.props = {
  MuiInputLabel: {
    shrink: true,
  },
  MuiInput: {
    disableUnderline: true,
  },
  MuiButton: {
    disableElevation: true,
  },
  MuiTooltip: {
    arrow: true,
  },
};
theme.overrides = {
  MuiInputLabel: {
    root: {
      fontSize: "1.2rem",
    },
  },
  MuiInput: {
    root: {
      border: `1px solid ${grey[300]}`,
      outline: `1px solid transparent`,
      paddingLeft: theme.spacing(1),
      "&$focused": {
        border: `1px solid ${theme.palette.textbox.focus.main}`,
        outline: `1px solid ${theme.palette.textbox.focus.main}`,
        boxShadow: `0 0 5px ${theme.palette.textbox.focus.main} !important`,
      },
      "&$error": {
        border: `1px solid ${theme.palette.textbox.focus.error}`,
        outline: `1px solid ${theme.palette.textbox.focus.error}`,
        "&$focused": {
          boxShadow: `0 0 5px ${theme.palette.textbox.focus.error} !important`,
        },
      },
    },
    disabled: {},
  },
  MuiTextField: {
    root: {},
  },
  MuiListItem: {
    root: {},
  },
  MuiListItemIcon: {
    root: {
      minWidth: 40,
    },
  },
  MuiSvgIcon: {
    root: {
      fontSize: "1rem",
    },
    fontSizeSmall: {
      fontSize: ".9rem",
    },
    fontSizeLarge: {
      fontSize: "1.2rem",
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: theme.spacing(1),
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
      textTransform: "uppercase",
    },
    fullWidth: {
      maxWidth: "325px",
    },
    containedCommand: {
      "&:hover": {
        backgroundColor: "red",
      },
    },
    containedSecondary: {},
  },
  // MuiCheckbox: {
  //   disableRipple: true,
  // },
};

export const colorPicker = [
  "#CD5555",
  "#8E2323",
  "#CC3232",
  "#DB2929",
  "#EE6363",
  "#FF0000",
  "#FF6666",
  "#FFC1C1",
  "#C65D57",
  "#E3170D",
  "#CC1100",
  "#FA8072",
  "#FF2400",
  "#CD4F39",
  "#FF6347",
  "#8B3E2F",
  "#F87531",
  "#FBA16C",
  "#FF7722",
  "#EE7621",
  "#E3701A",
  "#EE8833",
  "#FF7F00",
  "#EEDC82",
  "#FCDC3B",
  "#FFCC11",
  "#F0A804",
  "#FFEC8B",
  "#E3CF57",
  "#FBDB0C",
  "#FFE600",
  "#FFD700",
  "#FBEC5D",
  "#C8F526",
  "#CDE472",
  "#B3C95A",
  "#B3EE3A",
  "#A2C257",
  "#385E0F",
  "#ADFF2F",
  "#586949",
  "#488214",
  "#CFDBAA",
  "#61B329",
  "#93DB70",
  "#AEEEEE",
  "#00CDCD",
  "#5F9EA0",
  "#4A777A",
  "#8EE5EE",
  "#33A1C9",
  "#22316C",
  "#003EFF",
  "#3232CD",
  "#4D4DFF",
  "#6A5ACD",
  "#3300FF",
  "#7B68EE",
  "#380474",
  "#6600FF",
];


export default theme;
