import React, { useState, useEffect } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core/styles";

//External
import _ from "lodash";
import { useFormikContext } from "formik";

//Internal
import Language from "sccLanguage";
import Message from "sccMessage";
import { moduleMessage } from "@Styles//ModuleMessage";
import CustomDropdown from "@Components/CustomDropdown";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";
import Recipients from "./Recipients";

function Compose(props) {
  const [uploadFile, setUploadFile] = useState(null);
  const [cannedMessages, setCannedMessages] = useState([
    { value: 0, label: Language.translate("No Canned Messages") },
  ]);

  const maxFileSize = 100000;

  const formik = useFormikContext();

  //to be used in add edit
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const handleFileRead = async (event) => {
    const file = event.target.files[0] && event.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      const data = base64.substring(base64.indexOf(",") + 1);
      // adding data to the file object
      file.data = data;
      file.id = 1;
      if (file.size > maxFileSize) {
        file.attachmentTooBig = true;
      }
      if (file.name.match(/^[\w,\s-]{1,45}\.[A-Za-z]{2,4}$/) == null) {
        file.invalidFileName = true;
      }
      setUploadFile(file);
      formik.setValues((prevValues) => ({
        ...prevValues,
        _attachmentTooBig: file.attachmentTooBig,
        _invalidFileName: file.invalidFileName,
        attachments: [
          {
            attachment_name: file.name,
            attachment_type: file.type,
            attachment_size: file.size,
            attachment_data: file.data,
          },
        ],
        _file: {
          name: file.name,
          type: file.size,
          size: file.size,
        },
      }));
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function removeAttachment() {
    document.getElementById("contained-button-file").value = "";
    setUploadFile(null);
    formik.setValues((prevValues) => ({
      ...prevValues,
      _attachmentTooBig: false,
      _invalidFileName: false,
      attachments: [],
      _file: null,
    }));
  }
  //update the local recipients by combining the local users values and the AS selection
  function updateAssetRecipients(obj) {
    formik.setFieldValue("recipients", {
      ...obj,
      users:
        moduleItemData.recipients && moduleItemData.recipients.users
          ? moduleItemData.recipients.users
          : [],
    });
  }
  function onUserChange(e) {
    const newUsers = _.xor(moduleItemData.recipients.users, [
      parseInt(e.target.value),
    ]);
    formik.setFieldValue("recipients.users", newUsers);
  }

  function handleRecipientDelete(key, value) {
    const arrNew = moduleItemData.recipients[key].filter(function (
      val,
      index,
      arr
    ) {
      return value !== val;
    });
    formik.setFieldValue(`recipients.${key}`, arrNew);
  }

  useEffect(() => {
    Message.loadCannedMessage().then(function (messages) {
      const cMessages = Object.values(messages.data.result).map(function (
        value
      ) {
        var result = {};
        result.label =
          value.canned_number +
          " - " +
          Language.translate(value.canned_message);
        result.value = value.id;
        return result;
      });
      cMessages.unshift({
        label: Language.translate("No Canned Messages"),
        value: 0,
        selected: true,
      });
      setCannedMessages(cMessages);
    });
  }, []);

  const useStyles = makeStyles((theme) => ({
    ...moduleMessage(theme),
  }));
  const classes = useStyles();

  return (
    <Grid container className={classes.compose}>
      <Grid item xs={12} className="heading">
        {Language.translate("Compose Message")}
      </Grid>
      <Grid item xs={12}>
        {
          <Recipients
            onUserChange={onUserChange}
            updateAssetRecipients={updateAssetRecipients}
            handleRecipientDelete={handleRecipientDelete}
            recipients={moduleItemData.recipients}
          />
        }
      </Grid>
      <Grid item xs={12}>
        <CustomDropdown
          label={Language.translate("Canned Message")}
          name="cannedmessage_id"
          value={moduleItemData.cannedmessage_id || 0}
          onChange={handleFormInput}
          onBlur={handleFormBlur}
          error={
            formik.touched.cannedmessage_id &&
            Boolean(formik.errors.cannedmessage_id)
          }
          helperText={
            formik.touched.cannedmessage_id && formik.errors.cannedmessage_id
          }
        >
          {cannedMessages.map((option) => (
            <MenuItem
              selected={option.selected}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </CustomDropdown>
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label={Language.translate("Message")}
          value={moduleItemData.message}
          name="message"
          autoComplete="off"
          onChange={handleFormInput}
          onBlur={handleFormBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
      </Grid>
      {uploadFile && uploadFile.attachmentTooBig && (
        <Grid container item xs={12} className="warningText">
          <strong>
            {`${
              Math.round((uploadFile.size / 1000) * 10) / 10
            } KB ${Language.translate("File too large")} - ${Language.translate(
              "MAX"
            )} 100 KB`}
          </strong>
        </Grid>
      )}
      {uploadFile && uploadFile.invalidFileName && (
        <Grid container item xs={12} className="warningText">
          <strong>
            {Language.translate(
              "INVALID FILE NAME. File names must be between 1 and 45 characters long and include only Alphanumeric underscore or comma characters and a file extension"
            )}
          </strong>
        </Grid>
      )}
      {uploadFile &&
        !uploadFile.invalidFileName &&
        !uploadFile.attachmentTooBig &&
        moduleItemData.recipients.devices.length > 0 && (
          <Grid container item xs={12} className="warningText">
            <strong>
              {Language.translate(
                "Warning: Attachments will only be sent to devices which are compatible to receive them"
              )}
            </strong>
          </Grid>
        )}
      <Grid container item xs={12} className="attachments">
        <Grid item xs={2} className="messageAttachmentButton">
          <label htmlFor="contained-button-file">
            <CustomButton
              color="command"
              variant="contained"
              startIcon={<AttachFileIcon />}
              component="span"
            >
              {Language.translate("Attachment").toUpperCase()}
            </CustomButton>
          </label>
          <input
            accept="*/*"
            id="contained-button-file"
            type="file"
            onChange={(e) => handleFileRead(e)}
            className="fileUploadInput"
          />
        </Grid>
        <Grid item xs={4}>
          {!uploadFile ? (
            <div className="maxSizeInfoBox">
              <p className="maxSizeInfo">{`${Language.translate(
                "MAX"
              )} 100 KB`}</p>
            </div>
          ) : (
            <div className="fileNameBox">
              <p className="fileName">{uploadFile && uploadFile["name"]}</p>
            </div>
          )}
        </Grid>
        <Grid item xs={4} className="fileSize">
          {uploadFile && `${Math.round((uploadFile.size / 1000) * 10) / 10} KB`}
        </Grid>
        <Grid item xs={2}>
          {uploadFile && uploadFile.size > 0 && (
            <CustomButton
              startIcon={<DeleteIcon />}
              color="cancel"
              onClick={removeAttachment}
            >
              {Language.translate("REMOVE")}
            </CustomButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Compose;
