import React from "react";

// External
import _ from "lodash";
import { toLower } from "lodash";

// Material-UI
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import Alert from "sccAlert";

const useStyles = makeStyles((theme) => ({
  DeviceAlertBarAbsolute: {
    position: "absolute",
    top: -30,
    right: 0,
  },
  DeviceAlertBar: {
    boxShadow: "none !important",
    color: `${theme.palette.colors.blue.dark} !important`,
    float: "right",
    width: "fit-content",
    display: "table-row",
    "& ul": {
      minHeight: "initial",
      margin: 5,
      alignItems: "flex-end",
      display: "block",
      "& span": {
        display: "table-cell",
      },
    },

    "& .Emergency": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
        width: "fit-content",
      },
    },
    "& .Geofence": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.colors.orange.main,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        width: "fit-content",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
      },
    },
    "& .Report": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.colors.black.light,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
        width: "fit-content",
      },
    },
    "& .Speed": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.colors.purple.main,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
        width: "fit-content",
      },
    },
    "& .Non-Report": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.colors.green.main,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
        width: "fit-content",
      },
    },
    "& .Cargo": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.alerts.cargo.main,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
        width: "fit-content",
      },
    },
    "& .Vehicle": {
      paddingLeft: 5,
      position: "relative",
      "& .MuiSvgIcon-fontSizeSmall": {
        backgroundColor: theme.palette.alerts.vehicle.main,
        fontSize: 12,
      },
      "& span": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.buttons.delete.main,
        fontSize: "8px !important",
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        justifySelf: "center",
        alignItems: "center",
        height: 10,
        minWidth: 10,
        width: "fit-content",
      },
    },
  },
}));

export default function DeviceAlertBar({
  device,
  // if isDataDisplay is true, we apply absolute style to DeviceAlertBar, check Single Device dataDisplay view
  isDeviceDataDisplay = false,
}) {
  const alertTypes = Alert.getAlertType();
  const deviceAlertsList = !_.isEmpty(alertTypes)
    ? _.map(alertTypes, (data) => {
        if (data.type === "Message") {
          return null;
        } else {
          return Alert.getDeviceAlert(device.id, data.type);
        }
      })
    : [];

  const classes = useStyles();

  return (
    <div
      className={`DeviceAlertBar ${
        isDeviceDataDisplay && "DeviceAlertBarAbsolute"
      }`}
    >
      <ul className="listing">
        {deviceAlertsList?.length > 0
          ? _.map(deviceAlertsList, (deviceAlerts, index) => {
              if (_.isNull(deviceAlerts) || deviceAlerts.length <= 0) {
                return "";
              } else {
                const dataType = deviceAlerts[0]?.type || "";

                return (
                  <React.Fragment key={index}>
                    <CustomTooltip title={dataType} placement="top">
                      <Badge
                        badgeContent={deviceAlerts.length}
                        className={dataType}
                      >
                        <CustomIcon
                          type={toLower(dataType)}
                          id={`icon-${toLower(dataType)}`}
                          fontSize="small"
                        />
                      </Badge>
                    </CustomTooltip>
                  </React.Fragment>
                );
              }
            })
          : ""}
      </ul>
    </div>
  );
}
