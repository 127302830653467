import React, { useEffect } from "react";
import CustomTextField from "@Components/CustomTextField";

function CustomSearch(props) {
  const {
    name,
    label,
    value,
    filteredList,
    searchField,
    onChange,
    updateFilteredData,
    indexedObject,
    ...other
  } = props;
  let filters = props.filters;
  let originalData = props.data || {};

  // create object with id as object item key
  const arrayToObject = (arr, key) =>
    arr && Object.assign({}, ...arr.map((item) => ({ [item[key]]: item })));

  useEffect(() => {
    originalData = filters
      ? filteredList(Object.values(originalData), filters)
      : originalData;
    const filteredDataArr =
      searchField && originalData
        ? originalData.filter((item) => {
            let result = false;
            for (var i = 0; i < searchField.length; i++) {
              if (
                item[searchField[i]] &&
                item[searchField[i]].toLowerCase().includes(value.toLowerCase())
              ) {
                result = true;
                break;
              }
            }
            return result;
          })
        : originalData;

    const filteredData = indexedObject
      ? filteredDataArr && { ...filteredDataArr }
      : filteredDataArr && arrayToObject(filteredDataArr, "id");

    updateFilteredData(filteredData, value === "");
  }, [value]);

  useEffect(() => {
    originalData = filters
      ? filteredList(Object.values(originalData), filters)
      : originalData;
  }, [filters]);

  return (
    <CustomTextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      {...other}
    />
  );
}

export default CustomSearch;
