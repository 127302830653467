import React from "react";

// Material-UI
import { makeStyles } from "@material-ui/core";

// Internal
import Zones from "./Zones";
import Logo from "./Logo";
import Clientlogo from "./ClientLogo";

const useStyles = makeStyles((theme) => ({
  LogoZonesRoot: {
    position: "fixed",
    minWidth: "650px",
    maxWidth: "750px",
    zIndex: "100",
    left: "5px",
    top: "5px",

    "& .navbar-header": {
      "& .client_logo": {
        maxWidth: "120px",
        height: "50px",
        float: "left",
      },

      "& .custom_logo": {
        maxWidth: "120px",
        height: "50px",
        float: "left",
        background: "#293239 !important",
      },

      "& .navbar-brand.scc-logo": {
        width: "133px",
        height: "50px",
        float: "left",
        padding: "0",
        backgroundColor: "#293239",
      },

      "& .logo": {
        maxHeight: "100%",
        maxWidth: "100%",
        padding: "5px",
        margin: "0",
        position: "relative",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },

      "& .customLogo": {
        maxHeight: "40px",
        maxWidth: "110px",
        padding: "5px",
        margin: "0",
        position: "relative",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },

      "& .navbar-brand": {
        marginRight: "5px",
      },
    },
  },
}));

export default function MapTopLeftLogoZones() {
  const classes = useStyles();

  return (
    <div className={classes.LogoZonesRoot}>
      <Logo />
      <Clientlogo />
      <Zones />
    </div>
  );
}
