
var Group= require("sccGroup").default;
var User= require("sccUser").default;
var Sa= require("sccSa").default;
var AddressBook = require("sccAddressBook").default;
var _= require("lodash");
/**
 * MasterData class
 * 
 * @class
 */
function MasterData(){}


/**
 * gets the XML representation of devices
 * 
 * @param {Array} devices list of devices to be added 
 * @return {String} XML string for list of devices
 */
MasterData.getDevices= function(devices){
	var dataStr="";
	
	_.each(devices, function(device){			
		dataStr+=	"<asset transmissionID='"+device.comm_id+"' id='"+device.comm_id+"'>"+
						"<imagePath>arrow_blue.ico</imagePath>"+
						"<alarmImagePath>arrow_red.ico</alarmImagePath>"+
						MasterData.getGroupsOfDevice(device)+
						"<name>"+device.name+"</name>"+
					"</asset>";
	});
	
	return dataStr;
};



/**
 * gets the XML representation of groups
 * 
 * @param {Array} groups list of groups to be added 
 * @return {String} XML string for list of groups
 */
MasterData.getGroups= function(groups){
	var dataStr="";	
		
	_.each(groups, function(group){
		dataStr+=	"<assetGroup transmissionID='"+group.comm_id+"' id='"+group.comm_id+"'>"+
						"<name>"+group.title+"</name>"+
					"</assetGroup>";
	});
	
	return dataStr;	
};


/**
 * gets the XML representation for the list of groups that a device belongs to
 *  
 * @param {Object} device device object to be evaluated 
 * @return {String} XML string for list of groups of the input device
 */
MasterData.getGroupsOfDevice= function(device){
	var groupsStr= "";

	// checking groups to see if device belongs to it
	_.each(Group.get(), function(group){
		if(_.indexOf(group.devices, device.id) > -1){
			groupsStr+="<assetGroup>"+group.comm_id+"</assetGroup>";
		}
	});
	
	return groupsStr;
};


/**
 * gets the XML representation of users
 * 
 * @param {Array} users list of users to be added 
 * @return {String} XML string for list of users
 */
MasterData.getUsers= function(users){
	var dataStr= "";
	
	_.each(users, function(user){
		// users that are not permitted would be skipped without error
		if (!user) return;

		/*
			IMPORTANT:
			Special case. This was added for release of platform version 2.7.0
			This should be revised and removed when MH is able to treat customer admin users and client separately
			This shall be done in the same sprint as when when task '2959' and '2958' is resolved in backlog sprint
		*/
		if(user.client_comm_id){
			dataStr+=	"<user transmissionID='"+user.client_comm_id+"' id='"+user.client_comm_id+"'>"+
			"<name>"+user.first_name+ " " +user.last_name+"</name>"+
			"</user>";
		}
		else if(user.comm_id){
			dataStr+=	"<user transmissionID='"+user.comm_id+"' id='"+user.comm_id+"'>"+
			"<name>"+user.first_name+ " " +user.last_name+"</name>"+
			"</user>";
		}
	});

	return dataStr;
};


/**
 * generates the Main Master Data file for the SCCT
 * 
 * @param {Number} groupId Id of the group for which Master Data file should be genereated. Means all if null.
 * @return {String} XML string of Master Data file  
 */
MasterData.generateMain= function(groupId){
	var xmlStr="<?xml version='1.0' encoding='UTF-8' ?>";	 
	xmlStr+= MasterData.getDataMain(groupId);
	return xmlStr;
};


/**
 * gets master data for assets, groups, and users
 * 
 * @param {Number} groupId Id of the group for which Master Data file should be genereated. Means all if null.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getDataMain= function(groupId){
	var dataStr="";
	dataStr+= MasterData.getDevicesMain(groupId);
	dataStr+= MasterData.getGroupsMain(groupId);
	dataStr+= MasterData.getUsersMain(groupId);	
	return "<masterData>"+ dataStr+ "</masterData>";
};




/**
 * gets the list of devices
 * 
 * @param {Number} groupId Id of the group for which Master Data file should be genereated. Means all if null.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getDevicesMain= function(groupId){
	var Device= require("sccDevice").default;
	var devices;
	if(groupId){
		// getting devices of a group
		devices= _.map(Group.get(groupId).devices, function(deviceId){
			return Device.get(deviceId);
		});
	}else{
		// getting all devices
		devices= Device.get();
	}
	return MasterData.getDevices(devices);
};



/**
 * gets the group information to the master data xml file
 * 
 * @param {Number} groupId Id of the group for which Master Data file should be genereated. Means all if null.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getGroupsMain= function(groupId){

	var groups;
		
	if(groupId){
		groups= Group.getSubGroups(Group.get(groupId));
	}else{
		groups=  Group.get();
	}
		
	return MasterData.getGroups(groups);	
};


/**
 * gets the user information to the master data xml file
 * 
 * @param {Number} groupId Id of the group for which Master Data file should be genereated. Means all if null.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getUsersMain= function(groupId){
	var users;
	
	if(groupId){
		var userIds= Group.getPermittedUsers(Group.get(groupId));
		users= _.map(userIds, function(userId){
			return User.get(userId);
		});
	}else{
		users= User.get();
	}

	return MasterData.getUsers(users);
};



/**
 * generates the master data file for situational awareness subscriptions
 * 
 * @param {Number} saId Id of the situational awareness.
 * @return {String} XML string of Master Data file 
 */
MasterData.generateSA= function(saId){	
	var xmlStr="<?xml version='1.0' encoding='UTF-8' ?>";
	xmlStr+= MasterData.getDataSA(saId);
	return xmlStr; 	
};


/**
 * generates the master data file for Devices, Groups, and Users of a situational awareness
 * 
 * @param {Number} saId Id of the situational awareness.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getDataSA= function(saId){
	var dataStr="";
	dataStr+= MasterData.getDevicesSA(saId);
	dataStr+= MasterData.getGroupsSA(saId);
	dataStr+= MasterData.getUsers(User.get());
	return "<masterData>"+ dataStr+ "</masterData>";	
};


/**
 * appends the asset information to the master data xml file for situational awareness subscriptions
 * 
 * @param {Number} saId Id of the situational awareness.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getDevicesSA= function(saId){
	var Device= require("sccDevice").default;
	var devices= _.map(Sa.get(saId).members.devices, function(deviceId){
		return Device.get(deviceId);
	});

	return MasterData.getDevices(devices);	
};


/**
 * appends the group information to the master data xml file for situational awareness subscriptions
 * 
 * @param {Number} saId Id of the situational awareness.
 * @return {String} partial XML string of Master Data file
 */
MasterData.getGroupsSA= function(saId){
	var groups= _.map(Sa.get(saId).members.groups, function(groupId){		
		return Group.get(groupId);		
	});
	
	return MasterData.getGroups(groups);	
};



/**
 * generates the Main Master Data file Address Book
 * 
 * @param {Number}abId Id of the group for which Master Data file should be genereated. Means all if null.
 * @return {String} XML string of Master Data file  
 */
MasterData.generateAddressBook= function(abId){	
	var xmlStr="<?xml version='1.0' encoding='UTF-8' ?>";
	xmlStr+= MasterData.getDataAB(abId);
	return xmlStr; 	
};

/**
 * generates the master data file for Devices, Groups, and Users of a Address Book
 * 
 * @param {Number} abId Id of an Address Book.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getDataAB= function(abId){
	var dataStr="";
	dataStr+= MasterData.getDevicesAB(abId);
	//dataStr+= MasterData.getGroupsAB(abId);
	dataStr+= MasterData.getUsersAB(abId);
	return "<masterData>"+ dataStr+ "</masterData>";	
};
/**
 * appends the asset information to the master data xml file for Address Book subscriptions
 * 
 * @param {Number} abId Id of the Address Book.
 * @return {String} partial XML string of Master Data file 
 */
MasterData.getDevicesAB= function(abId){
	var Device= require("sccDevice").default;
	var arr = AddressBook.get(abId).members.devices;
	// Return all devices in groups
	var goupDevices= _.map(AddressBook.get(abId).members.groups, function(groupId){
		return Group.get(groupId).devices;
	});
	// Remove all duplicate devices and flatten multiple arrays to just one array
	var total = _.flatten(_.union(arr, goupDevices));
	// Loop through devices selected and return device info of selected devices
	var devices = _.map(total, function(deviceId){
		return Device.get(deviceId);
	});
	return MasterData.getDevices(devices);	
};

/**
 * appends the group information to the master data xml file for Address Book members
 * 
 * @param {Number} abId Id of the Address Book.
 * @return {String} partial XML string of Master Data file
 */
MasterData.getGroupsAB= function(abId){
	var groupsArray = AddressBook.get(abId).members.groups;
	var groups= _.map(groupsArray, function(groupId){		
		return Group.get(groupId);		
	});
	return MasterData.getGroups(groups);	
};

/**
 * appends the users information to the master data xml file for Address Book members
 * 
 * @param {Number} abId Id of the Address Book.
 * @return {String} partial XML string of Master Data file
 */
MasterData.getUsersAB= function(abId){
	var users= _.map(AddressBook.get(abId).members.users, function(userID){		
		return User.get(userID);		
	});
	
	return MasterData.getUsers(users);	
};

module.exports= MasterData;