import React, { useContext, useEffect } from "react";

//Material UI
import { makeStyles } from "@material-ui/core/styles";

// Components
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import AssetsForm from "./AssetsForm";
import AssetsList from "./AssetsList";
import Group from "../../group/scripts/Group";
import GroupForm from "./GroupForm";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;

export default function ModuleWrapperAssetsBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleData: Group.getGroupTree().groups,
      filteredModuleData: Group.getGroupTree().groups,
    }));
  }, []);

  const classes = useStyles();

  return mwState.wrapperDisplayMode === MW_ASSETS_MODULE_MODE ? (
    <ul className={classes.listContainer}>
      <AssetsList />
    </ul>
  ) : mwState.wrapperDisplaySubMode === 1 ? (
    <GroupForm />
  ) : (
    <AssetsForm />
  );
}
