import React from "react";

// External
import _ from "lodash";

// Material-UI
import { Collapse, Grid } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import LocationButton from "../../../reusable-components/CustomAssetWrapper/AssetDevice/LocationButton";
import Language from "sccLanguage";
import HermesGatewaysDetail from "./HermesGatewaysDetail";
import Clock from "sccClock";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),

  nameOverflow: {
    "& span": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "100%",
      display: "inline-block",
    },
  },
}));

export default function HermesGatewaysList({
  data,
  handleClick,
  openDetailsFor,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <Grid container alignItems="center">
          <Grid item xs={4} className={classes.nameOverflow}>
            <CustomTooltip title={data.name} arrow placement="top">
              {data.name}
            </CustomTooltip>
          </Grid>
          <Grid item xs={4}>
            {Language.translate("Last Reported") +
              ": " +
              (data.report_timestamp && data.report_timestamp != undefined
                ? Clock.formatTimestamp(data.report_timestamp)
                : "N/A")}
          </Grid>
          <Grid item container justifyContent="flex-end" xs={4}>
            <LocationButton deviceId={data.id} />
          </Grid>
        </Grid>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <HermesGatewaysDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
