import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { toLower } from "lodash";
import clsx from "clsx";

// Material-UI
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import CheckIcon from "@material-ui/icons/Check";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import OpacityIcon from "@material-ui/icons/Opacity";

// Internal
import Language from "sccLanguage";
import Device from "sccDevice";
import Alert from "sccAlert";
import TimeUtils from "sccTimeUtils";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import CustomButton from "@Components/CustomButton";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import AlertDetail from "./AlertDetail";
import { moduleListing } from "@Styles/ModuleListing";
import LocationButton from "../../../reusable-components/CustomAssetWrapper/AssetDevice/LocationButton";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  listContainer: {
    ...moduleListing(theme).listContainer,
    "& .Emergency": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.emergency.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.emergency.main,
      },
    },
    "& .Geofence": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.geofence.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.geofence.main,
      },
    },
    "& .Report": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.report.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.report.main,
      },
    },
    "& .Speed": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.speed.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.speed.main,
      },
      "& .geofence": {
        color: theme.palette.alerts.geofence.main,
        fontSize: ".6rem",
      },
    },
    "& .Non-Report": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.nonreport.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.nonreport.main,
      },
    },
    "& .Cargo": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.cargo.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.cargo.main,
      },
    },
    "& .Vehicle": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.vehicle.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.vehicle.main,
      },
    },
  },

  cargoIcon: {
    color: theme.palette.alerts.cargo.main,
    height: "2em",
    width: "2em",
  },

  geofenceIcon: {
    display: "flex",

    "& span": {
      display: "flex",
      "& span": {
        alignItems: "center",
      },
    },
  },
}));

function ModuleWrapperAlertsBody(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const [dummyTimer, setDummyTimer] = useState([]);

  const classes = useStyles();

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  //updating the data with the device_title to be displayed in listing and used in search
  let filteredModuleData = mwState.filteredModuleData;

  if (mwState.selectedFilter) {
    if (mwState.selectedFilter["alert_type_id"]?.length > 0) {
      filteredModuleData = _.filter(filteredModuleData, (data) =>
        mwState.selectedFilter["alert_type_id"].includes(data.alert_type_id)
      );
    }
  }

  const moduleData = mwState.moduleData;
  _.each(Object.values(moduleData), (alert) => {
    alert.device_title = Device.get(alert.device_id).name;
  });
  _.each(Object.values(filteredModuleData), (alert) => {
    alert.device_title = Device.get(alert.device_id).name;
  });
  //to be used in the listing page
  filteredModuleData = mwState.convertAndSort(
    filteredModuleData,
    mwState.searchField
  );

  // sort Alerts by time from earliest to lasted
  const sortedFilteredModuleData = _.isEmpty(filteredModuleData)
    ? []
    : filteredModuleData.sort(
        (a, b) => b?.info?.start_timestamp - a?.info?.start_timestamp
      );

  // get Cargo Alert Icon
  const getCargoAlertIcon = (alert) => {
    const cargoType = Alert.getCargoAlertType(
      alert.info.cargo_alert_type_id
    ).type;

    switch (cargoType) {
      case "Door":
        return <MeetingRoomIcon className={classes.cargoIcon} />;
      case "Temperature":
        return <WhatshotIcon className={classes.cargoIcon} />;
      case "Humidity":
        return <OpacityIcon className={classes.cargoIcon} />;
      default:
      case "Battery":
        return <BatteryCharging60Icon className={classes.cargoIcon} />;
    }
  };

  useEffect(() => {
    //  rerenders alert age every one second
    const interval = setInterval(() => setDummyTimer([]), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ul className={classes.listContainer}>
      {!_.isEmpty(sortedFilteredModuleData) ? (
        sortedFilteredModuleData
          .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
          .map((data, index) => {
            const deviceInfo = Device.get(data.device_id);

            return (
              <React.Fragment key={data.id}>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    Alert.getAlertType(data.alert_type_id).type
                  )}
                  key={data.id}
                  onClick={() => handleClick(data.id)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CustomTooltip
                      title={Alert.getAlertType(data.alert_type_id).type}
                      placement="left"
                    >
                      <span>
                        {data.type === "Cargo" ? (
                          getCargoAlertIcon(data)
                        ) : (
                          <CustomIcon
                            type={toLower(data.type)}
                            id={`icon-${toLower(data.type)}`}
                            fontSize="small"
                          />
                        )}
                      </span>
                    </CustomTooltip>
                  </ListItemIcon>
                  <ListItemText
                    id={data.id}
                    className={classes.itemPrimaryText}
                    primary={
                      <Grid container>
                        <Grid
                          item
                          container
                          xs={8}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={9}>
                            {data.device_title}
                          </Grid>
                          {data.info.geofence_id && data.type === "Speed" ? (
                            <Grid item xs={1} className={classes.geofenceIcon}>
                              <CustomTooltip
                                title={Language.translate("Geofence")}
                                placement="top"
                              >
                                <span>
                                  <CustomIcon
                                    type="geofence"
                                    id={`icon-geofence`}
                                    fontSize="small"
                                  />
                                </span>
                              </CustomTooltip>
                            </Grid>
                          ) : (
                            <Grid item xs={1}>
                              &nbsp;
                            </Grid>
                          )}
                          <Grid item xs={2}>
                            <LocationButton deviceId={data.device_id} />
                          </Grid>
                        </Grid>
                        <Grid item container xs={4} alignItems="center">
                          <CustomTooltip
                            title={Language.translate("Age")}
                            placement="left"
                          >
                            <span>
                              {TimeUtils.getAge(data.info.start_timestamp)}
                            </span>
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                    }
                  />
                  <ListItemSecondaryAction
                    className={classes.itemActionButtonsContainer}
                  >
                    {_.isEmpty(data.acknowledgements) ? (
                      <CustomTooltip
                        title={Language.translate("Acknowledge")}
                        placement="left"
                      >
                        <span>
                          <CustomButton
                            disabled={data.acknowledgeDisabled}
                            onClick={_.debounce((e) => {
                              e.preventDefault();
                              Alert.acknowledge([data.id]);
                            }, 1000)}
                            size="medium"
                            color="command"
                            variant="contained"
                            startIcon={<CheckIcon />}
                          >
                            {Language.translate("Acknowledge")}
                          </CustomButton>
                        </span>
                      </CustomTooltip>
                    ) : (
                      `${
                        Object.keys(data.acknowledgements).length
                      } ${Language.translate("Users")}`
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse
                  in={openDetailsFor === data.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <AlertDetail
                    data={data}
                    age={TimeUtils.getAge(data.info.start_timestamp)}
                    deviceInfo={deviceInfo}
                  />
                </Collapse>
              </React.Fragment>
            );
          })
      ) : (
        <div className="noResults">{Language.translate("No Results!")}</div>
      )}
    </ul>
  );
}

export default ModuleWrapperAlertsBody;
