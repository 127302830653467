import { default as Module } from "sccModule";
var Utils = require("sccUtils");
var Images = require("sccImage").default;

/**
 * The base object for Options module
 *
 */
class OptionsModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "options",
    };

    super(options);
  }

  init() {
    this.addCustomSocket("put:/options/image/platform", function (data) {
      if (!data.id) throw new Error("Socket received data must have 'id'.");

      Images.set(data, data.id);

      //REFRESH REACT CLIENT LOGO COMPONENT
      const {
        default: Clientlogo,
      } = require("../../clock/components/MapTopLeftLogoZones/ClientLogo");
      Clientlogo.refreshLogoImages();
    });

    this.addCustomSocket("delete:/options/image/platform", function (data) {
      if (!data.id) throw new Error("Socket received data must have 'id'.");

      Images.set(null, data.id);

      //REFRESH REACT CLIENT LOGO COMPONENT
      const {
        default: Clientlogo,
      } = require("../../clock/components/MapTopLeftLogoZones/ClientLogo");
      Clientlogo.refreshLogoImages();

      const hiddenBtn = document.getElementById("hiddenLogoBtn");
      if (hiddenBtn) {
        hiddenBtn.click();
      }
    });

    return Promise.resolve();
  }
}

OptionsModule.prototype.deletePlatformImage = function () {
  var options = {
    url: this.routeUrl + "/image/platform",
    method: "DELETE",
    data: {},
  };
  return Utils.httpRequestHandler(options);
};

//module.exports= New OptionsModule();
export default new OptionsModule();
