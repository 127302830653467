export const moduleListing = (theme) => ({
  listContainer: {
    margin: "0 0 2px 0",
    padding: 0,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .listing": {
      padding: 3,
      margin: 5,
    },
    "& .MuiListItem-secondaryAction": {
      paddingRight: 100,
    },
    "& .noResults": {
      textAlign: "center",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 40,
    },
  },
  listItem: {
    height: 40,
    backgroundColor: theme.palette.colors.gray.main,
    borderTop: `2px solid ${theme.palette.colors.gray.light}`,
    borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
    borderLeft: `5px solid ${theme.palette.colors.gray.main}`,
    "& .MuiListItemIcon-root": {
      minWidth: 30,
      "& .MuiSvgIcon-root": {
        fontSize: ".65rem",
      },
    },
    "& .MuiListItemText-root": {
      "& .MuiListItemText-primary": {
        fontSize: ".9rem",
      },
      "& .MuiChip-root": {
        fontSize: ".9rem",
      },
      "& .MuiChip-colorPrimary": {
        backgroundColor: theme.palette.colors.primary.main,
      },
    },
  },
  enabled: {
    background: theme.palette.colors.green.light,
    marginLeft: 1,
    borderLeft: `5px solid ${theme.palette.geofence.greenIcon.dark} !important`,
    "& .CustomIcon": {
      color: theme.palette.colors.blue.dark,
    },
  },
  itemActionButtonsContainer: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
  },
  pending: {
    backgroundColor: theme.palette.colors.orange.light,
    color: theme.palette.colors.white.main,
    fontSize: ".9rem",
  },
  noResults: {
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
  },
});
