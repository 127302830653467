import React, { useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles, Grid, MenuItem } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import Switch from "@material-ui/core/Switch";

// Internal
import CustomButton from "@Components/CustomButton";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomIcon from "@Components/CustomIcon";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  assetsFormSettingsOrion: {
    margin: "40px 0 0 0",
    padding: 10,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .settingsBtnSendSettingsCode": {
      float: "right",
      margin: "20px 0",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 6,
      paddingRight: 6,
    },
    "& .MuiListItem-root": {
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .itemTextFirst": {
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      textTransform: "uppercase",
      fontWeight: 700,
    },
    "& .itemTextSecond": {
      width: "50%",
      marginTop: -1,
      marginLeft: -1,
      "& .MuiSwitch-root": {
        margin: 0,
      },
      "& .unlockIconBox": {
        width: 20,
        float: "left",
        marginTop: 12,
      },
      "& .switchBox": {
        width: 60,
        float: "left",
        marginTop: 5,
      },

      "& .lockIconBox": {
        "& .hide": {
          display: "none",
        },
        width: 20,
        float: "left",
        marginTop: 12,
      },
    },
    "& .itemTextThird": {
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      marginLeft: -1,
      "& .dropdown": {
        marginTop: "0px !important",
        marginBottom: "0px !important",
      },
    },
    "& .MuiTypography-root": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& .parentOpen": {
      borderBottom: 0,
    },
    "& .parentClose": {
      borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
    },
  },
}));

export default function AssetsFormOrionSettings(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const pollCommands = mwState.pollCommands;
  const minimize = mwState.minimizePollSettingsForOrion;
  const maximize = mwState.maximizePollSettingsForOrion;

  const measurementsUA = ["Metric", "Imperial"];

  const distanceEnabledUA = [
    { item: "Enabled", value: "Enabled" },
    { item: "Disabled", value: "Disabled" },
  ];

  const distanceIntervalUAinMeters = [
    "0 meters",
    "10 meters",
    "20 meters",
    "50 meters",
    "100 meters",
    "500 meters",
    "1000 meters",
  ];
  const distanceIntervalUAinYards = [
    "0 yards",
    "10 yards",
    "20 yards",
    "50 yards",
    "100 yards",
    "500 yards",
    "1000 yards",
  ];

  const reportIntervalUA2 = [
    { id: 900000, value: "No Report" },
    { id: 10, value: "10 sec" },
    { id: 15, value: "15 sec" },
    { id: 30, value: "30 sec" },
    { id: 60, value: "1 min" },
    { id: 120, value: "2 min" },
    { id: 300, value: "5 min" },
    { id: 600, value: "10 min" },
    { id: 900, value: "15 min" },
    { id: 1800, value: "30 min" },
    { id: 3600, value: "1 hour" },
    { id: 7200, value: "2 hours" },
    { id: 21600, value: "6 hours" },
    { id: 43200, value: "12 hours" },
    { id: 86400, value: "24 hours" },
  ];

  const alertIntervalUA2 = [
    { id: 900000, value: "No Report" },
    { id: 5, value: "1 sec" },
    { id: 5, value: "5 sec" },
    { id: 10, value: "10 sec" },
    { id: 15, value: "15 sec" },
    { id: 30, value: "30 sec" },
    { id: 60, value: "1 min" },
    { id: 120, value: "2 min" },
    { id: 300, value: "5 min" },
    { id: 600, value: "10 min" },
    { id: 900, value: "15 min" },
    { id: 1800, value: "30 min" },
    { id: 3600, value: "1 hour" },
    { id: 7200, value: "2 hours" },
    { id: 21600, value: "6 hours" },
    { id: 43200, value: "12 hours" },
    { id: 86400, value: "24 hours" },
  ];

  useEffect(() => {
    formik.setFieldValue("settings", minimize(moduleItemData.settingsVal));
    formik.setFieldValue(
      "sendSettings",
      JSON.stringify(moduleItemData.settingsVal)
    );
  }, [moduleItemData.settingsVal]);

  useEffect(() => {
    formik.setFieldValue(
      "settingsVal",
      maximize(JSON.parse(moduleItemData.settings))
    );

    if (
      moduleItemData.settingsVal.report_i === "1 sec" ||
      moduleItemData.settingsVal.report_i === "5 sec"
    ) {
      // if user had selected either 1 sec or 5sec option before the implementation of
      // the "1sec and 5 sec for Orion devices removal" commit in an update, the setting will change to 10 sec.
      formik.setFieldValue("settingsVal.report_i", "10 sec");
    }
  }, []);

  function changeDistanceInterval(e) {
    if (e.target.value === "Metric")
      formik.setFieldValue("settingsVal.distance_i", "0 meters");
    else formik.setFieldValue("settingsVal.distance_i", "0 yards");
  }

  const sendSettingsCode = () => {
    // Send Device Settings Commands
    pollCommands(moduleItemData, "send_settings", moduleItemData.sendSettings);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.assetsFormSettingsOrion}>
        <Grid item xs={12} md={12}>
          <ListItem>
            <ListItemText
              md={4}
              className="itemTextFirst"
              primary={Language.translate("REPORTING INTERVAL")}
            />
            <ListItemText
              md={4}
              className="itemTextSecond"
              primary={
                <div>
                  <div className="lockIconBox">
                    {!moduleItemData.settingsVal.lock_report_i && (
                      <CustomIcon id={`icon-unlock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                  <div className="switchBox">
                    <Switch
                      name="settingsVal.lock_report_i"
                      value={moduleItemData.settingsVal.lock_report_i}
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      checked={
                        moduleItemData.settingsVal.lock_report_i === true
                      }
                      color="primary"
                      labelPlacement="top"
                    />
                  </div>
                  <div
                    className={`${
                      !moduleItemData.settingsVal.lock_report_i && "hide"
                    } lockIconBox`}
                  >
                    {moduleItemData.settingsVal.lock_report_i && (
                      <CustomIcon id={`icon-lock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                </div>
              }
            />
            <ListItemText
              md={4}
              className="itemTextThird"
              primary={
                <CustomDropdown
                  name="settingsVal.report_i"
                  className="dropdown"
                  value={moduleItemData.settingsVal.report_i}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                >
                  {reportIntervalUA2.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={Language.translate(String(option.value))}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={4}
              className="itemTextFirst"
              primary={Language.translate("Alerts Interval").toUpperCase()}
            />
            <ListItemText
              md={4}
              className="itemTextSecond"
              primary={
                <div>
                  <div className="lockIconBox">
                    {!moduleItemData.settingsVal.lock_alert_i && (
                      <CustomIcon id={`icon-unlock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                  <div className="switchBox">
                    <Switch
                      name="settingsVal.lock_alert_i"
                      value={moduleItemData.settingsVal.lock_alert_i}
                      checked={moduleItemData.settingsVal.lock_alert_i === true}
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      color="primary"
                      labelPlacement="top"
                    />
                  </div>
                  <div
                    className={`${
                      !moduleItemData.settingsVal.lock_alert_i && "hide"
                    } lockIconBox`}
                  >
                    {moduleItemData.settingsVal.lock_alert_i && (
                      <CustomIcon id={`icon-lock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                </div>
              }
            />
            <ListItemText
              md={4}
              className="itemTextThird"
              primary={
                <CustomDropdown
                  name="settingsVal.alert_i"
                  className="dropdown"
                  value={String(moduleItemData.settingsVal.alert_i)}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                >
                  {alertIntervalUA2.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={4}
              className="itemTextFirst"
              primary={Language.translate("Distance Interval").toUpperCase()}
            />
            <ListItemText
              md={4}
              className="itemTextSecond"
              primary={
                <div>
                  <div className="lockIconBox">
                    {!moduleItemData.settingsVal.lock_distance_i && (
                      <CustomIcon id={`icon-unlock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                  <div className="switchBox">
                    <Switch
                      name="settingsVal.lock_distance_i"
                      value={moduleItemData.settingsVal.lock_distance_i}
                      checked={
                        moduleItemData.settingsVal.lock_distance_i === true
                      }
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      color="primary"
                      labelPlacement="top"
                    />
                  </div>
                  <div
                    className={`${
                      !moduleItemData.settingsVal.lock_distance_i && "hide"
                    } lockIconBox`}
                  >
                    {moduleItemData.settingsVal.lock_distance_i && (
                      <CustomIcon id={`icon-lock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                </div>
              }
            />
            <ListItemText
              md={4}
              className="itemTextThird"
              primary={
                <CustomDropdown
                  name="settingsVal.distance_i"
                  className="dropdown"
                  value={String(moduleItemData.settingsVal.distance_i)}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                >
                  {moduleItemData.settingsVal.measurements === "Metric"
                    ? distanceIntervalUAinMeters.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : distanceIntervalUAinYards.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={4}
              className="itemTextFirst"
              primary={Language.translate("Distance Enabled").toUpperCase()}
            />
            <ListItemText
              md={4}
              className="itemTextSecond"
              primary={
                <div>
                  <div className="lockIconBox">
                    {!moduleItemData.settingsVal.lock_distance_enabled && (
                      <CustomIcon id={`icon-unlock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                  <div className="switchBox">
                    <Switch
                      name="settingsVal.lock_distance_enabled"
                      value={moduleItemData.settingsVal.lock_distance_enabled}
                      checked={
                        moduleItemData.settingsVal.lock_distance_enabled ===
                        true
                      }
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      color="primary"
                      labelPlacement="top"
                    />
                  </div>
                  <div
                    className={`${
                      !moduleItemData.settingsVal.lock_distance_enabled &&
                      "hide"
                    } lockIconBox`}
                  >
                    {moduleItemData.settingsVal.lock_distance_enabled && (
                      <CustomIcon id={`icon-lock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                </div>
              }
            />
            <ListItemText
              md={4}
              className="itemTextThird"
              primary={
                <CustomDropdown
                  name="settingsVal.distance_enabled"
                  className="dropdown"
                  value={moduleItemData.settingsVal.distance_enabled}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                >
                  {distanceEnabledUA.map((key) => (
                    <MenuItem
                      key={key.item}
                      value={Language.translate(key.value)}
                    >
                      {key.item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={4}
              className="itemTextFirst"
              primary={Language.translate("MEASUREMENT")}
            />
            <ListItemText
              md={4}
              className="itemTextSecond"
              primary={
                <div>
                  <div className="lockIconBox">
                    {!moduleItemData.settingsVal.lock_measurements && (
                      <CustomIcon id={`icon-unlock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                  <div className="switchBox">
                    <Switch
                      name="settingsVal.lock_measurements"
                      value={moduleItemData.settingsVal.lock_measurements}
                      checked={
                        moduleItemData.settingsVal.lock_measurements === true
                      }
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      color="primary"
                      labelPlacement="top"
                    />
                  </div>
                  <div
                    className={`${
                      !moduleItemData.settingsVal.lock_measurements && "hide"
                    } lockIconBox`}
                  >
                    {moduleItemData.settingsVal.lock_measurements && (
                      <CustomIcon id={`icon-lock`} style={{ fontSize: 10 }} />
                    )}
                  </div>
                </div>
              }
            />
            <ListItemText
              md={4}
              className="itemTextThird"
              primary={
                <CustomDropdown
                  name="settingsVal.measurements"
                  value={moduleItemData.settingsVal.measurements}
                  className="dropdown"
                  onChange={(e) => {
                    changeDistanceInterval(e);
                    handleFormInput(e);
                  }}
                  onBlur={handleFormBlur}
                >
                  {measurementsUA.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            className="settingsBtnSendSettingsCode"
            onClick={(e) => {
              e.target.blur();
              sendSettingsCode();
            }}
            size="medium"
            color="command"
            variant="contained"
            startIcon={<SettingsIcon />}
          >
            {Language.translate("Send Settings Code")}
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
