//var Permission= require("sccPermission");
import { default as Module } from "sccModule";
var Utils= require("sccUtils").default;

/**
 * The base class for MapsModule
 * 
 * @class MapsModule
 */
class MapsModule extends Module.Module{
	constructor(){
		super({
			moduleName: "map_layer"
		});
	}

	init(){
		return Module.Module.prototype.init.call(this);
	}

	getMapLayers(){
		return Utils.httpRequestHandler({
			method: "GET",
			url: Utils.apiUrlPrefix+ "/map_layer/all"
		})
		.then(function(response){			
			return Promise.resolve(response.data.result);
		});
	
	}
}


//module.exports = New MapsModule();
//export default MapsModule;
export default new MapsModule();