import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RssFeedIcon from "@material-ui/icons/RssFeed";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import { AppContext } from "../../../AppContext";

import Device from "sccDevice";
import Language from "sccLanguage";
import Sa from "sccSa";
import SADetail from "./SADetail";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

export default function SAList(props) {
  const data = props.data;
  const [mwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const handleSendLastReport = (data) => {
    data["poll_sa"] = true;
    sendLastReport(data);
  };

  function sendLastReport(editObj) {
    const devices = Device.get();
    // Checks if all the subscribers selected are in Devices and not deleted by the time a user saves SA rule
    if (!_.isUndefined(editObj)) {
      var removeArray = _.difference(
        editObj.subscribers.devices,
        _.keys(devices).map(Number)
      );
      if (removeArray.length > 0) {
        editObj.subscribers.devices = _.intersection(
          editObj.subscribers.devices,
          _.keys(devices).map(Number)
        );
      }
    }
    var objCloned = _.cloneDeep(editObj);

    // Requirement: if members and subscribers have not changed do not set poll_sa to true
    if (objCloned) {
      const saMembers = Sa.get(editObj.id).members;
      const editObjMembers = editObj.members;
      const checkMembers = _.isEqual(saMembers, editObjMembers);
      const saSubscribers = Sa.get(editObj.id).subscribers;
      const editObjSubscribers = editObj.subscribers;
      const checkSubscribers = _.isEqual(saSubscribers, editObjSubscribers);
      // Check if changes have occurred in Members or Subscribers
      if (checkMembers && checkSubscribers) {
        //loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
        updateSa(objCloned);
        saveSa(editObj);
      } else {
        if (_.isUndefined(objCloned.id)) {
          if (editObj) {
            //user has clicked to update SA(send last report) immediately after creating an SA rule
            //loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
            updateSa(editObj);
            saveSa(editObj);
          }
        } else {
          objCloned["poll_sa"] = true;
          //loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
          updateSa(objCloned);
          saveSa(objCloned);
        }
      }
    } else {
      //user has clicked to update SA(send last report), immediately. Did not attempt to access edit menus
      //loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
      updateSa(editObj);
      saveSa(editObj);
    }
  }
  function updateSa(obj) {
    const errorMessage = Language.translate("Failed to send SA report");
    _.each(obj.subscribers.devices, (id) => {
      let device = Device.get(id);
      device.latest_sa_timestamp = Math.trunc(Date.now() / 1000);
      var options;
      options = {
        url: Utils.apiUrlPrefix + "/device",
        body: device,
        method: "PUT",
      };

      return Utils.httpRequestHandler(options)
        .then(() => {
          return Promise.resolve();
        })
        .catch(function (err) {
          appState.displaySnackbarMessage({
            title: mwState.title,
            message: errorMessage,
            variant: "error",
          });
        });
    });
  }
  function saveSa(obj) {
    let successMessage, errorMessage;
    successMessage = Language.translate("Successfully Updated");
    errorMessage = Language.translate("Failed to Update");

    var options;
    options = {
      url: Utils.apiUrlPrefix + "/sa",
      body: obj,
      method: "PUT",
    };

    return Utils.httpRequestHandler(options)
      .then(function () {
        //save the settings part
        appState.displaySnackbarMessage({
          title: mwState.title,
          message: successMessage,
          subMessage: obj.title,
          variant: "success",
        });
      })
      .catch(function (err) {
        appState.displaySnackbarMessage({
          title: mwState.title,
          message: errorMessage,
          subMessage: obj.title,
          variant: "error",
        });
      });
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={data.title}
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {mwState.verifyEditPermission && (
            <CustomTooltip
              title={Language.translate("Send last report")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="feed"
                onClick={() => handleSendLastReport(data)}
                placement="bottom"
              >
                <RssFeedIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {mwState.verifyEditPermission && (
            <CustomTooltip
              title={Language.translate("Edit")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleEdit(data)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {mwState.verifyDeletePermission && (
            <CustomTooltip
              title={Language.translate("Delete")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() =>
                  handleItemDelete(
                    `${mwState.routeUrl}/${data.id}`,
                    mwState.title,
                    "SA",
                    data.title
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <SADetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
