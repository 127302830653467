import React, { useState, useEffect } from "react";

// Material-UI
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import LoopIcon from "@material-ui/icons/Loop";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import AdminDevice from "sccAdminDevice";
import Client from "sccClient";
import Clock from "sccClock";
import Feed from "sccFeed";
import Language from "sccLanguage";
import Device from "sccDevice";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  registered: {
    backgroundColor: theme.palette.buttons.action.main,
    color: theme.palette.colors.white.main,
  },
  pending: {
    backgroundColor: theme.palette.colors.orange.light,
    color: theme.palette.colors.white.main,
  },
  chips: {
    alignItems: "normal",
  },
}));

export default function AdminDeviceDetail(props) {
  const data = props.data;
  const [registration, setRegistration] = useState("Not Registered");
  const incomingModes = Object.values(
    AdminDevice.getDeviceType(data.type_id).device_incoming_modes
  );
  const modes = Object.values(AdminDevice.getDeviceType(data.type_id).modes);
  const feeds = Object.values(Feed.get());

  useEffect(() => {
    if (data.registration_status === 0) {
      setRegistration("Pending");
    }
    if (data.registration_status === 1) {
      setRegistration("Registered");
    }
    if (data.registration_status === 2 && data.id) {
      setRegistration("Not Registered");
    }
  }, [data.registration_status]);

  const classes = useStyles();

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Registration")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            id="registrationStatus"
            primary={
              <React.Fragment>
                {data.registration_status === 0 ? (
                  <Chip
                    label={Language.translate(registration)}
                    size="small"
                    className={classes.pending}
                    icon={<LoopIcon className={classes.pending} />}
                  />
                ) : (
                  <Chip
                    label={Language.translate(registration)}
                    size="small"
                    className={classes.registered}
                  />
                )}
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Status")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.active ? "Active" : "Inactive"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Customer")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={Client.get(data.client_id).company}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Device Type")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={AdminDevice.getDeviceType(data.type_id).title}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Name")}
          />

          <ListItemText md={6} className="itemTextSecond" primary={data.name} />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Comm ID")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.comm_id}
          />
        </ListItem>

        {[
          ...Device.getHermesDevices(),
          "NORTAC Orion",
        ].includes(AdminDevice.getDeviceType(data.type_id).title) ? (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={
                AdminDevice.getDeviceType(data.type_id).title === "NORTAC Orion"
                  ? `${Language.translate("IMEI")}`
                  : `${Language.translate("Serial Number")}`
              }
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.Serial_No ? data.Serial_No : "N/A"}
            />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("IMEI")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.imei}
            />
          </ListItem>
        )}
        {AdminDevice.getDeviceType(data.type_id).title === "NORTAC Orion" && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("Device ID")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.imei}
            />
          </ListItem>
        )}
        {["Sentry H6120 BM", "Sentry H6110 MP", "SAT-COM Leopard1"].includes(
          AdminDevice.getDeviceType(data.type_id).title
        ) && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("ALE Address")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.ale_address}
            />
          </ListItem>
        )}
        {AdminDevice.getDeviceMode(data) === "Gateway Device" && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("IP Address")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.ip_address || "N/A"}
            />
          </ListItem>
        )}
        {AdminDevice.getDeviceType(data.type_id).components.zigbee_id && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("Zigbee")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.device_configurations.zigBee_id}
            />
          </ListItem>
        )}

        {AdminDevice.getDeviceType(data.type_id).components.iridex_pin && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("Registration Pin")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.device_configurations.iridex_pin}
            />
          </ListItem>
        )}

        {data.sms && (
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("SMS")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.sms ? data.sms : "N/A"}
            />
          </ListItem>
        )}
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Encryption")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.encryption_key ? data.encryption_key : "N/A"}
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Decryption")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.decryption_key ? data.decryption_key : "N/A"}
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
        </ListItem>

        {/* MOBILE TERMINATED */}
        <ListItem className={classes.chips}>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Mobile Terminated")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={
              incomingModes.find((x) => x.id === data.device_incoming_mode) ? (
                <Chip
                  size="small"
                  label={
                    incomingModes.find(
                      (x) => x.id === data.device_incoming_mode
                    ).title
                  }
                />
              ) : (
                "N/A"
              )
            }
          />
        </ListItem>
        {/* MODE */}
        <ListItem className={classes.chips}>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Mode")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={
              <Chip
                size="small"
                label={modes.find((x) => x.id === data.mode).title}
              />
            }
          />
        </ListItem>
        {/* FEED */}
        <ListItem className={classes.chips}>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Feed")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={feeds.map((item) => {
              return (
                data.feed_codes.indexOf(item.id) > -1 && (
                  <Chip key={item.id} size="small" label={item.title} />
                )
              );
            })}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Last Reported")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={
              data.report_timestamp
                ? Clock.formatTimestamp(data.report_timestamp)
                : "N/A"
            }
          />
        </ListItem>
      </Grid>
    </Grid>
  );
}
