var Utils= require("sccUtils");
var UserSetting= require("sccUserSetting").default;
var Language = require("sccLanguage").default;

const log = require("loglevel");
log.setLevel(log.levels.DEBUG);

/**
 * GuiUtils class 
 * 
 * @class
 */
function GuiUtils(){}

/**
 * opens a new window
 */
GuiUtils.openPopupWindow = function (url, name) {
	var newWindow = window.open(GuiUtils.encodeUrl(url), name, "location=no, scrollbars=yes, resizable=yes, toolbar=no, menubar=no, top=100, left=400, width=1050, height=570");
	// If my main window has focus - set it to the popup
	if (window.focus) {
		newWindow.focus();
	}
	
	newWindow.addEventListener("load", function () {
		//This checks if we are translating an extended map window. It needs its own if statement because it's translation is different than the others
		if(name.includes("Extended")){
			newWindow.document.title = Language.translate("Extended") + " " + name[name.length-1] + " | SCCTitan " + Language.translate("Platform");
			return;
		}
		//Dynamically sets the window title based on the name passed
		newWindow.document.title = Language.translate(name) + " | SCCTitan " + Language.translate("Platform");
		// switch (name) {
		// case "History":
		// 	newWindow.document.title = Language.translate("History") + " | SCCTitan " + Language.translate("Platform");
		// 	return;

		// case "Report":
		// 	newWindow.document.title = Language.translate("Reports") + " | SCCTitan " + Language.translate("Platform");
		// 	return;

		// case "Extended" + :
		// 	newWindow.document.title = Language.translate("Extended Map") + " | SCCTitan " + Language.translate("Platform");
		// 	return;
		// }
	});

	return newWindow;
};


/**
 * 
 */
GuiUtils.encodeUrl= function(url){
	if (url.indexOf("?")>0){
		var encodedParams = "?";
		var parts = url.split("?");
		var params = parts[1].split("&");
		for(var i = 0; i < params.length; i++){
			if (i > 0){
				encodedParams += "&";
			}
			if (params[i].indexOf("=")>0) //Avoid null values
			{
				var p = params[i].split("=");

				encodedParams += (p[0] + "=" + escape(encodeURI(p[1])));
			}
			else{
				encodedParams += params[i];
			}
		}
		url = parts[0] + encodedParams;
	}
	return url;
};




/*
 * Function: GuiUtils.confirmBox
 * shows a confirmation window with "Yes" and "No" buttons. 
 * 
 * Parameters:
 * msg: {string} the message to be shown
 * func1 - {function} the function that is called if "Yes" is pressed
 * arg1 - {object} argument passed to func1
 * func2 - {function} function that is called if "No" is pressed
 * arg2 - {object} argument passed to func2 
 */
GuiUtils.confirmBox= function(msg, func1, arg1, func2, arg2){
	return Utils.confirm(msg, function(result){
		if(result){
			if (func1) func1(arg1);
		}else{
			if (func2) func2(arg2);
		}
	});
};


//This function returns the correct conversion ratio for the speed format sepected by the user in his settings
GuiUtils.getSpeedUnitRatio= function(){
	switch(UserSetting.get("speed_format")){
	case "MPH":
		//converting speed value in MPH to KPH
		return 0.621371;
	case "KNOTS":
		//converting speed value in MPH to KNOTS
		return 0.539957;
	default:
		return 1;
	}
};


/**
 * saves the given text into file on the client side
 */
GuiUtils.saveTextAsFile= function(data, filename, type)
{
	var textToWrite = data;
	var textFileAsBlob = new Blob([textToWrite], {type: type});
	var saveAs = require("file-saver").saveAs;
	saveAs(textFileAsBlob, filename);
};

/**
 * destroys the dummy element created for saving the file
 */
GuiUtils.destroyClickedElement= function(event)
{
	document.body.removeChild(event.target);
};



/*
	This function uses the decimal value for direction and returns direction as a string i.e 
	North, North East, North West, South, South East, South West, East, West
*/
 
GuiUtils.ConvertHeadingToString = function (heading){
	if(heading > 337.5 || heading <= 22.5) return Language.translate("North");
	if(heading > 22.5 && heading <= 67.5) return Language.translate("North East");
	if(heading > 67.5 && heading <= 112.5) return Language.translate("East");
	if(heading > 112.5 && heading <= 157.5) return Language.translate("South East");
	if(heading > 157.5 && heading <= 202.5) return Language.translate("South");
	if(heading > 202.5 && heading <= 247.5) return Language.translate("South West");
	if(heading > 247.5 && heading <= 292.5) return Language.translate("West");
	if(heading > 292.5 && heading <= 337.5) return Language.translate("North West");	
	
	return "N/A";
};


//module.exports= GuiUtils;
export default GuiUtils;