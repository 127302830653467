import React, { useState, useEffect } from "react";
import { default as Images } from "sccImage";
var Utils = require("sccUtils");

export default function Logo(props) {
	const [version, setVersion] = useState("");

	useEffect(() => {
		platformVersion(Utils, setVersion);
	}, []);
	return (
		<React.Fragment>
			<div className="logo">
				<img id="logoimage"
					src={Images.getImageCollection("platform_images").scc_titan_logo.default} alt="Titan by NORTAC Platform"/>
				<p className="versionText">
					{props.translateText("Version")}
					<span> {version}</span>
				</p>
			</div>
			<div className="bottomlogoimage">
				<img id="bottomlogoimage" src={Images.getImageCollection("platform_images").scc_titan_logo_bottom.default} alt="Titan by NORTAC Platform"/>
			</div>
		</React.Fragment>
	);
}

/**
 * Gets the current version of the platform
 */
function platformVersion(Utils, setVersion) {
	return fetch(Utils.default.apiUrlPrefix + "/welcome")
		.then((res) => res.json())
		.then((data) => setVersion(data.version))
		.catch((e) => console.log(e));
}