// External
import _ from "lodash";

// Internal
import Language from "sccLanguage";
import Menu from "sccMenu";
import Nr from "sccNr";
import Utils from "sccUtils";

/**
 * NEAREST RESPONDER menu class
 *
 * @class NrMenu
 * @param {object} options - nearest responder menu information object
 **/
class NrMenu extends Menu {
  constructor() {
    const options = {
      tab: "NR",
      shortTitle: "NR",
      title: "Nearest Responder",
      moduleName: "nr",
      mainClass: "Nearest-Responder",
      searchable: true,
      imageClass: "sitemap",
      module: Nr,
      showPagination: true,
    };
    super(options);
  }

  // overriding the add method of the Menu class
  add(obj) {
    obj = _.assign(obj, {
      radius: 100000,
      report_age: 24 * 60 * 60,
      device_count: 5,
      members: { groups: [], devices: [] },
    });
    super.add(obj);
  }

  testUniqueGroup(group, editObj) {
    var foundNr = _.find(Nr.get(), function (nr) {
      return _.indexOf(nr.members.groups, group.id) > -1;
    });

    if (foundNr) {
      var message = Language.translate(
        "Each Group should be part of one Rule only. Group '{0}' is already in use by Rule '{1}'.",
        group.title,
        foundNr.title
      );
      Utils.alert(message, function () {
        editObj.members.groups = _.xor(editObj.members.groups, [group.id]);
      });

      return false;
    }

    return true;
  }
}

export default new NrMenu();
