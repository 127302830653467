import React from "react";

// Material-UI
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",

    "& .MuiInputBase-root": {
      height: 32,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
    },
  },
}));

function CustomDropdown(props) {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    error = null,
    helperText,
    onChange,
    disabled = false,
    ...other
  } = props;
  return (
    <TextField
      variant="standard"
      select
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      classes={{
        root: classes.root,
      }}
      {...(error && { error, helperText })}
      {...other}
    >
      {props.children}
    </TextField>
  );
}
export default CustomDropdown;
