import React, { useState, useContext } from "react";

// Material-UI
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import CustomTooltip from "@Components/CustomTooltip";
import Switch from "@material-ui/core/Switch";

// Internal
import { UserSettingContext } from "../../../user_setting/context/UserSettingContext";
import Language from "sccLanguage";
import UserSetting from "sccUserSetting";
import "./MapSettings.scss";

export default function MapSettings(props) {
  const { userSettingData, setUserSettingData } =
    useContext(UserSettingContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userId] = useState(UserSetting.get("id"));

  const handleChangeAssetAnnotation = (e) => {
    const newUserData = {
      ...userSettingData,
      asset_annotation: e.target.checked,
    };
    setUserSettingData(newUserData);
    UserSetting.update({ id: userId, asset_annotation: e.target.checked });
  };
  const handleChangePoiAnnotation = (e) => {
    const newUserData = {
      ...userSettingData,
      poi_annotation: e.target.checked,
    };
    setUserSettingData(newUserData);
    UserSetting.update({ id: userId, poi_annotation: e.target.checked });
  };
  const handleChangeAssetLabels = (e) => {
    const newUserData = {
      ...userSettingData,
      asset_label: e.target.checked,
    };
    setUserSettingData(newUserData);
    UserSetting.update({ id: userId, asset_label: e.target.checked });
  };
  const handleChangePoiLabels = (e) => {
    const newUserData = {
      ...userSettingData,
      poi_label: e.target.checked,
    };
    setUserSettingData(newUserData);
    UserSetting.update({ id: userId, poi_label: e.target.checked });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "mapSettingsPopover" : undefined;
  //const { asset_annotation, poi_annotation, asset_label, poi_label } = copyUserSettings;

  return (
    <div>
      <CustomTooltip
        title={Language.translate("Map Settings")}
        arrow
        placement="top"
      >
        <Button
          className="mapSettingsBtn btn btn-default"
          aria-describedby={id}
          variant="contained"
          color="default"
          onClick={handleClick}
          startIcon={<SettingsIcon />}
        ></Button>
      </CustomTooltip>
      <Popover
        className="mapSettingsPopover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <h5>{Language.translate("Map Settings")}</h5>

        <ul className="mapSettingsMenu">
          <li>
            {Language.translate("Asset Annotations")}
            <Switch
              checked={userSettingData?.asset_annotation || false}
              onChange={handleChangeAssetAnnotation}
              size="small"
              color="primary"
              name="asset_annotation"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </li>
          <li>
            {Language.translate("POI Annotations")}
            <Switch
              checked={userSettingData?.poi_annotation || false}
              onChange={handleChangePoiAnnotation}
              size="small"
              color="primary"
              name="poi_annotation"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </li>
          <li>
            {Language.translate("Asset Labels")}
            <Switch
              checked={userSettingData?.asset_label || false}
              onChange={handleChangeAssetLabels}
              size="small"
              color="primary"
              name="asset_label"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </li>
          <li>
            {Language.translate("POI Labels")}
            <Switch
              checked={userSettingData?.poi_label || false}
              onChange={handleChangePoiLabels}
              size="small"
              color="primary"
              name="poi_label"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </li>
        </ul>
      </Popover>
    </div>
  );
}
