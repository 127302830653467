import React, { useState } from "react";

import _ from "lodash";

import Group from "sccGroup";
import Device from "sccDevice";

const CustomAssetSelectorContext = React.createContext([{}, () => {}]);
const DEFAULT_MAIN_GROUP_TITLE = process.env.REACT_APP_DEFAULT_MAIN_GROUP_TITLE;

const CustomAssetSelectorContextProvider = (props) => {
  const resetAssetSelectorState = () => {
    setAssetSelectorState((p) => ({
      ...initialState,
    }));
  };

  const initialState = {
    setStateForDeviceSelection,
    setStateForGroupSelection,
    setStateForDeviceFromDevice,
    setStateForEditMode,
    setHideDeviceCheckbox,
    setHideDevices,
    setShowSelectedOnly,
    setHideGroupCheckbox,
    setShowGroupRadioButtons,
    setStateForSelection,
    setStateForGroupRadioSelection,
    setDevicesButtons,
    setGroupButtons,
    addOtherGroupsToSelection,
    removeOtherGroupsFromSelection,
    minimizeSelection,
    maximizeSelection,
    buildCollectionToDisplay,
    totalDevicesFromGroups,
    resetAssetSelectorState,
    editMode: false,
    hideDeviceCheckbox: false,
    hideDevices: false,
    showSelectedOnly: false,
    hideGroupCheckbox: false,
    showGroupRadioButtons: false,
    devicesButtons: false,
    groupButtons: false,
    selection: {},
    collection: {},
    collectionOriginal: {},
  };

  const [assetSelectorState, setAssetSelectorState] = useState(initialState);

  function setStateForSelection(obj, name) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      selection: { ...assetSelectorState.selection, [name]: obj },
    }));
  }

  function setStateForEditMode(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      editMode: type,
    }));
  }
  function setHideDeviceCheckbox(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      hideDeviceCheckbox: type,
    }));
  }
  function setHideGroupCheckbox(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      hideGroupCheckbox: type,
    }));
  }
  function setShowGroupRadioButtons(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      showGroupRadioButtons: type,
    }));
  }
  function setHideDevices(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      hideDevices: type,
    }));
  }
  function setDevicesButtons(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      devicesButtons: type,
    }));
  }
  function setGroupButtons(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      groupButtons: type,
    }));
  }
  function setShowSelectedOnly(type) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      showSelectedOnly: type,
    }));
  }

  //device clicked directly
  function setStateForDeviceFromDevice(id, name) {
    const groups = assetSelectorState.selection[name]
      ? assetSelectorState.selection[name].groups
      : [];
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      selection: {
        ...assetSelectorState.selection,
        [name]: { groups: groups, devices: id },
      },
    }));
  }

  function removeOtherGroupsFromSelection(selection, devices) {
    //remove groups selected based on device deselected
    const Device = require("sccDevice").default;
    devices.forEach((deviceId) => {
      const groups = Device.get(deviceId).groups;
      groups.forEach((groupId) => {
        if (selection.groups.indexOf(groupId) > -1)
          selection.groups.splice(selection.groups.indexOf(groupId), 1);
      });
    });
  }

  //instead of using the Group.groupTree() we rebuild the collection bottom-up based on the selection that we wish to display
  function buildCollectionToDisplay(selectionOrg) {
    const groups = selectionOrg.groups;

    let collection = {
      id: 1,
      title: DEFAULT_MAIN_GROUP_TITLE,
      groups: [],
      devices: selectionOrg.devices,
    };
    let groupIds = [];

    let obj;

    groups.forEach((group) => {
      if (Group.get(group) !== undefined) {
        if (Group.get(group).title === DEFAULT_MAIN_GROUP_TITLE) {
          collection.devices = Group.get(group).devices;
        } else {
          let objGroup = Group.get(group);
          obj = {
            id: objGroup.id,
            title: objGroup.title,
            groups: [],
            devices: objGroup.devices,
          };
          recursive(obj, objGroup.parent_id);
        }
      }
    });
    return collection;

    function recursive(group, parentId) {
      groupIds.push(group.id);
      if (groupIds.indexOf(parentId) === -1) {
        if (
          Group.get(parentId) === undefined ||
          Group.get(parentId).title === DEFAULT_MAIN_GROUP_TITLE
        ) {
          collection.groups.push(group);
        } else {
          let objGroup = Group.get(parentId);
          obj = {
            id: objGroup.id,
            title: objGroup.title,
            groups: [group],
            devices: [],
          }; //no devices to be shown for the parental chain
          recursive(obj, objGroup.parent_id);
        }
      } else {
        findNestedObject(collection.groups, parentId).groups.push(group);
      }
    }

    //finds the nested object in the array of nested objects based on the selected id
    function findNestedObject(object, valueToMatch) {
      for (let i = 0; i < object.length; i += 1) {
        if (object[i].id === valueToMatch) {
          return object[i];
        }
        const child = findNestedObject(object[i].groups, valueToMatch);
        if (child !== null) {
          return child;
        }
      }
      return null;
    }
  }

  function addOtherGroupsToSelection(selection) {
    //groups selected based on device selected
    const Device = require("sccDevice").default;
    selection.devices.forEach((deviceId) => {
      const groups = Device.get(deviceId)?.groups;
      // Loop through each group to get total devices

      if (groups) {
        groups.forEach((groupId) => {
          selection.groups = _.uniq(
            selection.groups.concat(checkGroups(groupId, selection.devices))
          ).sort();
        });
        function checkGroups(groupId, arrDevices) {
          //Get all devices in group
          if (Group.get(groupId)) {
            const devices = Group.get(groupId).devices;
            if (_.intersection(devices, arrDevices).length === devices.length) {
              return [groupId];
            }
            return [];
          }
        }
      }
    });
  }

  function setStateForDeviceSelection(id, name) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      selection: {
        ...assetSelectorState.selection,
        [name]: { groups: [], devices: id },
      },
    }));
  }

  function setStateForGroupSelection(id, name) {
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      selection: {
        ...assetSelectorState.selection,
        [name]: {
          groups: id,
          devices: [...assetSelectorState.selection[name].devices],
        },
      },
    }));
  }

  function setStateForGroupRadioSelection(id, name) {
    const devices = assetSelectorState.selection[name]
      ? assetSelectorState.selection[name].devices
      : [];
    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      selection: {
        ...assetSelectorState.selection,
        [name]: { groups: id, devices: devices },
      },
    }));
  }

  function maximizeSelection(selectionOrg) {
    const groups = _.compact(selectionOrg.groups);
    const devices = _.compact(selectionOrg.devices);

    let selection = { groups: [], devices: devices }; //_.clone(selectionOrg)
    let obj;

    groups.forEach((group) => {
      obj = { groups: [Group.get(group)] };
      selection = recursive(obj, selection);
    });

    return selection;

    function recursive(group, selection) {
      if (group.devices && group.devices.length > 0) {
        selection.devices = _.uniq(
          selection.devices.concat(group.devices)
        ).sort();

        addOtherGroupsToSelection(selection);
      }
      if (
        group.groups &&
        group.groups.length !== 0 &&
        group.groups[0] !== undefined
      ) {
        selection.groups = _.uniq(
          selection.groups.concat(group.groups.map(({ id }) => id))
        );
        // Loop through each group and run recursive again to add id's for group and devices array
        group.groups.forEach((group) => {
          selection = recursive(group, selection);
        });
      }
      return selection;
    }
  }

  function minimizeSelection(selectionOrg) {
    const Group = require("sccGroup").default;

    const groups = selectionOrg.groups;
    const devices = selectionOrg.devices;

    let groupsToDelete = [];
    let devicesToDelete = [];
    let selection = {};

    groups.forEach((group) => {
      if (Group.get(group).groups) {
        groupsToDelete = _.uniq(
          groupsToDelete.concat(Group.get(group).groups.map(({ id }) => id))
        );
      }
      if (Group.get(group).devices) {
        devicesToDelete = _.uniq(
          devicesToDelete.concat(Group.get(group).devices)
        );
      }
    });

    selection = {
      groups: _.compact(_.difference(groups, groupsToDelete)),
      devices: _.compact(_.difference(devices, devicesToDelete)),
    };
    return selection;
  }

  function totalDevicesFromGroups(groupArray, deviceArray) {
    let devicesFromGroups = [];
    let devicesFromDevices = [];

    if (groupArray) {
      groupArray.forEach((groupId) => {
        if (Group.get(groupId)) {
          devicesFromGroups.push(Group.get(groupId).devices);
        }
      });
    }

    if (deviceArray) {
      deviceArray.forEach((deviceId) => {
        if (Device.get(deviceId)) {
          devicesFromDevices.push(deviceId);
        }
      });
    }


    return _.compact(_.uniq(_.union(_.flatten(devicesFromGroups), devicesFromDevices)));
  }

  return (
    <CustomAssetSelectorContext.Provider
      value={[assetSelectorState, setAssetSelectorState]}
    >
      {props.children}
    </CustomAssetSelectorContext.Provider>
  );
};
export { CustomAssetSelectorContext, CustomAssetSelectorContextProvider };
