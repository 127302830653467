import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import "./styles/MapBaseLayers.scss";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CustomAlert from "../../../../reusable-components/CustomAlert/CustomAlert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Language = require("sccLanguage").default;
const _ = require("lodash");

export default function MapBaseLayers(props) {
  const OlMap =
    props.history == 1
      ? require("sccOlMapHistory").default
      : require("sccOlMapNew").default;
  const [baseMapValue, setBaseMapValue] = useState();
  const [invalidGoogleMapsKeyAlert, setInvalidGoogleMapsKeyAlert] =
    useState(false);
  const [googleMapsAPIloadFailAlert, setGoogleMapsAPIloadFailAlert] =
    useState(false);
  const baseLayer = OlMap.map.getLayers().getArray()[0];
  const baseLayers = baseLayer.getLayers().getArray();

  const classes = useStyles();

  const handleToggle = (layer, baseLayer) => () => {
    var ableToSwitch = true;
    if (baseLayer.values_.title == "Base Maps") {
      if (layer.values_.title.includes("Google")) {
        if (!window.googleMapsAPIloaded) {
          setGoogleMapsAPIloadFailAlert(true);
          ableToSwitch = false;
        } else if (window.invalidGoogleMapKey) {
          //if google maps API not authenticated, the following occurs and layer is not switched.
          setInvalidGoogleMapsKeyAlert(true);
          ableToSwitch = false;
        }
      }
    }

    if (ableToSwitch) {
      // Change map layer
      window.olMap.BaseLayer.toggleLayerVisibility(layer, baseLayer);
      // set new state
      setBaseMapValue(layer.get("title"));
    }
  };

  return (
    <div className="panel baseMaps">
      <h5>{Language.translate("Base Maps")}</h5>
      <CustomAlert
        titleText={Language.translate("Access Denied")}
        text={Language.translate(
          "A Google Maps key is required. Contact a NORTAC Administrator to gain access."
        )}
        open={invalidGoogleMapsKeyAlert}
        onOkAction={() => setInvalidGoogleMapsKeyAlert(false)}
      />
      <CustomAlert
        titleText={Language.translate("Access Denied")}
        text={
          Language.translate("Failed to load Google Maps.") +
          " " +
          Language.translate(
            "Please check your internet connection or Firewall settings."
          )
        }
        open={googleMapsAPIloadFailAlert}
        onOkAction={() => setGoogleMapsAPIloadFailAlert(false)}
      />
      <List className={`baseMapsListBox ${classes.root}`} dense={false}>
        {baseLayers.map((layer, id) => {
          const labelId = `radio-list-label-${layer.get("title")}`;
          return (
            <ListItem
              key={id}
              className="baseMapsListBoxItem"
              role={undefined}
              dense
              button
              onClick={handleToggle(layer, baseLayer)}
            >
              <ListItemIcon className="baseMapsListBoxItemRadioBtn">
                <Radio color="primary" checked={layer.getVisible()} />
              </ListItemIcon>
              <ListItemText
                className="baseMapsListBoxItemTitle"
                id={labelId}
                primary={layer.get("title")}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
