// External
import bootbox from "bootbox";
import _ from "lodash";

// Internal
import Menu from "sccMenu";
import AddressBook from "sccAddressBook";
import Language from "sccLanguage";
import Sync from "sccSync";
import Permission from "sccPermission";
import Utils from "sccUtils";

/**
 * Address Book menu class
 *
 * @class AddressBookMenu
 * @param {object} options - address book menu information object
 */
class AddressBookMenu extends Menu {
  constructor() {
    const options = {
      tab: "AddressBook",
      shortTitle: "AB",
      title: "Address Book",
      moduleName: "address_book",
      mainClass: "AddressBook",
      searchable: true,
      //imageClass: "fa-binoculars",
      //verifyAddPermission: Permission.default.verify("user", "add"),
      imageClass: "address-book",
      module: AddressBook,
      showPagination: true,
    };
    super(options);
    this.saveMsgText = Language.translate(
      "Would you like to sync this Address Book to the intended Subscribers upon saving?"
    );
  }

  /*
   * Return all the devices from AddressBook Subscribers object and add them to array
   */
  getAllDevicesSelected() {
    const arr = [];
    _.each(AddressBook.get(), (ab) => {
      arr.push(ab.subscribers.devices);
    });
    return _.union(_.flatten(arr));
  }

  /*
   * Check if device is part of AddressBook Subscribers array
   */
  isDeviceAnAddressBookSubscriber(id) {
    const subscribers = this.getAllDevicesSelected();
    return subscribers.indexOf(id) > -1;
  }

  /**
   * overriding the add method of the Menu class
   *
   */
  add(obj) {
    obj = _.assign(obj, {
      members: { groups: [], devices: [], users: [] },
      subscribers: { groups: [], devices: [] },
    });
    super.add(obj);
  }

  /**
   * overriding the save method of the Menu class
   * add confirm box
   */
  save(obj) {
    const $this = this;
    const $scope = this.$scope;
    var objCloned = _.cloneDeep(this.$scope.editObj);
    //var msg= Language.translate("Would you like to sync this Address Book to the intended Subscribers upon saving?");

    obj = objCloned;

    //Check subscribers
    const abSubscribers = AddressBook.get($scope.editObj.id).subscribers;
    const editObjSubscribers = $scope.editObj.subscribers;
    const checkSubscribers = _.isEqual(abSubscribers, editObjSubscribers);

    //Check members
    const abMembers = AddressBook.get($scope.editObj.id).members;
    const editObjMembers = $scope.editObj.members;
    const checkMembers = _.isEqual(abMembers, editObjMembers);

    if (!Permission.isModuleAvailable("sync")) {
      super.save(obj);
      return;
    } else {
      if ($scope.editObj.id == null) {
        //Is original AB is undefined; editObj is assumed to be a newly added AB
        if (!this.hasSubscribers($scope.editObj)) {
          super.save(obj);
          return;
        }
      } //AB is an Edited AB
      else {
        if (!this.hasSubscribers($scope.editObj) && checkSubscribers) {
          //Has no subs and subs have not changed
          super.save(obj);
          return;
        } else if (checkMembers && checkSubscribers) {
          //Subscribers and members have not changed
          super.save(obj);
          return;
        } else if (
          !this.hasSubscribers($scope.editObj) &&
          !this.hasMembers($scope.editObj) &&
          checkSubscribers
        ) {
          //no subs no members AND Subs changed
          super.save(obj);
          return;
        } else if (
          !this.hasSubscribers($scope.editObj) &&
          this.hasMembers($scope.editObj) &&
          checkSubscribers
        ) {
          //no subs, has members AND Subs changed
          super.save(obj);
          return;
        }
      }
    }
    if (objCloned) {
      // Check if changes have occurred in Members or Subscribers
      // If NO changes occurred
      if (checkMembers && checkSubscribers) {
        // no change in obj
        $this.dialogBox(obj, this.saveMsgText);
      } else {
        if (_.isUndefined(objCloned.id)) {
          //
          $this.dialogBox(obj, this.saveMsgText);
        } else {
          //objCloned["poll_sa"] = true;
          // obj changed
          //msg=  Language.translate("Do you want to SYNC changes to this Address Book to the intended Subscribers");
          $this.dialogBox(objCloned, this.saveMsgText);
        }
      }
    } else {
      $this.dialogBox(obj, this.saveMsgText);
    }
  }

  dialogBox(obj, msg) {
    const $this = this;
    bootbox.dialog({
      title: Language.translate("Sync Address Book"),
      message: msg,
      buttons: {
        cancel: {
          label: Language.translate("Cancel"),
          className: "btn-link pull-left",
        },
        noclose: {
          label: Language.translate("No"),
          className: "btn-default",
          callback: function () {
            $this.saveOnly(obj);
          },
        },
        ok: {
          label: Language.translate("Yes"),
          className: "btn-primary",
          callback: function () {
            $this.saveAndSync(obj);
          },
        },
      },
    });
  }

  saveOnly(obj) {
    // Sends flag to backend not to sync addressbook
    obj["sync_now"] = false;
    super.save(obj);
  }

  /**
   * overriding the save method of the Menu class
   *
   */
  saveAndSync(obj) {
    // Sends flag to backend to also sync addressbook

    // Check if already sync pending as to not send another sync request

    obj["sync_now"] = true;
    obj["status"] = true;

    super.save(obj);
  }

  syncNow(obj) {
    obj["sync_now"] = true;
    obj["status"] = true;
    var options = {
      url: Utils.apiUrlPrefix + "/address_book/sync",
      method: "PUT",
      body: obj,
    };
    return Utils.httpRequestHandler(options);
  }

  // /**
  //  * Sync the address book that was selected
  //  *
  //  */
  // syncAB(ab){
  // 	//alert("User clicked SYNC", ab.id);
  // 	this.saveAndSync(ab);
  // }

  /**
   * Download the address book that was selected
   *
   */
  downloadAB(ab) {
    // MASTER DATA BUTTON FUNCTION
    const MasterData = require("../../device/scripts/MasterData.js");
    const GuiUtils = require("sccGuiUtils").default;
    var abId = null;
    var fileTitle = "";
    var message = "";

    abId = ab.id;
    fileTitle = "MasterData_Address_Book_(" + ab.title + ").xml";
    message = Language.translate(
      "Generating the master data file for Address Book '{0}'. Please wait...",
      ab.title
    );

    Utils.notify(ab.title, message, "", "success");
    var data = MasterData.generateAddressBook(abId);
    GuiUtils.saveTextAsFile(data, fileTitle, "application/xml");
  }

  /**
   * Check type of subscribers
   *
   */
  subscriberTypes(devices) {
    const arr = [];
    const Device = require("sccDevice").default;
    _.each(devices, (id) => {
      arr.push(Device.get(id).type);
    });
    return _.union(_.flatten(arr));
  }

  /**
   * Re-Enable Sync button after 24 hours after syncing
   *
   */
  syncData(addressBookObj) {
    const devices = addressBookObj.subscribers.devices;
    // Get the current time in timestamp
    var now = new Date().getTime();
    // Empty array to add device ids if 24hours have passed
    var arr = [];
    // 24hours in timestamp is 86400000 - in milliseconds
    var day = 86400000;
    // var day = 30000;
    // Loop through the devices that are subscribers and get the Sync data for each device
    _.each(devices, (device) => {
      // Get sync data per device
      var syncDataForDevice = Sync.get(device);
      // Subtract now from last time a ring was sent
      var sub = now - syncDataForDevice?.last_ring_sent;

      // Check for completed sync as well
      var syncIsDone =
        syncDataForDevice?.last_synced_timestamp >
        syncDataForDevice?.last_ring_sent;
      // If the differenceof sub is greater than 24 hours, add it to array, or if the sync is completed or synced
      if (
        sub >= day ||
        syncDataForDevice?.status === "synced" ||
        syncIsDone
      ) {
        arr.push(syncDataForDevice?.id);
      } else {
        // Might need another clause, will check back
      }
    });

    // Checks the devices as subscribers in addressbook with the devices in new array
    // If there is a difference between arrays length, then the sync button should be DISABLED
    const diff = _.difference(devices, arr);
    return diff.length > 0;
  }

  hasSubscribers(ab) {
    return (
      ab.subscribers.groups.length > 0 || ab.subscribers.devices.length > 0
    );
  }

  hasMembers(ab) {
    return (
      ab.members.groups.length > 0 ||
      ab.members.devices.length > 0 ||
      ab.members.users.length > 0
    );
  }

  showSyncTags(ab, tagToCheck) {
    var syncIsActive = Permission.isModuleAvailable("sync");

    if (!syncIsActive) {
      return false; // Do no display tags when sync is disabled.
    }

    if (!this.hasSubscribers(ab)) {
      return false; // ABs without subscribers are not sync'ed
    }

    var devices = ab.subscribers.devices;
    //var now = new Date().getTime();
    var syncedDevices = [];

    _.each(devices, (device) => {
      var syncDataForDevice = Sync.get(device);

      if (
        syncDataForDevice &&
        syncDataForDevice.last_synced_timestamp >
          syncDataForDevice.last_ring_sent
      ) {
        syncedDevices.push(syncDataForDevice.id);
      }
    });

    var diff = _.difference(devices, syncedDevices);
    var syncingCount = diff.length;

    if (tagToCheck === "Not Synced") {
      return !ab.status;
    } else if (tagToCheck === "Syncing") {
      return syncingCount > 0 && ab.status;
    } else if (tagToCheck === "Synced") {
      return syncingCount === 0 && ab.status;
    }

    return true;
  }

  // init($scope){
  // 	return super.init($scope)
  // 	.then(()=> {
  // 		$scope.menuInitialized = true;
  // 		return Promise.resolve();
  // 	});
  // }
}

export default new AddressBookMenu();
//module.exports= New AddressBookMenu();
