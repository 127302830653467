import React, { useContext } from "react";

// External
import _ from "lodash";
import { toLower } from "lodash";

// Material-UI
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import Badge from "@material-ui/core/Badge";

// Internal
import { HistoryContext } from "../../../../history/components/HistoryContext";
import "./DeviceHistoryAlertBar.scss";

export default function DeviceHistoryAlertBar({
  // if isDataDisplay is true, we apply absolute style to DeviceAlertBar, check Single Device dataDisplay view
  isDeviceDataDisplay = false,
  device,
}) {
  const [state] = useContext(HistoryContext);
  const Historic = state.Historic;
  const currentReport = Historic.getCurrentDeviceReports(device.id);

  const getReportAlerts = (report) => {
    let alerts = [];
    if (Historic.hasAlert(report, "emergency")) {
      alerts.push("Emergency");
    }
    if (Historic.hasAlert(report, "geofence")) {
      alerts.push("Geofence");
    }
    if (Historic.hasAlert(report, "speed")) {
      alerts.push("Speed");
    }
    if (Historic.hasAlert(report, "vehicle")) {
      alerts.push("Vehicle");
    }
    if (Historic.hasAlert(report, "cargo")) {
      !alerts.includes("cargo") && alerts.push("Cargo");
    }
    if (Historic.hasAlert(report, "non_report")) {
      alerts.push("Non-Report");
    }

    return alerts;
  };

  const currentAlerts = getReportAlerts(currentReport);

  return (
    <div
      className={`DeviceAlertBar ${
        isDeviceDataDisplay && "DeviceAlertBarAbsolute"
      }`}
    >
      <div className="listing">
        {currentAlerts.length <= 0
          ? null
          : _.map(currentAlerts, (data, index) => (
              <React.Fragment key={data + index}>
                <CustomTooltip title={data} placement="top">
                  <Badge className={data}>
                    <CustomIcon
                      type={toLower(data)}
                      id={`icon-${toLower(data)}`}
                      fontSize="small"
                    />
                  </Badge>
                </CustomTooltip>
              </React.Fragment>
            ))}
      </div>
    </div>
  );
}
