import React from "react";
import { ReportContextProvider } from "./ReportContext";
import Report from "./Report";

function Wrapper() {
  return (
    <ReportContextProvider>
      <Report />
    </ReportContextProvider>
  );
}

export default Wrapper;
