import * as Yup from "yup";

const regexIPaddrMatch = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/gm;
const regexUrlMatch =
  /^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
const regexSpecialChar = /^[^<>\\|;:[\]{}=~`&'"%*^=#!@$]+$/;
const regexSpecialCharAcceptingZeroChar = /^[^<>\\|;:[\]{}=~`&'"%*^=#!@$]*$/;
const regexPassword = /^(?=.*\d)(?=.*[a-zA-Z]).{7,40}$/;
const regexSMS = /^[+]?\d*$/;
const regexIMEI = /^[0-9A-Z]*$/;
const regexPhone = /^\+?\d+$/;
const regexSerialNo =
  /[0-9a-fA-F]{2}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{2}?/;
const regexMGRS = /^ *[0-9]{1,2} *[a-z] *[a-z]{2} *[0-9]{2,} *[0-9]{2,} *$/gim;

const baseSchema = (Language) => ({
  MGRS: Yup.string().matches(
    regexMGRS,
    Language.translate("Valid MGRS coordinate input required")
  ),
  dropdownSelectNum: Yup.number()
    .typeError(Language.translate("This field must be selected"))
    .required(Language.translate("This field must be selected")),
  dropdownSelectStr: Yup.string()
    .typeError(Language.translate("This field must be selected"))
    .required(Language.translate("This field must be selected")),
  dropdownSelectBoolean: Yup.boolean()
    .typeError(Language.translate("This field must be selected"))
    .required(Language.translate("This field must be selected")),
  notRequiredString: Yup.string()
    .typeError(Language.translate("This field must be alpha-numeric"))
    .nullable(true),
  requiredString: Yup.string()
    .required(Language.translate("This field is required"))
    .typeError(Language.translate("This field is required")),
  requiredStringMinMax: Yup.string()
    .required(Language.translate("This field is required"))
    .typeError(Language.translate("This field is required"))
    .matches(
      regexSpecialChar,
      Language.translate("This field contains invalid characters")
    )
    .min(
      3,
      Language.translate("This field length must be at least 3 characters long")
    )
    .max(
      45,
      Language.translate(
        "This field length must be less than or equal to 45 characters long"
      )
    ),
  requiredStringMinMaxDyn: (min = 3, max = 45) =>
    Yup.string()
      .required(Language.translate("This field is required"))
      .typeError(Language.translate("This field is required"))
      .matches(
        regexSpecialChar,
        Language.translate("This field contains invalid characters")
      )
      .min(
        min,
        Language.translate(
          "This field length must be at least " + min + " characters long"
        )
      )
      .max(
        max,
        Language.translate(
          "This field length must be less than or equal to " +
            max +
            " characters long"
        )
      ),
  notRequiredStringMinMax: Yup.string()
    .typeError(Language.translate("This field is required"))
    .matches(
      regexSpecialChar,
      Language.translate("This field contains invalid characters")
    )
    .min(
      3,
      Language.translate("This field length must be at least 3 characters long")
    )
    .max(
      45,
      Language.translate(
        "This field length must be less than or equal to 45 characters long"
      )
    )
    .nullable(true),
  notRequiredStringMinMaxEmptyString:
    // checks if the label input has empty string/no input or has a a min of 3 char and a max of 45 char. It checks at the end if the string contains any invalid special char.
    Yup.string()
      .test(
        "is_label_empty_or_long_enough",
        "This field length must be at least 3 characters long",
        (value) => {
          if (value) {
            return value.length >= 3;
          }
          return true;
        }
      )
      .max(
        45,
        Language.translate(
          "This field length must be less than or equal to 45 characters long"
        )
      )
      .matches(
        regexSpecialCharAcceptingZeroChar,
        Language.translate("This field contains invalid characters")
      ),
  requiredStringPassword: Yup.string()
    .required(Language.translate("This field is required"))
    .typeError(Language.translate("This field is required"))
    .min(
      7,
      Language.translate("This field length must be at least 7 characters long")
    )
    .max(
      20,
      Language.translate(
        "This field length must be less than or equal to 20 characters long"
      )
    )
    .matches(
      regexPassword,
      Language.translate("Password must contain at least one number")
    ),
  requiredNumber: Yup.number()
    .required(Language.translate("This field is required"))
    .typeError(Language.translate("This field must be a number")),
  notRequiredNumber: Yup.number()
    .typeError(Language.translate("This field must be a number"))
    .nullable(true),
  requiredNumberPositiveInt: Yup.number()
    .required(Language.translate("This field is required"))
    .min(0, Language.translate("This field must be greater than or equal to 0"))
    .integer(Language.translate("This field must be an integer"))
    .typeError(Language.translate("This field must be a number")),
  notRequiredNumberPositiveIntGreaterZero: Yup.number()
    .min(1, Language.translate("This field must be greater than or equal to 1"))
    .typeError(Language.translate("This field must be a number"))
    .nullable(true),
  notRequiredNumberPositiveInt: Yup.number()
    .min(0, Language.translate("This field must be greater than or equal to 0"))
    .integer(Language.translate("This field must be an integer"))
    .typeError(Language.translate("This field must be a number")),
  requiredNumberPositive: Yup.number()
    .required(Language.translate("This field is required"))
    .min(0, Language.translate("This field must be greater than or equal to 0"))
    .typeError(Language.translate("This field must be a number")),
  notRequiredNumberPositive: Yup.number()
    .min(0, Language.translate("This field must be greater than or equal to 0"))
    .typeError(Language.translate("This field must be a number"))
    .nullable(true),
  notRequiredMax8DigitsNumberWithMax2Decimals: Yup.number()
    .min(0, Language.translate("This field must be greater than or equal to 0"))
    .typeError(Language.translate("This field must be a number"))
    .nullable(true)
    .test(
      "maxLength",
      "Maximum of 8 digits",
      (number) => !number || number.toString().length < 9
    )
    .test(
      "maxDigitsAfterDecimal",
      "Only 2 digits after a decimal",
      (number) => !number || Number.isInteger(number * 10 ** 2)
    ),
  notRequiredNewSettingsCode: Yup.string()
    .typeError(Language.translate("This field is required"))
    .matches(
      regexSpecialChar,
      Language.translate("This field contains invalid characters")
    )
    .min(
      3,
      Language.translate("This field length must be at least 3 characters long")
    )
    .max(
      170,
      Language.translate(
        "This field length must be less than or equal to 170 characters long"
      )
    )
    .nullable(true),
  emailRequired: Yup.string()
    .required(Language.translate("This field is required"))
    .email(Language.translate("This field should be a valid email"))
    .min(
      3,
      Language.translate("This field length must be at least 3 characters long")
    )
    .max(
      45,
      Language.translate(
        "This field length must be less than or equal to 45 characters long"
      )
    ),
  phoneNumber: Yup.string()
    .matches(
      regexPhone,
      Language.translate(
        "Only numbers allowed and no special characters allowed"
      )
    )
    .min(
      8,
      Language.translate("This field should have a minimum of") +
        " 8 " +
        Language.translate("digits")
    )
    .max(
      16,
      Language.translate("This field should have a maximum of") +
        " 16 " +
        Language.translate("digits")
    )
    .nullable(true),
  ale_address: Yup.string()
    .required(Language.translate("This field is required"))
    .matches(
      /^[A-Z0-9-]+$/,
      Language.translate("This field contains invalid characters")
    )
    .uppercase()
    .min(1, Language.translate("Must contain at least 1 character"))
    .max(15, Language.translate("Must be 1-15 characters"))
    .nullable(true),
  ip_address: Yup.string()
    .matches(
      regexIPaddrMatch,
      Language.translate("This field is not a valid IP Address")
    )
    .nullable(true),
  imei: Yup.string()
    .required(Language.translate("This field is required"))
    .uppercase()
    .matches(regexIMEI, Language.translate("This field is not a valid IMEI"))
    .max(
      45,
      Language.translate("This field should have a maximum of") +
        " 45 " +
        Language.translate("characters")
    ),
  Serial_No: Yup.string()
    .required(Language.translate("This field is required"))
    .matches(
      regexSerialNo,
      Language.translate(
        "Must be formatted as follows: XX-XXXX-XXXX-XXXX-XX Where X is either a number or an uppercase letter from A-F. Ex. A1-A123-A123-A123-A1"
      )
    )
    .max(
      20,
      Language.translate("This field should have a maximum of") +
        " 20 " +
        Language.translate("characters")
    ),
  restrictedString: Yup.string().matches(
    regexSpecialChar,
    Language.translate("This field contains invalid characters")
  ),
  url: Yup.string().matches(
    regexUrlMatch,
    Language.translate("This field is not a valid url")
  ),
  encryption_key: Yup.string()
    .matches(
      regexSpecialChar,
      Language.translate("This field contains invalid characters")
    )
    .uppercase()
    .min(
      64,
      Language.translate(
        "This field must contain 64 characters of numbers or capital letters. No special characters allowed."
      )
    )
    .max(
      64,
      Language.translate(
        "This field must contain 64 characters of numbers or capital letters. No special characters allowed."
      )
    ) // .matches(/^[A-Z0-9]{64}$/, "This field contains invalid characters") //Not yet sure if this is needed as we check special chars above
    .nullable(true),
  decryption_key: Yup.string()
    .matches(
      regexSpecialChar,
      Language.translate("This field contains invalid characters")
    )
    .uppercase()
    .min(
      64,
      Language.translate(
        "This field must contain 64 characters of numbers or capital letters. No special characters allowed."
      )
    )
    .max(
      64,
      Language.translate(
        "This field must contain 64 characters of numbers or capital letters. No special characters allowed."
      )
    ) // .matches(/^[A-Z0-9]{64}$/, "This field contains invalid characters") //Not yet sure if this is needed as we check special chars above
    .nullable(true),
  password: Yup.string().matches(
    regexPassword,
    Language.translate("Password must contain at least one number")
  ),
  apn_host: Yup.string()
    .min(
      3,
      Language.translate("This field length must be at least 3 characters long")
    )
    .max(
      45,
      Language.translate(
        "This field length must be less than or equal to 45 characters long"
      )
    )
    .matches(
      regexSpecialChar,
      Language.translate("This field contains invalid characters")
    ),
  sms: Yup.string()
    .min(8, Language.translate("This field should have a minimum of 8 digits"))
    .max(
      16,
      Language.translate("This field should have a maximum of 16 digits")
    )
    .matches(
      regexSMS,
      Language.translate(
        "Only numbers allowed and no special characters allowed"
      )
    )
    .nullable(true),
});

export default baseSchema;
