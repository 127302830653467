import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  role_id: baseSchema(Language).dropdownSelectNum,
  first_name: baseSchema(Language).requiredStringMinMax,
  last_name: baseSchema(Language).requiredStringMinMax,
  email: baseSchema(Language).emailRequired,
  phone_number: baseSchema(Language).phoneNumber,
  email_registration: Yup.boolean(),
  username: Yup.string().when("email_registration", {
    is: false,
    then: baseSchema(Language).requiredStringMinMax,
  }),
  password: Yup.string().when("email_registration", {
    is: false,
    then: baseSchema(Language).requiredStringPassword,
  }),
  confirm: Yup.string().when("email_registration", {
    is: false,
    then: baseSchema(Language).requiredStringPassword.oneOf(
      [Yup.ref("password"), null],
      Language.translate("Passwords are not identical")
    ),
  }),
});

export default schema;
