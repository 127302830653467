import { default as Module } from "sccModule";
var Permission = require("sccPermission");
var Utils = require("sccUtils");
const log = require("loglevel");

/**
 * The base class for PoiCategory
 *
 * @class PoiCategory
 */
class PoiCategory extends Module.Module {
  constructor() {
    super({
      eventName: "poi/category",
      moduleName: "poi/category",
    });
  }

  init() {
    if (
      !Permission.default.verify("poi", "view") &&
      !Permission.default.verify("client", "view")
    ) {
      log.debug("User is not permitted for POI module");
      return Promise.resolve();
    }

    return Module.Module.prototype.init.call(this);
  }

  /**
   * DELETE category from the DB\
   * @param {Number} 		categoryId 		Id of the category
   * @param {Number[]} 	imageIds 		Array of image ids being deleted
   */
  deleteCategoryImages(categoryId, imageIds) {
    var options = {
      url:
        Utils.apiUrlPrefix +
        "/poi/category/image/" +
        categoryId +
        "/[" +
        imageIds +
        "]",
      method: "DELETE",
    };

    return Utils.httpRequestHandler(options);
  }
}

//module.exports= New PoiCategory();
export default new PoiCategory();
