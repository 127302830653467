var Utils = require("sccUtils").default;
var Menu = require("sccMenu").default;
var _ = require("lodash");
var log = require("loglevel");
var Permission = require("sccPermission");
var Language = require("sccLanguage").default;

/**
 * Permission Menu class
 *
 * @class PermissionMenu
 * @param {object} options - Permission menu information object
 */
class PermissionMenu extends Menu {
  constructor() {
    const options = {
      tab: "permission",
      title: "Roles",
      mainClass: "Roles-Permissions",
      imageClass: "lock",
      isSubMenu: true,
      verifyAddPermission: Permission.default.verify("user", "addsibling"),
      verifyDeletePermission: true,
      module: Permission,
      showPagination: true,
    };

    super(options);
    // Angular timeout function to be initialized on init
    this.$timeout = null;
  }

  init($scope, $timeout) {
    super.init($scope);
    this.$timeout = $timeout;

    const $this = this;
    $scope.disabledActions = {};

    return Permission.default
      .loadAllRoles() //gets all the roles/permissions
      .then(function (roles) {
        log.debug("Roles", roles);
        $scope.permissionChanged = function (perm) {
          // updating the editObj based on the checked status
          $scope.editObj.permissions = _.xor($scope.editObj.permissions, [
            perm.id,
          ]);

          $this.manageDisabledActions(perm);
          $this.autoSelectView(perm);
          $this.autoSelectAll(perm);
        };

        return Promise.resolve();
      });
  }

  /**
   * overrides the method from the Menu class
   */
  delete(role) {
    if (role.role_users.length > 0) {
      Utils.notify({
        message:
          "This role is assigned to one or more users. Please unassign this role from respective users before deleting.",
        title: "Cannot Delete Role",
        type: "error",
      });
      return;
    } else {
      super.delete(role);
      return;
    }
  }

  /**
   * overrides the method from the Menu class
   */
  openEditMenu(obj) {
    super.openEditMenu(obj);
    this.initAddEdit();

    var $this = this;
    _.each(obj.permissions, function (permId) {
      var perm = Permission.default.getAllPermissions(permId);
      if (perm.action === "all") {
        $this.manageDisabledActions(perm);
      }

      $this.autoSelectView(perm);
    });
  }

  /**
   * overrides the method from the Menu class
   */
  openAddMenu(obj) {
    super.openAddMenu(obj);
    this.initAddEdit();
  }

  /**
   * initializes the add and edit menu data
   */
  initAddEdit() {
    var $scope = this.$scope;

    $scope.disabledActions = {};

    // Add an empay array to editObj if editObj does not need permissions array
    if (!$scope.editObj.permissions) {
      $scope.editObj.permissions = [];
    }
  }

  /**
   * overriding the save method to confirm saving a role with empty permission actions
   */
  save() {
    var $scope = this.$scope;
    const $this = this;
    const roles = $scope.editObj.permissions;
    const device = this.deviceIdsInRoles();
    const geofence = this.geofenceIdsInRoles();
    const alarm = this.alarmIdsInRoles();
    const alert = this.alertIdsInRoles();
    const group = this.groupIdsInRoles();
    const checkDevice = _.intersection(roles, device);
    const checkGeofence = _.intersection(roles, geofence);
    const checkAlarm = _.intersection(roles, alarm);
    const checkAlert = _.intersection(roles, alert);
    const checkGroup = _.intersection(roles, group);
    //@@@@ Return to this after View is implemented for groups

    if (checkDevice.length && !checkAlarm.length) {
      Utils.notify({
        message: Language.translate(
          "You need to check Alarms if you want Assets."
        ),
        header: "Roles",
        type: "error",
      });
      return;
    }

    if (!checkDevice.length && checkAlarm.length) {
      Utils.notify({
        message: Language.translate(
          "You need to check Assets if you want Alarms."
        ),
        header: "Roles",
        type: "error",
      });
      return;
    }

    if (checkGeofence.length && (!checkDevice.length || !checkAlarm.length)) {
      Utils.notify({
        message: Language.translate(
          "You need to check Assets and Alarms if you want Geofence."
        ),
        header: "Roles",
        type: "error",
      });
      return;
    }

    if (checkAlert.length && !checkDevice.length) {
      Utils.notify({
        message: Language.translate(
          "You need to check Assets if you want Alert Rules."
        ),
        header: "Roles",
        type: "error",
      });
      return;
    }

    if (checkGroup.length > 0 && group[0] !== checkGroup[0]) {
      Utils.notify({
        message: Language.translate(
          "Select All Actions for GROUPS permissions to enable GROUPS usage"
        ),
        header: "Roles",
        type: "error",
      });
      return;
    }

    if (!_.isEmpty($scope.editObj.permissions)) {
      return super.save();
    }

    return Utils.confirm(
      "You did not select any action for this role. Do you wish to continue?",
      function (confirmed) {
        if (confirmed) {
          Menu.prototype.save.call($this);
        }
      }
    );
  }
  /**
   * Get all Device ids in Roles and add to array
   */
  deviceIdsInRoles() {
    const perms = Permission.default.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "device") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }
  /**
   * Get all Alarm ids in Roles and add to array
   */
  alarmIdsInRoles() {
    const perms = Permission.default.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "alarm") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  //Get all Geofence ids in Roles and add to array
  geofenceIdsInRoles() {
    const perms = Permission.default.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "geofence") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  alertIdsInRoles() {
    const perms = Permission.default.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "ar") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  groupIdsInRoles() {
    const perms = Permission.default.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "group") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  /**
   * managing the actions that need to be disabled when all is selected.
   */
  manageDisabledActions(perm) {
    var $scope = this.$scope;
    var $timeout = this.$timeout;
    // finding the permission object for the 'all' action
    // corresponding to module of the permission being modified
    var allPerm = _.find(Permission.default.getAllPermissions(), {
      module: perm.module,
      action: "all",
    });

    // nothing to be done if module does not have 'all' action
    if (!allPerm) return;
    if (!allPerm.id)
      throw new Error(
        "Could not obtain the id of 'all' action for module " + perm.module
      );

    // getting all permission IDs for actions other than 'all'
    var otherPermIds = _.map(
      _.filter(Permission.default.getAllPermissions(), function (permission) {
        return permission.module === perm.module && permission.action !== "all";
      }),
      "id"
    );

    if (_.indexOf($scope.editObj.permissions, allPerm.id) > -1) {
      // this is a hack because it won't apply the changes without timeout
      $timeout(function () {
        // deselecting actions other than 'all' when 'all' action is selected.
        // this an extra measure besides disabling to prevent selecting 'all'
        // and other actions of the same module.
        $scope.editObj.permissions = _.difference(
          $scope.editObj.permissions,
          otherPermIds
        );
      }, 0);
    }

    if (allPerm.id === perm.id) {
      _.each(otherPermIds, function (id) {
        if (_.indexOf($scope.editObj.permissions, allPerm.id) > -1) {
          // disabling other actions if 'all' action is selected
          $scope.disabledActions[id] = true;
        } else {
          // enabling other actions if 'all' action is deselected
          delete $scope.disabledActions[id];
        }
      });
    }
  }

  autoSelectAll(perm) {
    var $scope = this.$scope;

    // nothing to be done if action is all
    if (perm.action === "all") return;

    var allPerm = _.find(
      Permission.default.getAllPermissions(),
      function (permission) {
        return permission.module === perm.module && permission.action === "all";
      }
    );

    // nothing to be done if the module does not have 'all' action
    if (!allPerm) return;

    var allPermId = allPerm.id;

    // getting all permission IDs for actions other than 'all'
    var nonAllPermIds = _.map(
      _.filter(Permission.default.getAllPermissions(), function (permission) {
        return (
          permission.module === perm.module &&
          _.indexOf(["all"], permission.action) === -1
        );
      }),
      "id"
    );

    // if every non-"all" action is selected
    if (
      _.intersection(nonAllPermIds, $scope.editObj.permissions).length ===
      nonAllPermIds.length
    ) {
      $scope.editObj.permissions = _.union($scope.editObj.permissions, [
        allPermId,
      ]);
      this.manageDisabledActions({
        id: allPermId,
        module: perm.module,
        action: "all",
      });
    }
  }

  autoSelectView(perm) {
    var $scope = this.$scope;

    // nothing to be done if action is 'view' or 'all'
    if (_.indexOf(["all", "view"], perm.action) > -1) {
      return;
    }

    var viewPerm = _.find(
      Permission.default.getAllPermissions(),
      function (permission) {
        return (
          permission.module === perm.module && permission.action === "view"
        );
      }
    );

    // nothing to be done if the module does not have 'view' action
    if (!viewPerm) return;

    var viewPermId = viewPerm.id;

    // getting all permission IDs for actions other than 'all' and 'view'
    var nonViewPermIds = _.map(
      _.filter(Permission.default.getAllPermissions(), function (permission) {
        return (
          permission.module === perm.module &&
          _.indexOf(["view", "all"], permission.action) === -1
        );
      }),
      "id"
    );

    // getting the list of non view actions that are selected
    var selectedNonViewIds = _.intersection(
      nonViewPermIds,
      $scope.editObj.permissions
    );

    // automatically selecting view and disabling checkbox if a non-view action is selected
    if (selectedNonViewIds.length) {
      $scope.editObj.permissions = _.union($scope.editObj.permissions, [
        viewPermId,
      ]);
      $scope.disabledActions[viewPermId] = true;
    } else {
      // enabling the view if no non-view action is selected.
      delete $scope.disabledActions[viewPermId];
    }
  }
}

export default new PermissionMenu();
