import { default as OlMapOverlay } from "sccOlMapOverlay";

// External
import _ from "lodash";
import olStyleStyle from "ol/style/Style";
import olStyleText from "ol/style/Text";
import olStyleFill from "ol/style/Fill";
import olStyleStroke from "ol/style/Stroke";
import olStyleRegularShape from "ol/style/RegularShape";

// Internal
import UserSetting from "sccUserSetting";
import Historic from "sccHistory";
import Alert from "sccAlert";
import DeviceOverlay from "sccDeviceOverlay";

// the diameter in pixels to use to size the icon
const iconDiameter = 40;
const clusterDiameter = 40;

class HistoryOverlay extends OlMapOverlay.OlMapOverlay {
  constructor(options) {
    super(options);
    this.moduleName = "history_overlay";
  }

  init() {
    this.addOverlay();
  }

  addFeature(dataObj) {
    super.addFeature(dataObj);
    this.refreshFollowedDevices(true);
  }

  /**
   *
   */
  refresh(data) {
    super.refresh(data);
    this.refreshFollowedDevices();
  }

  /**
   * gets the style for showing a feature
   * @param {Object} feature feature that is being styled
   * @return {Object} style to show the feature
   * @override
   */
  getStyle(feature) {
    super.getStyle();
    const features = feature.get("features");
    var size = (features && features.length) || 1;

    if (Number(size) === 1) {
      const f = features ? features[0] : feature;
      return getSingularStyle(f);
    }

    return getClusterStyle(features);
  }

  /**
   * gets cluster list information
   * @return {array}
   * @override
   */
  getFeatureData() {
    return Historic.getCurrentDeviceReports();
  }

  /**
   * gets the style for select icon and selected feature
   * @param {Object} feature clone of the selected feature
   * @return {Object} style to show the feature
   * @override
   */
  getSelectStyle(feature) {
    // if feature has id, then it is the selected feature and must be
    // styled as before.
    // if feature does not have id, then it is the select icon
    if (feature.getId()) {
      return getSingularStyle(feature);
    }

    const fill = new olStyleFill({ color: [0, 0, 128, 0.2] });
    const stroke = new olStyleStroke({
      color: "black",
      lineDash: [7, 7],
      width: 2,
    });
    const iconStyle = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: fill,
        stroke: stroke,
        points: 4,
        radius: getSelectIconRadius(),
        angle: Math.PI / 4,
      }),
    });
    return iconStyle;
  }

  /**
   * opens the history module with the device selected
   * @param {Object} device device information object
   */
  openAssetInHistory(device) {
    History.openWindowLoadAsset(device.imei, device.name);
  }

  /**
   * makes sure devices that must be followed are visible on the map
   */
  refreshFollowedDevices(forceZoomIn) {
    const deviceIds = Historic.getFollowedDevices();
    if (!deviceIds.length) return;

    if (forceZoomIn) {
      this.centerAndZoomTo(deviceIds);
      return;
    }

    const olExtent = require("ol/extent");
    const mapExtent = this.OlMap.getExtent();

    // build a multipoint geometry from points of followed features to make sure it is on the map
    const geom = this.buildMultiPointGeometry(deviceIds);
    const geomExtent = geom.getExtent();
    // check if geometry is viewable
    if (olExtent.containsExtent(mapExtent, geomExtent)) return;

    // center the geometry to the map
    const center = this.getGeometryCenter(geom);
    this.OlMap.setCenterAndZoom(center);

    // check if geometry if viewable after centering
    if (olExtent.containsExtent(mapExtent, geomExtent)) return;

    // zoom to the extent of geometry
    this.zoomToGeometryExtent(geom);
  }
}

/**
 * gets the radius of selection icon
 */
function getSelectIconRadius() {
  // get the sqrt(2) times the diameter/2 since it is a square
  // adds 2 pixels to avoid intersecting the icon
  return (iconDiameter * Math.SQRT2) / 2 + 2;
}

function getSingularStyle(feature) {
  const deviceId = feature.getId();
  const report = Historic.getCurrentDeviceReports(deviceId);
  const Device = require("sccDevice").default;
  const device = Device.get(report?.device_id);
  const strokeWidth = 4;
  const olStyleCircle = require("ol/style/Circle").default;

  if (report === null) {
    return [];
  } else {
    if (
      _.isNull(device.ale_address) ||
      Device.getDeviceMode(device) != "Gateway Device"
    ) {
      const iconStyle = new olStyleStyle({
        // image: new olStyleIcon(iconOptions),
        image: new olStyleCircle({
          radius: iconDiameter / 2 - strokeWidth,
          stroke: new olStyleStroke({
            color: device.color || "#000",
            width: strokeWidth,
          }),
          fill: new olStyleFill({
            color: [255, 255, 255, 1],
          }),
        }),
        text: new olStyleText({
          font: "16px Courier New,Calibri,sans-serif",
          fill: new olStyleFill({ color: "#fff" }),
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
            width: 5,
          }),
          textAlign: "left",
          offsetX: iconDiameter / 2,
          offsetY: iconDiameter / 2,
          text: getIconText(device),
        }),
      });

      const style2 = new olStyleStyle({
        text: new olStyleText({
          font: DeviceOverlay.getHeadingFontExternal(report?.speed),
          text: DeviceOverlay.getHeadingTextExternal(report?.speed),
          fill: new olStyleFill({
            color: getTextOutlineColor(report),
          }),
          rotation: DeviceOverlay.getHeadingRotationExternal(
            report?.heading,
            report?.speed
          ),
          rotateWithView: getIconRotateWithView(),
        }),
      });
      return [iconStyle, style2];
    } else {
      const outerCircleWidth = 2;
      const innerCircleRadius = 8;
      const innerCircleWidth = 3;
      const squareDisplacementValue = 12;

      const outerCircle = new olStyleStyle({
        image: new olStyleCircle({
          radius: iconDiameter / 2 - outerCircleWidth,
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
            width: outerCircleWidth,
          }),
          fill: new olStyleFill({
            color: [255, 255, 255, 1],
          }),
        }),
        text: new olStyleText({
          font: "16px Courier New,Calibri,sans-serif",
          fill: new olStyleFill({ color: "#fff" }),
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
            width: 5,
          }),
          textAlign: "left",
          offsetX: iconDiameter / 2,
          offsetY: iconDiameter / 2,
          text: text,
        }),
      });

      const innerCircle = new olStyleStyle({
        image: new olStyleCircle({
          radius: iconDiameter / 2 - innerCircleRadius,
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
            width: innerCircleWidth / 2,
          }),
          fill: new olStyleFill({
            color: [255, 255, 255, 1],
          }),
        }),
      });

      const topSquare = new olStyleStyle({
        image: new olStyleRegularShape({
          fill: new olStyleFill({
            color: getTextOutlineColor(report),
          }),
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
          }),
          points: 4,
          radius: 4,
          angle: Math.PI / 4,
          displacement: [0, squareDisplacementValue],
        }),
      });

      const rightSquare = new olStyleStyle({
        image: new olStyleRegularShape({
          fill: new olStyleFill({
            color: getTextOutlineColor(report),
          }),
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
          }),
          points: 4,
          radius: 4,
          angle: Math.PI / 4,
          displacement: [squareDisplacementValue, 0],
        }),
      });

      const leftSquare = new olStyleStyle({
        image: new olStyleRegularShape({
          fill: new olStyleFill({
            color: getTextOutlineColor(report),
          }),
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
          }),
          points: 4,
          radius: 4,
          angle: Math.PI / 4,
          displacement: [squareDisplacementValue * -1, 0],
        }),
      });

      const bottomSquare = new olStyleStyle({
        image: new olStyleRegularShape({
          fill: new olStyleFill({
            color: getTextOutlineColor(report),
          }),
          stroke: new olStyleStroke({
            color: getTextOutlineColor(report),
          }),
          points: 4,
          radius: 4,
          angle: Math.PI / 4,
          displacement: [0, squareDisplacementValue * -1],
        }),
      });

      if (device.speed > 0) {
        const arrow = new olStyleStyle({
          text: new olStyleText({
            font: "normal 18px FontAwesome",
            text: DeviceOverlay.getHeadingText(report.speed),
            fill: new olStyleFill({
              color: getTextOutlineColor(report),
            }),
            rotation: DeviceOverlay.getHeadingRotation(
              report.heading,
              report.speed
            ),
            rotateWithView: getIconRotateWithView(),
          }),
        });

        return [
          outerCircle,
          innerCircle,
          arrow,
          topSquare,
          rightSquare,
          leftSquare,
          bottomSquare,
        ];
      } else {
        const centerSquare = new olStyleStyle({
          image: new olStyleRegularShape({
            fill: new olStyleFill({
              color: getTextOutlineColor(report),
            }),
            stroke: new olStyleStroke({
              color: getTextOutlineColor(report),
            }),
            points: 4,
            radius: 9,
            angle: Math.PI / 4,
          }),
        });

        const cross = new olStyleStyle({
          image: new olStyleRegularShape({
            fill: new olStyleFill({
              color: getTextOutlineColor(report),
            }),
            stroke: new olStyleStroke({
              color: getTextOutlineColor(report),
            }),
            points: 4,
            radius: 12,
            radius2: 0,
            angle: 0,
          }),
        });

        return [
          outerCircle,
          innerCircle,
          cross,
          centerSquare,
          topSquare,
          rightSquare,
          leftSquare,
          bottomSquare,
        ];
      }
    }
  }
}

/**
 * get whether or not the icon needs to rotate when map rotates
 * @return {Boolean} true if icon needs to rotate and false otherwise
 */
function getIconRotateWithView() {
  return true;
}

/**
 * gets the icon image to be shown when features are clustered
 * @param {Array} features list of features in the cluster
 * @return {String} string representation of the image
 */
function getClusterColor(features) {
  let imageTag;
  let currentPriority = 1000;
  _.each(features, function (feature) {
    const deviceId = feature.getId();
    const report = Historic.getCurrentDeviceReports(deviceId);
    const newTag = getAlertTag(report);
    const newAlertPriority = Alert.getAlertPriority(newTag);
    if (!imageTag || currentPriority > newAlertPriority) {
      currentPriority = newAlertPriority;
      imageTag = newTag;
    }
  });
  const color = require("ol/color");
  return color.asArray(Alert.getAlertColor(imageTag));
}

/**
 * returns the style for showing clustered features
 * @param {Array} features list of features in the cluster
 * @return {Object} cluster style
 */
function getClusterStyle(features) {
  var size = features.length;
  const olStyleCircle = require("ol/style/Circle").default;
  const strokeWidth = 2;

  var style = new olStyleStyle({
    image: new olStyleCircle({
      radius: clusterDiameter / 2 - strokeWidth,
      stroke: new olStyleStroke({
        color: "white",
        width: strokeWidth,
      }),
      fill: new olStyleFill({
        color: getClusterColor(features),
      }),
    }),
    text: new olStyleText({
      font: "15px Calibri,sans-serif",
      text: size.toString(),
      fill: new olStyleFill({
        color: "#fff",
      }),
    }),
  });

  return [style];
}

/**
 * gets the emergency tag to be used to fetch respective image from the collection
 * @param {Number} report report object
 * @return {String} image tag for collection
 */
function getAlertTag(report) {
  let imageTag = "Normal";

  //var test = Historic.hasAlert(report, "vehicle");
  //log.info("=================== hasAlert 1 :", test);

  if (Historic.hasAlert(report, "emergency")) {
    imageTag = "Emergency";
  } else if (Historic.hasAlert(report, "geofence")) {
    imageTag = "Geofence";
  } else if (Historic.hasAlert(report, "speed")) {
    imageTag = "Speed";
  } else if (Historic.hasAlert(report, "vehicle")) {
    imageTag = "Vehicle";
  } else if (Historic.hasAlert(report, "cargo")) {
    imageTag = "Cargo";
  } else if (Historic.hasAlert(report, "vehicle")) {
    imageTag = "Vehicle";
  } else if (Historic.hasAlert(report, "non_report")) {
    imageTag = "Non-Report";
  }

  return imageTag;
}

/**
 * gets the alert color to be used for text outline
 * @param {Number} report
 * @return {String} hex representation of the alert color
 */
function getTextOutlineColor(report) {
  return Alert.getAlertColor(getAlertTag(report));
}

/**
 * gets the text to be shown on the device icon
 * @param {Object} device device object
 * @return {String} text at device position
 */
function getIconText(device) {
  let deviceName = "";
  if (UserSetting.get("asset_label")) {
    deviceName = device.name;
  }

  let annotation = "";
  let separatorLine = "";
  if (UserSetting.get("asset_annotation")) {
    annotation = device.annotation;
    separatorLine = annotation !== "" && deviceName !== "" ? "\n\n" : "";
  }

  return deviceName + separatorLine + annotation;
}

export default new HistoryOverlay({
  OlMap: require("sccOlMapHistory").default,

  id: "history_overlay",

  // overlay title
  title: "History",

  // whether to show the layer in layer switcher
  noSwitcherOption: true,

  zIndex: 150,

  // whether or not features of the layer are selectable
  isSelectable: true,

  /**
   * Contains clustering options and if provided layer would be clustered.
   */
  cluster: {
    popupContainerId: "olMapHistoryClusterPopup",
    offset: [iconDiameter / 2 + 5, -(35 + iconDiameter / 2)],
  },

  /**
   * Provides info for the popup window.
   * Popup would be disabled if this property is not provided
   */
  popup: {
    containerId: "olMapHistoryPopup",
    offset: [iconDiameter / 2 + 5, -(35 + iconDiameter / 2)],
  },
});
