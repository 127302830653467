import olMap from "ol/Map";
import _ from "lodash";
import AlertMapBaseLayer from "./AlertMapBaseLayer";
import { defaults as defaultControls } from "ol/control";
const olView = require("ol/View").default;
const olProj = require("ol/proj");
const olLayerGroup = require("ol/layer/Group").default;
const olStyle = require("ol/style/IconImageCache").shared;
const Language = require("sccLanguage").default;
const Utils = require("sccUtils").default;

olStyle.setSize(1000);
const minZoom = 3;
const maxZoom = 19;
const defaultViewOptions = {
  center: [0, 0],
  zoom: 3,
  extent: olProj.get("EPSG:3857").getExtent(),
  minZoom: minZoom,
  maxZoom: maxZoom,
  constrainResolution: true,
};

// Set the localized titles
let baseMapTitle = Language.translate("Base Maps");
let overlaysTitle = Language.translate("Overlays");

class AlertMenuMap {
  constructor(options) {
    this.moduleName = "map";
    this._activeLayers = {};
    this.options = options;
    this.map = null;
    this.overlays = null;
    this.baseLayers = null;
    this.mapQuickZoomInLevel = 16;
    this.evtType = null;
    this.editMode = null;
    this.currentMapsAPI = null;
    this.searchCoordMarkerOverlay = null;
    // holds functions to run upon moveend event of the map
    this.moveEndEvents = {};

    // holds custom click event functions
    this.clickEvents = {};

    // holds functions to run upon zoom change event of the map
    this.zoomChangeEvents = {};
  }

  static thePublicImage = null;

  init($scope) {
    if ($scope) {
      this.$scope = $scope;
      $scope.OlMap = this;
    }
    // window.googleMapsAPIloaded = false;
    // const googleMapLicenseInit = require("sccGoogleMapInit");
    const $this = this;
    window.alertMap = this;
    // googleMapLicenseInit.initGoogleMapLicense();
    return this.loadActiveLayers()
      .then(function () {
        return $this.initMaps();
      })
      .catch((err) => {
        throw new Error(err);
      });
    // return $this.initMaps();
  }

  /**
   * gets the active map layers for the current user
   *
   */
  loadActiveLayers() {
    const options = {
      url: Utils.apiUrlPrefix + "/map_layer",
      method: "GET",
      data: {},
    };

    const $this = this;
    return Utils.httpRequestHandler(options).then(function (response) {
      $this._activeLayers = response.data;
      return $this._activeLayers;
    });
  }

  /**
   * transforms "EPSG:4326" coordinate format to "EPSG:3857"
   * @param {Array} coord coordinate array
   * @return {Array} new coordinate
   */
  transformToMapCoordinate(coord) {
    return olProj.transform(coord, "EPSG:4326", "EPSG:3857");
  }

  initMaps() {
    const $this = this;
    this.overlays = new olLayerGroup({
      title: overlaysTitle,
      layers: [],
    });

    this.baseLayers = new olLayerGroup({
      title: baseMapTitle,
      layers: [],
    });

    window.alertMap = this;

    this.map = new olMap({
      layers: [$this.baseLayers, $this.overlays],
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
      view: new olView(defaultViewOptions),
      target: $this.options.mapDiv,
      controls: defaultControls({ attribution: false }),
    });

    this.BaseLayer = new AlertMapBaseLayer({
      map: this.map,
      baseLayers: this.baseLayers,
    });

    this.BaseLayer.init().then(() => {
      window.alertMap.loadMapLayers();
    });

    this.setupAttributionControl();

    return Promise.resolve();
  }

  /**
   * adds custom vector overlay layer to the map
   * @param {Object} overlay the overlay layer to be added to the map
   */
  addCustomOverlay(overlay) {
    var pushToOverlays = function (overlay) {
      var overlaysLayer = window.alertMap.overlays.getLayers();
      overlaysLayer.push(overlay);
    };

    this.handleOverlayObjWhenOlMapLoaded(overlay, pushToOverlays); //pushes overlay object to overlays array through an async timeout loop
  }

  handleOverlayObjWhenOlMapLoaded(overlay, func) {
    // When loading an extended map or a history map,
    // due to async loading of modules, sometimes the "addCustomOverlay" and "addMapOverlay" methods return an error since
    // this object (olMap) and its appropriate field variable objects haven't been fully initialized.
    // Therefore, this method executes the tasks pertaining to the overlay object once the olmap object and its field objects
    // have been fully initialized.

    if (window.alertMap && !window.alertMap.overlays) {
      //calls itself
      //every 50 milliseconds until olMap's overlays field has initialized appropriately.
      setTimeout(function () {
        window.alertMap.handleOverlayObjWhenOlMapLoaded(overlay, func);
      }, 50);
    } else {
      func(overlay);
    }
  }

  /**
   * sets map center and zoom level programatically
   * @param {Array} coord center coordinate
   * @param {Number} zoom zoom level
   */
  setCenterAndZoom(coord, zoom) {
    const view = window.alertMap.map.getView();
    if (!view) {
      throw new Error("could not get view object of the map");
    }
    if (!zoom)
      //if zoom not passed, keeps current zoom
      zoom = window.alertMap.getZoom();

    view.animate({
      center: coord,
      zoom: zoom,
      duration: 1000,
    });
  }

  setupAttributionControl() {
    if (!this.options.attributionDisplay) return;
    const elementId = this.options.attributionDisplay.elementId;
    const olAttribution = require("ol/control/Attribution").default;
    const attribution = new olAttribution({
      collapsible: false,
      target: document.getElementById(elementId),
    });
    window.alertMap.map.addControl(attribution);
  }

  loadMapLayers() {
    const $this = this;
    _.each(this._activeLayers.result, function (layer) {
      if (layer.is_base_layer) {
        $this.BaseLayer.add(layer);
      } else {
        //$this.addOverlay(layer.code);
      }
    });

    $this.BaseLayer.setDefaultMap(); //check if there is a default map to display, if not assign one
  }

  /**
   * adds a marker layer and markers at the locations provided
   * @param {Array} coordinates coordinates collection
   * @param {*} center center of the map to be set
   * @param {*} zoom zoom level to be set
   */
  addMarker(coordinates, center, zoom) {
    const $this = this;
    const olFeature = require("ol/Feature").default;
    const olGeomPoint = require("ol/geom/Point").default;
    const iconFeatures = _.map(coordinates, (coord) => {
      const newCoord = $this.transformToMapCoordinate(coord);
      return new olFeature({
        geometry: new olGeomPoint(newCoord),
      });
    });

    const olLayerVector = require("ol/layer/Vector").default;
    const olSourceVector = require("ol/source/Vector").default;
    const vectorSource = new olSourceVector({
      features: iconFeatures, //add an array of features
    });
    const olStyleStyle = require("ol/style/Style").default;
    const olStyleStroke = require("ol/style/Stroke").default;
    const olStyleFill = require("ol/style/Fill").default;
    const olStyleRegularShape = require("ol/style/RegularShape").default;
    const stroke = new olStyleStroke({ color: "black", width: 2 });
    const fill = new olStyleFill({ color: "red" });
    const style = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: fill,
        stroke: stroke,
        points: 4,
        radius: 10,
        radius2: 3,
        angle: 0,
      }),
    });
    const vectorLayer = new olLayerVector({
      source: vectorSource,
      style: style,
    });
    this.addCustomOverlay(vectorLayer);
    this.setCenterAndZoom(this.transformToMapCoordinate(center), zoom);
  }
}

export default new AlertMenuMap({
  attributionDisplay: {
    elementId: "alertMapAttrDisplay",
  },
  mapDiv: "alertMapView",
  layerSwitcherDisplay: {
    target: "alertMapLayerSwitcher",
    tipLabel: "Map Layers", // Optional label for button
  },
});
