import React, { useEffect, useState, useContext } from "react";

// Material-UI
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Load from "sccLoad";
import Permission from "sccPermission";
import EnvVar from "sccEnvVar";
import Images from "sccImage";
import Language from "sccLanguage";
import Device from "sccDevice";
import DeviceMenu from "sccDeviceMenu";
import Profile from "sccProfile";
import UserSetting from "sccUserSetting";
import Alert from "sccAlert";
import AlertMenu from "sccAlertMenu";
import Group from "sccGroup";
import Socket from "sccSocket";
import Reports from "sccReport";
import Clock from "sccClock";
import PermissionMenu from "sccPermissionMenu";
import Report from "../../report/components/";
import CustomButton from "@Components/CustomButton";
// import { AppContext } from "../../../AppContext";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    zIndex: 50000,
    top: 20,
    left: "0",
    width: "102%",
    height: "100%",
    backgroundColor: theme.palette.colors.blue.dark,
    padding: theme.spacing(1),
    color: theme.palette.colors.white.main,
  },

  logo: {
    width: "200px",
  },
}));

function ReportMain() {
  window.Promise = require("bluebird");

  const [resolvedModuleCount, setResolvedModuleCount] = useState(0);
  const [totalModuleCount, setTotalModuleCount] = useState(0);
  const [initialized, setInitialized] = useState(Load.initialized);
  const [openModel, setOpenModel] = useState(true);
  const [refreshText] = useState(Language.translate("Refresh"));
  const [mwState] = useContext(ModuleWrapperContext);
  const classes = useStyles();

  let $scope = mwState.$scope;

  var sequencedInit = [
    {
      module: Permission,
      dependencies: [],
    },
    {
      module: EnvVar,
      dependencies: [],
    },
    {
      module: Language,
      dependencies: [],
    },
    {
      module: Images,
      dependencies: [],
    },
    {
      module: UserSetting,
      dependencies: [],
    },
    {
      module: Profile,
      dependencies: [],
    },
    {
      module: { moduleName: "permission_menu" },
      dependencies: ["permission"],
      initFunc: function () {
        return PermissionMenu.init($scope);
      },
    },
    {
      module: Clock,
      dependencies: ["user_setting"],
    },
    {
      module: Device,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "device_menu" },
      dependencies: ["device"],
      initFunc: function () {
        return DeviceMenu.init($scope);
      },
    },
    {
      module: Alert,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "alert_menu" },
      dependencies: ["alert"],
      initFunc: function () {
        return AlertMenu.init($scope);
      },
    },
    {
      module: Reports,
      dependencies: ["permission", "device"],
    },
    {
      module: History,
      dependencies: ["permission"],
    },
    {
      module: Group,
      dependencies: ["device", "env_var"],
    },
    {
      module: Socket,
      dependencies: ["device"],
    },
  ];
  const loader = (dependencies, module, initFunc) => {
    return new Promise((resolve) => {
      Load.inject(dependencies, module, initFunc);
      resolve(module);
    });
  };

  const startTime = Date.now();
  const doNextPromise = (index) => {
    loader(
      sequencedInit[index].dependencies,
      sequencedInit[index].module,
      sequencedInit[index].initFunc
    ).then((module) => {
      index++;
      if (index < sequencedInit.length) {
        doNextPromise(index);
      } else
        console.log(`@@@Total: ${(Date.now() - startTime) / 1000} seconds.`);
    });
  };

  const updateLoaderStats = () => {
    const updateInterval = setInterval(() => {
      setTotalModuleCount((p) => Load.totalModuleCount);
      setResolvedModuleCount((p) => Load.resolvedModuleCount);
      if (
        Load.totalModuleCount === Load.resolvedModuleCount &&
        Load.totalModuleCount > 10
      ) {
        setInitialized(true);
        clearInterval(updateInterval);
      }
    }, 100);
  };

  useEffect(() => {
    doNextPromise(0);
    Load.resolve();
    updateLoaderStats();
  }, []);

  const handleClose = () => {
    setOpenModel(false);
  };

  return (
    <>
      {!initialized && (
        <Modal open={openModel} onClose={handleClose}>
          <Grid
            container
            spacing={5}
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.paper}
          >
            <Grid item>
              <CircularProgress color="inherit" size="15rem" thickness={5} />
            </Grid>
            <Grid item>
              <img
                className={classes.logo}
                src={
                  Images.getImageCollection("platform_images").scc_titan_logo
                    .default
                }
                alt="TITAN BY NORTAC"
              />
            </Grid>
            <Grid item>
              <p>
                {Language.translate("Loading")}&nbsp;
                {resolvedModuleCount}&nbsp;
                {Language.translate("of")}&nbsp;
                {totalModuleCount}&nbsp;{Language.translate("Modules")}
              </p>
            </Grid>
            <Grid container item justifyContent="flex-end">
              <CustomButton
                size="large"
                color="cancel"
                id="btnRefresh"
                variant="contained"
                type="submit"
              >
                {refreshText}
              </CustomButton>
            </Grid>
          </Grid>
        </Modal>
      )}
      {initialized && <Report initialized={initialized} />}
    </>
  );
}

export default ReportMain;
