import React, { useState, useContext } from "react";

// external
import _ from "lodash";

// Material-UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import SyncIcon from "@material-ui/icons/Sync";
import { makeStyles, alpha } from "@material-ui/core/styles";

// Internal
import Language from "sccLanguage";
import Sync from "sccSync";
import User from "sccUser";
import Device from "sccDevice";
import Group from "sccGroup";
import TimeUtils from "sccTimeUtils";
import CustomIcon from "@Components/CustomIcon";
import AssetSelectorDisplay from "@Components/CustomAssetWrapper/AssetSelectorListingDisplay/AssetSelectorDisplay";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  abDetails: {
    padding: "20px",
    "& .table": {
      marginTop: "20px",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "6px",
      paddingRight: "6px",
    },
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiListItemText-root": {
      marginTop: "0",
      marginBottom: "0",
    },
    "& .ABInfoListItemText-first": {
      background: theme.palette.colors.gray.main,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "50%",
      marginTop: "-1px",
    },
    "& .ABInfoListItemText-second": {
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "50%",
      marginTop: "-1px",
      marginLeft: "-1px",
    },
    "& .subscriberDevice:hover + .lastSyncedTime": {
      display: "block",
    },
    "& .lastSyncedTime": {
      display: "none",
    },
  },

  subscriberName: {
    display: "flex",
    alignItems: "center",
  },

  syncedChip: {
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.green.main,
    borderColor: theme.palette.colors.green.main,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
    marginLeft: "4px",
  },

  refreshChip: {
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.blue.main,
    borderColor: theme.palette.colors.blue.main,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
    marginLeft: "4px",
  },

  syncIcon: {
    color: theme.palette.colors.white.main,
    width: 12,
    height: 12,
    marginRight: 4,
  },

  pendingIcon: {
    color: theme.palette.colors.white.main,
    width: 12,
    height: 12,
    marginRight: 4,
  },

  pendingChip: {
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.orange.main,
    borderColor: theme.palette.colors.orange.main,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
    marginLeft: "4px",
  },

  lastSynced: {
    color: alpha(theme.palette.colors.black.main, 0.5),
    backgroundColor: theme.palette.colors.gray.dark,
    padding: "4px 0px 4px 4px",
    width: 76,
  },

  lastSyncedTime: {
    color: alpha(theme.palette.colors.black.main, 0.5),
  },

  lastSyncGrid: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "4px",
  },

  subscriberContainer: {
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    marginTop: 4,
    padding: 4,
    fontSize: 14,
  },

  noUser: {
    fontSize: 14,
    textAlign: "center",
    padding: "4px 4px 0px 4px",
  },
}));

export default function ABDetail(props) {
  const data = props.data;
  const [value, setValue] = useState(0);
  const [memberTabValue, setMemberTabValue] = useState(0);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const buildCollectionToDisplay = assetSelectorState.buildCollectionToDisplay;

  function handleTabs(e, val) {
    setValue(val);
  }

  function handleMemberTabValue(e, val) {
    setMemberTabValue(val);
  }

  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }
  // Get sync status on subscriber
  const getSyncStatus = (deviceId) => {
    switch (Sync.get(deviceId).status) {
      case "synced":
        return (
          <div className={classes.syncedChip}>
            <DoneIcon className={classes.syncIcon} />
            {Language.translate("Up-to-date")}
          </div>
        );
      case "syncing":
        return (
          <div className={classes.refreshChip}>
            <SyncIcon className={classes.syncIcon} />
            {Language.translate("Syncing")}
          </div>
        );
      default:
        return (
          <div className={classes.pendingChip}>
            <CircularProgress size={12} className={classes.syncIcon} />
            {Language.translate("Pending")}
          </div>
        );
    }
  };

  const getMembersGroup = (groups) => {
    if (groups?.length > 0) {
      const newGroups = [];

      if (groups?.length > 0) {
        groups.forEach((groupId) => {
          if (Group.get(groupId)) {
            newGroups.push(Group.get(groupId));
          }
        });
      }
      return groups.map((groupId) => Group.get(groupId));
    }
    return [];
  };

  const totalDevicesFromGroups = (groupArray, deviceArray) => {
    let devicesFromGroups = [];
    let devicesFromDevices = [];
    if (groupArray.length > 0) {
      groupArray.forEach((groupId) => {
        if (Group.get(groupId)?.devices) {
          _.each(Group.get(groupId)?.devices, (deviceId) => {
            if (Device.get(deviceId)) {
              devicesFromGroups.push(deviceId);
            }
          });
        }
      });
    }

    if (deviceArray.length > 0) {
      deviceArray.forEach((deviceId) => {
        if (Device.get(deviceId)) {
          devicesFromDevices.push(deviceId);
        }
      });
    }

    return _.uniq(_.union(_.flatten(devicesFromGroups), devicesFromDevices));
  };

  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.abDetails}>
      <Tabs
        value={value}
        onChange={handleTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          label={Language.translate("Information")}
          icon={<CustomIcon style={{ fontSize: 9 }} id="icon-info-circle" />}
        />

        <Tab
          label={Language.translate("Subscribers")}
          icon={
            <Badge
              color="primary"
              badgeContent={
                Device.getAvaiableDevices(data.subscribers?.devices)?.length > 0
                  ? Device.getAvaiableDevices(data.subscribers?.devices).length
                  : 0
              }
            >
              <CustomIcon style={{ fontSize: 9 }} id="icon-group" />
            </Badge>
          }
        />
        <Tab
          label={Language.translate("Members")}
          icon={
            <Badge
              color="primary"
              badgeContent={
                data.members?.users?.length +
                  totalDevicesFromGroups(
                    data?.members?.groups || [],
                    data?.members?.devices || []
                  ).length || 0
              }
            >
              <CustomIcon style={{ fontSize: 9 }} id="icon-group" />
            </Badge>
          }
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <List>
          <ListItem>
            <ListItemText
              md={6}
              className="ABInfoListItemText-first"
              primary={Language.translate("Title").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ABInfoListItemText-second"
              primary={data.title}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ABInfoListItemText-first"
              primary={Language.translate("Note").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ABInfoListItemText-second"
              primary={data.note}
            />
          </ListItem>
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          {Device.getAvaiableDevices(data.subscribers?.devices)?.length > 0 ? (
            Device.getAvaiableDevices(data.subscribers.devices).map(
              (deviceId) => {
                const device = Device.get(deviceId);
                if (device) {
                  return (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.subscriberContainer}
                      key={deviceId}
                    >
                      <Grid item xs={8} className={classes.subscriberName}>
                        {device.name} {getSyncStatus(deviceId)}
                      </Grid>

                      <Grid item container xs={4} alignItems="center">
                        <Grid item xs={6} className={classes.lastSyncGrid}>
                          {Sync.get(deviceId)?.last_synced_timestamp
                            ? TimeUtils.getAge(
                                Sync.get(deviceId)?.last_synced_timestamp / 1000
                              )
                            : "N/A"}
                        </Grid>
                        <Grid item xs={6} className={classes.lastSyncedTime}>
                          <div className={classes.lastSynced}>
                            {Language.translate("Last Synced") + ":"}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } else return null;
              }
            )
          ) : (
            <div className={classes.noUser}>
              {Language.translate("There are no Subscribers")}
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
          <Tabs
            value={memberTabValue}
            onChange={handleMemberTabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={Language.translate("Users")}
              style={{ fontSize: 9 }}
              icon={
                <Badge
                  badgeContent={
                    data.members?.users?.length > 0
                      ? data.members?.users?.length
                      : 0
                  }
                  color="primary"
                >
                  <CustomIcon style={{ fontSize: 9 }} id="icon-laptop" />
                </Badge>
              }
            />
            <Tab
              label={Language.translate("Assets")}
              style={{ fontSize: 9 }}
              icon={
                <Badge
                  badgeContent={
                    totalDevicesFromGroups(
                      data?.members?.groups || [],
                      data?.members?.devices || []
                    ).length || 0
                  }
                  color="primary"
                >
                  <CustomIcon style={{ fontSize: 9 }} id="icon-group" />
                </Badge>
              }
            />
          </Tabs>
          <TabPanel value={memberTabValue} index={0}>
            <div>
              {data.members?.users?.length > 0 ? (
                data.members?.users?.map((userId) => {
                  const user = User.get(userId);

                  return (
                    <div className={classes.subscriberContainer} key={userId}>
                      {user.username}
                    </div>
                  );
                })
              ) : (
                <div className={classes.noUser}>
                  {Language.translate("There are no users in Members")}
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={memberTabValue} index={1}>
            <div>
              {Group.getDeviceCount(data.members) !== 0 ? (
                <AssetSelectorDisplay
                collection={{
                  groups: [buildCollectionToDisplay(data.members)],
                }}
                name="members"
              />
              ) : (
                <div className={classes.noUser}>
                  {Language.translate("There are no Assets in Members")}
                </div>
              )}
            </div>
          </TabPanel>
        </div>
      </TabPanel>
    </Grid>
  );
}
