import React, { useContext } from "react";

// Material-UI
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import ARDetail from "./ARDetail";
import { moduleListing } from "@Styles/ModuleListing";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import Language from "sccLanguage";

function ARList(props) {
  const data = props.data;
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const [mwState] = useContext(ModuleWrapperContext);
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const useStyles = makeStyles((theme) => ({
    ...moduleListing(theme),
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={`${classes.listItem} ${data.enabled && classes.enabled}`}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={data.title}
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {mwState.verifyEditPermission && (
            <CustomTooltip
              title={Language.translate("Edit")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleEdit(data)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {mwState.verifyDeletePermission && (
            <CustomTooltip
              title={Language.translate("Delete")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() =>
                  handleItemDelete(
                    `${mwState.routeUrl}/${data.id}`,
                    mwState.title,
                    mwState.moduleName,
                    data.title
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <ARDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}

export default ARList;
