import React, { useContext } from "react";
import clsx from "clsx";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
import TabletMacIcon from "@material-ui/icons/TabletMac";
import MenuIcon from "@material-ui/icons/Menu";

// Internal
import Language from "sccLanguage";
import { HistoryContext } from "./HistoryContext";
import Notification from "./Notification";
import Map from "../../map/components/Map/index.js";
import GoogleMap from "../../map/components/GoogleMap";
import LeftPanel from "../components/LeftPanel/";
import RightPanel from "../components/RightPanel/";
import BottomPanel from "./BottomPanel";
import CustomDrawer from "../../../reusable-components/Drawer/CustomDrawer";
import MapBottomLeft from "../../map/components/MapBottomLeftScale";

const useStyles = makeStyles((theme) => ({
  show: {
    visibility: "visible",
  },
  paper: {
    position: "fixed",
    zIndex: 50000,
    top: 20,
    left: "0",
    width: "102%",
    height: "100%",
    backgroundColor: alpha(theme.palette.colors.black.main, 0.85),
    padding: theme.spacing(8),
    color: theme.palette.colors.white.main,
  },

  logo: {
    width: "200px",
  },
  openerButton: {
    border: `1px solid ${theme.palette.colors.gray.main}`,
    borderRadius: 0,
    backgroundColor: theme.palette.colors.white.main,
    boxShadow:
      `0px 3px 3px -2px ${alpha(theme.palette.colors.black.main, 0.80)}, ` +
      `0px 3px 4px 0px ${alpha(theme.palette.colors.black.main, 0.86)}, ` +
      `0px 1px 8px 0px ${alpha(theme.palette.colors.black.main, 0.88)}`,
    position: "absolute",
    zIndex: 10,
    top: 0,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.light,
    },
  },
  openerLeftPanel: {
    left: 0,
  },
  openerRightPanel: {
    right: 8,
  },
  hide: {
    visibility: "hidden",
  },
  badge: {
    "& .MuiBadge-badge": {
      fontSize: "1.25rem",
      color: theme.palette.colors.white.main,
      backgroundColor: theme.palette.colors.green.dark,
    },
  },

  rootHistory: {
    ".MapComponent": {
      height: "calc(100% - 0px)",
      "&.active": {
        height: "calc(100% - 70px)",
      },
    },
  },

}));

export default function History() {
  const [state] = useContext(HistoryContext);

  const showLeftPanel = state.showLeftPanel;
  const showRightPanel = state.showRightPanel;
  const notificationOptions = state.notificationOptions;
  const showNotification = state.showNotification;
  const updateKeysInState = state.updateKeysInState;
  const hideNoEvents = state.hideNoEvents;
  const Historic = state.Historic;
  const eventsDatasetValid = state.eventsDatasetValid;
  const showHistoryControls = state.showHistoryControls;

  const selectedDevicesLength = Object.keys(Historic.playOptions.device).length;

  const classes = useStyles();

  return (
    <><div className={classes.rootHistory}>
      <CustomDrawer
        titleIcon={<HistoryIcon fontSize="large" />}
        title={Language.translate("History")}
        open={showLeftPanel}
        height={showHistoryControls ? "calc(100% - 70px)" : "100%"}
        onCloseClick={() => {
          updateKeysInState({ showLeftPanel: false });
        }}
        component={<LeftPanel />}
      />
      <IconButton
        className={clsx(
          classes.openerButton,
          classes.openerLeftPanel,
          showLeftPanel && classes.hide
        )}
        onClick={() => {
          updateKeysInState({ showLeftPanel: true });
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <CustomDrawer
        titleIcon={<TabletMacIcon fontSize="large" />}
        title={
          selectedDevicesLength === 0 || !eventsDatasetValid
            ? Language.translate("No Results!")
            : Language.translate("Assets")
        }
        errorOnTop={hideNoEvents}
        direction="rtl"
        width="400px"
        height={showHistoryControls ? "calc(100% - 70px)" : "100%"}
        open={showRightPanel}
        onCloseClick={() => {
          updateKeysInState({ showRightPanel: false });
        }}
        component={<RightPanel />}
      />
      <IconButton
        className={clsx(
          classes.openerButton,
          classes.openerRightPanel,
          showRightPanel && classes.hide
        )}
        onClick={() => {
          updateKeysInState({ showRightPanel: true });
        }}
      >
        {selectedDevicesLength === 0 || !eventsDatasetValid ? (
          <TabletMacIcon fontSize="large" />
        ) : (
          <Badge badgeContent={selectedDevicesLength} className={classes.badge}>
            <TabletMacIcon fontSize="large" />
          </Badge>
        )}
      </IconButton>
      <BottomPanel />
      <MapBottomLeft />
      <Map history="1" />
      <GoogleMap />
      <Notification
        options={notificationOptions}
        closeNotification={() => updateKeysInState({ showNotification: false })}
        showNotification={showNotification}
      ></Notification></div>
    </>
  );
}
