import React, { useState, useEffect, useContext } from "react";

// External
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

// Internal
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";
import Logo from "./Logo";
import baseSchema from "../../../validation/BaseValidation";
import { AppContext } from "../../../AppContext";
import Utils from "sccUtils";
import log from "loglevel";
import Language from "sccLanguage";


//return back to homepage
const goToTitan = (e) => {
  e.preventDefault()
  window.location.href = "/";
};

function Register(props) {
  const [appState] = useContext(AppContext);
	let queryParameters = window.location.search;
	if (!queryParameters || queryParameters === "") {
		queryParameters = "?lang=en";
	}
	let languageCode = queryParameters.split("=")[1];
  const schema = Yup.object().shape({
    username: baseSchema(Language).requiredStringMinMax,
    first_name: baseSchema(Language).requiredStringMinMax,
    last_name: baseSchema(Language).requiredStringMinMax,
    email: baseSchema(Language).emailRequired,
    password: baseSchema(Language).requiredStringPassword,
    confirmPassword: baseSchema(Language).requiredStringPassword.oneOf(
      [Yup.ref("password"), null],
      Language.translate("Passwords are not identical")
    ),
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      return true;
    },
  });

  const pageData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const [registerButtontext, setRegisterButtontext] = useState(translateText("Register"));
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [holdTheDisplay, setHoldTheDisplay] = useState(true);

  const { token } = useParams();

  useEffect(() => {
    document.title = translateText("User Registration | TITAN BY NORTAC") + " " + Language.translate("Platform");
    //validating the token and getting initial data
    fetch(Utils.apiUrlPrefix + "/user/register/info/", {
      method: "get",
      headers: { "x-access-token": token}
    })
    .then((res) => {
      setTokenError(res.status !== 200);  //setting the token error, so nothing else works
      setHoldTheDisplay(false)
      return res.json();
    })
    .then((data) => {
      formik.setValues(data.result);	
    })
    .catch((e) => console.log('translating',e));
  }, []);

  const submitRegistration = () => {
    log.info("Registering username and password", JSON.stringify(pageData), token);
    return fetch(Utils.apiUrlPrefix + "/user/register", {
      method: "PUT",
      headers: { "Content-Type": "application/json", "x-access-token": token },
      body: JSON.stringify(pageData),
    })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      log.info("Registered username and password successfully", data);
      let errorRegistering = false;
      if (data.message) {
        errorRegistering = false;
      } else if(data.error === "Validation error: Username is already in use"){
        appState.displaySnackbarMessage({
          title: 'USER REGISTRATION',
          message: 'Username is already in use',
          subMessage : null,
          variant: 'error',
        });
        errorRegistering= true;
      } else if(data.error_info.name){
        appState.displaySnackbarMessage({
          title: 'USER REGISTRATION',
          message: 'An error has occured. Please try again later',
          subMessage : null,
          variant: 'error',
        });
        errorRegistering= true;
      }

      if (errorRegistering) {
        setRegisterButtontext(translateText("Register"));
        return Promise.resolve();
      }
      setRegistrationComplete(true);
      return Promise.resolve();
    })
    .catch((e) => {
      log.info(e)
    });
  };

  function translateText(str) {
    return Language.translateLogin(str, languageCode);
  }

  //function to handle signin button click
  const handleSignIn = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    setRegisterButtontext(translateText("Checking"));
    submitRegistration();
  };

  return (
    <div className="login">
      <Logo translateText={translateText}></Logo>
      {
        holdTheDisplay ? <Grid container item className="messageBox" spacing={0} direction="column" align="center" justify="center">
          <CircularProgress size="3rem" thickness={5} />
        </Grid>
      : 
        tokenError ? (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <p className="message message-failure">
                <h2>{translateText("Oops")}!</h2>
                <h4>404 {translateText("Not Found")}</h4>
                {translateText("Sorry! an error has occured. Requested page not found.")}
              </p>
              <br />
              <br />
            </Grid>
          </Grid>
        ) : registrationComplete ? (
          <form className="loginForm">
          <Grid container direction="column">
            <Grid item>
              <p className="message message-success">
                <h4>
                  {translateText("You Have Successfully Registered")}
                </h4>
                {translateText("Name")}: {pageData.first_name} {pageData.last_name}<br/><br/>
                {translateText("Login Name")}: {pageData.username}
              </p>
            </Grid>
            <Grid item>
              <CustomButton
                fullWidth
                size="large"
                color="command"
                onClick={goToTitan}
                variant="contained"
                type="submit"
              >
                {translateText("Login to TITAN BY NORTAC")}
              </CustomButton>
            </Grid>
          </Grid></form>
        ) : (
          <form
            className="loginForm"
            id="loginPageForm"
            name="logForm"
            noValidate
            autoComplete="off"
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <h4>{translateText("User Registration")}</h4>
                {/* <div align='right'>* {translateText("Required fields")}</div> */}
              </Grid>
              <Grid item>
                <CustomTextField
                  name="username"
                  fullWidth
                  placeholder={translateText("Username")}
                  value={pageData.username}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  name="first_name"
                  fullWidth
                  placeholder={translateText("First Name")}
                  value={pageData.first_name}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.first_name && Boolean(formik.errors.first_name)
                  }
                  helperText={formik.touched.first_name && formik.errors.first_name}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  name="last_name"
                  fullWidth
                  placeholder={translateText("Last Name")}
                  value={pageData.last_name}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={formik.touched.last_name && formik.errors.last_name}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  type="email"
                  name="email"
                  fullWidth
                  placeholder={translateText("Email")}
                  value={pageData.email}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  type="password"
                  name="password"
                  fullWidth
                  placeholder={translateText("Password")}
                  value={pageData.password}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  type="password"
                  name="confirmPassword"
                  fullWidth
                  placeholder={translateText("Confirm Password")}
                  value={pageData.confirmPassword}
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Grid>
              <Grid item>
                <CustomButton
                  fullWidth
                  size="large"
                  color="command"
                  id="btnSubmit"
                  disabled={!_.isEmpty(formik.errors) || pageData.username===''}
                  onClick={handleSignIn}
                  variant="contained"
                  type="submit"
                >
                  {registerButtontext}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        )
      }
      <div className="copyright">
        {translateText("All Rights Reserved © 2021 NORTAC DEFENCE™")}
      </div>
    </div>
  );
}

export default Register;