export const moduleForm = (theme) => ({
  formContainer: {
    margin: "15px 5px 15px 15px",
    padding: 0,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .pullRight": {
      textAlign: "right",
      margin: 5,
    },
    "& .itemCountWrap": {
      display: "flex",
      justifyContent: "flex-end",
      maxHeight: 80,
      "& .CustomItemCount": {
        marginLeft: 10,
      },
    },
    "& .switchWrap": {
      "& .MuiTypography-root": {
        "& > .MuiGrid-root": {
          width: 100,
        },
        left: 0,
      },
      display: "flex",
    },
    "& .unitsLabel": {
      "& > p": {
        padding: "4px 8px",
        backgroundColor: theme.palette.colors.gray.dark,
        border: `1px solid ${theme.palette.colors.gray.dark}`,
        fontSize: "0.9rem",
      },
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 6,
      paddingRight: 6,
    },
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.colors.black.main,
      opacity: "1.0 !important",
    },
  },
});
