import React, { createRef } from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// Internal
import LeftPanel from "./LeftPanel";
import { SnackbarProvider } from "notistack";
import { CustomAssetSelectorContextProvider } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    fontSize: "1.5rem",
    backgroundColor: theme.palette.textbox.error.main,
    maxWidth: 200,
  },
  icons: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

function History() {
  const classes = useStyles();

  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={(key) => (
        <div className={classes.icons}>
          <IconButton className={classes.expand} onClick={onClickDismiss(key)}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      classes={{ contentRoot: classes.contentRoot }}
    >
      <CustomAssetSelectorContextProvider>
        <LeftPanel></LeftPanel>
      </CustomAssetSelectorContextProvider>
    </SnackbarProvider>
  );
}

export default History;
