import React, { useContext, useState } from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import SyncIcon from "@material-ui/icons/Sync";

// Internal
import Language from "sccLanguage";
import AddressBookMenu from "sccAddressBookMenu";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleListing } from "@Styles/ModuleListing";
import ABDetail from "./ABDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  listItemAb: {
    cursor: "pointer",
    backgroundColor: theme.palette.colors.gray.dark,
    display: "flex",
    alignItems: "center",
    height: 46,
    marginTop: 1,

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.main,
    },
  },
}));

export default function ABList(props) {
  const data = props.data;
  const [mwState] = useContext(ModuleWrapperContext);
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const openDetailsFor = props.openDetailsFor;
  const handleClick = props.handleClick;

  const [syncData, setSyncData] = useState(0);

  const handleDownload = (ab) => {
    AddressBookMenu.downloadAB(ab);
  };
  const handleSync = (ab) => {
    AddressBookMenu.syncNow(ab).then(() => {
      // refresh sync icon
      setTimeout(() => {
        setSyncData(syncData + 1);
      }, 500);
    });
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} style={{ fontSize: 9 }} />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          className={classes.itemPrimaryText}
          id={data.id}
          primary={data.title}
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {mwState.verifyEditPermission && ( //the condition needs to be changed
            <CustomTooltip
              title={Language.translate("Download")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="feed"
                onClick={() => handleDownload(data)}
              >
                <GetAppIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {/*  display sync icon */}
          {AddressBookMenu.showSyncTags(data, "") &&
            !AddressBookMenu.syncData(data) && (
              <CustomTooltip
                title={Language.translate("Sync")}
                placement="bottom"
              >
                <IconButton
                  edge="end"
                  aria-label="feed"
                  onClick={() => handleSync(data)}
                >
                  <SyncIcon />
                </IconButton>
              </CustomTooltip>
            )}
          {/* display syncing icon */}
          {AddressBookMenu.showSyncTags(data, "") &&
            AddressBookMenu.syncData(data) && (
              <CustomTooltip
                title={Language.translate("Syncing")}
                placement="bottom"
              >
                <span className="abSyncingSpan">
                  <IconButton edge="end" disabled={true} onClick={() => null}>
                    <SyncIcon className="abRotatingSync" />
                  </IconButton>
                </span>
              </CustomTooltip>
            )}
          {mwState.verifyEditPermission && (
            <CustomTooltip
              title={Language.translate("Edit")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleEdit(data)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {mwState.verifyDeletePermission && (
            <CustomTooltip
              title={Language.translate("Delete")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() =>
                  handleItemDelete(
                    `${mwState.routeUrl}/${data.id}/true`,
                    "address book",
                    data.title
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <ABDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
