import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  title: baseSchema(Language).requiredStringMinMax,
});

export default schema;
