export default {
	"Asset": "V2_Asset",
	"Group": "V2_Group",
	"Each Group should be part of one Rule only. Group '{0}' is already in use by Rule '{1}'.": "V2_NR_Unique_Group_Message",
	"Required fields": "V2_Required_fields",
	"Individual Assets": "V2_Individual_Asset",
	"Triggers": "V2_Triggers",
	"Tactical": "V2_Tactical",
	"Locate on map": "V2_Locate_on_map",
	"Area Type": "V2_Area_Type",
	"EXCLUSIVE": "V2_EXCLUSIVE",
	"INCLUSIVE": "V2_INCLUSIVE",
	"Geo-Sync": "V2_Geo_Sync",
	"Min Speed": "V2_Min_Speed",
	"Max Speed": "V2_Max_Speed",
	"Rectangle": "V2_Rectangle",
	"Device Groups": "V2_Device_Groups",
	"Minimum Speed": "V2_Minimum_Speed",
	"Maximum Speed": "V2_Maximum_Speed",
	"Information": "V2_Information", 
	"Radius": "V2_Radius",
	"Width": "V2_Width",
	"Speed Unit": "V2_Speed_Unit",
	"Automatic Sync": "V2_Automatic_Sync",
	"Synced": "V2_Synced",
	"Sync All": "V2_Sync_All",
	"Success": "V2_Success",
	"Error": "V2_Error",
	"Contacts": "V2_Contacts",
	"SMS": "V2_SMS",
	"Total": "V2_Total",
	"Alerts": "V2_Alerts",
	"Cargo": "V2_Cargo",
	"No results!": "V2_No_results",
	"Role": "V2_Role",
	"Old Password": "V2_Old_Password",
	"Permission": "V2_Permission",
	"Personal Info": "V2_Personal_Info",
	"Generating the master data file for Group '{0}'. Please wait...": "V2_Generate_Master_Data_Group_Message",
	"Generating the master data file. Please wait": "V2_Generate_Master_Data_Message",
	"Telephone": "V2_Telephone",
	"Parent Group": "V2_Parent_Group",
	"Member Assets": "V2_Member_Assets",
	"Update": "V2_Update",
	"Generate": "V2_Generate",
	"Select Parent Group": "V2_Select_Parent_Group",
	"Select Assets": "V2_Select_Assets",
	"Select a Parent Group to view Assets": "V2_Select_a_Parent_Group_to_view_Assets",
	"No Assets Available": "V2_No_Assets_Available",
	"Currently there are no Users": "V2_Currently_there_are_no_Users",
	"Edit Group": "V2_Edit_Group",
	"New Group Title": "V2_New_Group_Title",
	"Add Group": "V2_Add_Group",
	"Edit Device": "V2_Edit_Device",
	"Edit Nearest Responder": "V2_Edit_Nearest_Responder",
	"Add Nearest Responder": "V2_Add_Nearest_Responder",
	"Clear": "V2_Clear",
	"There are no Assets or Groups selected": "V2_No_Assets_or_Groups_Selected",
	"Sub-Groups": "V2_Sub_Groups",
	"Add Sub-Group Here": "V2_Add_Sub_Group_Here",
	"BACK": "V2_BACK",
	"Generate File": "V2_Generate_File",
	"Select a Group": "V2_Select_a_Group",
	"Default": "V2_Default",
	"Kilometers": "V2_Kilometers",
	"KM": "V2_KM",
	"Minutes": "V2_Minutes",
	"Feet": "V2_Feet",
	"Max Radius": "V2_Max_Radius",
	"Maximum Radius": "V2_Maximum_Radius",
	"Asset Groups": "V2_Asset_Groups",
	"Edit Asset": "V2_Edit_Asset",
	"Center Asset": "V2_Center_Asset",
	"Lock and Center": "V2_Lock_Center",
	"Go to History": "V2_Go_to_history",
	"Creator": "V2_Creator",
	"Are you sure you would like to reject the POI?": "V2_Reject_POI_Prompt",
	"Accept": "V2_Accept",
	"Decline": "V2_Decline",
	"Created by": "V2_Created_by",
	"Hostile": "V2_Hostile",
	"Category": "V2_Category",
	"Affiliation": "V2_Affiliation",
	"Threat": "V2_Threat",
	"OK": "V2_OK",
	"Standard": "V2_Standard",
	"Hide": "V2_Hide",
	"Required": "V2_Required",
	"Subgroup": "V2_Subgroup",
	"Add New Contact": "V2_Add_New_Contact",
	"At least one contact info": "V2_At_least_one_contact_info",
	"Locate": "V2_Locate",
	"LOCATE": "V2_LOCATE",
	"Must be numbers only": "V2_numbers_only_message",
	"Must be numbers and up to 2 decimal points": "V2_must_be_2_decimal_points_message",
	"At least 1 checkbox must be checked": "V2_at_least_1_checkbox_message",
	"Must be numbers and up to 3 decimal points": "V2_must_be_3_decimal_points_message",
	"Not a valid telephone": "V2_not_a_valid_telephone_message",
	"MONITOR": "V2_MONITOR",
	"Remove all": "V2_Remove_all",
	"No Canned Message": "V2_No_Canned_Message",
	"No Messages in": "V2_No_Messages_in",
	"Compose": "V2_Compose",
	"page": "V2_page",
	"Trash": "V2_Trash",
	"Mark as Unread": "V2_Mark_as_Unread",
	"Mark as Read": "V2_Mark_as_Read",
	"Restore Message": "V2_Restore_Message",
	"Move to Trash": "V2_Move_to_Trash",
	"Restore": "V2_Restore",
	"SETTINGS": "V2_SETTINGS",
	"No Messages": "V2_No_Messages",
	"Edit Mode": "V2_Edit_Mode",
	"Last Settings Report": "V2_Last_Settings_Report",
	"TEMPERATURE LOW": "V2_TEMPERATURE_LOW",
	"TEMPERATURE HIGH": "V2_TEMPERATURE_HIGH",
	"HUMIDITY HIGH": "V2_HUMIDITY_HIGH",
	"SHOCK HIGH": "V2_SHOCK_HIGH",
	"REPORTING INTERVAL": "V2_REPORTING_INTERVAL",
	"START HOUR": "V2_START_HOUR",
	"Monitor": "V2_Monitor",
	"Tag Id": "V2_Tag_Id_New",
	"Door": "V2_Door",
	"Temperature": "V2_Temperature",
	"Humidity": "V2_Humidity",
	"Shock": "V2_Shock",
	"Battery": "V2_Battery",
	"Open": "V2_Open",
	"Closed": "V2_Closed",
	"No Shock": "V2_No_Shock",
	"Low": "V2_Low",
	"Normal": "V2_Normal",
	"Select a Geofence": "V2_Select_Geofence",
	"No Geofence selected": "V2_No_Geofence_Selected",
	"Saving": "V2_Saving",
	"Size": "V2_Size",
	"No alerts found": "V2_No_alerts_found",
	"Device": "V2_Device",
	"Successfully sent command to": "V2_Success_For_Command",
	"Failed to send command to": "V2_Failure_For_Command",
	"Successfully Updated": "V2_Successfully_Updated",
	"Successfully Created": "V2_Successfully_Created",
	"Failed to send information": "V2_Failure_For_Info",
	"Are you sure you want to delete": "V2_Delete_Prompt",
	"Successfully Deleted": "V2_Successfully_Deleted",
	"Deleting": "V2_Deleting",
	"Your access token has expired or revoked. You will be redirected to the login page in a few seconds.": "V2_Token_Expiry_Message",
	"Are you sure you want to logout now?": "V2_Logout_Prompt",
	"Logout": "V2_Logout",
	"Password Requirements": "V2_Password_Requirements",
	"Numbers (0-9), mandatory": "V2_Password_Numbers",
	"Alphabets (A-Z, a-z), mandatory": "V2_Password_Alphabets",
	"Special characters (_.-), optional": "V2_Password_Special_Characters",
	"Minimum length 7 and maximum length 20": "V2_Password_Length",
	"Must be 64 Hexadecimal characters": "V2_Encryption_validation",
	"Must be positive numbers": "V2_positive_number_validation",
	"Cannot exceed total number of assets registered under client": "V2_NR_asset_limit_validation",
	"Resend Registration Link": "V2_Resend_Registration_Link",
	"Register": "V2_Register",
	"You Have Successfully Registered": "V2_successful_register_message",
	"User Registration": "V2_User_Registration",
	"ZONE": "V2_Zone",
	"Company Name": "V2_Company_Name",
	"Company Address": "V2_Company_Address",
	"Country": "V2_Country",
	"City": "V2_City",
	"Limit": "V2_Limit",
	"User": "V2_User",
	"Features": "V2_Features",
	"Device Limit": "V2_Device_Limit",
	"User Limit": "V2_User_Limit",
	"Active": "V2_Active",
	"Inactive": "V2_Inactive",
	"No Expiry Date": "V2_No_Expiry_Date",
	"Alert Rule": "V2_Alert_Rule",
	"Toggle Asset Label": "V2_Toggle_Asset_Label",
	"Toggle Asset Annotation": "V2_Toggle_Asset_Annotation",
	"Zoom to Assets": "V2_Zoom_to_Assets",
	"Zoom to Asset": "V2_Zoom_to_Asset",
	"Show History": "V2_Show_History",
	"The maximum supported zoom level at this location is": "V2_Max_Zoom_Message",
	"Customer Logo": "V2_Customer_Logo",
	"Are you sure you would like to delete the platform logo": "V2_platform_logo_delete_message",
	"Roles-Permissions": "V2_Roles_Permissions",
	"Add New POI": "V2_Add_new_POI",
	"Zoom to POI": "V2_Zoom_to_POI",
	"Edit POI": "V2_Edit_POI",
	"Summary": "V2_Summary",
	"Position/Alarms": "V2_Position_Alarms",
	"Are you sure you want to delete these messages": "V2_Confirm_Delete_Messages",
	"Are you sure you want to delete this message": "V2_Confirm_Delete_Message",
	"You have not selected any contacts": "V2_No_Contacts_Message",
	"You have to select a canned message OR compose a message": "V2_Select_Canned_Or_Compose",
	"Exceeded file size limit. Please ensure file size is not bigger than 5 KB": "V2_Attachment_Limit_Message",
	"Loading sent messages": "V2_Loading_sent_messages",
	"Re-Send Message": "V2_ReSend_Message",
	"Loading trash messages": "V2_Loading_trash_messages",
	"Back To Inbox": "V2_Back_To_Inbox",
	"No messages in trash": "V2_No_Trash_Messages",
	"Are you sure you want to recover this message": "V2_Confirm_Message_Recovery",
	"Upload File": "V2_Upload_File",
	"New message from": "V2_New_message_from",
	"Data is too large. Please narrow the search result": "V2_History_Change_Too_Large_Message",
	"No result. Change the date range or the asset and try again": "V2_History_Change_Date_Range_Message",
	"Select an asset and search again": "V2_History_Select_Asset_Message",
	"Locate Geofence": "V2_Locate_Geofence",
	"Min speed only accepts integers or decimals": "V2_min_speed_validation",
	"Max speed only accepts integers or decimals": "V2_Geo_Max_Speed_Validation",
	"Min speed cannot be greater than Max speed": "V2_Geo_Min_Max_Compare",
	"Geofence name must have a maximum of 25 characters": "V2_Geo_Title_Length_Validation",
	"Age/End": "V2_Age_End",
	"Ack": "V2_Ack",
	"Reset By Device": "V2_Reset_By_Device",
	"RESET BY DEVICE": "V2_RESET_BY_DEVICE",
	"Write a message": "V2_Write_a_message",
	"Send Message": "V2_Send_Message",
	"New Settings Code": "V2_New_Settings_Code",
	"Poll Settings": "V2_Poll_Settings",
	"Settings Code": "V2_Settings_Code",
	"Clients": "V2_Clients",
	"Geofences": "V2_Geofences",
	"Nearest Responders": "V2_Nearest_Responders",
	"Alert Rules": "V2_Alert_Rules",
	"History Playback": "V2_History_Playback",
	"View": "V2_View",
	"Polling": "V2_Polling",
	"Manage AES": "V2_Manage_AES",
	"All Actions": "V2_All_Actions",
	"Emergency Alerts": "V2_Emergency_Alerts",
	"Geofence Alerts": "V2_Geofence_Alerts",
	"Asset Reporting Alerts": "V2_Asset_Reporting_Alerts",
	"Cargo Alerts": "V2_Cargo_Alerts",
	"Measure Area": "V2_Measure_Area",
	"Draw area on map": "V2_Draw_area_on_map",
	"Measure length": "V2_Measure_Length",
	"Draw line on map": "V2_Draw_line_on_map",
	"Zoom Out Full": "V2_Zoom_out_full",
	"Map Layers": "V2_Map_Layers",
	"Back to List": "V2_Back_To_List",
	"Close Menu": "V2_Close_Menu",
	"File too large": "V2_File_too_large",
	"Select an image": "V2_Select_an_image",
	"Preview Image": "V2_Preview_Image",
	"Extended Map": "V2_Extended_Map",
	"Too short": "V2_Too_Short_Error",
	"Too long": "V2_Too_Long_Error",
	"Must match the previous entry": "V2_Password_Match_Error",
	"Image is required": "V2_Image_Required_Message",
	"Must draw a Geofence": "V2_Must_draw_Geo_Msg",
	"Missing or invalid characters in password": "V2_Invalid_Password",
	"Invalid MGRS Coordinates": "V2_Invalid_MGRS",
	"Invalid email address": "V2_Email_Error",
	"Invalid Longitude": "V2_Invalid_Longitude",
	"Invalid Latitude": "V2_Invalid_Longitude",
	"Direction": "V2_Direction",
	"Geo-fence": "V2_Geofence",
	"Reshape": "V2_Reshape",
	"Show Unread": "V2_Show_Unread",
	"Collapse Groups": "V2_Collapse_Groups",
	"Compose your message": "V2_Compose_your_message",
	"SENDER": "V2_SENDER",
	"RESTORE": "V2_RESTORE",
	"MESSAGES": "V2_MESSAGES",
	"PLAY": "V2_PLAY",
	"STOP": "V2_STOP",
	"PAUSE": "V2_PAUSE",
	"NEXT TRIP": "V2_NEXT_TRIP",
	"LIVE TIME": "V2_LIVE_TIME",
	"START TIME": "V2_START_TIME",
	"END TIME": "V2_END_TIME",
	"FASTER": "V2_FASTER",
	"SLOWER": "V2_SLOWER",
	"Personal": "V2_Personal",
	"Feature": "V2_Feature",
	"Admin User": "V2_Admin_User",
	"Admin Device": "V2_Admin_Device",
	"Feed": "V2_Feed",
	"Mobile Terminated": "V2_Mobile_Terminated",
	"Client": "V2_Client",
	"Feed Code": "V2_Feed_Code",
	"Zigbee": "V2_Zigbee",
	"Mobile Originated": "V2_Mobile_Originated",
	"Configuration Settings": "V2_Configuration_Settings",
	"APN Host": "V2_APN_Host",
	"APN User": "V2_APN_User",
	"APN Password": "V2_APN_Password",
	"Customer": "V2_Customer",
	"Add Customer Admin": "V2_Customer_Admin",
	"SOS": "V2_SOS",
	"Not Registered": "V2_Not_Registered",
	"Registered": "V2_Registered",
	"Feed Token": "V2_Feed_Token",
	"Filter": "V2_Filter",
	"Selected": "V2_Selected",
	"Generate Token": "V2_Generate_Token",
	"Login error. Customer account has been deactivated": "V2_customer_account_deactivated_error",
	"Login error. Customer account has expired": "V2_customer_account_expired_error",
	"Login error. Invalid username or password": "V2_invalid_credentials_error",
	"Reset Password": "V2_Reset_Password",
	"You Have Successfully Reset Your Password": "V2_successful_password_reset_error",
	"Login to SCCTitan": "V2_Login_to_SCCTitan",
	"Sign in": "V2_Sign_in",
	"Checking": "V2_Checking",
	"Registration": "V2_Registration",
	"Back to": "V2_Back_to",
	"Delete from Trash": "V2_Delete_from_Trash",
	"DegDec (EPSG:4326)": "V2_DegDec",
	"DMS": "V2_DMS",
	"EPSG:900913": "V2_EPSG",
	"MinDec": "V2_MinDec",
	"USNG (MGRS)": "V2_USNG",
	"Platform": "V2_Platform",
	"NATO": "V2_NATO",
	"Air": "V2_Air",
	"Ground": "V2_Ground",
	"Sea Surface": "V2_Sea_Surface",
	"Sea Sub Surface": "V2_Sea_Sub_Surface",
	"Friend": "V2_Friend",
	"Neutral": "V2_Neutral",
	"Unknown": "V2_Unknown",
	"Medical": "V2_Medical",
	"Armour": "V2_Armour",
	"Anti-Tank": "V2_Anti_Tank",
	"Please log out and log back in to see these changes applied": "V2_log_out_to_apply_changes",
	"A registration email has been sent to the user": "V2_registration_email_message",
	"Location": "V2_Location",
	"Latitude": "V2_Latitude",
	"Longitude": "V2_Longitude",	
	"Name": "V2_Name",
	"Groups": "V2_Groups",
	"Max No. of Assets": "V2_Max_No._of_Assets",
	"Report Age (H)": "V2_Report_Age_(H)",
	"Max Radius (KM)": "V2_Max_Radius_(KM)",
	"Master Data File": "V2_Master_Data_File",
	"Interval": "V2_Interval",
	"Distance": "V2_Distance",
	"Subscribers": "V2_Subscribers",
	"Members": "V2_Members",
	"Seconds": "V2_Seconds",
	"Metres": "V2_Metres",
	"Title": "V2_Title",
	"Info": "V2_Info",
	"Edit": "V2_Edit",
	"Delete": "V2_Delete",
	"TITLE": "V2_TITLE",
	"INFO": "V2_INFO",
	"EDIT": "V2_EDIT",
	"DELETE": "V2_DELETE",
	"OFF": "V2_OFF",
	"ON": "V2_ON",
	"Status": "V2_Status",
	"INACTIVE": "V2_INACTIVE",
	"ACTIVE": "V2_ACTIVE",	
	"Note": "V2_Note",
	"KPH": "V2_KPH",
	"MPH": "V2_MPH",
	"KNOTS": "V2_KNOTS",
	"Shape": "V2_Shape",
	"Polygon": "V2_Polygon",
	"Circle": "V2_Circle",
	"Path": "V2_Path",
	"Box": "V2_Box",
	"Edit Shape": "V2_Edit_Shape",
	"Re-shape": "V2_Re_shape",
	"Drag": "V2_Drag",
	"Rotate": "V2_Rotate",
	"Resize": "V2_Resize",
	"Type": "V2_Type",
	"Coordinates": "V2_Coordinates",
	"Pending": "V2_Pending",
	"Assets": "V2_Assets",
	"Users": "V2_Users",
	"Email": "V2_Email",
	"Emergency": "V2_Emergency",
	"Geofence": "V2_Geofence_2",
	"Speed": "V2_Speed",
	"Message": "V2_Message",
	"First Name": "V2_First_Name",
	"Last Name": "V2_Last_Name",
	"Marker": "V2_Marker",
	"Select a Marker": "V2_Select_a_Marker",
	"Login Name": "V2_Login_Name",
	"Password": "V2_Password",
	"New Password": "V2_New_Password",
	"Change Password": "V2_Change_Password",
	"Confirm Password": "V2_Confirm_Password",
	"Cancel": "V2_Cancel",
	"Save": "V2_Save",
	"Please wait": "V2_Please_wait",
	"Devices": "V2_Devices",
	"ADD": "V2_ADD",
	"Reset": "V2_Reset",
	"Action": "V2_Action",
	"Device Type": "V2_Device_Type",
	"Device Mode": "V2_Device_Mode",
	"Non Report Alert": "V2_Non_Report_Alert",
	"Comm ID": "V2_Comm_ID",
	"Encryption": "V2_Encryption",
	"Decryption": "V2_Decryption",
	"Unit of Speed": "V2_Unit_of_Speed",
	"Close": "V2_Close",
	"Show": "V2_Show",
	"Add": "V2_Add",
	"Search": "V2_Search",
	"Hours": "V2_Hours",
	"Hour": "V2_Hour",
	"Report Age": "V2_Report_Age",
	"IMEI": "V2_IMEI",
	"Zoom In": "V2_Zoom_In",
	"Settings": "V2_Settings",
	"History": "V2_History",
	"Area": "V2_Area_2",
	"Select": "V2_Select",
	"POI": "V2_POI",
	"From": "V2_From",
	"Canned": "V2_Canned",
	"Canned Message": "V2_Canned_Message",
	"Attachment": "V2_Attachment",
	"Date": "V2_Date",
	"To": "V2_To",
	"INBOX": "V2_INBOX",
	"SENT": "V2_SENT",
	"TRASH": "V2_TRASH",
	"of": "V2_of",
	"Reply": "V2_Reply",
	"Loading": "V2_Loading",
	"Submit": "V2_Submit",
	"All": "V2_All",
	"Annotation": "V2_Annotation",
	"Course": "V2_Course",
	"Altitude": "V2_Altitude",
	"Last Reported": "V2_Last_Reported",
	"POLL SETTINGS": "V2_POLL_SETTINGS",
	"TIME": "V2_TIME",
	"FIRMWARE": "V2_FIRMWARE",
	"SETTINGS CODE": "V2_SETTINGS CODE",
	"NEW": "V2_NEW",
	"EMERGENCY": "V2_EMERGENCY",
	"SPEED": "V2_SPEED",
	"GEOFENCE": "V2_GEOFENCE_2",
	"MESSAGE": "V2_MESSAGE",
	"POLL GPS": "V2_POLL_GPS",
	"SENDING": "V2_SENDING",
	"SEND": "V2_SEND",
	"Start": "V2_Start",
	"Age": "V2_Age",
	"End": "V2_End",
	"Acknowledge": "V2_Acknowledge",
	"Exclusive": "V2_Exclusive",
	"Inclusive": "V2_Inclusive",
	"ERROR": "V2_ERROR",
	"Location (MGRS)": "V2_Location_MGRS",
	"Location (Lat, Lon)": "V2_Location_regular",
	"Map": "V2_Map",
	"Layers": "V2_Layers",
	"Center Map Here": "V2_Center_Map_Here",
	"Geo-Fence": "V2_Geo-Fence",
	"Nearest Responder": "V2_Nearest_Responder",
	"Description": "V2_Description",
	"Profile": "V2_Profile",
	"User ID": "V2_User_ID",
	"Company": "V2_COMPANY",
	"Reports": "V2_Reports",
	"Situational Awareness": "V2_Situational_Awareness",
	"Unlimited": "V2_Unlimited",
	"Week": "V2_Week",
	"Month": "V2_Month",
	"NONE": "V2_NONE",
	"Loading Data": "V2_Loading_Data",
	"No data found!": "V2_No_data_found",
	"Equinox": "V2_Equinox",
	"You will lose all unsaved modifications. Do you want to continue?": "V2_unsaved_confirmation_msg",
	"show": "V2_show",
	"entries": "V2_entries",
	"No data available in table": "V2_No_data",
	"showing": "V2_showing",
	"to": "V2_to_to",
	"filtered from": "V2_filtered_from",
	"total records": "V2_total_records",
	"First": "V2_First",
	"Previous": "V2_Previous",
	"Next": "V2_Next",
	"Last": "V2_Last",
	"Panic": "V2_Panic",
	"Failed": "V2_Failed",
	"Sent": "V2_Sent",
	"Not Moved": "V2_Not_moved",
	"One Report": "V2_One_report",
	"meter": "V2_meter2",
	"km": "V2_kilo_meter",
	"Round Trip": "V2_Round_trip",
	"Single Trip": "V2_Single_trip",
	"All Trips": "V2_All_trips",
	"FROM": "V2_FROM",
	"TO": "V2_TO",
	"Duration": "V2_Duration",
	"Do you want to delete the geo-fence?": "V2_delete_geo-fence_msg",
	"You will lose all changes! Do you want to continue?": "V2_lose_changes_confirmation_msg",
	"Your current drawing would be deleted. Do you want to continue?": "V2_drawing_deleted_confirmation_msg",
	"Must draw a geo-fence!": "V2_draw_geo-fence_msg",
	"Must enter a title!": "V2_enter_title_msg",
	"This geo-fence name is already in use": "V2_geo-fence_name_in_use_msg",
	"Please enter a description!": "V2_enter_description_msg",
	"created": "V2_created",
	"updated": "V2_updated",
	"All changes cancelled!": "V2_changes_cancelled_msg",
	"Center Map": "V2_Center_Map",
	"Current": "V2_Current",
	"Day": "V2_Day",
	"YES": "V2_YES",
	"Time": "V2_Time",
	"Current Alerts": "V2_Current_Alerts",
	"Last Report": "V2_Last_Report",
	"RESET": "V2_RESET",
	"Points of Interest": "V2_Points_of_Interest",
	"Text Messaging": "V2_Text_Messaging",
	"Alarms": "V2_Alarms",
	"Speed Alerts": "V2_Speed_Alerts",
	"Map Zoom Levels": "V2_Map_Zoom_Levels",
	"Zoom Out": "V2_Zoom_Out",
	"Assets Non-Reporting": "V2_Assets_Non_Reporting",
	"Assets Reporting": "V2_Assets_Reporting",
	"h": "V2_h",
	"TYPE": "V2_TYPE",
	"Heading": "V2_Heading",
	"Messages": "V2_Messages",
	"ACKNOWLEDGEMENTS": "V2_ACKNOWLEDGEMENTS",
	"Asset Name": "V2_Asset_Name",
	"Acknowledged By": "V2_Acknowledged_By",
	"SPEED ALERTS": "V2_SPEED_ALERTS",
	"ASSETS Reporting": "V2_ASSETS_Reporting",
	"Regular": "V2_Regular",
	"Irregular": "V2_Irregular",
	"Min": "V2_Min",
	"Max": "V2_Max",
	"Unit": "V2_Unit",
	"meters": "V2_meters",
	"Delete All": "V2_Delete_All",
	"2 Weeks": "V2_Two_Weeks",
	"DATE": "V2_DATE",
	"CANNED MESSAGE": "V2_CANNED_MESSAGE",
	"Select all Assets": "V2_Select_all_Assets",
	"USERS": "V2_USERS",
	"Select all Users": "V2_Select_all_Users",
	"CLEAR": "V2_CLEAR",
	"Recover All": "V2_Recover_All",
	"GO": "V2_GO",
	"Please POLL for new diagnostics data!": "V2_POLL_for_new_diagnostics_data_msg",
	"WAVE": "V2_WAVE",
	"POLL": "V2_POLL",
	"POLLING...": "V2_POLLING_msg",
	"SENDING...": "V2_SENDING_msg",
	"LOCKED...": "V2_LOCKED_msg",
	"PENDING...": "V2_PENDING_msg",
	"Focus": "V2_Focus",
	"Show Path": "V2_Show_path",
	"Zeroize": "V2_Zeroize",
	"PENDING": "V2_PENDING",
	"Transfers": "V2_Transfers",
	"NO ERRORS": "V2_NO_ERRORS",
	"Jammer": "V2_Jammer",
	"Mode": "V2_Mode",
	"Manual": "V2_Manual",
	"Automatic": "V2_Automatic",
	"Last Poll": "V2_Last_Poll",
	"System Type": "V2_System_Type",
	"DIAG": "V2_DIAG",
	"Threats": "V2_Threats",
	"LOGS": "V2_LOGS",
	"Diagnostics": "V2_Diagnostics",
	"Coordinate Format": "V2_Coordinate_Format",
	"Emergency Audio": "V2_Emergency_Audio",
	"Emergency Alert Pop Up": "V2_Emergency_Alert_Pop_Up",
	"Language": "V2_Language",
	"Select Icon Set": "V2_Select_Icon_Set",
	"Clock Format": "V2_Clock_Fomat",
	"Clustering Threshold": "V2_Clustering_Threshold",
	"Clustering Distance pixels": "V2_Clustering_Distance_pixels",
	"Speed Format": "V2_Speed_Format",
	"Asset Label": "V2_Asset_Label",
	"Asset Annotation": "V2_Asset_Annotation",
	"Reports Age": "V2_Reports_Age",
	"Time Zone": "V2_Time_Zone",
	"No records available": "V2_No_records",
	"Start Date": "V2_Start_Date",
	"End Date": "V2_End_Date",
	"POI Marker": "V2_POI_Marker",
	"TOGGLE ENCRYPTION": "V2_TOGGLE_ENCRYPTION",
	"The form for this device is under construction!": "V2_device_form_under_construction",
	"Your current drawing will be lost. Do you want to continue?": "V2_Geo_Drawing_edit_message",
	"Your current changes will be lost. Do you want to continue?": "V2_menu_changes_cancel_message",
	"Feeds": "V2_Feeds",
	"Include Tags": "V2_Include_Tags",
	"inbox": "V2_inbox",
	"sent": "V2_sent",
	"trash": "V2_trash",
	"monitor": "V2_monitor",
	"Sync": "V2_Sync",
	"Details": "V2_Details",
	"Icon": "V2_Icon",
	"Syncing": "V2_Syncing",
	"Poi": "V2_Poi",
	"Last synced": "V2_Last_synced",
	"Last notified": "V2_Last_notified",
	"Last Modified": "V2_Last_Modified",
	"Registration Pin": "V2_Registration_Pin",
	"Up-to-date": "V2_Up_to_date",
	"By Device Type": "V2_By_Device_Type",
	"By Customer": "V2_By_Customer",
	"Modified By": "V2_Modified_By",
	"No Results found with": "V2_No_Results_found_with",
	"Username already taken": "V2_Username_already_taken",
	"Username has invalid characters": "V2_Username_has_invalid_characters",
	"add": "V2_add",
	"edit": "V2_edit",
	"remove": "V2_remove",
	"delete": "V2_delete",
	"decline": "V2_decline",
	"This customer has reached maximum asset limit": "V2_max_asset_limit_customer",
	"Attention": "V2_Attention",
	"MAX 5 KB": "V2_max_file_size",
	"MAX 255 Characters": "V2_max_char_limit_message",
	"Alert": "V2_Alert",
	"Reset By": "V2_Reset_By",
	"Triggered At": "V2_Triggered_At",
	"Acknowledge All": "V2_Acknowledge_All",
	"Acknowledged": "V2_Acknowledged",
	"Resetting": "V2_Resetting",
	"Non-Report Alerts": "V2_Non_Report_Alerts",
	"This shape cannot exceed 255 points. Please reduce points.": "V2_Geo_Max_Coordinate_Message",
	"Logo": "V2_Logo",
	"Roles": "V2_Roles",
	"HUMIDITY LOW": "V2_HUMIDITY_LOW",
	"SHOCK LOW": "V2_SHOCK_LOW",
	"Location of Alert": "V2_Location_of_Alert",
	"Warning: Attachments will only be sent to devices which are compatible to receive them": "V2_attachment_compatible_devices_message",
	"Alert Type": "V2_alert_type",
	"By": "V2_By",
	"Non-Report": "V2_Non_Report",
	"Refresh page": "V2_Refresh_page",
	"No alerts selected": "V2_No_alerts_selected",
	"Per Page": "V2_Per_Page",
	"Alert Info": "V2_Alert_Info",
	"Live Stats": "V2_Live_Stats",
	"Info of cargo status when the alert was triggered": "V2_cargo_alert_info_tool_tip",
	"Info of most recent cargo status": "V2_cargo_live_stats_tool_tip",	
	"Zoom to Geofence": "V2_Zoom_To_Geofence_New",
	"MAX": "V2_MAX",
	"Labels": "V2_Labels",
	"Length": "V2_Length",
	"Measure Length": "V2_Measure_Length",
	"Draw": "V2_Draw",
	"Poi Label": "V2_Poi_Label",
	"Poi Annotation": "V2_Poi_Annotation",
	"Geofence Label": "V2_Geofence_Label",
	"Geofence Annotation": "V2_Geofence_Annotation",
	"Poll GPS": "V2_Poll_GPS",
	"Poll": "V2_Poll",
	"Follow All": "V2_Follow_All",
	"Trail All": "V2_Trail_All",
	"View All": "V2_View_All",
	"Auto-Skip": "V2_Auto_Skip",
	"Report": "V2_Report",
	"Events": "V2_Events",
	"No Events": "V2_No_Events",
	"Currently there are no devices selected": "V2_No_Device_Selected_Message",
	"Please click on the map to populate location coordinates": "V2_Add_POI_Location_Via_Map",
	"Please click on the map to edit location coordinates": "V2_Edit_POI_Lacation_Via_Map",
	"Meters": "V2_Meters",
	"Please click on the geofence circumference or center to reshape your geofence": "V2_Edit_Circluar_Geofence_Info",
	"Please click on any point along the geofence to reshape your geofence": "V2_Edit_Path_Geofence_Info",
	"Please click on the geofence perimeter to reshape your geofence": "V2_Edit_Geofence_Polygon_Info",
	"Export As CSV": "V2_Export_As_CSV",
	"Event": "V2_Event",
	"Position": "V2_Position",
	"Tools": "V2_Tools",
	"The Geofence must be drawn within the extent of the map.": "V2_Geofence_Drawing_Boundaries_Message",
	"Warning": "V2_Warning",
	"WARNING": "V2_WARNING",
	"Annotations": "V2_Annotations",
	"Measure": "V2_Measure",
	"Position/Alerts": "V2_Position_Alerts",
	"Latest Reports": "V2_Latest_Reports",
	"Get": "V2_Get",
	"An email id or sms number is required": "V2_Email_SMS_Requirement",
	"There are no events for the selected time range": "V2_No_Events_Message",
	"Draw line to be measured": "V2_Draw_Line_To_Measure",
	"Register via Email": "V2_Register_via_Email",
	"ENABLE": "V2_ENABLE",
	"DISABLE": "V2_DISABLE",
	"Username": "V2_Username",
	"Calculating Data": "V2_Calculating_Data",
	"Load History": "V2_Load_History",
	"The data requested is large and may slow down your browser": "V2_Hisoric_Search_Warning",
	"The data requested is too large. Please narrow your search criteria and try again": "V2_Historic_Search_Error",
	"Quick Select": "V2_Quick_Select",
	"Today": "V2_Today",
	"Zoom In On Map": "V2_Zoom_In_On_Map",
	"Iridium Billing": "V2_Iridium_Billing",
	"Bytes": "V2_Bytes",
	"Inbox Mail": "V2_Inbox_Mail",
	"Run Macro": "V2_Run_Macro",
	"File Path": "V2_File_Path",
	"Macro Path": "V2_Macro_Path",
	"Macro File": "V2_Macro_File",
	"Execute": "V2_Execute",
	"Color": "V2_Color",
	"Two Factor Authentication": "V2_Two_Factor_Authentication",
	"Phone Number": "V2_Phone_Number",
	"Hide No Events": "V2_Hide_no_events",
	"General Settings": "V2_General_Settings",
	"Map Settings": "V2_Map_Settings",
	"Category List": "V2_Category_List",
	"Add Category": "V2_Add_Category",
	"Edit Category": "V2_Edit_Category",
	"Delete Selected Items": "V2_Delete_Selected_Items",
	"Upload Images": "V2_Upload_Images",
	"Images": "V2_Images",
	"Remove All": "V2_Remove_All",
	"By Category": "V2_By_Category",
	"General": "V2_General",
	"Your session will have to be reloaded to apply the changes. Are you sure you want to continue?": "V2_Page_Reload_Confirmation",
	"Asset Annotations": "V2_Asset_Annotations",
	"POI Annotations": "V2_POI_Annotations",
	"Geofence Annotations": "V2_Geofence_Annotations",
	"Asset Labels": "V2_Asset_Labels",
	"POI Labels": "V2_POI_Labels",
	"Geofence Labels": "V2_Geofence_Labels",
	"Assets with no events are hidden": "V2_No_Event_Asset_Message",
	"Remove Errors": "V2_Remove_Errors",
	"Errors": "V2_Errors",
	"File name already exists in database": "V2_File_Already_Exists_Message",
	"Remove": "V2_Remove",
	"Some large files were not uploaded. Max 1MB per image": "V2_Large_Image_Upload_Message",
	"Reload": "V2_Reload",
	"Created": "V2_Created",
	"CONNECTION LOST!": "V2_Connection_lost",
	"Please refresh your browser or check your internet connection.": "V2_Refresh_browser_message",
	"Login error. User's Role Prohibits Access": "V2_Login_Error_No_Access",
	"Search text must be less than 45 characters": "V2_Search_Text_Limit",
	"Compose Message": "V2_Compose_Message",
	"YOU HAVE NEW MESSAGES": "V2_You_Have_New_Messages",
	"Click here": "V2_Click_Here",
	"Empty": "V2_Empty",
	"Re-Send": "v2_Re_Send",
	"Maximize": "V2_Maximize",
	"Minimize": "V2_Minimize",
	"Unlock": "V2_Unlock",
	"This role does not have any permissions": "V2_Role_Empty_Permissions",
	"You need to check Alarms if you want Assets.": "V2_Need_to_check_Alarms",
	"You need to check Assets if you want Alarms.": "V2_Need_to_check_Assets",
	"No users selected": "V2_No_Users_Selected",
	"First Page": "V2_First_Page",
	"Last Page": "V2_Last_Page",
	"Trail": "V2_Trail",
	"Follow": "V2_Follow",
	"Overlays": "V2_Overlays",
	"Base Maps": "V2_Base_Maps",
	"OSM": "V2_OSM",
	"Google Physical": "V2_Google_Physical",
	"Google Hybrid": "V2_Google_hybrid",
	"Google Satellite": "V2_Google_Satellite",
	"Google Street": "V2_Google_Street",
	"Mapbox Street": "V2_Mapbox_Sreet",
	"Mapbox Satellite": "V2_Mapbox_Satellite",
	"Canada": "V2_Canada",
	"Australia": "V2_Australia",
	"Colombia": "V2_Colombia",
	"Ecuador": "V2_Ecuador",
	"France": "V2_France",
	"Germany": "V2_Germany",
	"United Kingdom": "V2_United_Kingdom",
	"United States": "V2_United_States",
	"Norway": "V2_Norway",
	"Mexico": "V2_Mexico",
	"Netherlands": "V2_Netherlands",
	"Sweden": "V2_Sweden",
	"Saudi Arabia": "V2_Saudi_Arabia",
	"Austria": "V2_Austria",
	"Finland": "V2_Finland",
	"Peru": "V2_Peru",
	"Brazil": "V2_Brazil",
	"Iraq": "V2_Iraq",
	"Ireland": "V2_Ireland",
	"UNAUTHORIZED USER": "__UNDEFINED",
	"Unauthorized": "__UNDEFINED",
	"There are no devices in Subscribers": "V2_No_Devices_Subscribers",
	"There are no devices in Tactical": "V2_No_Devices_Tactical",
	"There are no devices in Triggers": "V2_No_Devices_Triggers",
	"You do not have Permission to view Users": "__UNDEFINED",
	"North": "V2_North",
	"North East": "V2_North_East",
	"North West": "V2_North_West",
	"East": "V2_East",
	"South": "V2_South",
	"South East": "V2_South_East",
	"South West": "V2_South_West",
	"West": "V2_West",
	"Hide Unauthorized": "V2_Hide_Unauthorized",
	"View Unauthorized": "V2_View_Unauthorized",
	"Are you sure you would like to acknowledge": "V2_Want_To_Acknowledge",
	"No alerts to acknowledge": "V2_No_Alert_To_Acknowledge",
	"is not allowed to be empty": "V2_not_allowed_to_be_empty",
	"length must be at least 3 characters long": "V2_must_be_3_characters_long",
	"length must be less than or equal to 45 characters long": "V2_Less_or_equal_45_characters",
	"must be a number": "V2_must_be_number",
	"contains invalid characters": "V2_contains_invalid_characters",
	"must be a valid email": "V2_must_be_valid_email",
	"length must be at least 8 characters long": "V2_must_be_8_characters_long",
	"There are no devices in Members": "V2_no_devices_in_members",
	"Support": "V2_Support",
	"is invalid": "V2_is_invalid",
	"length must be at least 7 characters long": "V2_length_7_characters_long",
	"length must be less than or equal to 20 characters long": "V2_length_20_characters_long",
	"Passwords are not identical": "__UNDEFINED",
	"Wrong Old Password": "__UNDEFINED",
	"Finish": "V2_Finish",
	"You cannot delete this Role. One or more users are registered with this Role": "V2_You_cannot_delete_this_role",
	"Red": "V2_red",
	"Blue": "V2_blue",
	"Green": "V2_green",
	"Yellow": "V2_yellow",
	"Standalone":  "V2_Standalone",
	"Open Protocol": "V2_Open_Protocol",
	"This field": "V2_This_field",
	"must be an integer": "v2_must_be_an_integer",
	"should be integer and less than 4294967295": "v2_must_be_an_integer_and_less_than",
	"Paused": "V2_Paused",
	"Non Report": "V2_Non_Report",
	"Replay Ended": "V2_Replay_Ended",
	"must be a positive number": "V2_must_positive_number",
	"You did not select any action for this role. Do you wish to continue?": "V2_no_action_for_role_continue",
	"Max Speed cannot be less than Min Speed": "V2_max_speed_less_than_min",
	"Cannot be more than 7 numbers": "V2_cannot_be_more_than_7",
	"This field cannot exceed": "V2_cannot_exceed",
	"Try Again": "V2_try_again",
	"Hide Resetting Emergency Alerts": "V2_hide_resetting_alerts",
	"Show Resetting Emergency Alerts": "V2_show_resetting_alerts",
	"Resend Registration": "V2_resend_registration",
	"Update Image successful": "V2_update_image_successful",
	"No image saved": "V2_no_image_saved",
	"Main": "V2_Main",
	"Restored messages": "V2_Restored_messages",
	"Resent All PENDING messages": "V2_Resent_All_PENDING_messages",
	"Messages deleted from trash": "V2_Messages_deleted_from_trash",
	"Are you sure you want to permanately delete the messages?": "V2_permanately_delete_message",
	"Moved messages to trash": "V2_Moved_messages_to_trash",
	"Marked messages as read": "V2_Marked_messages_as_read",
	"Marked messages as unread": "V2_Marked_messages_as_unread",
	"You DO NOT have autorization to view this email.": "V2_no_autorization_view_email",
	"NOT AUTHORIZED": "V2_NOT_AUTHORIZED",
	"Please click on the geofence and drag to desired location": "V2_Please_click_on_the_geofence_and_drag",
	"Historic Data": "V2_Historic_Data",
	"Please be patient. You may experience a delay while downloading large data sets": "V2_Please_be_patient_etc",
	"CSV Export": "V2_CSV_Export",
	"No previous event available": "V2_No_previous_event_available",
	"Checked": "V2_Checked",
	"Unchecked": "V2_Unchecked",
	"Wave Limit": "V2_Wave_Limit",
	"SCCT Limit": "V2_SCCT_Limit",
	"Clear Data": "V2_Clear_Data",
	"Clearing Data": "V2_Clearing_Data",
	"Last SA sent": "V2_Last_SA_sent",
	"By De-selecting Situational Awareness you will delete ALL Sa Rules. Do you wish to continue?": "V2_deselect_sa_admin_features"






};
