import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Components
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import ClientForm from "./ClientForm";
import ClientList from "./ClientList";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

export default function ModuleWrapperClientBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);

  const filteredModuleData = mwState.convertAndSort(
    mwState.filteredModuleData,
    mwState.searchField
  );

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  const classes = useStyles();

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...p.moduleItemData, email_registration: true },
    }));
  }, []);

  return mwState.wrapperDisplayMode === MW_LIST_MODE ? (
    <ul className={classes.listContainer}>
      {!_.isEmpty(filteredModuleData) ? (
        filteredModuleData
          .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
          .map((data) => {
            return (
              <ClientList
                key={data.id}
                data={data}
                handleClick={handleClick}
                openDetailsFor={openDetailsFor}
              />
            );
          })
      ) : (
        <div className="noResults">{Language.translate("No Results!")}</div>
      )}
    </ul>
  ) : (
    <ClientForm />
  );
}
