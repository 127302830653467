import React, { useContext, useEffect } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import AttachmentIcon from "@material-ui/icons/Attachment";
import Badge from "@material-ui/core/Badge";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import ReplyIcon from "@material-ui/icons/Reply";
import NearMeIcon from "@material-ui/icons/NearMe";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

//External
import _ from "lodash";

//Internal
import Language from "sccLanguage";
import Profile from "sccProfile";
import Message from "sccMessage";
import MessageMenu from "sccMessageMenu";
import Permission from "sccPermission";
import Device from "sccDevice";
import User from "sccUser";
import Clock from "sccClock";
import Utils from "sccUtils";
import CustomButton from "@Components/CustomButton";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleMessage } from "@Styles//ModuleMessage";

function MessageRead(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const data = mwState.moduleItemData;
  const folderIndex = props.folderIndex;
  const options = [
    1,
    mwState.pagination.itemsPerPage,
    mwState.moduleData.currentFolder,
    mwState.moduleData.sortDesc,
  ]; //used for reloading
  if (mwState.searchExecuted) options[4] = mwState.searchText;
  const resendPendingMessages = mwState.resendPendingMessages;
  const markMessageAsRead = mwState.markMessageAsRead;

  function getMessageSentResult(result) {
    if (mwState.moduleData.currentFolder === "monitor") {
      return "-primary";
    }
    if (result === "pending") {
      return "-warning";
    } else if (result === "sent") {
      return "-success";
    } else if (result === "fail") {
      return "-danger";
    } else {
      return;
    }
  }
  function moveMessagesToTrash() {
    mwState.moveMessagesToTrash([data.id], options);
  }
  function restoreDeletedMessages() {
    mwState.restoreDeletedMessages([data.id], options);
  }
  function replyToMessage(e) {
    mwState.changeToComposeMode(e, data.id);
  }
  function actionsRender(index) {
    switch (index) {
      case 0:
        return (
          <Grid container>
            <Grid item xs={6}>
              {authorizedUser && (
                <CustomButton
                  variant="outlined"
                  onClick={replyToMessage}
                  color="default"
                  startIcon={<ReplyIcon />}
                >
                  {Language.translate("Reply").toUpperCase()}
                </CustomButton>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                onClick={moveMessagesToTrash}
                color="default"
                startIcon={<DeleteIcon />}
              >
                {Language.translate("TRASH")}
              </CustomButton>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container>
            {/* need to add this condition later. copied from ang system */}
            {/* {_.keys(Message.get().messages[data.id].recipients.device_message_status).length && } */}
            <Grid item xs={6}>
              {authorizedUserRecipient && (
                <CustomButton
                  variant="outlined"
                  color="default"
                  onClick={() => resendPendingMessages(data.id)}
                  startIcon={<NearMeIcon />}
                >
                  {Language.translate("Resend").toUpperCase()}
                  <Badge
                    color="default"
                    className="count"
                    badgeContent={
                      _.keys(
                        Message.get().messages[data.id].recipients
                          .device_message_status
                      ).length
                    }
                    max={9999}
                  />
                </CustomButton>
              )}
            </Grid>
            <Grid item xs={6} className={classes.trash}>
              <CustomButton
                variant="outlined"
                onClick={moveMessagesToTrash}
                color="default"
                startIcon={<DeleteIcon />}
              >
                {Language.translate("TRASH")}
              </CustomButton>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container>
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                onClick={restoreDeletedMessages}
                color="default"
                startIcon={<DeleteIcon />}
              >
                {Language.translate("RESTORE")}
              </CustomButton>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  }

  useEffect(() => {
    //mark message as read only if in inbox. This seems like a bug to me, but currently it return an error if the code is executed in trash
    if (options[2] === "inbox") {
      const userId = Profile.get("id");
      const messageObj = Message.get().messages[data.id];
      if (!messageObj.recipients.user_recipient_info[userId].message_read)
        markMessageAsRead(data.id, options);
    }
  }, []);

  useEffect(() => {
    if (mwState.reloadMessages) {
      mwState.refreshMessageCounts();
      setMwState((p) => ({ ...p, reloadMessages: false }));
    }
  }, [mwState.reloadMessages]);

  const useStyles = makeStyles((theme) => ({
    ...moduleMessage(theme),
    trash: {
      textAlign: "end",
    },
  }));
  const classes = useStyles();

  const authorizedUser =
    Message.get().messages[data.id].senders.devices.length > 0 &&
    Device.get(data.senders.devices) === undefined
      ? false
      : true;
  const authorizedUserRecipient =
    Message.get().messages[data.id].recipients.devices.length > 0 &&
    Device.get(data.recipients.devices) === undefined
      ? false
      : true;
  const handleAttachmentDownload = (e, attachment) => {
    e.preventDefault();
    Message.getAttachment(attachment.id)
      .then((response) => {
        var data = response.data.result;

        if (data) {
          MessageMenu.downloadAttachment(attachment, data);
        } else {
          Utils.notify(
            Language.translate("Fail to get attachment"),
            "",
            "",
            "error"
          );
        }
      })
      .catch(() => {
        Utils.notify(
          Language.translate("Fail to get attachment"),
          "",
          "",
          "error"
        );
      });
  };

  return (
    <Grid container className={classes.messageContainer}>
      <Grid item xs={9} className="date">
        {Clock.formatTimestamp(data.message_timestamp)}
      </Grid>
      <Grid item xs={3} className="action">
        {actionsRender(folderIndex)}
      </Grid>
      <Grid item xs={12}>
        <h2>
          {authorizedUser
            ? Message.get().messages[data.id].senders.devices.map(
                (deviceId) => Device.get(deviceId)?.name
              )
            : null}
          {Message.get().messages[data.id].senders?.users
            ? Message.get().messages[data.id].senders.users.map((userId) => {
                const getUser = User.get(userId);
                return getUser
                  ? getUser.first_name + " " + getUser.last_name
                  : "";
              })
            : null}
        </h2>
        {_.keys(data.attachments).length > 0 && authorizedUser
          ? Message.get().messages[data.id].attachments.map((attachment) => {
              return (
                <div className="attachments" key={attachment.attachment_name}>
                  <CustomTooltip
                    title={attachment.attachment_name}
                    arrow
                    placement="top"
                  >
                    <span
                      onClick={(e) => handleAttachmentDownload(e, attachment)}
                    >
                      <AttachmentIcon />
                      <span>
                        {attachment.attachment_name} (
                        {attachment.attachment_size}
                        kb)
                      </span>
                    </span>
                  </CustomTooltip>
                </div>
              );
            })
          : null}
      </Grid>
      <Divider />
      <Grid item xs={12}>
        {Message.get().messages[data.id].recipients.devices
          ? Message.get().messages[data.id].recipients.devices.map(
              (deviceId) => {
                return (
                  <Chip
                    className={`chip${getMessageSentResult(
                      Message.get().messages[data.id].recipients
                        .device_message_status[deviceId].message_status
                    )}`}
                    avatar={
                      <Avatar>
                        <LaptopMacIcon />
                      </Avatar>
                    }
                    label={
                      Device.get(deviceId)?.name === undefined
                        ? ""
                        : Device.get(deviceId)?.name
                    }
                    variant="outlined"
                    key={deviceId}
                  />
                );
              }
            )
          : null}
        {Message.get().messages[data.id].recipients.users &&
        Permission.verify("user", "view")
          ? Message.get().messages[data.id].recipients.users.map((userId) => {
              const getUser = User.get(userId);
              return (
                <Chip
                  className="chip-success"
                  avatar={
                    <Avatar>
                      <LaptopMacIcon />
                    </Avatar>
                  }
                  label={
                    getUser ? getUser.first_name + " " + getUser.last_name : ""
                  }
                  variant="outlined"
                  key={userId}
                />
              );
            })
          : null}
      </Grid>
      <Grid item xs={12}>
        {authorizedUser ? (
          <>
            <h3>{Language.translate("Canned Message")}</h3>
            {Message.getCannedMessageById(data.cannedmessage_id)}
          </>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        {authorizedUser ? (
          <>
            <h3>{Language.translate("Message")}</h3>
            {data.message}
          </>
        ) : (
          <p>
            {Language.translate(
              "You DO NOT have authorization to view this message."
            )}
          </p>
        )}
      </Grid>
    </Grid>
  );
}

export default MessageRead;
