const io = require("socket.io-client");
const Utils = require("sccUtils").default;
const _ = require("lodash");

const log = require("loglevel");
log.setLevel(log.levels.DEBUG);

/**
 * Socket class
 *
 * @class
 */
class Socket {
  constructor() {
    const $this = this;
    this.moduleName = "socket";
    // shows whether or not the socket is disconnected
    this.isDisconnected = false;
    this.count = 0;
    this.socket = io({
      transports: ["polling", "websocket"],
      auth: {
        token: "Bearer " + Utils.getCookie("accesstoken"),
      },
    });


    // Handling token expiration
    this.socket.on("connect_error", (error) => {
      if (error.data?.type === "UnauthorizedError") {
        log.warn("User token has expired");
      }
      log.warn("socket error: " + JSON.stringify(error));
    });

    this.socket.on("messages", function (data) {
      log.info(
        "Socket event in new handler with data: " + JSON.stringify(data)
      );
    });

    this.socket.on("connect", function () {
      log.info("Socket: connected");
      $this.isDisconnected = false;
      clearTimeout(this.count);
      $this.socket.emit("authenticate", {
        token: Utils.getCookie("accesstoken"),
      });
    });

    this.socket.on("authenticated", function () {
      log.info("Socket: authentication successfull");
    });

    this.socket.on("unauthorized", function () {
      log.warn("Socket: authentication failed");
    });

    this.socket.on("error", function (err) {
      log.warn("Socket: error", err);
    });

    this.socket.on("disconnect", function () {
      log.warn("Socket: disconnected");
      this.count = setTimeout(function () {
        $this.isDisconnected = true;
      }, 30000);
    });

    /**
     * socket event for when MH sends a sync request
     */
    this.socket.on("get:/sync/:comm_id/:watermark", function (data) {
      var Device = require("sccDevice").default;
      log.info("Socket: get:/sync/:comm_id/:watermark", data);
      Utils.notify(data.message);
      return Device.setSyncInfo(data.result);
    });

    /**
     * sets the socket event for tactical sync plugin updates
     */
    this.socket.on("plugin:sync", function (data) {
      var Device = require("sccDevice").default;
      log.info("Socket: plugin:sync", data);

      Utils.notify(data.message, "", "", "success");
      return Device.setSyncInfo(data.result);
    });
  }

  init($scope) {
    const Device = require("sccDevice").default;
    // operations that need to run upon receiving new device report
    // TODO: once the asset module is revised, this code should be changed to
    // only apply the changes on the fron-end and refresh corresponding map layers
    this.socket.on("post:/report", function (data) {
      log.info("Socket: post:/report", data);
      var DeviceOverlay = require("sccDeviceOverlay").default;
      var report = data.result;

      // refining the report object
      var deviceId = report.device_id;

      const device = Device.get(deviceId);
      
      // hermes module update
      if (
        Device.getHermesDevices().includes(device.type)
      ) {
        const HermesGateways = require("sccHermesGateways").default;
        HermesGateways.set(report, deviceId);
      }

      report = _.omit(report, ["id", "device_id"]);

      Device.set(report, deviceId);

      if ($scope) {
        $scope.safeApply();
      }

      const {
        default: DeviceDataDisplay,
      } = require("../modules/device/components/DataDisplay");

      DeviceDataDisplay.updateFeatureSelected();

      //DeviceMap.updateFeatures(Device.get(deviceId));
      if (DeviceOverlay.initialized) {
        DeviceOverlay.refresh(Device.get(deviceId), null);
		    DeviceOverlay.hideClusterPopup();
      }
    });

    return Promise.resolve();
  }
}
export default new Socket();
