import React, { useState, useContext } from "react";

//Material UI
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// Components
import AssetSelectorDisplay from "@Components/CustomAssetWrapper/AssetSelectorListingDisplay/AssetSelectorDisplay";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomIcon from "@Components/CustomIcon";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import SADetailSubscriber from "./SADetailSubscriber";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function SADetail(props) {
  const data = props.data;
  const [value, setValue] = useState(0);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const buildCollectionToDisplay = assetSelectorState.buildCollectionToDisplay;

  function handleTabs(e, val) {
    setValue(val);
  }

  const classes = useStyles();

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={Language.translate("Information")}
            icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
          />
          <Tab
            label={Language.translate("Subscribers")}
            icon={
              <Badge
                badgeContent={
                  Group.getDeviceCount(
                    data.subscribers,
                    Group.getGroupTactical()
                  ) !== 0
                    ? Group.getDeviceCount(
                        data.subscribers,
                        Group.getGroupTactical()
                      )
                    : null // "0" will indicate no devices
                }
                color="primary"
              >
                <CustomIcon fontSize="small" id="icon-group" />
              </Badge>
            }
          />
          <Tab
            label={Language.translate("Members")}
            icon={
              <Badge
                badgeContent={
                  Group.getDeviceCount(data.members) !== 0
                    ? Group.getUniqueDeviceCount(data.members)
                    : null // "0" will indicate no devices
                }
                color="primary"
              >
                <CustomIcon fontSize="small" id="icon-group" />
              </Badge>
            }
          />
        </Tabs>
        {value === 0 && (
          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst splitIn3"
                primary={Language.translate("Title").toUpperCase() + ":"}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.title}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst splitIn3"
                primary={
                  Language.translate("Interval").toUpperCase() +
                  Language.translate(" (Seconds)").toUpperCase() +
                  ":"
                }
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.interval}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                md={4}
                className="itemTextFirst splitIn3"
                primary={
                  Language.translate("Distance").toUpperCase() +
                  " (" +
                  Language.translate("Meters").toUpperCase() +
                  "):"
                }
              />
              <ListItemText
                md={8}
                className="itemTextSecond"
                primary={data.distance}
              />
            </ListItem>
          </List>
        )}
        {value === 1 && (
          <List>
            {Group.getDeviceCount(
              data.subscribers,
              Group.getGroupTactical()
            ) !== 0 ? (
              data.subscribers &&
              data.subscribers.devices.map((deviceId) => {
                const device = Device.get(deviceId);
                if (device) {
                  return <SADetailSubscriber device={device} key={deviceId} />;
                }
              })
            ) : (
              <ListItem className={classes.noResults}>
                <ListItemText className="itemTextAlone">
                  {Language.translate("There are no devices in Subscribers")}
                </ListItemText>
              </ListItem>
            )}
          </List>
        )}
        {value === 2 && (
          <List>
            {Group.getDeviceCount(data.members) !== 0 ? (
              <>
                <AssetSelectorDisplay
                  collection={{
                    groups: [buildCollectionToDisplay(data.members)],
                  }}
                  name="members"
                />
              </>
            ) : (
              <ListItem className={classes.noResults}>
                <ListItemText className="itemTextAlone">
                  {Language.translate("There are no Assets in Members")}
                </ListItemText>
              </ListItem>
            )}
          </List>
        )}
      </Grid>
    </Grid>
  );
}
