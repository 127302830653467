import React, { useContext, useEffect } from "react";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import FeedsDetail from "./FeedsDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

export default function FeedsList(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const data = props.data;
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...p.moduleItemData },
    }));
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon>
          <CustomTooltip title={data.title} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText id={data.id} primary={`${data.title}`} />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {data.role_id !== 2 ? (
            <React.Fragment>
              {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Edit")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              )}
              {mwState.verifyDeletePermission && (
                <CustomTooltip
                  title={Language.translate("Delete")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() =>
                      handleItemDelete(
                        `${mwState.routeUrl}/${data.id}`,
                        "Feed",
                        data.title
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </React.Fragment>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <FeedsDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
