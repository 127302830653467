import React, { useContext, useState } from "react";
// External
import { CompactPicker } from "react-color";
import * as Yup from "yup";

// Material-UI

import { Button, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

// Internal
import CustomSwitch from "@Components/CustomSwitch";
import Language from "sccLanguage";
import Permission from "sccPermission";
import CustomDialogYesNo from "@Components/CustomDialogYesNo/CustomDialogYesNo";
import CustomTextField from "@Components/CustomTextField/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip/CustomTooltip";
import { colorPicker } from "@Styles/theme";
import _ from "lodash";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { UserSettingContext } from "@Modules/user_setting/context/UserSettingContext";
import baseSchema from "@Validations/BaseValidation";
import { GeofenceSettingContext } from "@Modules/geofence/context/GeofenceSettingContext";
import GeofenceSetting from "@Modules/geofence/scripts/GeofenceSetting";

// This function is the setting tab for the Geofence Setting. It inlcudes activation and customization of color palettes.
function GefenceColourUtility(props) {
  const Geofence = require("sccGeofence").default;
  const geofences = Geofence.get();
  const routeUrlGeoSetting = "api/v1/geofence/geofence_setting";
  const alertMessage = Language.translate(
    "Are you sure! Disabling this feature will revert any colors to their defaults. This may cause temporary performance spikes."
  );

  const [mwState] = useContext(ModuleWrapperContext);
  const updateModuleData = mwState.updateModuleData;
  const labelSchema = baseSchema(Language).notRequiredStringMinMaxEmptyString;

  const [showInPicker, setShowInPicker] = useState(false);
  const [showExPicker, setShowExPicker] = useState(false);
  const { userSettingData, setUserSettingData } =
    useContext(UserSettingContext);
  const [geofenceSettingData, setGeofenceSettingData] = useState(
    useContext(GeofenceSettingContext)
  );
  const startUpIsColorActive = GeofenceSetting.get("custom_colour_enabled")
    ? JSON.parse(GeofenceSetting.get("custom_colour_enabled"))
    : false;
  const startUpInclusivePalette = GeofenceSetting.get("inclusive_palette")
    ? JSON.parse(GeofenceSetting.get("inclusive_palette"))
    : [];
  const startUpExclusivePalette = GeofenceSetting.get("exclusive_palette")
    ? JSON.parse(GeofenceSetting.get("exclusive_palette"))
    : [];
  const [InclusiveColors, setInclusiveColor] = useState(
    startUpInclusivePalette
  );

  const [inclusiveLabel, setInclusiveLabel] = useState("");
  const [ExclusiveColors, setExclusiveColor] = useState(
    startUpExclusivePalette
  );
  const [exclusiveLabel, setExclusiveLabel] = useState("");
  const [showPalette, setShowPalette] = useState(startUpIsColorActive);
  const [showDeleteWarning, setDeleteShowWarning] = useState();
  const [showDeleteWarningExclusive, setDeleteShowWarningExclusive] =
    useState();
  const [deleteIndex, setDeleteIndex] = useState();
  const [tempColor, setTempColor] = useState("");
  const [colorId, setColorId] = useState("");

  // Colour picker index
  const [selectedInclusiveColorIndex, setSelectedInclusiveColorIndex] =
    useState(0);
  const [selectedExclusiveColorIndex, setSelectedExclusiveColorIndex] =
    useState(0);

  const [cirleInHover, setCircleInHover] = useState(null);
  const [cirleExHover, setCircleExHover] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [exclusiveSpacing, setExclusiveSpacing] = useState(320);
  const [inclusiveSpacing, setInclusiveSpacing] = useState(320);

  const [tempCreationState, setTempCreationState] = useState(true);
  const [editObject, setEditObject] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const maxColourListLength = 8;
  let activeShowPaletteState = showPalette;

  // test dataPacket
  const dataPacket = {
    showPalette,
    InclusiveColors,
    ExclusiveColors,
  };

  const messageOn = {
    title: Language.translate("Geofence Settings"),
    text: Language.translate("Successfully Updated"),
    noRefresh: true,
  };

  function updateAndStay(routeUrlGeoSetting, message) {
    if (GeofenceSetting.get("id") || GeofenceSetting.get("client_id")) {
      geofenceSettingData.id = JSON.parse(GeofenceSetting.get("id"));
      geofenceSettingData.client_id = JSON.parse(
        GeofenceSetting.get("client_id")
      );
    }

    geofenceSettingData.custom_colour_enabled = dataPacket.showPalette;
    geofenceSettingData.inclusive_palette = JSON.stringify(
      dataPacket.InclusiveColors
    );
    geofenceSettingData.exclusive_palette = JSON.stringify(
      dataPacket.ExclusiveColors
    );

    updateModuleData(geofenceSettingData, routeUrlGeoSetting, message).then(
      (data) => {
        if (!data.error && data.result !== null) {
          setGeofenceSettingData(data.result);
        }
      }
    );
  }
  const updateDataSet = (message) => {
    updateAndStay(routeUrlGeoSetting, message);
  };

  //generates a random alphanumeric (base36) id prefixed with an underscore
  const idGenerator = () => {
    let id = "_" + Math.random().toString(36).substring(2, 9);
    return id;
  };
  //returns id after duplication check
  const handleIdGeneration = () => {
    let inclusiveIds = startUpInclusivePalette;
    let exclusiveIds = startUpExclusivePalette;
    let idGenerated = idGenerator();
    for (
      let inclusiveIdsIndex = 0;
      inclusiveIdsIndex < inclusiveIds.length;
      inclusiveIdsIndex++
    ) {
      if (inclusiveIds[inclusiveIdsIndex].id === idGenerated) {
        idGenerated = idGenerator();
      }
    }
    for (
      let exclusiveIdsIndex = 0;
      exclusiveIdsIndex < exclusiveIds.length;
      exclusiveIdsIndex++
    ) {
      if (exclusiveIds[exclusiveIdsIndex].id === idGenerated) {
        idGenerated = idGenerator();
      }
    }

    return idGenerated;
  };
  const handleLabelValidation = (labelData) => {
    labelSchema
      .validate(labelData)
      .then((validLabel) => {
        setError(false);
        setErrorMessage("");
      })
      .catch((invalidLabel) => {
        setError(true);
        setErrorMessage(invalidLabel.errors[0]);
      });
  };
  // handles inclusive coloured circled changes on onClicks
  const handleInclusiveCircleClick = (index) => {
    setSelectedInclusiveColorIndex(index);
    // checks if we are adding a new coloured circled
    if (index === InclusiveColors.length) {
      setTempCreationState(true);
      setInclusiveLabel(""); // makes sure that the label starts as empty
    } else {
      setTempCreationState(false);
      // sets the color for the selected coloured circled
      setInclusiveLabel(""); // makes sure that the label starts as empty
      setInclusiveLabel(InclusiveColors[index].label || "");
      setColorId(
        InclusiveColors[index].id
          ? InclusiveColors[index].id
          : handleIdGeneration()
      );
      let colorObj = {
        id: colorId,
        hex: InclusiveColors[index].hex,
        label: InclusiveColors[index].label,
      };
      setEditObject(colorObj);
    }
    // shows the color picker
    setShowInPicker(true);
    let maxCircleTotalLength = 320;
    let individualCircleLength = 320 / maxColourListLength;
    // spaces selected picker according to its colored index value, 320px was found to be a fitting lenght for the coloured circled
    if (InclusiveColors.length === maxColourListLength) {
      let spacing =
        maxCircleTotalLength -
        (InclusiveColors.length - index - 1) * individualCircleLength;
      setInclusiveSpacing(spacing);
    } else {
      let spacing =
        maxCircleTotalLength -
        (InclusiveColors.length - index) * individualCircleLength;
      setInclusiveSpacing(spacing);
    }
  };
  // handles exclusive coloured circled changes on onClicks
  const handleExclusiveCircleClick = (index) => {
    setSelectedExclusiveColorIndex(index);
    // checks if we are adding a new coloured circled
    if (index === ExclusiveColors.length) {
      setExclusiveLabel(""); // makes sure that the label starts as empty
    } else {
      //
      setTempCreationState(false);
      setExclusiveLabel("");
      setExclusiveLabel(ExclusiveColors[index].label || "");
      setColorId(
        ExclusiveColors[index].id
          ? ExclusiveColors[index].id
          : handleIdGeneration()
      );
      let colorObj = {
        id: colorId,
        hex: ExclusiveColors[index].hex,
        label: ExclusiveColors[index].label,
      };
      setEditObject(colorObj);
    }
    // shows the color picker
    setShowExPicker(true);
    let maxCircleTotalLength = 320;
    let individualCircleLength = 320 / maxColourListLength;
    // spaces selected picker according to its colored index value, 320px was found to be a fitting lenght for the coloured circled
    if (ExclusiveColors.length === maxColourListLength) {
      let spacing =
        maxCircleTotalLength -
        (ExclusiveColors.length - index - 1) * individualCircleLength;
      setExclusiveSpacing(spacing);
    } else {
      let spacing =
        maxCircleTotalLength -
        (ExclusiveColors.length - index) * individualCircleLength;
      setExclusiveSpacing(spacing);
    }
  };
  const handleInClosePicker = () => {
    setShowInPicker(false);
    setTempColor("");
    if (tempCreationState) {
      handleInclusiveColorDelete(selectedInclusiveColorIndex);
    } else {
      let newColors = [...InclusiveColors];
      newColors[selectedInclusiveColorIndex] = editObject;
      setEditObject({});
      setInclusiveColor(newColors);
    }
    setError(false);
    setErrorMessage("");
  };

  const handleExClosePicker = () => {
    setShowExPicker(false);
    if (tempCreationState) {
      handleExclusiveColorDelete(selectedExclusiveColorIndex);
    } else {
      let newColors = [...ExclusiveColors];
      newColors[selectedExclusiveColorIndex] = editObject;
      setEditObject({});
      setExclusiveColor(newColors);
    }
    setError(false);
    setErrorMessage("");
  };

  // adds/edit color and labels
  const handleInclusiveColorChange = (color) => {
    setTempColor(color.hex);
    const newColor = [...InclusiveColors];
    newColor[selectedInclusiveColorIndex] = {
      id: colorId ? colorId : handleIdGeneration(),
      hex: color.hex,
      label: inclusiveLabel,
    };
    setInclusiveColor(newColor);
    dataPacket.InclusiveColors = newColor;
  };

  // inclusive label can be save without changing the color
  const handleInclusiveSave = () => {
    const newColors = [...InclusiveColors];
    handleLabelValidation(inclusiveLabel);
    if (error === false) {
      if (newColors[selectedInclusiveColorIndex]) {
        const existingColor = newColors[selectedInclusiveColorIndex];
        existingColor.label = inclusiveLabel;
      }
      setInclusiveColor(newColors);
      dataPacket.InclusiveColors = newColors;
      updateDataSet(messageOn);
      setShowInPicker(false);
      setTempColor("");
      setColorId("");
    }
  };

  // adds/edit color and labels
  const handleExclusiveColorChange = (color) => {
    setTempColor(color.hex);
    const newColor = [...ExclusiveColors];
    newColor[selectedExclusiveColorIndex] = {
      id: colorId ? colorId : handleIdGeneration(),
      hex: color.hex,
      label: exclusiveLabel,
    };
    setExclusiveColor(newColor);
    dataPacket.ExclusiveColors = newColor;
  };

  // exlusive label can be save without changing the color
  const handleExclusiveSave = () => {
    const newColors = [...ExclusiveColors];
    handleLabelValidation(exclusiveLabel);
    if (error === false) {
      if (newColors[selectedExclusiveColorIndex]) {
        const existingColor = newColors[selectedExclusiveColorIndex];
        existingColor.label = exclusiveLabel;
      }
      setExclusiveColor(newColors);
      dataPacket.ExclusiveColors = newColors;
      updateDataSet(messageOn);
      setShowExPicker(false);
      setTempColor("");
      setColorId("");
    }
  };

  // deletes color according to its index positioning
  const handleInclusiveColorDelete = (index) => {
    const newColors = [...InclusiveColors];
    newColors.splice(index, 1);
    setInclusiveColor(newColors);
    dataPacket.InclusiveColors = newColors;
    updateDataSet(messageOn);
  };
  // deletes color according to its index positioning
  const handleExclusiveColorDelete = (index) => {
    const newColors = [...ExclusiveColors];
    newColors.splice(index, 1);
    setExclusiveColor(newColors);
    dataPacket.ExclusiveColors = newColors;
    updateDataSet(messageOn);
  };

  // add "|| not being used by geofences" into the if statement
  function handlesInclusiveDeleteWarning(index) {
    let id = InclusiveColors[index].id;
    let label = InclusiveColors[index].label;
    let isThereGeofences = _.isEmpty(geofences);
    let matchIds = false;
    if (!isThereGeofences) {
      for (const geoId in geofences) {
        if (geofences.hasOwnProperty(geoId)) {
          const entry = geofences[geoId];
          if (entry.custom_colour_id === id) {
            setDeleteShowWarning(true);
            setDeleteIndex(index);
            matchIds = true;
          }
        }
      }
      if (!matchIds && label === "") {
        handleInclusiveColorDelete(index);
        setDeleteShowWarning(false);
      } else {
        setDeleteShowWarning(true);
        setDeleteIndex(index);
      }
    } else {
      handleInclusiveColorDelete(index);
      setDeleteShowWarning(false);
    }
  }

  function handlesExclusiveDeleteWarning(index) {
    let id = ExclusiveColors[index].id;
    let label = ExclusiveColors[index].label;
    let isThereGeofences = _.isEmpty(geofences);
    let matchIds = false;
    if (!isThereGeofences) {
      for (const geoId in geofences) {
        if (geofences.hasOwnProperty(geoId)) {
          const entry = geofences[geoId];
          if (entry.custom_colour_id === id) {
            setDeleteShowWarningExclusive(true);
            setDeleteIndex(index);
            matchIds = true;
          }
        }
      }
      if (!matchIds && label === "") {
        handleExclusiveColorDelete(index);
        setDeleteShowWarningExclusive(false);
      } else {
        setDeleteShowWarningExclusive(true);
        setDeleteIndex(index);
      }
    } else {
      handleExclusiveColorDelete(index);
      setDeleteShowWarningExclusive(false);
    }
  }
  // "no" action for the delete inclusive and exclusive warning
  const handleNoDeleteAction = () => {
    setDeleteShowWarning(false);
    setDeleteShowWarningExclusive(false);
  };
  // "yes" action for the delete inclusive warning
  const handleYesDeleteAction = () => {
    handleInclusiveColorDelete(deleteIndex);
    setDeleteShowWarning(false);
  };
  // "yes" action for the delete excluive warning
  const handleYesDeleteActionExclusive = () => {
    handleExclusiveColorDelete(deleteIndex);
    setDeleteShowWarningExclusive(false);
  };

  // display warning message with label or with index number
  const colorAlertMessage = () => {
    let color = "";
    if (InclusiveColors[deleteIndex].label !== "") {
      color = InclusiveColors[deleteIndex].label;
    } else {
      color = "color #" + (deleteIndex + 1);
    }
    return `Are you sure you want to delete "${color}"? This may cause temporary performance spikes.`;
  };

  // display warning message with label or with index number
  const colorAlertMessageExclusive = () => {
    let color = "";
    if (ExclusiveColors[deleteIndex].label !== "") {
      color = ExclusiveColors[deleteIndex].label;
    } else {
      color = "color #" + (deleteIndex + 1);
    }
    return `Are you sure you want to delete "${color}"? This may cause temporary performance spikes.`;
  };

  const style = {
    addCircle: {
      display: "flex",
      backgroundColor: "#e0e0e0",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      border: "2px solid black",
      margin: "5px",
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 20,
    },
    circlesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    coloredCircle: {
      position: "relative",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      border: "2px solid black",
      marginLeft: "5px",
      marginRight: "5px",
      marginBottom: "-5px",
      marginTop: "-5px",
      margin: "-5px 5px -5px 5px",
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
    },
    closingTab: {
      position: "absolute",
      top: "-8px",
      right: "-8px",
      backgroundColor: "white",
      borderRadius: "40%",
      border: "2px solid black",
      width: "12px",
      height: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
    },
    geoTitle: {
      fontWeight: "normal",
      fontSize: "24px",
      marginTop: "0px",
      marginBottom: "10px",
    },
    pickerInclusiveContainer: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "245px",
      marginTop: "-7px",
      zIndex: "1000",
      marginTop: "-7px",
      marginLeft: `${inclusiveSpacing}px`,
    },
    pickerInclusive: {
      position: "fixed",
      top: "0px",
      right: "0px",
      left: "0px",
      bottom: "0px",
    },
    pickerExclusiveContainer: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "245px",
      marginTop: "-7px",
      zIndex: "1000",
      marginTop: "-7px",
      marginLeft: `${exclusiveSpacing}px`,
    },
    pickerExclusive: {
      position: "fixed",
      top: "0px",
      right: "0px",
      left: "0px",
      bottom: "0px",
    },
  };

  // handles any action with the color pickers, opens and closes them. Also, shows alert when false
  function handleShowPalette() {
    activeShowPaletteState = !showPalette;
    setShowPalette(activeShowPaletteState);
    if (activeShowPaletteState) {
      setShowAlert(false);
      dataPacket.showPalette = activeShowPaletteState;
      updateDataSet(messageOn);
    } else {
      setShowAlert(true);
    }
  }

  //handles reponse from alert
  const handleYesAction = () => {
    setShowAlert(false);
    dataPacket.showPalette = activeShowPaletteState;
    updateDataSet(messageOn);
  };
  //handles reponse from alert
  const handleNoAction = () => {
    setShowAlert(false);
    setShowPalette(true);
    dataPacket.showPalette = !activeShowPaletteState;
  };

  return (
    <div className="SettingsBody">
      <h4 style={style.geoTitle}>{Language.translate("Geofence Utility")}</h4>
      <List className="listing">
        {
          <>
            <ListItem className="listItem">
              <ListItemText primary={"Custom Colour Palette"} />
              <ListItemSecondaryAction>
                <CustomSwitch
                  name="geo_palette"
                  onChange={handleShowPalette}
                  checked={showPalette}
                  color="primary"
                  labelPlacement="top"
                />
              </ListItemSecondaryAction>
            </ListItem>
            {showPalette && (
              <ListItem className="listItem">
                <ListItemText
                  primary={Language.translate("Inclusive Palette")}
                />
                <ListItemSecondaryAction>
                  <>
                    <div style={style.circlesContainer}>
                      {InclusiveColors.map((color, index) => {
                        const circleStyle = {
                          ...style.coloredCircle,
                          backgroundColor: color.hex,
                        };
                        const closingX = {
                          ...style.closingTab,
                          opacity: cirleInHover === index ? 1 : 0,
                        };
                        return (
                          <CustomTooltip
                            key={index}
                            title={InclusiveColors[index].label}
                            arrow
                            placement="bottom"
                          >
                            <div
                              key={index}
                              style={circleStyle}
                              onClick={() => handleInclusiveCircleClick(index)}
                              onMouseOver={(e) => (
                                (e.currentTarget.style.transform =
                                  "scale(1.1)"),
                                setCircleInHover(index)
                              )}
                              onMouseOut={(e) => (
                                (e.currentTarget.style.transform = "scale(1)"),
                                setCircleInHover(null)
                              )}
                            >
                              <div
                                style={closingX}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlesInclusiveDeleteWarning(index);
                                }}
                              >
                                X
                              </div>
                            </div>
                          </CustomTooltip>
                        );
                      })}
                      {InclusiveColors.length < maxColourListLength && (
                        <div
                          style={style.addCircle}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.15)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                          onClick={() =>
                            handleInclusiveCircleClick(InclusiveColors.length)
                          }
                        >
                          +
                        </div>
                      )}
                    </div>
                  </>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            {showInPicker && (
              <div style={style.pickerInclusiveContainer}>
                <div
                  style={style.pickerInclusive}
                  onClick={handleInClosePicker}
                ></div>

                <div>
                  <CompactPicker
                    color={InclusiveColors[selectedInclusiveColorIndex]}
                    onChange={handleInclusiveColorChange}
                    colors={colorPicker}
                  />
                  <CustomTextField
                    style={{ backgroundColor: "white" }}
                    variant="filled"
                    label=" Label: "
                    value={inclusiveLabel}
                    onChange={(e) => {
                      setInclusiveLabel(e.target.value);
                      handleLabelValidation(e.target.value);
                    }}
                    error={error}
                    helperText={errorMessage}
                  />
                  <Button variant="contained" onClick={handleInclusiveSave}>
                    Save
                  </Button>
                  <Button variant="contained" onClick={handleInClosePicker}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}
            {showPalette && (
              <ListItem className="listItem">
                <ListItemText
                  primary={Language.translate("Exclusive Palette")}
                />
                <ListItemSecondaryAction>
                  <>
                    <div style={style.circlesContainer}>
                      {ExclusiveColors.map((color, index) => {
                        const circleStyle = {
                          ...style.coloredCircle,
                          backgroundColor: color.hex,
                        };
                        const closingX = {
                          ...style.closingTab,
                          opacity: cirleExHover === index ? 1 : 0,
                        };

                        return (
                          <CustomTooltip
                            key={index}
                            title={ExclusiveColors[index].label}
                            arrow
                            placement="bottom"
                          >
                            <div
                              key={index}
                              style={circleStyle}
                              onClick={() => handleExclusiveCircleClick(index)}
                              onMouseOver={(e) => (
                                (e.currentTarget.style.transform =
                                  "scale(1.1)"),
                                setCircleExHover(index)
                              )}
                              onMouseOut={(e) => (
                                (e.currentTarget.style.transform = "scale(1)"),
                                setCircleExHover(null)
                              )}
                            >
                              <div
                                style={closingX}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlesExclusiveDeleteWarning(index);
                                }}
                              >
                                X
                              </div>
                            </div>
                          </CustomTooltip>
                        );
                      })}
                      {ExclusiveColors.length < maxColourListLength && (
                        <div
                          style={style.addCircle}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.15)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                          onClick={() =>
                            handleExclusiveCircleClick(ExclusiveColors.length)
                          }
                        >
                          +
                        </div>
                      )}
                    </div>
                  </>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            {showExPicker && (
              <div style={style.pickerExclusiveContainer}>
                <div
                  style={style.pickerExclusive}
                  onClick={handleExClosePicker}
                ></div>
                <CompactPicker
                  color={ExclusiveColors[selectedExclusiveColorIndex]}
                  onChange={handleExclusiveColorChange}
                  colors={colorPicker}
                />
                <div>
                  <CustomTextField
                    style={{ backgroundColor: "white" }}
                    variant="filled"
                    label=" Label: "
                    value={exclusiveLabel}
                    onChange={(e) => {
                      setExclusiveLabel(e.target.value);
                      handleLabelValidation(e.target.value);
                    }}
                    error={error}
                    helperText={errorMessage}
                  />
                  <Button variant="contained" onClick={handleExclusiveSave}>
                    Save
                  </Button>
                  <Button variant="contained" onClick={handleExClosePicker}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </>
        }
      </List>
      {showAlert && (
        <CustomDialogYesNo
          onYesAction={handleYesAction}
          onNoAction={handleNoAction}
          open={true}
        >
          {alertMessage}
        </CustomDialogYesNo>
      )}
      {showDeleteWarning && (
        <CustomDialogYesNo
          onYesAction={handleYesDeleteAction}
          onNoAction={handleNoDeleteAction}
          open={true}
        >
          {colorAlertMessage()}
        </CustomDialogYesNo>
      )}
      {showDeleteWarningExclusive && (
        <CustomDialogYesNo
          onYesAction={handleYesDeleteActionExclusive}
          onNoAction={handleNoDeleteAction}
          open={true}
        >
          {colorAlertMessageExclusive()}
        </CustomDialogYesNo>
      )}
    </div>
  );
}

export default GefenceColourUtility;
