import React, { useState, useEffect } from "react";

//Material UI
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";

const SADetailSubscriber = ({ device }) => {
  // UPDATE AGE dynamically
  const [age, setAge] = useState(
    TimeUtils.getAge(device.latest_sa_timestamp) || "N/A"
  );

  // update age state
  const calcAge = (rt) => {
    setAge(TimeUtils.getAge(rt));
  };

  useEffect(() => {
    //  update age every one second
    if (device.latest_sa_timestamp) {
      const interval = setInterval(
        () => calcAge(device.latest_sa_timestamp),
        1000
      );

      calcAge(device.latest_sa_timestamp);

      return () => {
        clearInterval(interval);
      };
    } else {
      setAge("N/A");
    }
  }, [device, device.latest_sa_timestamp]);

  return (
    <ListItem>
      <ListItemText
        className="itemTextAlone"
        primary={device.name}
        secondary={
          device.recently_updated_sa_timestamp ? (
            `${Language.translate("Pending")}...`
          ) : device.latest_sa_timestamp !== 0 ? (
            <CustomTooltip
              title={Language.translate("Last SA Sent")}
              placement="top"
            >
              <span>{age}</span>
            </CustomTooltip>
          ) : null
        }
      />
    </ListItem>
  );
};

export default SADetailSubscriber;
