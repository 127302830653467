import React, { useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import HistorySlider from "./HistorySlider/index.js";
import ReplayBarButtonGroup from "./ReplayBarButtonGroup/index.js";
import DisplayCentralLabels from "./DisplayCentralLabels/DisplayCentralLabels";
import PlaybarSettings from "./PlaybarSettings/PlaybarSettings";
import EventReports from "./EventReports/EventReports";
import { HistoryContext } from "../HistoryContext";

const useStyles = makeStyles((theme) => ({
  bottomPanel: {
    position: "fixed",
    width: "100%",
    left: "0",
    right: "0",
    bottom: "-120px",
    height: "70px",
    transition: "0.2s",
    background: theme.palette.colors.white.main,
    padding: "0 20px 10px 20px",
    zIndex: "509",
    boxShadow:
      `0 16px 24px 2px ${theme.palette.colors.gray.dark}, ` +
      `0 6px 30px 5px ${theme.palette.colors.gray.main}, ` +
      `0 8px 10px -5px ${theme.palette.colors.gray.white}`,
    "&.active": {
      bottom: "0",
    },
  },

  playbarButtons: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    zIndex: "508",
    "& .MuiButton-startIcon": {
      margin: 0,
    },

    "& .MuiButtonGroup-contained": {
      boxShadow: "none",
      "& div": {
        height: "35px",
        minWidth: "35px",
        alignItems: "center",
        marginLeft: "5px",
        "& .MuiButton-contained": {
          backgroundColor: theme.palette.colors.white.main,
        }
      },
    },
  },
}));

export default function BottomPanel() {
  const [state] = useContext(HistoryContext);
  const showHistoryControls = state.showHistoryControls;

  useEffect(() => {
    //moves map bottom left elements so that they aren't hidden by history controls bar
    const olMap = require("sccOlMapNew").default;
    if (showHistoryControls) {
      if (olMap.currentMapsAPI === "OlMaps") {
        _.each(
          document.getElementsByClassName("mapBottomLeftElements"),
          (element) => {
            element.style.bottom = "70px";
          }
        );
      } else if (olMap.currentMapsAPI === "googleMaps") {
        _.each(
          document.getElementsByClassName("mapBottomLeftElements"),
          (element) => {
            element.style.bottom = "90px";
          }
        );
      }
    } else {
      if (olMap.currentMapsAPI === "OlMaps") {
        _.each(
          document.getElementsByClassName("mapBottomLeftElements"),
          (element) => {
            element.style.bottom = "0px";
          }
        );
      } else if (olMap.currentMapsAPI === "googleMaps") {
        _.each(
          document.getElementsByClassName("mapBottomLeftElements"),
          (element) => {
            element.style.bottom = "20px";
          }
        );
      }
    }
  });

  const classes = useStyles();

  return (
    <div
      className={`${classes.bottomPanel} ${
        showHistoryControls ? "active" : ""
      }`}
    >
      {showHistoryControls ? (
        <Grid container>
          <Grid item xs={12}>
            <HistorySlider />
          </Grid>
          <Grid container direction="row" item xs={12}>
            <Grid item xs={3}>
              <ReplayBarButtonGroup />
            </Grid>
            <Grid item xs={6}>
              <DisplayCentralLabels />
            </Grid>
            <Grid item xs={3}>
              <div className={classes.playbarButtons}>
                <ButtonGroup
                  aria-label="outlined vertical contained primary button group"
                  variant="contained"
                >
                  <PlaybarSettings />
                  <EventReports />
                </ButtonGroup>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
}
