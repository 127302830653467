import { default as Module } from "sccModule";
import Utils from "sccUtils";
var Permission = require("sccPermission");
const log = require("loglevel");
const Device = require("sccDevice").default;
const _ = require("lodash");
/**
 * The base class for Hermes Gateways
 *
 * @class hermes_gateways
 */
class HermesGatewaysModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "hermes_gateways",
    };

    super(options);
  }

  init() {
    if (!Permission.default.verify("hermes_gateways", "view")) {
      log.debug("User is not permitted for Hermes module");
      return Promise.resolve();
    }

    return super.init();
  }

  onSocketDelete(url, data) {
    this._data = _.remove(this._data, function (device) {
      return device.id != data.id;
    });
  }

  onSocketUpdate(url, data) {
    if (
      (data.type == "Sentry H6120 BM" || data.type == "SAT-COM Leopard1") &&
      Device.getDeviceMode(data) == "Gateway Device"
    ) {
      var gatewayToUpdate;
      gatewayToUpdate = _.find(this._data, function (gatewayDevices) {
        return gatewayDevices.id == data.id;
      });
      /*Case where you update a device mode from standalone to gateway device.*/
      if (_.isNull(gatewayToUpdate) || _.isUndefined(gatewayToUpdate)) {
        var $this = this;
        this.getReportsForDevice(data.id).then(function (reports) {
          data.reports = reports;
          $this._data.push(data);
        });
      } else {
        _.assign(gatewayToUpdate, data);
      }
      return;
    } else if (
      /*Case where you recieve a report from a BM and it could potentially have changed modes from being a gateway device.*/
      (data.type == "Sentry H6120 BM" ||
        data.type == "SAT-COM Leopard1" ||
        data.type == "Trellisware TSM TW-950") &&
      Device.getDeviceMode(data) != "Gateway Device"
    ) {
      this._data = _.filter(this._data, function (device) {
        return device.id != data.id;
      });
      return;
    }
  }

  onSocketAdd(url, data) {
    if (
      data.type == "Sentry H6120 BM" &&
      Device.getDeviceMode(data) == "Gateway Device"
    ) {
      this._data.push(data);
    }
  }

  setData(data, value, keys, merge) {
    merge = merge === false ? false : true; // would merge objects if merge is true or null

    if (!keys) {
      data = value || {};
      return data;
    }

    keys = _.concat([], keys); // convert to an array

    // holding the last key
    var lastKey = keys.pop();

    // getting the last object in the tree
    var obj = this.searchKeysIn(data, keys);

    var gatewayDevice;

    //Standalone devices case
    if (!_.isNull(value.parent_id) && !_.isUndefined(value.parent_id)) {
      gatewayDevice = _.find(obj, function (element) {
        return element.id == value.parent_id;
      });

      if (_.isNull(gatewayDevice) || _.isUndefined(gatewayDevice)) {
        log.error("Standalone device report has no parent device.");
        return data;
      }

      var standaloneDevice = _.find(gatewayDevice.reports, function (element) {
        return Device.get(element.device_id).comm_id == value.comm_id;
      });
      const Alert = require("sccAlert");

      //CASE where standalone device is not currently in the gateways reports list.
      //SOLUTION: append to reports array
      if (_.isNull(standaloneDevice) || _.isUndefined(standaloneDevice)) {
        if (
          _.isNull(gatewayDevice.reports) ||
          _.isUndefined(gatewayDevice.reports)
        ) {
          gatewayDevice.reports = [];
        }
        gatewayDevice.reports.push(value);

        const deviceID = value.device_id;
        const speed = Alert.getDeviceAlert(deviceID, "Speed");
        const emergency = Alert.getDeviceAlert(deviceID, "Emergency");
        const geofence = Alert.getDeviceAlert(deviceID, "Geofence");
        const nonReport = Alert.getDeviceAlert(deviceID, "Non-Report");

        if (speed != null) {
          this.updateDeviceAlert(value, speed[0]);
        }
        if (emergency != null) {
          this.updateDeviceAlert(value, emergency[0]);
        }
        if (geofence != null) {
          this.updateDeviceAlert(value, geofence[0]);
        }
        if (nonReport != null) {
          this.updateDeviceAlert(value, nonReport[0]);
        }
        return data;
      }

      if (merge && _.isObject(value)) {
        // merge if object or array and key exists
        _.assign(standaloneDevice, value);
      } else {
        // assignment if a value type
        standaloneDevice = value;
      }

      standaloneDevice.report = {
        parent_id: standaloneDevice.parent_id,
      };
      const deviceID = value.device_id;
      const speed = Alert.getDeviceAlert(deviceID, "Speed");
      const emergency = Alert.getDeviceAlert(deviceID, "Emergency");
      const geofence = Alert.getDeviceAlert(deviceID, "Geofence");
      const nonReport = Alert.getDeviceAlert(deviceID, "Non-Report");

      if (speed != null) {
        this.updateDeviceAlert(standaloneDevice, speed[0]);
      }
      if (emergency != null) {
        this.updateDeviceAlert(standaloneDevice, emergency[0]);
      }
      if (geofence != null) {
        this.updateDeviceAlert(standaloneDevice, geofence[0]);
      }
      if (nonReport != null) {
        this.updateDeviceAlert(standaloneDevice, nonReport[0]);
      }
    }
    //Gateway devices case.
    else {
      gatewayDevice = _.find(obj, function (element) {
        return element.comm_id == value.comm_id;
      });

      //Device.get(lastKey)

      // CASE: a report for a gateway device that does not exist
      if (_.isNull(gatewayDevice) || _.isUndefined(gatewayDevice)) {
        if (
          !_.isNull(Device.get(lastKey)) &&
          !_.isUndefined(Device.get(lastKey))
        ) {
          //Gateway device exists, but is not currently in the Hermes module
          log.error(
            "Gateway device not found. Cannot update hermes module with new report."
          );
          return data;
        } else {
          log.error(
            "Gateway device not found. Cannot update hermes module with new report."
          );
          return data;
        }
      }

      var tempValue = value;
      delete tempValue.id;
      _.assign(gatewayDevice, tempValue);
    }

    return data;
  }

  updateDeviceAlert(device, alert) {
    var standaloneDevice = {};
    var gatewayDevice = {};
    if (!_.isNull(device.parent_id) && !_.isUndefined(device.parent_id)) {
      gatewayDevice = _.find(this._data, function (element) {
        return element.id == device.parent_id;
      });

      standaloneDevice = _.find(gatewayDevice.reports, function (element) {
        return Device.get(element.device_id).comm_id == device.comm_id;
      });
    } else if (
      !_.isNull(device.report) &&
      !_.isUndefined(device.report) &&
      !_.isNull(device.report.parent_id) &&
      !_.isUndefined(device.report.parent_id)
    ) {
      gatewayDevice = _.find(this._data, function (element) {
        return element.id == device.report.parent_id;
      });

      standaloneDevice = _.find(gatewayDevice.reports, function (element) {
        return Device.get(element.device_id).comm_id == device.comm_id;
      });
    }

    if (
      _.isNull(standaloneDevice.alerts) ||
      _.isUndefined(standaloneDevice.alerts)
    ) {
      standaloneDevice.alerts = [];
    }
    if (alert.info.end_timestamp != null) {
      // clear alert
      var indexOfAlertToClear = standaloneDevice.alerts.indexOf(alert.type);
      if (indexOfAlertToClear > -1) {
        standaloneDevice.alerts.splice(indexOfAlertToClear, 1);
      }
    } else {
      //set alert
      var indexOfAlertToAdd = standaloneDevice.alerts.indexOf(alert.type);
      if (indexOfAlertToAdd < 0) {
        standaloneDevice.alerts.push(alert.type);
      }
    }
    log.info(
      "**** standalone device found: " + JSON.stringify(standaloneDevice)
    );
  }
}

HermesGatewaysModule.prototype.getReportsForDevice = function (deviceID) {
  var options;
  options = {
    url:
      Utils.apiUrlPrefix +
      "/hermes_gateways/standalonereportsforgateway/" +
      deviceID,
    method: "GET",
  };

  return Utils.httpRequestHandler(options).then(function (val) {
    return val.data;
  });
};

HermesGatewaysModule.prototype.getRemoteReportList = function (gateway) {
  log.info("**** Hermes Module reports: ", gateway.reports);
  return [];
};

HermesGatewaysModule.prototype.getGatewayByCommID = function (commID) {
  return _.find(this._data, function (device) {
    return device.comm_id == commID;
  });
};

HermesGatewaysModule.prototype.updateDevice = function (data) {
  if (Device.getDeviceMode(data) == "Gateway Device") {
    var gatewayToUpdate;
    gatewayToUpdate = _.find(this._data, function (gatewayDevices) {
      return gatewayDevices.comm_id == data.comm_id;
    });
    _.assign(gatewayToUpdate, data);
    return;
  }
};

// if device has registered to a gateway device, return gateway device ids
HermesGatewaysModule.prototype.getParentGatewayDevice = function (deviceId) {
  const allGatewaysData = this?._data ? Object.values(this._data) : [];
  const returnGatewayDeviceIds = {};

  if (allGatewaysData.length > 0) {
    _.each(allGatewaysData, (gateway) => {
      if (gateway.reports?.length > 0) {
        _.each(gateway.reports, (report) => {
          if (returnGatewayDeviceIds[report.device_id]) {
            returnGatewayDeviceIds[report.device_id].push(gateway.id);
          } else {
            returnGatewayDeviceIds[report.device_id] = [gateway.id];
          }
        });
      }
    });
    if (deviceId) {
      if (returnGatewayDeviceIds[deviceId]) {
        return returnGatewayDeviceIds[deviceId];
      } else {
        return [];
      }
    } else {
      return returnGatewayDeviceIds;
    }
  }
};

export default new HermesGatewaysModule();
