import React, { useState, useEffect } from "react";

// Internal
import Language from "sccLanguage";
import Utils from "sccUtils";
import Logo from "./Logo";
import Login from "./Login";
import TwoFactorLogin from "./TwoFactorLogin";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

function LoginPage(props) {
  manageDiscreetMode();

  const [version, setVersion] = useState("");
  const [languageCode, setLanguageCode] = useState("en");

  useEffect(() => {
    platformVersion(Utils, setVersion);

    document.body.classList.add("login");
    document.body.classList.add("loginPage");

    let queryParameters = window.location.search;

    let languageCode =
      !queryParameters || queryParameters === ""
        ? "en"
        : queryParameters.split("=")[1];

    setLanguageCode(languageCode);
  }, []);

  const translateText = (str) => {
    return Language.translateLogin(str, languageCode);
  };

  let showlogo =
    props.showlogo === undefined || props.showlogo === null
      ? true
      : props.showlogo;

  const initialLoginState = {
    step: props.step || 1,
    errorOnPage: 0,
    errorMessage: "",
    languageCode: languageCode,
    userId: "",
    tfaExpiry: "",
  };
  const [loginState, setLoginState] = useState(initialLoginState);

  function makeDiscreetModeChanges() {
    var style = document.getElementById("root").style;
    style.setProperty("--background", "black");

    document.title = Language.translate("Sign in");
  }

  function manageDiscreetMode() {
    return Utils.httpRequestHandler({
      method: "get",
      url: Utils.apiUrlPrefix + "/discreetMode_setting",
    })
      .then(function (response) {
        window.discreetMode =
          response?.data?.result["PLATFORM_DISCREETMODE"] === "true"
            ? true
            : false;
        return window.discreetMode;
      })
      .then(function (discreetMode) {
        if (discreetMode === true) makeDiscreetModeChanges();
        else
          document.title =
            Language.translate("Sign In | TITAN BY NORTAC") +
            " " +
            Language.translate("Platform");

        return Promise.resolve();
      });
  }

  function setErrorMessage(str) {
    if (str !== "") {
      setLoginState((p) => {
        return Object.assign({}, p, {
          errorOnPage: 1,
          errorMessage: str,
          languageCode: p.languageCode,
        });
      });
    } else {
      setLoginState((p) => {
        return Object.assign({}, p, {
          errorOnPage: 0,
          errorMessage: "",
          languageCode: p.languageCode,
        });
      });
    }
    return str;
  }

  const StepShow = () => {
    if (loginState.step === 1) {
      return (
        <Login
          translateText={translateText}
          setLoginState={setLoginState}
          setErrorMessage={setErrorMessage}
          loginState={loginState}
        ></Login>
      );
    } else if (loginState.step === 2) {
      return (
        <TwoFactorLogin
          translateText={translateText}
          setLoginState={setLoginState}
          setErrorMessage={setErrorMessage}
          loginState={loginState}
        ></TwoFactorLogin>
      );
    } else if (loginState.step === 3) {
      return (
        <ForgotPassword
          translateText={translateText}
          setLoginState={setLoginState}
          setErrorMessage={setErrorMessage}
          loginState={loginState}
        ></ForgotPassword>
      );
    } else if (loginState.step === 4) {
      return (
        <ResetPassword
          translateText={translateText}
          setLoginState={setLoginState}
          setErrorMessage={setErrorMessage}
          loginState={loginState}
        ></ResetPassword>
      );
    }
  };

  function platformVersion(Utils, setVersion) {
    return fetch(Utils.apiUrlPrefix + "/welcome")
      .then((res) => res.json())
      .then((data) => setVersion(data.version))
      .catch((e) => console.log(e));
  }

  return (
    <React.Fragment>
      {window.discreetMode ? (
        <p className="discreetModeVersionTag">
          {translateText("Version")}
          <span> {version}</span>
        </p>
      ) : null}
      <div className="login">
        <div
          id="errormsg"
          className={loginState.errorOnPage ? "errorLoginBox" : "hidden"}
        >
          <div className="alert">{loginState.errorMessage}</div>
        </div>
        {showlogo && !window.discreetMode ? (
          <Logo translateText={translateText}></Logo>
        ) : (
          ""
        )}
        {StepShow()}
      </div>
      {!window.discreetMode ? (
        <div className="copyright">
          {translateText("All Rights Reserved © 2021 NORTAC DEFENCE™")}
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default LoginPage;
