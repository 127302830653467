import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";
import Profile from "sccProfile";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";

const useStyles = makeStyles((theme) => ({
  profileBody: {
    padding: "8px",

    "& .toggleBtn": {
      margin: "7px",
    },

    "& .showChangePassword": {
      margin: "7px",
    },

    "& .hideChangePassword": {
      display: "none",
    },

    "& .MuiListItem-gutters": {
      paddingLeft: "6px",
      paddingRight: "6px",
    },

    "& .MuiListItem-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },

    "& .MuiListItemText-root": {
      marginTop: "0",
      marginBottom: "0",
    },

    "& .ProfileInfoListItemText-first": {
      background: theme.palette.colors.gray.main,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "6px",
      width: "50%",
      height: "24px",
    },

    "& .ProfileInfoListItemText-second": {
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "6px",
      width: "50%",
      marginTop: "-1px",
      marginLeft: "-1px",
      height: "24px",
    },

    "& .MuiChip-root": {
      marginRight: "5px",
    },

    "& .pull-right": {
      textAlign: "right",
      margin: "5px",
      "& .password-requirements": {
        bgcolor: theme.palette.colors.white.main,
        border: `1px solid ${theme.palette.colors.gray.dark}`,
        padding: "8px",
      },

      "& .Password-Requirements-Tooltip": {
        bgcolor: theme.palette.colors.white.main,
        color: theme.palette.colors.black.main,
      },
    },
  },
}));

function ModuleWrapperProfileBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [value, setValue] = useState(0);
  const [changePassword, setChangePassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(true);

  function getPermissionData(moduleName) {
    let listItemText = "";
    let listItemTextArr = [];
    if (Permission.getDataByModule("permissions")[moduleName].length > 1) {
      Permission.getDataByModule("permissions")[moduleName].forEach((item) => {
        listItemText = Permission.getActionName(item.action);
        listItemTextArr.push(listItemText);
      });
    } else if (
      Permission.getDataByModule("permissions")[moduleName][0].action === "all"
    ) {
      listItemText = Language.translate("All Actions");
      listItemTextArr.push(listItemText);
    } else {
      listItemText =
        Permission.getDataByModule("permissions")[moduleName][0].action;
      listItemTextArr.push(listItemText);
    }
    return listItemTextArr.map((item) => (
      <Chip key={item} size="small" label={Language.translate(item)} />
    ));
  }

  const profile = {
    id: 1,
    old_password: "",
    password: "",
    confirm: "",
  };

  function handleClick(e) {
    if (!changePassword) {
      setMwState((p) => ({
        ...p,
        moduleItemData: { ...profile },
      }));
    } else {
      setMwState((p) => ({
        ...p,
        moduleItemData: {},
      }));
    }
    setChangePassword(!changePassword);
  }

  const formik = useFormikContext();

  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  useEffect(() => {
    if (moduleItemData.old_password) {
      setMwState((p) => ({
        ...p,
        moduleFormHasChanges: true,
      }));
    } else {
      setMwState((p) => ({
        ...p,
        moduleFormHasChanges: false,
      }));
    }
  }, [moduleItemData]);

  function handleTabs(e, val) {
    setValue(val);
    if (val === 1) setShowChangePassword(false);
    else setShowChangePassword(true);
  }

  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  const classes = useStyles();

  return (
    <div className={classes.profileBody}>
      <Tabs
        value={value}
        onChange={handleTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          label="Personal Info"
          style={{ fontSize: 9 }}
          icon={<CustomIcon style={{ fontSize: 9 }} id="icon-user" />}
        />
        <Tab
          label="Permission"
          style={{ fontSize: 9 }}
          icon={<CustomIcon style={{ fontSize: 9 }} id="icon-lock" />}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <List>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={
                Language.translate("User").toUpperCase() +
                " " +
                Language.translate("ID") +
                ":"
              }
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Profile.get("id")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("Role").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Language.translate(Permission.get("title"))}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("Login Name").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Profile.get("username")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("First Name").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Profile.get("first_name")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("Last Name").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Profile.get("last_name")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("Email").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Profile.get("email")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("Company").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Profile.get(["client", "company"])}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-first"
              primary={Language.translate("Country").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="ProfileInfoListItemText-second"
              primary={Language.translate(Profile.get(["client", "country"]))}
            />
          </ListItem>
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          {Object.keys(Permission._dataByModule.permissions).map((item) => (
            <ListItem key={item}>
              <ListItemText
                md={6}
                className="ProfileInfoListItemText-first"
                primary={Language.translate(item).toUpperCase() + ":"}
              />
              <ListItemText
                md={6}
                className="ProfileInfoListItemText-second"
                primary={getPermissionData(item)}
              />
            </ListItem>
          ))}
        </List>
      </TabPanel>
      {showChangePassword && (
        <React.Fragment>
          <CustomButton
            onClick={handleClick}
            size="medium"
            color="command"
            variant="contained"
            className="toggleBtn"
          >
            {Language.translate("Change Password")}
            {changePassword ? <ExpandLess /> : <ExpandMore />}
          </CustomButton>
          <div
            className={
              changePassword ? "showChangePassword" : "hideChangePassword"
            }
          >
            <Grid container item xs={12}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <CustomTextField
                    label={Language.translate("Old Password")}
                    value={moduleItemData.old_password}
                    name="old_password"
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={
                      formik.touched.old_password &&
                      Boolean(formik.errors.old_password)
                    }
                    helperText={
                      formik.touched.old_password && formik.errors.old_password
                    }
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="pull-right">
                    <Tooltip
                      className="Password-Requirements-Tooltip"
                      title={
                        <div>
                          <h5>{Language.translate("Password Requirements")}</h5>
                          <Divider />
                          <ul>
                            <li>
                              {Language.translate("Numbers (0-9)")},{" "}
                              <b>{Language.translate("mandatory")}</b>
                            </li>
                            <li>
                              {Language.translate("Alphabets") + " (A-Z, a-z)"},{" "}
                              <b>{Language.translate("mandatory")}</b>
                            </li>
                            <li>
                              {Language.translate("Special characters") +
                                " (_.-)"}
                              , <b>{Language.translate("optional")}</b>
                            </li>
                            <li>
                              {Language.translate(
                                "Minimum length 7 and maximum length 20"
                              )}
                            </li>
                          </ul>
                        </div>
                      }
                      placement="top"
                    >
                      <button className="password-requirements">
                        {Language.translate(
                          "Password Requirements"
                        ).toUpperCase()}
                      </button>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    label={Language.translate("Password")}
                    value={moduleItemData.password}
                    name="password"
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    label={Language.translate("Confirm password")}
                    value={moduleItemData.confirm}
                    name="confirm"
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={
                      formik.touched.confirm && Boolean(formik.errors.confirm)
                    }
                    helperText={formik.touched.confirm && formik.errors.confirm}
                    type="password"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default ModuleWrapperProfileBody;
