import React from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function UserDetail(props) {
  const data = props.data;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container item className={classes.detailsContainer}>
        <Grid item xs={12} md={12}>
          {data.username ? (
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst splitIn3"
                primary={Language.translate("Login Name")}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.username}
              />
            </ListItem>
          ) : null}
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("First Name")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.first_name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("Last Name")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.last_name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst splitIn3"
              primary={Language.translate("Email")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.email}
            />
          </ListItem>
          {data.phone_number ? (
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst splitIn3"
                primary={Language.translate("Phone Number")}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.phone_number}
              />
            </ListItem>
          ) : null}
          {Permission.getAllRoles([
            data.role_id,
            Language.translate("title"),
          ]) ? (
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst splitIn3"
                primary={Language.translate("Role")}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={Permission.getAllRoles([
                  data.role_id,
                  Language.translate("title"),
                ])}
              />
            </ListItem>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
