import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

function CustomListItemCollapse(props) {
  const { id, title, count, error, color } = props;
  const { collapse, ...others } = props;
  const [open, setOpen] = React.useState(collapse);

  const handleClick = () => {
    setOpen(!open);
  };

  const useStyles = makeStyles((theme) => ({
    listItemContainer: {
      display: "block",
      border: `1px solid ${theme.palette.collapse.border.main}`,
      marginTop: 5,
      marginBottom: 5,
      "& .MuiListItem-root": {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    listItem: {
      backgroundColor: theme.palette.collapse.header.main,
      color: theme.palette.colors.white.main,
    },
    listItemText: {
      flex: "initial",
      paddingLeft: 10,
    },
    listItemIcon: {
      marginLeft: 20,
      "& .MuiBadge-badge": {
        color: theme.palette.colors.white.main,
        backgroundColor: theme.palette.chips.green.main,
      },
    },
    listItemContent: {
      padding: 0,
      "& .MuiListItem-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    listExpandIcon: {
      position: "absolute",
      right: 15,
    },
  }));
  const classes = useStyles();

  return (
    <span className={classes.listItemContainer}>
      <ListItem key={id} onClick={handleClick} className={classes.listItem}>
        <ListItemText className={classes.listItemText} primary={title} />
        <ListItemIcon className={classes.listItemIcon}>
          <Badge badgeContent={count} color={color} />
          {error && (
            <Chip
              label={error}
              style={{
                backgroundColor: "#B63E5A",
                color: "#fff",
                height: "20px",
              }}
              size="small"
            />
          )}
        </ListItemIcon>
        <span className={classes.listExpandIcon}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </span>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        {...others}
        className={classes.listItemContent}
      >
        {props.children}
      </Collapse>
    </span>
  );
}

export default CustomListItemCollapse;
