import { default as Module } from "sccModule";
const Permission = require("sccPermission");
const Utils = require("sccUtils").default;
const log = require("loglevel");
const _ = require("lodash");

/**
 * The base class for AdminDeviceModule
 *
 * @class AdminDeviceModule
 */
class AdminDeviceModule extends Module.Module {
  constructor() {
    super({
      moduleName: "admin_device",
      getterSetter: ["DeviceType"],
    });
  }
  init() {
    log.debug(
      "User for Device module",
      Permission.default.verify("admin_device", "add")
    );
    if (!Permission.default.verify("admin_device", "view")) {
      log.debug("User is not permitted for Device module");
      return Promise.resolve();
    }
    const $this = this;
    return this.loadDeviceType().then(function (deviceTypeResponse) {
      $this.setDeviceType(deviceTypeResponse.data.result);
      return Module.Module.prototype.init.call($this);
    });
  }
  loadDeviceType() {
    const options = {
      url: Utils.apiUrlPrefix + "/device/type",
      method: "GET",
    };
    return Utils.httpRequestHandler(options);
  }
  onSocketAdd(url, data) {
    const Client = require("sccClient").default;
    //updates customer data and adds a device to the client/customer editObj devices array
    Client.get(data.client_id).devices.push(data.id);
    Module.Module.prototype.onSocketAdd.call(this, url, data);
  }
  onSocketDelete(url, data) {
    var Client = require("sccClient").default;
    //updates customer data and removes a device from the client/customer editObj devices array
    Client.get(data.client_id).devices.pop(data.id);
    Module.Module.prototype.onSocketDelete.call(this, url, data);
  }
}

AdminDeviceModule.prototype.getDeviceMode = function (device) {
  if (_.isNull(device) || _.isUndefined(device)) return;
  var $this = this;
  return this.getDeviceType(device.type_id).modes[device.mode].title;
};

export default new AdminDeviceModule();
