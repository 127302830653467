import React, { Component } from "react";

// Material-UI
import Paper from "@material-ui/core/Paper";

// Internal
import ClusterDisplayButtonGroup from "./ClusterDisplayButtonGroup";
import ClusterDisplayHeader from "./ClusterDisplayHeader";
import ClusterList from "./ClusterList";
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import MinimizeContextProvider from "../context/MinimizeContext.js";

export default class ClusterDisplay extends Component {
  constructor() {
    super();
    this.state = {
      devices: [],
    };

    if (!ClusterDisplay.publicInstance) {
      ClusterDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      ClusterDisplay.publicInstance.setFeatureSelected();
    }, 0);
  }

  setFeatureSelected() {
    if (this.props.history === "1") {
      this.setState({ devices: HistoryOverlay.getClusterFeatureIds() });
    } else {
      this.setState({ devices: DeviceOverlay.getClusterFeatureIds() });
    }
  }

  render() {
    const { devices } = this.state;
    const history = this.props.history || 0;

    return (
      <MinimizeContextProvider>
        <Paper
          elevation={3}
          id={
            Number(history) === 1
              ? "olMapHistoryClusterPopup"
              : "olMapDeviceClusterPopup"
          }
        >
          {devices ? (
            <React.Fragment>
              <ClusterDisplayHeader items={devices} history={history} />
              <ClusterDisplayButtonGroup devices={devices} history={history} />
              <ClusterList devices={devices} history={history} />
            </React.Fragment>
          ) : null}
        </Paper>
      </MinimizeContextProvider>
    );
  }
}
