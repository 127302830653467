import { default as Module } from "sccModule";
const Profile = require("sccProfile").default;
const Utils = require("sccUtils").default;

/**
 * The base object for User module
 *
 */
class UserModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "user",
    };
    super(options);
  }
}

/**
 * overrides the default onSocketUpdate method from Module class
 */
UserModule.prototype.onSocketUpdate = function (url, data) {
  if (!data.id) throw new Error("Socket received data must have 'id'.");
  if (parseInt(Profile.get()?.id) === parseInt(data.id)) {
    const dateAfter1Week = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    Utils.setCookie("userrole", data.role_id, {
      path: "/",
      expires: dateAfter1Week,
    });
    var btn = document.getElementById("reloadHelper");
    btn.click();
  }
};

export default new UserModule();
