import React from "react";
import ResetPassword from "../../login/components/ResetPassword";

function ResetPasswordMain() {
  return (
    <div>
      <ResetPassword />
    </div>
  );
}

export default ResetPasswordMain;
