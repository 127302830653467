const $ = require("jquery");

/**
 * TimeUtils class
 *
 * @class
 */
function TimeUtils() {}

/*
 * Function: TimeUtils.getAge
 * Given an input timestamp in seconds,
 * computes the age using the current time
 *
 * Parameters:
 * timestamp - {Integer} input timestamp value in seconds
 *
 * Return:
 * {string} human readable age string
 */
TimeUtils.getAge = function (ts) {
  /* Panic Duration */

  var setNowtime = new Date();
  var tsNew = new Date(ts * 1000);
  var deltaZ = setNowtime.getTime() - tsNew.getTime();

  return TimeUtils.formatAge(deltaZ);
};

TimeUtils.formatAge = function (difference) {
  var dateStr = "";

  if (isNaN(difference)) {
    return "-";
  }

  if (difference < 0) {
    dateStr += "-";
    difference = -difference;
  }

  var daysRemaining = Math.floor(difference / 1000 / 60 / 60 / 24);
  var hoursRemaining = Math.floor(
    difference / 1000 / 60 / 60 - 24 * daysRemaining
  );
  var minutesRemaining = Math.floor(
    difference / 1000 / 60 - 24 * 60 * daysRemaining - 60 * hoursRemaining
  );
  var secondsRemaining = Math.floor(
    difference / 1000 -
      24 * 60 * 60 * daysRemaining -
      60 * 60 * hoursRemaining -
      60 * minutesRemaining
  );

  if (daysRemaining > 0) {
    dateStr += daysRemaining + "d ";
  }
  if (hoursRemaining > 0) {
    dateStr += hoursRemaining + "h ";
  }
  if (minutesRemaining > 0) {
    dateStr += minutesRemaining + "m ";
  }
  if (daysRemaining === 0) {
    dateStr += secondsRemaining + "s";
  }
  return dateStr;
};

TimeUtils.getTimeString = function (timestamp, dateTime) {
  if (timestamp == null || isNaN(timestamp)) {
    return "--";
  }

  timestamp *= 1000;

  var a = new Date(parseInt(timestamp));
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();

  // convert timestamp to 12 hour clock or 24 hour clock
  var timeFormat = $("#profile_clock_format").val(); // get time format from profile setting
  var ampm = "";
  if (timeFormat && timeFormat === "12") {
    // convert 24 hour to 12 hour with AM PM
    ampm = " am";
    if (hour > 11) {
      ampm = " pm";
    }
    if (hour > 12) {
      hour -= 12;
    }
    if (hour === 0) {
      hour = 12;
    }
  }
  // end of adding

  var lmin = min < 10 ? "0" + min : min;
  var lsec = sec < 10 ? "0" + sec : sec;
  var time = hour + ":" + lmin + ":" + lsec + ampm;
  date = month + " " + date + ", " + year;
  var dateTimeStr = date + " " + time;

  if (dateTime) {
    dateTime.time = time;
    dateTime.date = date;
  }

  return dateTimeStr;
};

/*
 * Function: TimeUtils.timeConverter
 * converts input timestamp value to human readable format
 *
 * Parameters:
 * timestamp - {Integer} input timestamp value in milliseconds
 *
 * Return:
 * {string} human readable date-time string
 */
TimeUtils.timeConverter = function (timestamp) {
  var a = new Date(parseInt(timestamp));
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();

  // added by Jing for convering timestamp to 12 hour clock or 24 hour clock
  var timeFormat = $("#profile_clock_format").val(); // get time format from profile setting
  var ampm = "";
  if (timeFormat && timeFormat === "12") {
    // convert 24 hour to 12 hour with AM PM
    ampm = " am";
    if (hour > 11) {
      ampm = " pm";
    }
    if (hour > 12) {
      hour -= 12;
    }
    if (hour === 0) {
      hour = 12;
    }
  }
  // end of adding

  var lmin = min < 10 ? "0" + min : min;
  var lsec = sec < 10 ? "0" + sec : sec;
  var time =
    month +
    " " +
    date +
    ", " +
    year +
    " " +
    hour +
    ":" +
    lmin +
    ":" +
    lsec +
    ampm;
  return time;
};

/**
 * gets the current timestamp in seconds
 */
TimeUtils.getTimestamp = function () {
  return Math.round(new Date().getTime() / 1000);
};

/**
 * get the cuurent date and time in a tag format (YYYY_MM_DD_hh_mm_ss)
 */
TimeUtils.getCurrentDateTimeAsATag = function () {
  const dateTime = new Date();
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1;
  const day = dateTime.getDate();
  const hour = dateTime.getHours();
  const minute = dateTime.getMinutes();
  const seconds = dateTime.getSeconds();

  let dateTimeTag =
    year + "_" + month + "_" + day + "_" + hour + "_" + minute + "_" + seconds;
  return dateTimeTag;
};

module.exports = TimeUtils;
