export const loginTranslations =
"en,fr,es\n\
Francais,English,Español\n\
Version,Version,Versión\n\
Forgot password?,Mot de passe oublié?,Olividó su contraseña?\n\
Forgot Password?,Mot de passe oublié?,Olividó su contraseña?\n\
Refresh,Rafraîchir,Actualizar\n\
Enter your username below to reset password.,Entrez votre nom d'utilisateur ci-dessous pour réinitialiser le mot de passe.\n\
Success!,Succès!,Éxito!\n\
An email was sent to you with a link to reset your password.,Un e-mail vous a été envoyé avec un lien pour réinitialiser votre mot de passe.,Introduzca su nombre de usario para restablecer la contraseña.\n\
Back to login,Retour à la page de connexion,Volver al inicio de sesión.\n\
Two Factor Authentication,Authentification à deux facteurs,Autenticación de dos factores\n\
Enter Two Factor Authentication Code to Sign In.,Entrez le code d'authentification à deux facteurs pour vous connecter.,Introduzca el código de autenticación de dos factores para iniciar sesión.\n\
SIGN IN,SE CONNECTER,INICIAR SESIÓN\n\
All Rights Reserved © 2021 NORTAC DEFENCE™,Tous droits réservés © 2021 NORTAC DEFENCE™,Todos los derechos reservados © 2021 NORTAC DEFENCE™\n\
Sign In | TITAN BY NORTAC,Connexion | TITAN BY NORTAC,Iniciar sesión | TITAN BY NORTAC\n\
Sign In,Se connecter,Iniciar sesión\n\
Sign in,Se connecter,Iniciar sesión\n\
Submit,Soumettre,Enviar\n\
Error resetting password,Erreur lors de la réinitialisation du mot de passe, Error al reingresar su contraseña.\n\
Checking,Vérification,Verificar\n\
Login error. Customer account has been deactivated,Erreur d'identification. Le compte client a été désactivé.,Error de inicio de sesión. Se ha desactivado la cuenta de cliente.\n\
Login error. Customer account has expired,Erreur d'identification. Le compte client a expire.,Error de inicio de sesión. La cuenta de client ha caducada.\n\
Login error. Invalid username or password,Erreur d'identification. Nom d'utilisateur ou mot de passe invalide.,Error de inicio de sesión. Nombre de usario o contraseña no válidos.\n\
Username,Nom d'utilisateur,Nombre de usario\n\
Password,Mot de passe,Contraseña\n\
Code,Code,Código\n\
Two Factor Authentication Failed.,L’authentification de deux facteurs a échoué.,Error de autenticación de dos factores.\n\
Error: User with username,Erreur: Mauvais nom d’utilisateur,Error: Usario con nombre de usario\n\
does not exist,n’existe pas,no existe\n\
Login error. User's Role Prohibits Access,Erreur de connexion. Le rôle de l’utilisateur interdit l’accès,Error de inicio de sesión. El rol del usario prohibe el acceso.\n\
Passwords are not identical,Les mots de passe ne sont pas identiques,Las contraseñas no son idénticas.\n\
Reset Password,Réinitialiser le mot de passe,Restablecer la contraseña\n\
Error resetting password,Erreur lors de la réinitialisation du mot de passe,Error al restablecer la contraseña\n\
Reset Password | TITAN BY NORTAC,Réinitialiser le mot de passe | TITAN BY NORTAC,Restablecer la contraseña | TITAN BY NORTAC\n\
Oops,Oops,Ups\n\
Not Found,Pas trouvé,No encontrado\n\
Sorry! an error has occured. Requested page not found.,Pardon! une erreur est survenue. Page demandée introuvable.,Lo siento! Ha ocurrido un error. Página solicitada no encontrada.\n\
Confirm Password,Confirmez le mot de passe,Confirmar contraseña\n\
Confirm password,Confirmez le mot de passe,Confirmar contraseña\n\
Register,S'inscrire,Inscribirse\n\
User Registration | TITAN BY NORTAC,Enregistrement de l'utilisateur | TITAN BY NORTAC,Registro de usuario | TITAN BY NORTAC\n\
User Registration,Enregistrement de l'utilisateur,Registro de usuario\n\
First Name,Prénom,Primer nombre\n\
Last Name,Nom de famille,Apellido\n\
Email,E-mail,Correo electrónico\n\
You Have Successfully Reset Your Password,Vous avez réussi à réinitialiser votre mot de passe,Has restablecido correctamente tu contraseña\n\
Login to TITAN BY NORTAC,Connectez-vous à TITAN BY NORTAC,Ingrese a TITAN BY NORTAC\n\
You Have Successfully Registered,Vous êtes enregistré avec succès,Te has registrado con éxito\n\
Name,Nom,Nombre\n\
Login Name,Identifiant,Nombre de inicio de sesión\n\
";