import React, { useState, useContext } from "react";

// Material-UI
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

// Internal
import { HistoryContext } from "../../HistoryContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  ReplayBarButtonGroup: {
    margin: "0",
    padding: "0",
  },

  iconSize: {
    fontSize: 18,
  },

  button: {
    width: 50,
    height: 30,
  },
}));

export default function ReplayBarButtonGroup() {
  const [state] = useContext(HistoryContext);
  const Historic = state.Historic;
  const [playInterval, setPlayInterval] = useState();
  const { updateKeysInState, sliderChanged, playEvent, sliderTicks } = state;
  const classes = useStyles();

  let eventFinished = state.eventFinished;
  let noPrevEvent = state.noPrevEvent;

  const handleRewind = () => {
    playPreviousReportEvent();
  };
  const handlePlay = () => {
    let newPlayInterval;
    // set auto skip interval to 2000 which avoid map changes too quick to show device icon
    if (Historic.playOptions.autoSkip) {
      newPlayInterval = setInterval(
        () => playAutoSkip(newPlayInterval),
        2000 / Historic.playSpeed
      );
    } else {
      newPlayInterval = setInterval(
        () => playNextReportEvent(newPlayInterval),
        2000 / Historic.playSpeed
      );
    }
    setPlayInterval(newPlayInterval);
    updateKeysInState({ playEvent: true, playInterval: newPlayInterval });
  };

  const handlePause = () => {
    pausePlay();
  };
  const handleForward = () => {
    playNextReportEvent();
  };

  function pausePlay() {
    clearInterval(playInterval);
    updateKeysInState({ playEvent: false, playInterval: null });
  }

  function playAutoSkip(playInterval) {
    if (Historic.currentTimestamp >= Historic.getEndTimestamp()) {
      clearInterval(playInterval);
      updateKeysInState({
        playEvent: false,
        eventFinished: true,
        playInterval: null,
      });

      return;
    }
    playNextReportEvent(playInterval);
  }

  // next button click
  function playNextReportEvent(playInterval) {
    const currentTime = Historic.currentTimestamp;

    let nextTime = [];

    sliderTicks.forEach((tick) => {
      if (tick.value > currentTime) {
        nextTime.push(tick.value);
      }
    });

    if (nextTime.length === 0) {
      clearInterval(playInterval);
      updateKeysInState({
        playEvent: false,
        eventFinished: true,
        noPrevEvent: false,
        playInterval: null,
        currentTimestamp: Historic.getEndTimestamp(),
      });
      //Forcing the replay to end the video
      Historic.currentTimestamp = Historic.getEndTimestamp();
      updateKeysInState(Historic);
      sliderChanged();
    } else {
      nextTime.sort((a, b) => a - b);
      updateKeysInState({
        noPrevEvent: false,
      });
      Historic.currentTimestamp = nextTime[0];
      sliderChanged();
    }
  }

  // previous button click
  function playPreviousReportEvent() {
    const currentTime = Historic.currentTimestamp;

    let lastTime = [];

    sliderTicks.forEach((tick) => {
      if (tick.value < currentTime) {
        lastTime.push(tick.value);
      }
    });

    updateKeysInState({ playEvent: false });
    if (lastTime.length === 0) {
      updateKeysInState({ noPrevEvent: true });
      //Forcing the replay to start the video
      Historic.currentTimestamp = Historic.getStartTimestamp();
      sliderChanged();
    } else {
      lastTime.sort((a, b) => b - a);
      Historic.currentTimestamp = lastTime[0];
      sliderChanged();
    }
  }

  return (
    <div className={classes.ReplayBarButtonGroup}>
      <ButtonGroup
        variant="contained"
        color="default"
        aria-label="contained primary button group"
      >
        <Button
          onClick={handleRewind}
          disabled={noPrevEvent}
          className={classes.button}
        >
          <SkipPreviousIcon className={classes.iconSize} />
        </Button>
        {!playEvent ? (
          <Button
            onClick={handlePlay}
            disabled={eventFinished}
            className={classes.button}
          >
            <PlayArrowIcon />
          </Button>
        ) : (
          <Button onClick={handlePause}>
            <PauseIcon className={classes.iconSize} />
          </Button>
        )}

        <Button
          onClick={handleForward}
          disabled={eventFinished}
          className={classes.button}
        >
          <SkipNextIcon className={classes.iconSize} />
        </Button>
      </ButtonGroup>
    </div>
  );
}
