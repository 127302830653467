import React from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";

// Internal
import CustomIcon from "@Components/CustomIcon";
import POIOverlay from "sccPoiOverlay";
import POIMenu from "sccPoiMenu";
import Language from "sccLanguage";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  poiClusterDisplayHeader: {
    backgroundColor: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: alpha(theme.palette.colors.black.main, 0.85),
  },

  poiClusterToolBar: {
    padding: "2px 0px",
    minHeight: 25,
    display: "flex",
    backgroundColor: alpha(theme.palette.colors.gray.light, 0.2),
  },

  poiAvatar: {
    backgroundColor: "transparent",
    color: theme.palette.colors.black.main,
    width: 28,
    height: 28,

    "& > svg": {
      fontSize: 12,
    },
  },

  poiClusterButton: {
    minHeight: 30,
    minWidth: 30,
    height: 30,
    width: 30,
    color: theme.palette.colors.black.main,

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: alpha(theme.palette.colors.black.main, 0.85),
    },
  },

  poiClusterterHeaderTitle: {
    flex: 1,
    fontSize: 18,
    padding: "0px 10px",
    color: alpha(theme.palette.colors.black.main, 0.85),
  },

  poiCloseIcon: {
    fontSize: "1.2rem",
    color: alpha(theme.palette.colors.black.main, 0.25),

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: theme.palette.colors.blue.dark,
    },
  },
}));

export default function ClusterDisplayHeader({ items }) {
  const handleClose = () => {
    POIOverlay.hideClusterPopup();
    POIOverlay.deselectFeature();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.poiClusterDisplayHeader}>
        <Toolbar className={classes.poiClusterToolBar}>
          <Avatar className={classes.poiAvatar}>
            <CustomIcon
              id={`icon-${POIMenu._options.imageClass}`}
              className={classes.poiAvatarIcon}
            />
          </Avatar>
          <Typography variant="h6" className={classes.poiClusterterHeaderTitle}>
            POI ({items.length})
          </Typography>
          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <Button
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.poiClusterButton}
            >
              <CloseIcon className={classes.poiCloseIcon} />
            </Button>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
